class BricksSummary {
    retailPrice;
    lowestPrice;
    lowestPriceWithoutRevolvingFund;
    highestPrice;
    highestPriceWithoutRevolvingFund;
    averagePrice;
    numberOfBricksInProperty;
    numberOfAvailableBricks;
    numberOfReservedBricks;
    numberOfMaximumBricksToPurchase;
    numberOfSellingBricksByTrader;
    listOfBricksOnSale;
    constructor(data) {
        this.retailPrice = data.retailPrice;
        this.lowestPrice = data.lowestPrice;
        this.lowestPriceWithoutRevolvingFund = data.lowestPriceWithoutRevolvingFund;
        this.highestPrice = data.highestPrice;
        this.highestPriceWithoutRevolvingFund = data.highestPriceWithoutRevolvingFund;
        this.averagePrice = data.averagePrice;
        this.numberOfBricksInProperty = data.numberOfBricksInProperty;
        this.numberOfAvailableBricks = data.numberOfAvailableBricks;
        this.numberOfReservedBricks = data.numberOfReservedBricks;
        this.numberOfMaximumBricksToPurchase = data.numberOfMaximumBricksToPurchase;
        this.numberOfSellingBricksByTrader = data.numberOfSellingBricksByTrader;
        this.listOfBricksOnSale = data.listOfBricksOnSale;
    }
}
export default BricksSummary;
