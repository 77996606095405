import autoBind from 'auto-bind';
import Contract from './Contract';
class UnsignedDocument extends Contract {
    _isSigned = false;
    constructor(file, type) {
        super(type, file);
        autoBind(this);
    }
    get contract() {
        if (!(this.file instanceof Blob))
            throw new Error();
        return this.file;
    }
    get isSigned() {
        return this._isSigned;
    }
}
export default UnsignedDocument;
