// eslint-disable-next-line max-classes-per-file
import GatewayAPI from '../GatewayAPI';
import APIError from '../../errors/APIError';
import NetworkError from '../../errors/NetworkError';

const NEIGHBORHOOD_NOT_FOUND = 'NEIGHBORHOOD_NOT_FOUND';

// TODO: Discuss where the errors should be declared
class LegacyEnrollmentError extends APIError {
  constructor(...args) {
    super(...args);
    this.name = 'LegacyEnrollmentError';
  }

  isNeighborhoodNotFound() {
    return this._code === NEIGHBORHOOD_NOT_FOUND;
  }
}

// TODO: Add tests
class LegacyEnrolmentAPI extends GatewayAPI {
  async getNeighborhoodsByZipCode(zipCode) {
    try {
      const { data } = await this._client.get('/enrollment/neighborhoods', { params: { zipCode } });

      return data;
    } catch (error) {
      return this._handleError(error, LegacyEnrollmentError);
    }
  }

  _handleError(error) {
    if (!error.response) {
      throw new NetworkError();
    }

    const { response } = error;
    const { code } = response.data;

    throw new LegacyEnrollmentError(code);
  }
}

export default LegacyEnrolmentAPI;
