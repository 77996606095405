import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import { Paragraph, Subtitle } from '@components/Styled';
import { Button } from '@components';
import { VerificationWrapper } from './ForgotPassword.style';
const UpdatePasswordSuccess = () => {
    const navigate = useNavigate();
    return (_jsxs(Fragment, { children: [_jsx(Subtitle, { children: "Has actualizado tu contrase\u00F1a con \u00E9xito" }), _jsxs(VerificationWrapper, { children: [_jsx(Paragraph, { children: "Tu contrase\u00F1a se ha actualizado correctamente. Ahora puedes iniciar sesi\u00F3n y continuar invirtiendo de forma segura." }), _jsx(Button, { onClick: () => navigate('/login'), children: "Ir a iniciar sesion" })] })] }));
};
export default UpdatePasswordSuccess;
