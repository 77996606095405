import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../../Icon';

import './style.scss';

const BackButton = props => {
  const {
    hasContrast,
    onClick,
    text,
    tid
  } = props;

  return (
    <button
      id="back-button"
      tid={tid}
      styleName="back-button"
      type="button"
      onClick={onClick}
    >
      <Icon
        name={!hasContrast ? 'icBackFilled' : 'icBackWhite'}
        alt="Botón Regresar"
        height="28"
        width="28"
      />
      <span>{text}</span>
    </button>
  );
};

BackButton.defaultProps = {
  hasContrast: false,
  tid: ''
};

BackButton.propTypes = {
  hasContrast: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  tid: PropTypes.string
};

export default BackButton;
