import { jsx as _jsx } from "react/jsx-runtime";
import { PROPERTY_TYPES } from '@constants';
import { Paragraph } from '@components/Styled';
import { colors } from '@components/Styled/mixins';
import { Badge } from './PropertyTypeBadge.style';
const propertyTypeColors = {
    OFFICE: colors.bg_blue_900,
    COMMERCIAL: colors.bg_orange_500,
    INDUSTRIAL: colors.bg_green_800,
    HOUSING: colors.bg_blue_300
};
const PropertyTypeBadge = ({ propertyType }) => (_jsx(Badge, { backgroundColor: propertyTypeColors[propertyType], children: _jsx(Paragraph, { color: "white", children: PROPERTY_TYPES[propertyType] }) }));
export default PropertyTypeBadge;
