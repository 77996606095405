const codeAreaCountries = [
  { label: 'México (+52)', code: '+52', id: 'MEX' },
  { label: 'Estados Unidos (+1)', code: '+1', id: 'USA' }
  // { label: 'Argentina (+54)', code: '+54', id: 'ARG' },
  // { label: 'Canadá (+1)', code: '+1', id: 'CAN' },
  // { label: 'Chile (+56)', code: '+56', id: 'CHL' },
  // { label: 'Colombia (+57)', code: '+57', id: 'COL' },
  // { label: 'España (+34)', code: '+34', id: 'ESP' },
  // { label: 'Guatemala (+502)', code: '+502', id: 'GTM' },
  // { label: 'Perú (+51)', code: '+51', id: 'PER' }
  // { label: 'Afghanistan (+93)', code: '+93', id: 'AFG' },
  // { label: 'Albania (+355)', code: '+355', id: 'ALB' },
  // { label: 'Andorra (+376)', code: '+376', id: 'AND' },
  // { label: 'Angola (+244)', code: '+244', id: 'AGO' },
  // { label: 'Antigua y Barbuda (+1)', code: '+1', id: 'ATG' },
  // { label: 'Arabia Saudita (+966)', code: '+966', id: 'SAU' },
  // { label: 'Algeria (+213)', code: '+213', id: 'DZA' },
  // { label: 'Armenia (+374)', code: '+374', id: 'ARM' },
  // { label: 'Australia (+61)', code: '+61', id: 'AUS' },
  // { label: 'Austria (+43)', code: '+43', id: 'AUT' },
  // { label: 'Azerbaiyán (+994)', code: '+994', id: 'AZE' },
  // { label: 'Bahamas (+1)', code: '+1', id: 'BHS' },
  // { label: 'Bangladés (+880)', code: '+880', id: 'BGD' },
  // { label: 'Barbados (+1)', code: '+1', id: 'BRB' },
  // { label: 'Bélgica (+32)', code: '+32', id: 'BEL' },
  // { label: 'Belice (+501)', code: '+501', id: 'BLZ' },
  // { label: 'Benín (+229)', code: '+229', id: 'BEN' },
  // { label: 'Bolivia (+591)', code: '+591', id: 'BOL' },
  // { label: 'Bosnia y Herzegovina (+387)', code: '+387', id: 'BIH' },
  // { label: 'Botsuana (+267)', code: '+267', id: 'BWA' },
  // { label: 'Brasil (+55)', code: '+55', id: 'BRA' },
  // { label: 'Brunéi (+673)', code: '+673', id: 'BRN' },
  // { label: 'Bulgaria (+359)', code: '+359', id: 'BGR' },
  // { label: 'Burkina Faso (+226)', code: '+226', id: 'BFA' },
  // { label: 'Burundi (+257)', code: '+257', id: 'BDI' },
  // { label: 'Cabo Verde (+238)', code: '+238', id: 'CPV' },
  // { label: 'Camboya (+855)', code: '+855', id: 'KHM' },
  // { label: 'Camerún (+237)', code: '+237', id: 'CMR' },
  // { label: 'Chad (+235)', code: '+235', id: 'TCD' },
  // { label: 'China (+86)', code: '+86', id: 'CHN' },
  // { label: 'Ciudad del Vaticano (+379)', code: '+379', id: 'VAT' },
  // { label: 'Comoras (+269)', code: '+269', id: 'COM' },
  // { label: 'Corea del Norte (+850)', code: '+850', id: 'PRK' },
  // { label: 'Corea del Sur (+82)', code: '+82', id: 'KOR' },
  // { label: 'Costa de Marfil (+225)', code: '+225', id: 'CIV' },
  // { label: 'Costa Rica (+506)', code: '+506', id: 'CRI' },
  // { label: 'Croacia (+385)', code: '+385', id: 'HRV' },
  // { label: 'Cuba (+53)', code: '+53', id: 'CUB' },
  // { label: 'Dinamarca (+45)', code: '+45', id: 'DNK' },
  // { label: 'Dominica (+1)', code: '+1', id: 'DMA' },
  // { label: 'Ecuador (+593)', code: '+593', id: 'ECU' },
  // { label: 'Egipto (+20)', code: '+20', id: 'EGY' },
  // { label: 'El Salvador (+503)', code: '+503', id: 'SLV' },
  // { label: 'Emiratos Árabes Unidos (+971)', code: '+971', id: 'ARE' },
  // { label: 'Eritrea (+291)', code: '+291', id: 'ERI' },
  // { label: 'Eslovaquia (+421)', code: '+421', id: 'SVK' },
  // { label: 'Eslovenia (+386)', code: '+386', id: 'SVN' },
  // { label: 'Estonia (+372)', code: '+372', id: 'EST' },
  // { label: 'Etiopía (+251)', code: '+251', id: 'ETH' },
  // { label: 'Filipinas (+63)', code: '+63', id: 'PHL' },
  // { label: 'Finlandia (+358)', code: '+358', id: 'FIN' },
  // { label: 'Fiji (+679)', code: '+679', id: 'FJI' },
  // { label: 'Francia (+33)', code: '+33', id: 'FRA' },
  // { label: 'Gabón (+241)', code: '+241', id: 'GAB' },
  // { label: 'Gambia (+220)', code: '+220', id: 'GMB' },
  // { label: 'Georgia (+995)', code: '+995', id: 'GEO' },
  // { label: 'Ghana (+233)', code: '+233', id: 'GHA' },
  // { label: 'Granada (+34)', code: '+34', id: 'GRD' },
  // { label: 'Grecia (+30)', code: '+30', id: 'GRC' },
  // { label: 'Guyana (+592)', code: '+592', id: 'GUY' },
  // { label: 'Guinea (+224)', code: '+224', id: 'GIN' },
  // { label: 'Guinea ecuatorial (+240)', code: '+240', id: 'GNQ' },
  // { label: 'Guinea-Bisáu (+245)', code: '+245', id: 'GNB' },
  // { label: 'Haití (+509)', code: '+509', id: 'HTI' },
  // { label: 'Honduras (+504)', code: '+504', id: 'HND' },
  // { label: 'Hungría (+36)', code: '+36', id: 'HUN' },
  // { label: 'India (+91)', code: '+91', id: 'IND' },
  // { label: 'Indonesia (+62)', code: '+62', id: 'IDN' },
  // { label: 'Irak (+964)', code: '+964', id: 'IRQ' },
  // { label: 'Irán (+98)', code: '+98', id: 'IRN' },
  // { label: 'Irlanda (+353)', code: '+353', id: 'IRL' },
  // { label: 'Islandia (+354)', code: '+354', id: 'ISL' },
  // { label: 'Islas Marshall (+692)', code: '+692', id: 'MHL' },
  // { label: 'Islas Salomón (+677)', code: '+677', id: 'SLB' },
  // { label: 'Israel (+972)', code: '+972', id: 'ISR' },
  // { label: 'Italia (+39)', code: '+39', id: 'ITA' },
  // { label: 'Jamaica (+1)', code: '+1', id: 'JAM' },
  // { label: 'Japón (+81)', code: '+81', id: 'JPN' },
  // { label: 'Jordania (+962)', code: '+962', id: 'JOR' },
  // { label: 'Kazajistán (+7)', code: '+7', id: 'KAZ' },
  // { label: 'Kenia (+254)', code: '+254', id: 'KEN' },
  // { label: 'Kirguistán (+996)', code: '+996', id: 'KGZ' },
  // { label: 'Kiribati (+686)', code: '+686', id: 'KIR' },
  // { label: 'Kuwait (+965)', code: '+965', id: 'KWT' },
  // { label: 'Laos (+856)', code: '+856', id: 'LAO' },
  // { label: 'Lesoto (+266)', code: '+266', id: 'LSO' },
  // { label: 'Letonia (+371)', code: '+371', id: 'LVA' },
  // { label: 'Líbano (+961)', code: '+961', id: 'LBN' },
  // { label: 'Liberia (+231)', code: '+231', id: 'LBR' },
  // { label: 'Libia (+218)', code: '+218', id: 'LBY' },
  // { label: 'Liechtenstein (+423)', code: '+423', id: 'LIE' },
  // { label: 'Lituania (+370)', code: '+370', id: 'LTU' },
  // { label: 'Luxemburgo (+352)', code: '+352', id: 'LUX' },
  // { label: 'Macedonia del Norte (+389)', code: '+389', id: 'MKD' },
  // { label: 'Madagascar (+261)', code: '+261', id: 'MDG' },
  // { label: 'Malasia (+60)', code: '+60', id: 'MYS' },
  // { label: 'Malaui (+265)', code: '+265', id: 'MWI' },
  // { label: 'Maldivas (+960)', code: '+960', id: 'MDV' },
  // { label: 'Malí (+223)', code: '+223', id: 'MLI' },
  // { label: 'Malta (+356)', code: '+356', id: 'MLT' },
  // { label: 'Marruecos (+212)', code: '+212', id: 'MAR' },
  // { label: 'Mauricio (+230)', code: '+230', id: 'MUS' },
  // { label: 'Mauritania (+222)', code: '+222', id: 'MRT' },
  // { label: 'Micronesia (+691)', code: '+691', id: 'FSM' },
  // { label: 'Moldavia (+373)', code: '+373', id: 'MDA' },
  // { label: 'Mónaco (+377)', code: '+377', id: 'MCO' },
  // { label: 'Mongolia (+976)', code: '+976', id: 'MNG' },
  // { label: 'Montenegro (+382)', code: '+382', id: 'MNE' },
  // { label: 'Mozambique (+258)', code: '+258', id: 'MOZ' },
  // { label: 'Namibia (+264)', code: '+264', id: 'NAM' },
  // { label: 'Nauru (+674)', code: '+674', id: 'NRU' },
  // { label: 'Nepal (+977)', code: '+977', id: 'NPL' },
  // { label: 'Nicaragua (+505)', code: '+505', id: 'NIC' },
  // { label: 'Níger (+227)', code: '+227', id: 'NER' },
  // { label: 'Nigeria (+234)', code: '+234', id: 'NGA' },
  // { label: 'Noruega (+47)', code: '+47', id: 'NOR' },
  // { label: 'Nueva Zelanda (+64)', code: '+64', id: 'NZL' },
  // { label: 'Omán (+968)', code: '+968', id: 'OMN' },
  // { label: 'Países Bajos (+31)', code: '+31', id: 'NLD' },
  // { label: 'Pakistán (+92)', code: '+92', id: 'PAK' },
  // { label: 'Palaos (+680)', code: '+680', id: 'PLW' },
  // { label: 'Panamá (+507)', code: '+507', id: 'PAN' },
  // { label: 'Papúa Nueva Guinea (+675)', code: '+675', id: 'PNG' },
  // { label: 'Paraguay (+595)', code: '+595', id: 'PRY' },

  // { label: 'Polonia (+48)', code: '+48', id: 'POL' },
  // { label: 'Portugal (+351)', code: '+351', id: 'PRT' },
  // { label: 'Reino Unido (+44)', code: '+44', id: 'GBR' },
  // { label: 'República Centroafricana (+236)', code: '+236', id: 'CAF' },
  // { label: 'República Checa (+420)', code: '+420', id: 'CZE' },
  // { label: 'República del Congo (+242)', code: '+242', id: 'COG' },
  // {
  //   label: 'República Democrática del Congo (+243)',
  //   code: '+243',
  //   id: 'COD'
  // },
  // { label: 'República Dominicana (+1)', code: '+1', id: 'DOM' },
  // { label: 'República Sudafricana (+27)', code: '+27', id: 'ZAF' },
  // { label: 'Ruanda (+250)', code: '+250', id: 'RWA' },
  // { label: 'Rumanía (+40)', code: '+40', id: 'ROU' },
  // { label: 'Rusia (+7)', code: '+7', id: 'RUS' },
  // { label: 'Samoa (+685)', code: '+685', id: 'WSM' },
  // { label: 'San Cristóbal y Nieves (+1)', code: '+1', id: 'KNA' },
  // { label: 'San Marino (+378)', code: '+378', id: 'SMR' },
  // { label: 'San Vicente y las Granadinas (+1)', code: '+1', id: 'VCT' },
  // { label: 'Santa Lucía (+1)', code: '+1', id: 'LCA' },
  // { label: 'Santo Tomé y Príncipe (+239)', code: '+239', id: 'STP' },
  // { label: 'Senegal (+221)', code: '+221', id: 'SEN' },
  // { label: 'Serbia (+381)', code: '+381', id: 'SRB' },
  // { label: 'Seychelles (+248)', code: '+248', id: 'SYC' },
  // { label: 'Sierra Leona (+232)', code: '+232', id: 'SLE' },
  // { label: 'Singapur (+65)', code: '+65', id: 'SGP' },
  // { label: 'Siria (+963)', code: '+963', id: 'SYR' },
  // { label: 'Somalia (+252)', code: '+252', id: 'SOM' },
  // { label: 'Sri Lanka (+94)', code: '+94', id: 'LKA' },
  // { label: 'Suazilandia (+268)', code: '+268', id: 'SWZ' },
  // { label: 'Sudán (+249)', code: '+249', id: 'SDN' },
  // { label: 'Sudán del Sur (+211)', code: '+211', id: 'SSD' },
  // { label: 'Suecia (+46)', code: '+46', id: 'SWE' },
  // { label: 'Suiza (+41)', code: '+41', id: 'CHE' },
  // { label: 'Surinam (+597)', code: '+597', id: 'SUR' },
  // { label: 'Tailandia (+66)', code: '+66', id: 'THA' },
  // { label: 'Tanzania (+255)', code: '+255', id: 'TZA' },
  // { label: 'Tayikistán (+992)', code: '+992', id: 'TJK' },
  // { label: 'Timor Oriental (+670)', code: '+670', id: 'TLS' },
  // { label: 'Togo (+228)', code: '+228', id: 'TGO' },
  // { label: 'Tonga (+676)', code: '+676', id: 'TON' },
  // { label: 'Trinidad y Tobago (+868)', code: '+868', id: 'TTO' },
  // { label: 'Túnez (+216)', code: '+216', id: 'TUN' },
  // { label: 'Turkmenistán (+993)', code: '+993', id: 'TKM' },
  // { label: 'Turquía (+90)', code: '+90', id: 'TUR' },
  // { label: 'Tuvalu (+688)', code: '+688', id: 'TUV' },
  // { label: 'Ucrania (+380)', code: '+380', id: 'UKR' },
  // { label: 'Uganda (+256)', code: '+256', id: 'UGA' },
  // { label: 'Uruguay (+598)', code: '+598', id: 'URY' },
  // { label: 'Uzbekistán (+998)', code: '+998', id: 'UZB' },
  // { label: 'Vanuatu (+678)', code: '+678', id: 'VUT' },
  // { label: 'Venezuela (+58)', code: '+58', id: 'VEN' },
  // { label: 'Vietnam (+84)', code: '+84', id: 'VNM' },
  // { label: 'Yemen (+967)', code: '+967', id: 'YEM' },
  // { label: 'Yibuti (+253)', code: '+253', id: 'DJI' },
  // { label: 'Zambia (+260)', code: '+260', id: 'ZMB' },
  // { label: 'Zimbabue (+263)', code: '+263', id: 'ZWE' }
];

export default codeAreaCountries;
