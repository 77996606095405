import { ANNUAL_UPDATE_LOG_STATUSES } from '@constants';
class AnnualUpdateLog {
    status;
    updatedAt;
    constructor(data) {
        this.status = data?.status
            ?? ANNUAL_UPDATE_LOG_STATUSES.dataConfirmed;
        this.updatedAt = data?.updatedAt;
    }
    isReadyToUpdate() {
        return this.status === ANNUAL_UPDATE_LOG_STATUSES.readyToUpdate;
    }
    isDataConfirmed() {
        return this.status === ANNUAL_UPDATE_LOG_STATUSES.dataConfirmed;
    }
}
export default AnnualUpdateLog;
