import axios from 'axios';

class APIClient {
  _headers = {
    'Content-Type': 'application/json'
  };

  constructor(baseURL, token = null) {
    const headers = {};

    if (token) {
      headers.Authorization = token.authorizationString;
    }

    this._client = axios.create({ baseURL, headers });
  }

  get(endpoint, options) {
    return this._client.get(endpoint, options);
  }

  post(endpoint, payload, optionalHeaders = {}) {
    return this._client.post(endpoint, payload, this._getHeaders(optionalHeaders));
  }

  put(endpoint, payload = undefined, optionalHeaders = {}) {
    return this._client.put(endpoint, payload, this._getHeaders(optionalHeaders));
  }

  patch(endpoint, payload = undefined, optionalHeaders = {}) {
    return this._client.patch(endpoint, payload, this._getHeaders(optionalHeaders));
  }

  delete(endpoint) {
    return this._client.delete(endpoint);
  }

  getBaseUrl() {
    return this._client.defaults.baseURL;
  }

  getToken() {
    return this._client.defaults.headers?.Authorization;
  }

  _getHeaders(optionalHeaders) {
    return { ...this._headers, ...optionalHeaders };
  }
}

export default APIClient;
