import React from 'react';

import {
  ActionMediaContainer,
  ContentMediaContainer,
  MediaContainer,
  TitleMediaContainer
} from '@components';

import { generalDataViewImage } from '../../../../../assets/images/EnrollmentStepImages';
import { DescriptionText, TitleText } from '../../EnrollmentMediaContainer.style';

const GENERAL_DATA_IMAGE_ALT = 'Persona con una llave que abre un ladrillo';

const InvestorFullNameMediaContainer = () => (
  <MediaContainer>
    <TitleMediaContainer>
      <TitleText>Tu eres el auténtico dueño</TitleText>
    </TitleMediaContainer>

    <ContentMediaContainer>
      <img src={generalDataViewImage} alt={GENERAL_DATA_IMAGE_ALT} />
    </ContentMediaContainer>

    <ActionMediaContainer>
      <DescriptionText>
        Los Ladrillos en los que inviertes son 100% de tu propiedad y pueden generar ingresos a
        través de rentas. Además, puedes venderlos y obtener una ganancia con la plusvalía o
        incluso heredarlos a tus seres queridos.
      </DescriptionText>
    </ActionMediaContainer>
  </MediaContainer>
);

export default InvestorFullNameMediaContainer;
