import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from '@lib/authentication';
import RenewSessionModal from './RenewSessionModal';
import SessionExpiringSoonModal from './SessionExpiringSoonModal';
const FREE_NAVIGATION_TIME_TO_EXPIRE = 300_000;
const REMAINING_SESSION_TIME_TO_EXPIRE = 60_000;
const SessionTimeManager = () => {
    const { refresh, token, lastUpdatedSession } = useAuth();
    const [isDisplayLoginModal, setIsDisplayLoginModal] = useState(false);
    const [isDisplaySessionExpiringSoonModal, setIsDisplaySessionExpiringSoonModal] = useState(false);
    const [tokenValue, setTokenValue] = useState(token?.value);
    const onPrompt = () => {
        setIsDisplaySessionExpiringSoonModal(true);
    };
    const onIdle = () => {
        setIsDisplaySessionExpiringSoonModal(false);
        setIsDisplayLoginModal(true);
    };
    const idleTimer = useIdleTimer({
        onPrompt,
        onIdle,
        timeout: FREE_NAVIGATION_TIME_TO_EXPIRE,
        promptBeforeIdle: REMAINING_SESSION_TIME_TO_EXPIRE,
        stopOnIdle: true,
        events: [],
        throttle: 500
    });
    const onSessionExpired = () => {
        setIsDisplaySessionExpiringSoonModal(false);
        setIsDisplayLoginModal(true);
    };
    const onContinueBrowsing = async () => {
        setIsDisplaySessionExpiringSoonModal(false);
        await refresh();
        idleTimer.reset();
    };
    useEffect(() => {
        idleTimer.reset();
    }, [lastUpdatedSession]);
    useEffect(() => {
        if (token && idleTimer && tokenValue !== token?.value) {
            setTokenValue(token?.value);
            idleTimer.activate();
        }
    }, [token, idleTimer]);
    useEffect(() => {
        const interval = setInterval(() => {
            // This part is when the device is sleep or the screen become sleep
            if (idleTimer.getRemainingTime() <= 0 && !isDisplayLoginModal) {
                setIsDisplayLoginModal(true);
            }
            else if (idleTimer.getRemainingTime() / 1000 === 60 && !isDisplaySessionExpiringSoonModal) {
                setIsDisplaySessionExpiringSoonModal(true);
            }
        }, 5_000);
        return () => {
            clearInterval(interval);
        };
    }, [idleTimer]);
    return (_jsxs(Fragment, { children: [(isDisplaySessionExpiringSoonModal && !isDisplayLoginModal) && (_jsx(SessionExpiringSoonModal, { onContinueBrowsing: onContinueBrowsing, onSessionExpired: onSessionExpired })), _jsx(RenewSessionModal, { isOpen: isDisplayLoginModal, onClose: () => setIsDisplayLoginModal(false) })] }));
};
export default SessionTimeManager;
