import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import { Subtitle } from '@components/Styled';
import { PasswordField, OneTimePassword, Button, RestrictionsPassword } from '@components';
import { OTPContainer, VerificationWrapper } from './ForgotPassword.style';
const PasswordHandler = ({ isUpdatingPassword, resendCode, handleSubmitPassword }) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(false);
    const isReadyToSubmit = () => isValidPassword
        && verificationCode?.length === 6
        && confirmPassword === newPassword
        && [...verificationCode].every(digit => digit !== '');
    return (_jsxs(Fragment, { children: [_jsx(Subtitle, { children: "Actualiza tu contrase\u00F1a" }), _jsx(OTPContainer, { children: _jsx(OneTimePassword, { resendOptions: ['email'], onChange: (code) => setVerificationCode(code), onSendCode: resendCode }) }), _jsxs(VerificationWrapper, { children: [_jsx(PasswordField, { id: "fp-new-password", placeholder: "Escribe una nueva contrase\u00F1a", label: "Nueva Contrase\u00F1a:", required: true, onChange: (event) => setNewPassword(event.target.value), value: newPassword, inputProps: { autoComplete: 'off' }, InputLabelProps: { requiredIndicator: 'asterisk' } }), newPassword.length > 0 && (_jsx(RestrictionsPassword, { password: newPassword, getValidation: (isValid) => setIsValidPassword(isValid) })), _jsx(PasswordField, { id: "fp-confirm-password", placeholder: "Escribe nuevamente la contrase\u00F1a", label: "Confirmar Contrase\u00F1a:", required: true, onChange: (event) => setConfirmPassword(event.target.value), value: confirmPassword, inputProps: { autoComplete: 'off' }, InputLabelProps: { requiredIndicator: 'asterisk' } }), _jsx(Button, { id: "forgot-password-submit-button", className: "margin-t-7", type: "submit", disabled: !isReadyToSubmit(), isLoading: isUpdatingPassword, onClick: () => handleSubmitPassword(verificationCode, newPassword), children: "Restablecer contrase\u00F1a" })] })] }));
};
export default PasswordHandler;
