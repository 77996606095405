import React from 'react';
import PropTypes from 'prop-types';

const Image = props => {
  const {
    alt,
    src,
    className,
    handleError,
  } = props;

  return (
    <img
      className={className}
      src={src}
      alt={alt}
      onError={handleError}
    />
  );
};

Image.defaultProps = {
  className: '',
  handleError: null,
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleError: PropTypes.func,
};

export default Image;
