import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Money } from '@types';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const IncomeReleaseWithoutInvoice = (props) => {
    const { notification } = props;
    const amountReceived = new Money(notification.params?.amountReceived);
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Ingresos liberados sin factura" }), _jsxs(Paragraph, { children: ["Has liberado", _jsx(Bold, { children: ` ${amountReceived.toString()} ` }), "de ingresos sin factura, cuando liberas tus ingresos sin comprobante fiscal es considerado un gasto no comprobable, por lo que 100 Ladrillos deber\u00E1 retener un 30% del valor de tus ingresos para el pago de impuestos."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default IncomeReleaseWithoutInvoice;
