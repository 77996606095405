class Address {
    city;
    municipality;
    state;
    country;
    externalNumber;
    internalNumber;
    neighborhood;
    typeOfRoad;
    street;
    zipCode;
    constructor(data) {
        this.city = data?.city;
        this.municipality = data?.municipality;
        this.state = data?.state;
        this.country = data?.country;
        this.externalNumber = data?.externalNumber;
        this.internalNumber = data?.internalNumber;
        this.neighborhood = data?.neighborhood;
        this.typeOfRoad = data?.typeOfRoad;
        this.street = data?.street;
        this.zipCode = data?.zipCode;
    }
    getCompleteAddress() {
        return `
      ${this.street}
      ${this.externalNumber}${this.internalNumber ? ` ${this.internalNumber}` : ''},
      Colonia ${this.neighborhood},
      ${this.municipality},
      C.P. ${this.zipCode},
      ${this.city},
      ${this.state}, ${this.country}.
    `;
    }
    hasAddress() {
        return Boolean(this.city
            && this.municipality
            && this.state
            && this.country
            && this.neighborhood
            && this.street
            && this.zipCode);
    }
}
export default Address;
