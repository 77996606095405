import { ForeignLeaseIncome } from '@entities/Account';

import { ForeignLeaseIncomesError, NetworkError } from '../errors';

import GatewayAPI from './GatewayAPI';

class ForeignLeaseIncomesAPI extends GatewayAPI {
  async getIncomes() {
    try {
      const { startDate, endDate } = this._getMonthRange();
      const { data } = await this._client.get(
        `/foreignLeaseIncomes?startDate=${startDate}&endDate=${endDate}`
      );

      return data.items.map(item => new ForeignLeaseIncome(item));
    } catch (error) {
      return this._handleError(error);
    }
  }

  _dateFormatterAsIso8061 = date => date.toISOString().substring(0, 10);

  _getMonthRange = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    return {
      startDate: this._dateFormatterAsIso8061(startDate),
      endDate: this._dateFormatterAsIso8061(endDate)
    };
  };

  _handleError = error => {
    if (!error.response) {
      throw new NetworkError();
    }

    const { response } = error;
    const { code } = response.data;

    throw new ForeignLeaseIncomesError(code);
  };
}

export default ForeignLeaseIncomesAPI;
