import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAPI, useRequest } from '@hooks';

import { useErrorReporter } from '@lib/errorReporter';
import { renderSomethingWentWrong } from '@utils';

import ActionText from '../ActionText';
import Button from '../Button';
import Modal from '../Modal';
import Text from '../Text';
import Title from '../Title';

import { ActionButtons, CancelProcessArea, ContentWrapper } from './CancelAccountUpgrade.style';

const CancelAccountUpgrade = () => {
  /** The dependency below is causing dependency cycles, for this reason the dependency is loaded
    * via require. Please, always avoid the use of require() calls in any other place under this
    * codebase unless you have a good reason to do so.
    *
    * We need to move the api module to the @lib module in order to remove the dependency cycle
    */
  const { useProfile } = require('@lib/profile');

  const errorReporter = useErrorReporter();
  const navigate = useNavigate();
  const { trader } = useAPI();
  const { reloadProfileData } = useProfile();

  const [showCancelUpgradeAccountModal, setShowCancelUpgradeAccountModal] = useState(false);

  const [
    cancelAccountLevelResponse,
    isCancelingAccountLevel,
    cancelAccountLevelError,
    cancelAccountLevelUpgrade
  ] = useRequest(trader.cancelAccountLevelUpgrade);

  const handleClose = () => {
    if (isCancelingAccountLevel) {
      return;
    }
    setShowCancelUpgradeAccountModal(false);
  };

  const handleCancelAccountUpgrade = async () => {
    navigate('/marketplace', { replace: true });

    await reloadProfileData();
  };

  useEffect(() => {
    if (cancelAccountLevelResponse && !cancelAccountLevelError) {
      handleCancelAccountUpgrade();
    } else if (cancelAccountLevelError) {
      errorReporter.critical(cancelAccountLevelError);
      renderSomethingWentWrong();
    }
  }, [cancelAccountLevelResponse, cancelAccountLevelError]);

  return (
    <Fragment>
      <CancelProcessArea>
        <Text>Aumentaré el límite de mi cuenta en un futuro. </Text>

        <ActionText
          id="cancel-confirm-account-data"
          color="info"
          disabled={isCancelingAccountLevel}
          onClick={() => setShowCancelUpgradeAccountModal(true)}
        >
          Suspender por ahora
        </ActionText>
      </CancelProcessArea>

      <Modal isOpen={showCancelUpgradeAccountModal} onClose={handleClose}>
        <ContentWrapper>
          <Title>
            ¿Deseas suspender el proceso para convertirte en usuario sin límites?
          </Title>

          <Text>
            Recuerda que puedes volver a empezar el proceso cuando desees.
            Estaremos aquí por si decides cambiar de opinión.
          </Text>
        </ContentWrapper>

        <ActionButtons>
          <Button
            id="cancel"
            color="secondary"
            disabled={isCancelingAccountLevel}
            onClick={handleClose}
          >
            Cancelar
          </Button>

          <Button
            id="suspend-upgrade-account"
            isLoading={isCancelingAccountLevel}
            onClick={cancelAccountLevelUpgrade}
          >
            Suspender
          </Button>
        </ActionButtons>
      </Modal>
    </Fragment>
  );
};

export default CancelAccountUpgrade;
