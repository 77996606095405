import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@components/Styled';
import { Icon, UpgradeAccountButton } from '@components';
import { useProfile } from '@lib/profile';
import withUpgradeAccountButton from '../../../hoc/withUpgradeAccountButton';
import NotificationsButton from './NotificationCenter/NotificationsButton';
import Navbar from './Navbar';
import { UserAvatar } from './Navbar.style';
const UpgradeButton = withUpgradeAccountButton(UpgradeAccountButton);
const PrivateNavbar = ({ onOpenMenu }) => {
    const { profile } = useProfile();
    return (_jsxs(Navbar, { onOpenMenu: onOpenMenu, children: [_jsx(UpgradeButton, { variant: "text" }), profile?.canBuy() && (_jsx(NotificationsButton, {})), profile?.isDoneRegistering() && (_jsxs(UserAvatar, { to: "/perfil", children: [_jsx(Icon, { name: "icAvatar", alt: "Avatar" }), _jsx(Text, { "data-heap-redact-text": true, children: profile.fullName || profile.email.address })] }))] }));
};
export default PrivateNavbar;
