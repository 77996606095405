import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { focusInput, regularExpressions as regex, renderSomethingWentWrong } from '@utils';
import { useAPI, useForm, useRequest } from '@hooks';
import { useErrorReporter } from '@lib/errorReporter';
import { useInsightsService } from '@lib/insightsService';
import { useAuth } from '@lib/authentication';
import {
  AdaptiveArea,
  Button,
  EmailField,
  Form,
  LegacyLink,
  Title
} from '@components';

import { AlternativeAction } from './Steps.styles';

const SIGNUP_URL = '/signup';

const stepOneSchema = {
  email: {
    required: true,
    format: {
      pattern: regex.emailFormat,
      message: 'No es un correo electrónico'
    }
  }
};

const ERROR_EMAIL_IS_NOT_REGISTERED = 'El correo electrónico no está registrado.';

const StepOne = ({ onSuccess }) => {
  const api = useAPI();
  const { clearSession } = useAuth();
  const errorReporter = useErrorReporter();
  const { authenticationEvent, reportInsight } = useInsightsService();
  const form = useForm({ initialValues: { email: '' }, schema: stepOneSchema });

  const [
    antiphishing,
    isLoadingAntiphishing,
    antiphishingError,
    getAntiphishing
  ] = useRequest(api.authentication.fetchAntiphishing);

  const mailInputRef = useRef(null);

  const onSubmit = () => {
    getAntiphishing(form.values.email);
  };

  const handleAntiphishingError = error => {
    if (error.isTraderNotFound()) {
      form.addError({
        field: 'email',
        message: ERROR_EMAIL_IS_NOT_REGISTERED
      });

      focusInput(mailInputRef);
    } else {
      errorReporter.critical(error);
      renderSomethingWentWrong();
    }
  };

  useEffect(() => {
    if (antiphishing && !antiphishingError) {
      clearSession();
      onSuccess(form.values.email, antiphishing);
    }
  }, [antiphishing]);

  useEffect(() => {
    if (antiphishingError) {
      reportInsight(authenticationEvent.emailError, antiphishingError);
      if (antiphishingError.name === 'AuthenticationError') {
        handleAntiphishingError(antiphishingError);
      } else if (antiphishingError) {
        errorReporter.critical(antiphishingError);
        renderSomethingWentWrong();
      }
    }
  }, [antiphishingError]);

  return (
    <Fragment>
      <Title>Iniciar sesión</Title>
      <Form id="login-email" className="login-email" onSubmit={form.handleSubmit(onSubmit)}>
        <EmailField
          inputProps={{ ref: mailInputRef }}
          id="email"
          focus
          label="¿Cuál es tu correo electrónico?"
          placeholder="Ejemplo: tu@correo.com"
          value={form.values.email}
          {...form.fieldProps('email')}
        />

        <AlternativeAction hideOnDesktop>
          {'¿Aún no tienes cuenta? '}
          <LegacyLink to={SIGNUP_URL}>Crea tu cuenta</LegacyLink>
          .
        </AlternativeAction>

        <AdaptiveArea>
          <Button
            id="continue"
            type="submit"
            disabled={!form.isValid()}
            isLoading={isLoadingAntiphishing}
          >
            Siguiente
          </Button>
        </AdaptiveArea>
      </Form>
    </Fragment>
  );
};

StepOne.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default StepOne;
