import styled from 'styled-components';

import { gradientBlueAndPurple } from '@components/Styled/mixins';

import Text from '../Text';

const Banner = styled.div`
  background: ${gradientBlueAndPurple};
  padding: ${({ theme }) => theme.spacing(3)};
  display: grid;
  gap: ${({ theme }) => theme.spacing(3)};
  grid-template-columns: ${({ theme }) => theme.spacing(15)} 3fr 2fr;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(0.5)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    grid-template-columns: 1fr;
    justify-content: center;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }

  > p:first-child {
    font-size: ${({ theme }) => theme.toRem(16)};
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  }

  > p:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    > p {
      text-align: center;
    }
  }
`;

const LimitText = styled(Text)`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.toRem(14)};
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h3 {
    font-size: ${({ theme }) => theme.toRem(20)};
    text-align: center;
  }

  > p {
    color: ${({ theme }) => theme.palette.grayH};
    text-align: center;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    grid-row: 2;
  }
`;

export {
  ActionWrapper,
  Banner,
  IconWrapper,
  InfoWrapper,
  LimitText
};
