import autoBind from 'auto-bind';

import EventsReporter from '../EventsReporter';

import SignUpEvents from './SignUpEvents';

class SignUpEventsReporter extends EventsReporter {
  constructor(insightsService) {
    super(insightsService);
    this._event = new SignUpEvents();

    autoBind(this);
  }

  start({ UTM_SOURCE = null, UTM_MEDIUM = null, UTM_CAMPAIGN = null }) {
    const properties = {
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN
    };

    const event = this._event.started(properties);

    this._insightsService.sendEvent(event);
  }

  complete({ UTM_SOURCE = null, UTM_MEDIUM = null, UTM_CAMPAIGN = null }) {
    const properties = {
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN
    };

    const event = this._event.completed(properties);

    this._insightsService.sendEvent(event);
  }

  buttonClick({
    buttonTitle = null,
    URL = null,
    UTM_SOURCE = null,
    UTM_MEDIUM = null,
    UTM_CAMPAIGN = null
  }) {
    const properties = {
      buttonTitle,
      URL,
      UTM_SOURCE,
      UTM_MEDIUM,
      UTM_CAMPAIGN
    };

    const event = this._event.buttonClick(properties);

    this._insightsService.sendEvent(event);
  }
}

export default SignUpEventsReporter;
