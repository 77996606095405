import styled, { keyframes } from 'styled-components';

const slideLeft = keyframes`
  from {
    left: 100%;
  }
  to {
    left: 0;
  }
`;

const slideRight = keyframes`
  from {
    left: -100%;
  }

  to {
    left: 0;
  }
`;

const SlidesStyled = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 24px 0;
  box-sizing: border-box;
  transition: all 0.5s ease;
  z-index: 199;

  &::after,
  &::before {
    position: absolute;
    background-color: rgba(${({ theme }) => theme.getRgbColor(theme.palette.grayJ)}, 0.95);
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &::before {
    background-color: transparent;
    z-index: 20
  }

  .lightbox-container {
    position: relative;
    height: 100%;
    box-sizing: border-box;
  }

  .ligthbox-inner {
    padding: 0;
    height: calc(100% - 48px);
    box-sizing: border-box;
    user-select: none;
  }

  .slides {
    position: relative;
    height: 100%;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .showing-left {
    opacity: 1;
    animation: ${slideLeft} 0.6s
  }

  .showing-right {
    opacity: 1;
    animation: ${slideRight} 0.6s
  }

  .arrows {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 3;
    justify-content: space-between;
    z-index: 22;
  }

  .img {
    object-fit: contain;
    width: 100%;
  }

  .lightbox-close {
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: 0;
    z-index: 22;
    padding-right: 24px;
  }

  .ligthbox-progress {
    ${({ theme }) => theme.typography.title.h3};
    font-size: ${({ theme }) => theme.typography.title.h3.fontSize}rem;
    color: ${({ theme }) => theme.palette.primary.contrastText};
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    z-index: 22;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(3, max-content);
    justify-content: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    padding: 49px;

    .ligthbox-progress {
      ${({ theme }) => theme.typography.title.h1};
      font-size: ${({ theme }) => theme.typography.title.h1.fontSize}rem;
      color: ${({ theme }) => theme.palette.primary.contrastText};
      bottom: 0;
      top: unset;
    }

    .lightbox-close {
      padding-right: 0;
    }

    .arrows {
      display: flex;
    }
  }

  @media (orientation: landscape) and (max-height:769px) {
    .ligthbox-progress {
      display: none;
    }
  }

  @media (min-height: 769px) {
    .ligthbox-inner {
      padding: 9%;
    }
  }
`;

export default SlidesStyled;
