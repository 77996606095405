class FiscalRegime {
    name;
    status;
    proofURL;
    proofUploadedAt;
    rejectionMotive;
    approvedAt;
    rejectedAt;
    constructor(fiscalRegimeData) {
        this.name = fiscalRegimeData.name;
        this.status = fiscalRegimeData.status;
        this.proofURL = fiscalRegimeData.proofURL;
        this.proofUploadedAt = fiscalRegimeData.proofUploadedAt;
        this.rejectionMotive = fiscalRegimeData.rejectionMotive;
        this.approvedAt = fiscalRegimeData.approvedAt;
        this.rejectedAt = fiscalRegimeData.rejectedAt;
    }
    isApproved() {
        return this.status === 'approved';
    }
    isWaitingForApproval() {
        return this.status === 'waiting_for_approval';
    }
    isRejected() {
        return this.status === 'rejected';
    }
}
export default FiscalRegime;
