class PropertyLog {
    id;
    title;
    description;
    photos;
    publishedAt;
    property;
    constructor(data) {
        this.id = data.id;
        this.title = data.title;
        this.description = data.description;
        this.photos = data.photos;
        this.property = data.property;
        this.publishedAt = new Date(data.publishedAt);
    }
}
export default PropertyLog;
