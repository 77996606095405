import InvestmentSummary from './InvestmentSummary';
import YearlyPeriod from './YearlyPeriod';
class InvestmentSimulation {
    summary;
    yearlyPeriods;
    constructor(data) {
        this.summary = new InvestmentSummary(data.summary);
        this.yearlyPeriods = data.yearlyPeriods.map(yearlyPeriod => new YearlyPeriod(yearlyPeriod));
    }
}
export default InvestmentSimulation;
