class OnSaleInvestment {
    bricks;
    totalAmount;
    averageAcquisitionPrice;
    onSaleAt;
    expiresAt;
    property;
    capitalGain;
    constructor(data) {
        this.averageAcquisitionPrice = data.averageAcquisitionPrice;
        this.capitalGain = data.capitalGain;
        this.bricks = data.bricks;
        this.property = data.property;
        this.totalAmount = data.totalAmount;
        this.onSaleAt = new Date(data.onSaleAt);
        this.expiresAt = new Date(data.expiresAt);
    }
    getNumberOfBricks() {
        return this.bricks.length;
    }
}
export default OnSaleInvestment;
