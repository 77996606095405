import { Operator } from '../entities';
import { OperatorError, NetworkError } from '../errors';

import GatewayAPI from './GatewayAPI';

class OperatorAPI extends GatewayAPI {
  async getOperator(operatorId) {
    try {
      const { data } = await this._client.get(`operators/${operatorId}`);

      return new Operator(data);
    } catch (error) {
      return this._handleError(error, OperatorError);
    }
  }

  _handleError(error, APIError) {
    if (!error.response) {
      throw new NetworkError();
    }

    const { code } = error.response.data;

    throw new APIError(code);
  }
}

export default OperatorAPI;
