import {
  CorrectDataError,
  CURPError,
  RFCError,
  TraderProfileError
} from '../errors/Enrollment';

import GatewayAPI from './GatewayAPI';

class EnrollmentAPI extends GatewayAPI {
  async updateCURP(curp) {
    try {
      const { data } = await this._client.post('/profile/curp/correct', { curp });

      return data;
    } catch (error) {
      return this._handleError(error, CURPError);
    }
  }

  async setTransactionalProfile(transactionalProfile) {
    try {
      const { data } = await this._client.post(
        '/profile/transactionalProfile',
        transactionalProfile
      );

      return data;
    } catch (error) {
      return this._handleError(error, TraderProfileError);
    }
  }

  async setTaxId(taxId) {
    try {
      const { data } = await this._client.post('/profile/taxId', taxId);

      return data;
    } catch (error) {
      return this._handleError(error, RFCError);
    }
  }

  async confirmData() {
    try {
      const { data } = await this._client.post('profile/confirmData');

      return data;
    } catch (error) {
      return this._handleError(error, CorrectDataError);
    }
  }

  async correctData(updatedData) {
    try {
      const { data } = await this._client.post('profile/correctData', updatedData);

      return data;
    } catch (error) {
      return this._handleError(error, CorrectDataError);
    }
  }

  _handleError = (error, APIEnrollmentError) => {
    if (!error.response) {
      throw APIEnrollmentError.getNetworkError();
    }

    const errorCode = error.response.data.code;
    const enrollmentError = new APIEnrollmentError(errorCode);

    throw enrollmentError;
  };
}

export default EnrollmentAPI;
