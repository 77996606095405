import { Token } from '../entities/Session';

// This class should be erased
export default class LocalStorageSessionStore {
  create(token) {
    this._storeToken(token);
  }

  load() {
    const value = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expirationDate');

    return value ? new Token(value, expirationDate) : null;
  }

  update(token) {
    if (!this.load()) {
      throw new Error('Session is not started');
    }

    this._storeToken(token);
  }

  clear() {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
  }

  _storeToken(token) {
    const expirationDate = token?.expirationDate ? token.expirationDate.toISOString() : '';

    localStorage.setItem('token', token.value);
    localStorage.setItem('expirationDate', expirationDate);
  }
}
