import ProfileError from '../Profile';

const INVALID_FILE = 'INVALID_FILE';
const MAXIMUM_BACK_FILE_SIZE_EXCEEDED = 'MAXIMUM_BACK_FILE_SIZE_EXCEEDED';
const MAXIMUM_FRONT_FILE_SIZE_EXCEEDED = 'MAXIMUM_FRONT_FILE_SIZE_EXCEEDED';
const MAXIMUM_FILE_SIZE_EXCEEDED = 'MAXIMUM_FILE_SIZE_EXCEEDED';
const UNSUPPORTED_IDENTIFICATION = 'UNSUPPORTED_IDENTIFICATION';
const INVESTOR_INVALID_STATE = 'INVESTOR_INVALID_STATE';
const EMPTY_BACK_FILE = 'EMPTY_BACK_FILE';
const EMPTY_FILE = 'EMPTY_FILE';
const EMPTY_FRONT_FILE = 'EMPTY_FRONT_FILE';
const INCORRECT_BACK_FILE_EXTENSION = 'INCORRECT_BACK_FILE_EXTENSION';
const INCORRECT_FILE_EXTENSION = 'INCORRECT_FILE_EXTENSION';
const INCORRECT_FRONT_FILE_EXTENSION = 'INCORRECT_FRONT_FILE_EXTENSION';
const INVALID_IDENTIFICATION_NUMBER = 'INVALID_IDENTIFICATION_NUMBER';
const THERE_IS_NO_IDENTIFICATION_TO_CORRECT = 'THERE_IS_NO_IDENTIFICATION_TO_CORRECT';
const INVESTOR_DOES_NOT_HAVE_PENDING_CORRECTIONS = 'INVESTOR_DOES_NOT_HAVE_PENDING_CORRECTIONS';
const INVALID_IDENTIFICATION_STATUS = 'INVALID_IDENTIFICATION_STATUS';

class IdentificationError extends ProfileError {
  constructor(code, details = null, name = 'IdentificationError') {
    super(code, name, details);
  }

  isInvalidFile() {
    return this._code === INVALID_FILE;
  }

  isUnsupportedIdentification() {
    return this._code === UNSUPPORTED_IDENTIFICATION;
  }

  isInvestorInvalidState() {
    return this._code === INVESTOR_INVALID_STATE;
  }

  isEmptyBackFile() {
    return this._code === EMPTY_BACK_FILE;
  }

  isEmptyFrontFile() {
    return this._code === EMPTY_FRONT_FILE;
  }

  isEmptyFile() {
    return this._code === EMPTY_FILE;
  }

  hasBackFileIncorrectFileExtension() {
    return this._code === INCORRECT_BACK_FILE_EXTENSION;
  }

  hasIncorrectFileExtension() {
    return this._code === INCORRECT_FILE_EXTENSION;
  }

  hasFrontFileIncorrectFileExtension() {
    return this._code === INCORRECT_FRONT_FILE_EXTENSION;
  }

  hasBackFileExceededSizeLimit() {
    return this._code === MAXIMUM_BACK_FILE_SIZE_EXCEEDED;
  }

  hasExceededSizeLimit() {
    return this._code === MAXIMUM_FILE_SIZE_EXCEEDED;
  }

  hasFrontFileExceededSizeLimit() {
    return this._code === MAXIMUM_FRONT_FILE_SIZE_EXCEEDED;
  }

  hasPassportFileError() {
    return (this.isEmptyFile()
      || this.isInvalidFile()
      || this.hasExceededSizeLimit()
      || this.hasIncorrectFileExtension());
  }

  hasINEFrontFileError() {
    return (this.isEmptyFrontFile()
      || this.hasFrontFileExceededSizeLimit()
      || this.hasFrontFileIncorrectFileExtension());
  }

  hasINEBackFileError() {
    return (this.isEmptyBackFile()
      || this.hasBackFileExceededSizeLimit()
      || this.hasBackFileIncorrectFileExtension());
  }

  isInvalidIdentificationNumber() {
    return this._code === INVALID_IDENTIFICATION_NUMBER;
  }

  isInvalidIdentificationStatus() {
    return this._code === INVALID_IDENTIFICATION_STATUS;
  }

  thereIsNoIdentificationToCorrect() {
    return this._code === THERE_IS_NO_IDENTIFICATION_TO_CORRECT;
  }

  isInvestorDoesNotHavePendingCorrections() {
    return this._code === INVESTOR_DOES_NOT_HAVE_PENDING_CORRECTIONS;
  }
}

export default IdentificationError;
