class InvestmentRents {
    latestRentDispersionDate;
    latestAmount;
    totalHistoricalAmount;
    constructor(data) {
        this.latestRentDispersionDate = data?.latestRentDispersionDate
            ? new Date(data.latestRentDispersionDate)
            : undefined;
        this.latestAmount = data.latestAmount;
        this.totalHistoricalAmount = data.totalHistoricalAmount;
    }
}
export default InvestmentRents;
