import InvestmentProfit from './InvestmentProfit';

// It should Refactor when update my account
class SoldInvestmentProfit extends InvestmentProfit {
  constructor(data) {
    super(data);

    this._salesProfit = data.salesProfit;
  }

  get salesProfitAmount() {
    return this._salesProfit.amount;
  }

  get salesProfitPercentage() {
    return this._salesProfit.percentage;
  }
}

export default SoldInvestmentProfit;
