import React, { useState, useEffect } from 'react';

import { View, Icon } from '@components';
import { renderSomethingWentWrong } from '@utils';
import { Subtitle } from '@components/Styled';

import AccountStatement from './components/AccountStatement';
import MyMovementsDataTable from './components/MyMovementsDataTable/MyMovementsDataTable';
import { fetchMovements, fetchAccountStatementDates } from './api';
import { MyMovementsDataTableContainer, MyMovementsHeaderContainer } from './MyMovements.style';

const MyMovements = () => {
  const [movements, setMovements] = useState([]);
  const [accountStatementDates, setAccountStatementDates] = useState([]);

  const getMovements = async () => {
    try {
      const { data } = await fetchMovements();

      setMovements(data.movements);
    } catch (error) {
      setMovements([]);
      renderSomethingWentWrong();
    }
  };

  const getAccountDates = async () => {
    try {
      const { data } = await fetchAccountStatementDates();

      setAccountStatementDates(data);
    } catch (error) {
      setAccountStatementDates([]);
      renderSomethingWentWrong();
    }
  };

  const initializeComponent = () => {
    getAccountDates();
    getMovements();
  };

  useEffect(() => {
    initializeComponent();
  }, []);

  return (
    <View id="my-movements" title="100 Ladrillos - Mis movimientos">
      <AccountStatement dates={accountStatementDates} />

      <MyMovementsHeaderContainer>
        <Icon name="icMyMovementsActive" alt="icMyMovementsActive" />

        <Subtitle>Mis movimientos</Subtitle>
      </MyMovementsHeaderContainer>

      <MyMovementsDataTableContainer>
        <MyMovementsDataTable movements={movements} />
      </MyMovementsDataTableContainer>
    </View>
  );
};

export default MyMovements;
