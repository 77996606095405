class Token {
    value;
    expirationDate;
    constructor(value, expirationDate) {
        this.value = value;
        this.expirationDate = new Date(expirationDate);
    }
    get authorizationString() {
        const AUTHORIZATION_TYPE = 'Bearer';
        return `${AUTHORIZATION_TYPE} ${this.value}`;
    }
    get expirationTimeInMilliseconds() {
        return this.calculateSessionTimeInMilliseconds();
    }
    isExpired() {
        const currentDate = new Date();
        return currentDate >= this.expirationDate;
    }
    isAuthenticated() {
        return Boolean(this.value);
    }
    updateExpirationDate(date) {
        if (date) {
            this.expirationDate = date;
        }
        else {
            const expirationDate = new Date();
            this.expirationDate = new Date(expirationDate.setMinutes(expirationDate.getMinutes() + 5));
        }
    }
    calculateSessionTimeInMilliseconds() {
        const SECONDS_UNIT = 60;
        const MILLISECONDS_UNIT = 1000;
        const currentDate = new Date();
        const timeDifferenceInMinutes = (this.expirationDate.getMinutes() - currentDate.getMinutes());
        const timeDifferenceInMilliseconds = (Math.abs(timeDifferenceInMinutes * SECONDS_UNIT * MILLISECONDS_UNIT));
        return timeDifferenceInMilliseconds;
    }
}
export default Token;
