class PropertyAddress {
    city;
    municipality;
    state;
    externalNumber;
    internalNumber;
    neighborhood;
    typeOfRoad;
    streetName;
    zipCode;
    coordinates;
    country;
    constructor(data) {
        this.city = data.city;
        this.municipality = data.municipality;
        this.state = data.state;
        this.externalNumber = data.externalNumber;
        this.internalNumber = data.internalNumber;
        this.neighborhood = data.neighborhood;
        this.typeOfRoad = data.typeOfRoad;
        this.streetName = data.streetName;
        this.zipCode = data.zipCode;
        this.coordinates = data.coordinates;
        this.country = data.country;
    }
    hasAddress() {
        return Boolean(this.city
            && this.state
            && this.country
            && this.neighborhood
            && this.streetName
            && this.zipCode);
    }
}
export default PropertyAddress;
