import { jsx as _jsx } from "react/jsx-runtime";
import NotificationItem from './NotificationItem';
import { AutomaticInvoicingSuccessful, BoughtBricks, CanceledPresaleInvestment, DepositFunds, DirectRentRelease, DispersedRents, DispersedRentsUSA, FiscalRegimeApproved, FiscalRegimeRejected, IncomeReleaseWithoutInvoice, InvoicedIncomeRelease, NewPropertyManagementLog, OverduePayment, PaymentPlanApproved, PaymentPlanPaid, RecurrentPaymentOfPaymentPlan, SalesOrder, SoldBricks, CrowdfundingRebateDispersion, WithdrawalRequestApproved, WithdrawalRequest, WithdrawalRequestRejected } from './templates';
const NotificationFactory = ({ notification }) => {
    const dynamicNotifications = {
        automaticInvoicingSuccessful: 'automaticInvoicingSuccessful',
        boughtBricks: 'buyerPurchase',
        canceledPresaleInvestment: 'investmentCancellation',
        depositFunds: 'depositFunds',
        directRentRelease: 'automaticRentReleaseWithoutInvoiceSuccessful',
        fiscalRegimeApproved: 'fiscalRegimeApproved',
        fiscalRegimeRejected: 'fiscalRegimeRejected',
        IncomeReleaseWithoutInvoice: 'rentReleaseWithoutInvoice',
        newCrowdfundingPropertyManagementLog: 'newCrowdfundingPropertyManagementLog',
        newPropertyManagementLog: 'newPropertyManagementLog',
        overduePayment: 'overduePayment',
        paymentPlanApproved: 'paymentPlanApproved',
        paymentPlanPaid: 'paymentPlanPaid',
        recurrentPaymentOfPaymentPlan: 'recurrentPaymentOfPaymentPlan',
        rentDispersion: 'rentDispersion',
        rentDispersionUSA: 'rentDispersionUSA',
        rentReleaseWithInvoice: 'rentReleaseWithInvoice',
        rentReleaseWithoutInvoice: 'rentReleaseWithoutInvoice',
        salesOrder: 'salesOrder',
        soldBricks: 'sellerPurchase',
        crowdfundingRebateDispersion: 'crowdfundingRebateDispersion',
        withdrawalRequestApproved: 'withdrawalRequestApproved',
        withdrawalRequest: 'withdrawalRequest',
        withdrawalRequestRejected: 'withdrawalRequestRejected'
    };
    switch (notification.type) {
        case dynamicNotifications.automaticInvoicingSuccessful:
            return _jsx(AutomaticInvoicingSuccessful, { notification: notification });
        case dynamicNotifications.boughtBricks:
            return _jsx(BoughtBricks, { notification: notification });
        case dynamicNotifications.canceledPresaleInvestment:
            return _jsx(CanceledPresaleInvestment, { notification: notification });
        case dynamicNotifications.depositFunds:
            return _jsx(DepositFunds, { notification: notification });
        case dynamicNotifications.rentDispersion:
            return _jsx(DispersedRents, { notification: notification });
        case dynamicNotifications.rentDispersionUSA:
            return _jsx(DispersedRentsUSA, { notification: notification });
        case dynamicNotifications.soldBricks:
            return _jsx(SoldBricks, { notification: notification });
        case dynamicNotifications.rentReleaseWithInvoice:
            return _jsx(InvoicedIncomeRelease, { notification: notification });
        case dynamicNotifications.rentReleaseWithoutInvoice:
            return _jsx(IncomeReleaseWithoutInvoice, { notification: notification });
        case dynamicNotifications.newPropertyManagementLog:
        case dynamicNotifications.newCrowdfundingPropertyManagementLog:
            return _jsx(NewPropertyManagementLog, { notification: notification });
        case dynamicNotifications.directRentRelease:
            return _jsx(DirectRentRelease, { notification: notification });
        case dynamicNotifications.salesOrder:
            return _jsx(SalesOrder, { notification: notification });
        case dynamicNotifications.fiscalRegimeApproved:
            return _jsx(FiscalRegimeApproved, { notification: notification });
        case dynamicNotifications.crowdfundingRebateDispersion:
            return _jsx(CrowdfundingRebateDispersion, { notification: notification });
        case dynamicNotifications.paymentPlanApproved:
            return _jsx(PaymentPlanApproved, { notification: notification });
        case dynamicNotifications.paymentPlanPaid:
            return _jsx(PaymentPlanPaid, { notification: notification });
        case dynamicNotifications.recurrentPaymentOfPaymentPlan:
            return _jsx(RecurrentPaymentOfPaymentPlan, { notification: notification });
        case dynamicNotifications.overduePayment:
            return _jsx(OverduePayment, { notification: notification });
        case dynamicNotifications.fiscalRegimeRejected:
            return _jsx(FiscalRegimeRejected, { notification: notification });
        case dynamicNotifications.withdrawalRequestApproved:
            return _jsx(WithdrawalRequestApproved, { notification: notification });
        case dynamicNotifications.withdrawalRequest:
            return _jsx(WithdrawalRequest, { notification: notification });
        case dynamicNotifications.withdrawalRequestRejected:
            return _jsx(WithdrawalRequestRejected, { notification: notification });
        default:
            return _jsx(NotificationItem, { notification: notification });
    }
};
export default NotificationFactory;
