import React from 'react';
import PropTypes from 'prop-types';

import ActionText from '../../../../components/ActionText';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import {
  InvestmentLimitBodyWrapper,
  InvestmentLimitText,
  UpgradeAccountModalArea,
  UpgradeAccountWrapper,
  UpgradeAccountIcon,
  UpgradeButton
} from '../../UpgradeAccount.styled';

const UpgradeAccountTransaction = ({ title, bodyInformation, onRequestUpgrade }) => (
  <UpgradeAccountWrapper>
    <Title>{title}</Title>

    <UpgradeAccountIcon
      alt="Imagen conviértete en un usuario sin límites"
      name="icUpgradeAccountModal"
    />

    <InvestmentLimitBodyWrapper>
      <UpgradeAccountModalArea>
        <Text>
          {bodyInformation}
        </Text>

        <Text>
          ¿Por qué tengo un
          {' '}
          <ActionText id="get-rfc" color="info" onClick={onRequestUpgrade}>
            límite de inversión
          </ActionText>
          ?
        </Text>
      </UpgradeAccountModalArea>

      <InvestmentLimitText>
        Solo necesitas una
        {' '}
        <strong>identificación oficial</strong>
        {' '}
        vigente.
      </InvestmentLimitText>
    </InvestmentLimitBodyWrapper>

    <UpgradeButton id="upgrade-account-button" rounded onClick={onRequestUpgrade}>
      Mejorar mi cuenta
    </UpgradeButton>
  </UpgradeAccountWrapper>
);

UpgradeAccountTransaction.propTypes = {
  bodyInformation: PropTypes.string.isRequired,
  onRequestUpgrade: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default UpgradeAccountTransaction;
