import React from 'react';
import Helmet from 'react-helmet';

const Title = ({ children }) => (
  <Helmet>
    {children}
  </Helmet>
);

export default Title;
