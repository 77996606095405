import React, {
  Fragment,
  useEffect,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountLockCodes, Money } from '@types';
import { useAPI, useForm, useRequest } from '@hooks';
import { convertDecimalNumberToCents, renderSomethingWentWrong } from '@utils';
import { useProfile } from '@lib/profile';
import { useErrorReporter } from '@lib/errorReporter';
import {
  Button,
  Icon,
  NotEnoughFundsModal,
  RenderIf,
  SelectField,
  TwoFactorConfirmation
} from '@components';
import {
  Headline1,
  Headline2,
  Paragraph,
  Subtitle,
  Text
} from '@components/Styled';

import {
  AddAccountInformation,
  AvailableBalance,
  FundsWrapper,
  NumberField,
  PanelWrapper,
  TwoFactorContainer,
  WithdrawForm,
  WithdrawFundsWrapper
} from './WithdrawFunds.style';

const formatAccountsAsOptions = accounts => (
  accounts?.map(account => (
    {
      label: account.name,
      id: account.number
    }
  ))
);

const withdrawSchema = {
  amount: {
    required: {
      message: 'Debes ingresar un monto.'
    }
  },
  targetAccount: {
    required: {
      message: 'La cuenta bancaria es obligatoria.'
    }
  }
};

const WithdrawReplace = () => {
  const errorReporter = useErrorReporter();
  const form = useForm({ schema: withdrawSchema });
  const navigate = useNavigate();
  const { investorBalance, profile, legacyProfile } = useProfile();
  const { isShadowLockCode: hasBeenShadowLocked } = new AccountLockCodes();
  const { withdrawal } = useAPI();

  const [
    availableAccounts, ,
    availableAccountsError,
    getAvailableAccounts
  ] = useRequest(withdrawal.getAvailableAccounts);

  const [hasEnoughFunds, setHasEnoughFunds] = useState(true);
  const [isWithdrawalRequested, setIsWithdrawalRequested] = useState(false);

  const handleErrors = error => {
    switch (error?.code) {
      case 'NOT_ENOUGH_FUNDS':
        setHasEnoughFunds(false);
        break;
      case 'WITHDRAWAL_ACCOUNT_NOT_BELONGS_TO_CLIENT':
        renderSomethingWentWrong('La cuenta destino no pertenece al titular de la cuenta.');
        break;
      case 'TRY_AGAIN':
        renderSomethingWentWrong('Este retiro no se puede realizar. Inténtalo más tarde.');
        break;
      default:
        errorReporter.critical(error);
        renderSomethingWentWrong();
        break;
    }
  };

  const handleSuccessfulWithdrawal = () => {
    const { amount, targetAccount } = form.values;

    navigate('/movements/withdraw/success', {
      state: {
        account: targetAccount,
        amount: convertDecimalNumberToCents(amount)
      }
    });
  };

  const handleCodeSending = sendingChannel => withdrawal.sendWithdrawalCode({
    email: profile.email,
    sendingChannel
  });

  const handleCodeVerification = code => {
    const amount = convertDecimalNumberToCents(form.values.amount);

    return withdrawal.withdrawFunds({
      account: form.values.targetAccount,
      amount,
      code
    });
  };

  useEffect(() => {
    if (availableAccountsError) {
      renderSomethingWentWrong(
        'Ocurrió un problema tratando de obtener las cuentas bancarias disponibles.'
      );
    }
  }, [availableAccountsError]);

  useEffect(() => {
    getAvailableAccounts();
  }, []);

  return (
    <Fragment>
      <NotEnoughFundsModal
        isOpen={!hasEnoughFunds}
        handleClose={() => setHasEnoughFunds(true)}
        availableAmount={investorBalance?.available}
      />

      <WithdrawFundsWrapper>
        <Headline1>Retirar fondos</Headline1>

        <Text>
          Para retirar fondos es necesario que el titular de la cuenta bancaria
          sea el mismo que el titular de la cuenta 100 Ladrillos.
        </Text>

        <Text>
          Los retiros de fondos se procesaran al día hábil siguiente.
        </Text>

        <PanelWrapper showTwoFactor={isWithdrawalRequested}>
          <FundsWrapper>
            <Icon height="35" width="35" name="icWithdrawRed" alt="Retirar" />

            <AvailableBalance>
              <Text>Saldo disponible para el retiro:</Text>

              <Text>{new Money(investorBalance?.available).toString()}</Text>
            </AvailableBalance>
          </FundsWrapper>

          <WithdrawForm>
            <Headline2>
              1.- Ingresa tus datos de retiro y solicita código de verificación
            </Headline2>

            <NumberField
              autoComplete="off"
              disabled={hasBeenShadowLocked(legacyProfile.lockCode)}
              id="withdrawal-input"
              label="Monto a retirar"
              name="amount"
              type="number"
              {...form.fieldProps('amount')}
            />

            <SelectField
              id="account-dropdown"
              options={formatAccountsAsOptions(availableAccounts)}
              label="Cuenta bancaria destino"
              placeholder="Selecciona una opción"
              selectedOption={form.values.targetAccount}
              {...form.fieldProps('targetAccount')}
              onChange={({ target: { value } }) => form.resetValues({
                targetAccount: value.label
              })}
            />

            <Button
              id="send-code-btn"
              disabled={!form.isValid() || hasBeenShadowLocked(legacyProfile.lockCode)}
              onClick={() => setIsWithdrawalRequested(true)}
            >
              Retirar fondos
            </Button>
          </WithdrawForm>

          <RenderIf condition={isWithdrawalRequested}>
            <TwoFactorContainer>
              <TwoFactorConfirmation
                onCancel={() => navigate(-1)}
                onError={handleErrors}
                onSuccess={handleSuccessfulWithdrawal}
                sendCode={handleCodeSending}
                senderOptions={['sms', 'email']}
                verifyCode={handleCodeVerification}
              />
            </TwoFactorContainer>
          </RenderIf>

          <AddAccountInformation>
            <Subtitle align="left" level="2">
              ¿Puedo retirar fondos a mis otras cuentas bancarias?
            </Subtitle>

            <Paragraph align="left">
              Si deseas agregar una nueva cuenta bancaria para realizar retiros, es necesario
              que utilices una cuenta bancaria previamente utilizada para fondear tu cuenta,
              simplemente realiza una transferencia de fondos desde $1.00 MXN hacia tu cuenta
              de 100 Ladrillos. Esta operación permitirá que registremos la nueva cuenta
              bancaria en tu perfil automáticamente, facilitándote la selección de esta
              para futuros retiros.
            </Paragraph>
          </AddAccountInformation>
        </PanelWrapper>
      </WithdrawFundsWrapper>
    </Fragment>
  );
};

export default WithdrawReplace;
