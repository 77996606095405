import React from 'react';
import PropTypes from 'prop-types';

import {
  Step,
  StepContent,
  StepHeader,
  StepperLayout,
  StepperIndicator
} from './Stepper.style';

const Stepper = props => {
  const {
    ActionsComponent,
    activeStepIndex,
    steps
  } = props;

  const activeStep = steps[activeStepIndex];

  return (
    <StepperLayout steps={steps} activeStepId={activeStep.id}>
      <div className="stepper-indicator-list">
        {steps.map(step => {
          const isLastStep = step.index === steps.length - 1;

          return (
            <StepperIndicator
              key={step.id}
              isActive={activeStep.id === step.id}
              isCompleted={step.index < activeStep.index}
              isLastStepIndicator={isLastStep}
            >
              <div className="step-number">
                {step.index + 1}
              </div>

              <div className="indicator-content">
                {step.name}
              </div>
            </StepperIndicator>
          );
        })}
      </div>

      <Step>
        <StepHeader titleText={activeStep.name} />

        <StepContent>
          {activeStep.content}
        </StepContent>

        {ActionsComponent}
      </Step>
    </StepperLayout>
  );
};

Stepper.defaultProps = {
  ActionsComponent: null,
  activeStepIndex: 0
};

Stepper.propTypes = {
  ActionsComponent: PropTypes.node,
  activeStepIndex: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.node
  })).isRequired
};

export default React.memo(Stepper);
