/* eslint-disable max-len */
const regularExpressions = {
  allWhiteSpaces: / /g,
  allWithoutLineBreak: /\r\n|\r|\n/,
  avoidAllCommas: /,/g,
  avoidAllPoints: /\./g,
  avoidLeadingZeros: /^0+/,
  camelCase: /(?:^\w|[A-Z]|\b\w)/g,
  curpFormat: /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
  dateFormat: /(\s*)(\+)/,
  // eslint-disable-next-line no-useless-escape
  emailFormat: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  identificationNumber: /^([A-Z]{6}[0-9]{8}[A-Z]{1}[0-9]{3})$/,
  investorNameFormat: /^([a-zA-ZÀ-ÖØ-öø-ÿ]+([-'.]?\s?[a-zA-ZÀ-ÖØ-öø-ÿ]+)*[-'.]?\s?)*$/,
  mimeType: /:(.*?);/,
  onlyLettersAndNumbers: /[^a-zA-Z0-9-Ññ]/g,
  onlyLettersAndNumbersUSFormat: /[^a-zA-Z0-9]/g,
  onlyNumbers: /\D+/g,
  onlyNumbersWithTwoDecimals: /^-?\d+(?:\.\d{0,2})?/,
  onlyNumberWithFourDecimals: /^-?\d+(?:\.\d{0,4})?/,
  onlyNumberWithTwoDecimals: /[,]+(?:\.\d{0,2})?/g,
  onlyValidNameSymbols: /[^a-zA-ZÀ-ÿ\u00f1\u00d1´\-'.\s]/g,
  onlyWords: /^[a-zA-Z\u00C0-\u00FF ]*$/,
  phoneFormat: /^(\d{2})(\d{4})(\d{4})$/,
  phonNumberFormat: /^[0-9]{10}/g,
  taxIdFormat: /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/,
  taxIdCheckDigitFormat: /^([A-Z\d]{2})([A\d])+$/i,
  squareBrackets: /[[\]]/g
};

export default regularExpressions;
