class RiskFormStatus {
    id;
    signedAt;
    ipAddress;
    latitude;
    longitude;
    constructor(riskFormData) {
        this.id = riskFormData.id;
        this.signedAt = riskFormData.signedAt;
        this.ipAddress = riskFormData.location.ipAddress;
        this.latitude = riskFormData.location.latitude;
        this.longitude = riskFormData.location.longitude;
    }
}
export default RiskFormStatus;
