import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon } from '@components';

import './style.scss';

const ErrorQuoter = props => {
  const { buttonLabel, errorQuoterAction } = props;

  return (
    <div styleName="error-quoter">
      <Icon name="icSadFace" alt="Error en cotizador" styleName="error-image" />
      <p styleName="error-message">
        <span>Lo sentimos, algo salió mal mientras</span>
        <span>cargábamos la información.</span>
        <span styleName="pronounced-text">Intenta actualizando esta sección.</span>
      </p>
      <Button
        id="error-quoter-button"
        tid="error-quoter-button"
        onClick={errorQuoterAction}
        color="secondary"
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

ErrorQuoter.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  errorQuoterAction: PropTypes.func.isRequired,
};

export default ErrorQuoter;
