import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';
import './style.scss';

const Tabs = props => {
  const [tabs, setTabs] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const {
    children,
    activeTab,
  } = props;

  const setTabItems = () => {
    const tabItems = children.length ? [...children] : [children];

    setTabs(tabItems);
  };

  const setActiveTab = tabIndex => {
    setActiveTabIndex(tabIndex);
  };

  useEffect(() => {
    setTabItems();
    setActiveTab(activeTab);
  }, []);

  return (
    <div tid="tabs-container" styleName="tabs-container">
      <ol styleName="tab-list">
        {tabs.map((tab, tabIndex) => {
          return (
            <Tab
              key={tab.props.id}
              isActive={activeTabIndex === tabIndex}
              setActiveTab={setActiveTab}
              tabIndex={tabIndex}
              {...tab.props}
            />
          );
        })}
      </ol>
      <div styleName="tab-content" tid="tab-content">
        {tabs[activeTabIndex]}
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  activeTab: 0,
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.number,
};

export default Tabs;
