import APIError from '../APIError';

const INVALID_START_DATE = 'INVALID_START_DATE';
const INVALID_END_DATE = 'INVALID_END_DATE';
const INVALID_DATE_RANGE = 'INVALID_DATE_RANGE';

class ForeignLeaseIncomesError extends APIError {
  constructor(code, details = null) {
    super(code, 'ForeignLeaseIncomesError', details);
  }

  hasInvalidStartDate() {
    return this._code === INVALID_START_DATE;
  }

  hasInvalidEndDate() {
    return this._code === INVALID_END_DATE;
  }

  hasInvalidDateRange() {
    return this._code === INVALID_DATE_RANGE;
  }
}

export default ForeignLeaseIncomesError;
