import React, {
  Fragment,
  useState,
  useRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from '../Icon';

import './style.scss';

const UploadedFileButton = props => {
  const { handleRemoveFile, file, id } = props;

  return (
    <Fragment>
      <p styleName="file-name" tid="file-name">{file.name}</p>
      <button
        id={`${id}-remove-file`}
        tid="remove-file"
        styleName="trash-icon"
        type="button"
        onClick={handleRemoveFile}
      >
        <Icon name="icTrashRed" alt="Remover archivo" />
      </button>
    </Fragment>
  );
};

const GeneralFileButton = props => {
  const { triggerFileUpload, label, id } = props;

  return (
    <button
      id={`${id}-uploader-trigger`}
      tid="file-uploader-trigger"
      type="button"
      styleName="label"
      onClick={triggerFileUpload}
    >
      {label}
    </button>
  );
};

const FileUploader = props => {
  const [file, setFile] = useState({});

  const fileInputRef = useRef();
  const hasUploadedFile = file instanceof File;

  const {
    handleFileSubscription,
    className,
    filesToAccept,
    id,
    iconName,
    label,
    required,
    subtitle,
    title,
    message,
    isError,
    name
  } = props;

  const triggerFileUpload = () => {
    const { current } = fileInputRef;
    current.value = '';

    if (!hasUploadedFile) {
      current.click();
    }
  };

  const handleFileChange = event => {
    const { target: { files } } = event;
    const [newFile] = files;

    setFile(newFile);
  };

  const handleRemoveFile = () => {
    setFile({});
  };

  const renderFileButton = () => (
    hasUploadedFile
      ? <UploadedFileButton handleRemoveFile={handleRemoveFile} file={file} id={id} />
      : <GeneralFileButton triggerFileUpload={triggerFileUpload} label={label} id={id} />
  );

  useEffect(() => {
    if (handleFileSubscription) {
      handleFileSubscription(file, name);
    }
  }, [file, name, handleFileSubscription]);

  const labelContainerStyle = cx({
    'label-container': true,
    'has-file-uploaded': hasUploadedFile
  });

  const fileUploaderStyleName = cx({
    'file-uploader': true,
    hasMessage: Boolean(message)
  });

  const contentStyleName = cx({
    content: true,
    error: isError
  });

  const messageStyleName = cx({
    message: true,
    error: isError
  });

  return (
    <div styleName={fileUploaderStyleName} className={className}>
      <p styleName="title" tid="title">{title}</p>
      <div styleName={contentStyleName}>
        <section styleName="image">
          <Icon tid="icon" name={iconName} alt="Subir archivo" />
        </section>
        <section styleName={labelContainerStyle}>
          {renderFileButton()}
        </section>
        <p tid="subtitle" styleName="subtitle">{subtitle}</p>
      </div>
      {message && <p styleName={messageStyleName}>{message}</p>}
      <input
        tid="file-input"
        data-testid="fileInput"
        ref={fileInputRef}
        type="file"
        name={name}
        accept={filesToAccept}
        required={required}
        id={id}
        onChange={handleFileChange}
      />
    </div>
  );
};

GeneralFileButton.propTypes = {
  triggerFileUpload: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

UploadedFileButton.propTypes = {
  handleRemoveFile: PropTypes.func.isRequired,
  file: PropTypes.oneOfType([
    PropTypes.instanceOf(File),
    PropTypes.shape({})
  ]).isRequired,
  id: PropTypes.string.isRequired
};

FileUploader.defaultProps = {
  className: '',
  iconName: 'icFile',
  isError: false,
  message: '',
  required: false,
  subtitle: '',
  name: ''
};

FileUploader.propTypes = {
  className: PropTypes.string,
  filesToAccept: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  isError: PropTypes.bool,
  label: PropTypes.string.isRequired,
  message: PropTypes.string,
  required: PropTypes.bool,
  subtitle: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  handleFileSubscription: PropTypes.func.isRequired
};

export default React.memo(FileUploader);
