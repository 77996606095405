import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import Title from '../Title';

import TextWrapper from './NotEnoughBricksModal.style';

const NotEnoughBricksModal = ({ handleClose, isOpen }) => (
  <Modal isOpen={isOpen} onClose={handleClose}>
    <Title>
      ¡Ladrillos insuficientes!
    </Title>

    <Icon name="icInsufficientBricks" alt="Ladrillos insuficientes" height={84} width={84} />

    <TextWrapper>
      Desafortunadamente ya no hay Ladrillos suficientes para usar este cupón
    </TextWrapper>

    <Button id="continue" onClick={handleClose}>
      Entiendo
    </Button>
  </Modal>
);

NotEnoughBricksModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default NotEnoughBricksModal;
