class Leaseholder {
    id;
    name;
    logoUrl;
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.logoUrl = data.logoUrl;
    }
}
export default Leaseholder;
