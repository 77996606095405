class Notifications {
    pagination;
    count;
    list;
    constructor(data) {
        this.list = data.items;
        this.pagination = data.pagination;
        this.count = data.count;
    }
}
export default Notifications;
