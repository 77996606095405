import React from 'react';

import './style.scss';

const QuoterOnSalePlaceholder = () => {
  return (
    <div styleName="quoter-on-sale-placeholder">
      <div styleName="bricks-quantity-label" />
      <div styleName="bricks-quantity-input" />
      <div styleName="buy-button" />
    </div>
  )
};

export default QuoterOnSalePlaceholder;
