import React from 'react';

import {
  ActionMediaContainer,
  ContentMediaContainer,
  MediaContainer,
  TitleMediaContainer
} from '@components';

import { verifyEmailViewImage } from '../../../../../assets/images/EnrollmentStepImages';
import { DescriptionText, TitleText } from '../../EnrollmentMediaContainer.style';

const VERIFY_EMAIL_IMAGE_ALT = 'Computadora mostrando una aplicación con un certificado de'
  + ' autorización, regulación y supervisión';

const VerifyEmailMediaContainer = () => (
  <MediaContainer>
    <TitleMediaContainer>
      <TitleText>Tu seguridad es nuestra prioridad</TitleText>
    </TitleMediaContainer>

    <ContentMediaContainer>
      <img src={verifyEmailViewImage} alt={VERIFY_EMAIL_IMAGE_ALT} />
    </ContentMediaContainer>

    <ActionMediaContainer>
      <DescriptionText>
        Nos enorgullece decir que estamos regulados por la CNBV; nuestros procesos son totalmente
        transparentes para ti.
      </DescriptionText>
    </ActionMediaContainer>
  </MediaContainer>
);

export default VerifyEmailMediaContainer;
