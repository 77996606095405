// Create INE and Passport entities, which inherit from Identification
import { IDENTIFICATION_STATUSES } from '@constants';
class Identification {
    type;
    number;
    status;
    updatedAt;
    rejectionMotive;
    file1;
    file2;
    constructor(data) {
        this.type = data?.type;
        this.number = data?.number;
        this.status = data?.status;
        this.updatedAt = data?.updatedAt;
        this.rejectionMotive = data?.rejectionMotive;
        if (this.type === 'ine') {
            this.file1 = data?.frontFileURL;
            this.file2 = data?.backFileURL;
        }
        else {
            this.file1 = data?.fileURL;
        }
    }
    isApproved() {
        return this.status === IDENTIFICATION_STATUSES.approved;
    }
    isRejected() {
        return this.status === IDENTIFICATION_STATUSES.rejected;
    }
    isPendingOfValidation() {
        return !this.isApproved() && !this.isRejected() && Boolean(this.number);
    }
    hasInconsistentData() {
        return this.rejectionMotive === 'inconsistent_data';
    }
    hasIdentification() {
        return Boolean(this.type && this.number && this.file1);
    }
}
export default Identification;
