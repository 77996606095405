import InvestmentRents from '../InvestmentRents';
class BricksDetail {
    averageAcquisitionPrice;
    capRate;
    lowestPrice;
    numberOfBricks;
    property;
    rents;
    totalAmount;
    capitalGain;
    constructor(data) {
        this.averageAcquisitionPrice = data.averageAcquisitionPrice;
        this.capRate = data.capRate;
        this.lowestPrice = data.lowestPrice;
        this.capitalGain = data.capitalGain;
        this.numberOfBricks = data.numberOfBricks;
        this.property = data.property;
        this.rents = new InvestmentRents(data.rents);
        this.totalAmount = data.totalAmount;
    }
}
export default BricksDetail;
