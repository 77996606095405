import autoBind from 'auto-bind';

class InvestorReporter {
  constructor(insightsService) {
    this._insightsService = insightsService;

    autoBind(this);
  }

  setSession(id) {
    this._insightsService.identifyInvestor(id);
  }

  closeSession() {
    this._insightsService.anonymizeInvestorIdentity();
  }

  setHistoricalProperties(profile, account) {
    if (profile && account) {
      const accountProperties = this._createAccountProperties(account);

      this._insightsService.setEventProperties({
        ...accountProperties,
        Status: profile?.status ?? null,
        'Account Level': profile?.accountLevel ?? null
      });
    }
  }

  setProfile(profile, fiscalRegime, hasAutomaticInvoice) {
    const profileProperties = this._createProfileProperties(profile);

    this._insightsService.setProfileProperties({
      ...profileProperties,
      'Tax Regime': fiscalRegime?.name ?? null,
      'Invoice Type': hasAutomaticInvoice ? 'Automatic' : 'Manual'
    });
  }

  setAccountData(account) {
    const accountProperties = this._createAccountProperties(account);

    this._insightsService.setProfileProperties({ ...accountProperties });
  }

  _createProfileProperties(profile) {
    return {
      'Account Level': profile?.accountLevel?.name ?? null,
      Status: profile?.status?.name ?? null,
      'Estimated monthly funds amount': profile?.transactionalProfile?.maximumAmount ?? null,
      'Estimated monthly number of moves': profile?.transactionalProfile?.numberOfMoves ?? null,
      'Source of Resources': profile?.transactionalProfile?.sourceOfResources ?? null,
      'Birth State': profile?.demographics?.birthState ?? null,
      'Birth Year': profile?.demographics?.birthdate?.split('-')[0] ?? null,
      Gender: profile?.demographics?.gender ?? null
    };
  }

  _createAccountProperties(account) {
    return {
      'Account Value': account?.accountValue?.amount,
      'Crowdfunding Bricks Qty': account?.crowdfundingBricks ?? 0,
      'SecondaryMarket Bricks Qty': account?.secondaryMarketBricks ?? 0,
      'User type': account?.hasInvestments?.() ? 'Investor' : 'User'
    };
  }
}

export default InvestorReporter;
