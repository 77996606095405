import styled, { css } from 'styled-components';

import { flexAsColumn, grid } from '@components/Styled/mixins';

const LayoutDistribution = styled.section`
  ${grid()};

  height: 100%;
  width: 100%;
  grid-template-areas:
    "navbar"
    "content";
  grid-template-rows: ${({ theme }) => theme.spacing(8)} auto;
`;

const ContentDistribution = styled.div`
  ${flexAsColumn()};

  grid-area: content;
  background-color: ${({ theme }) => theme.palette.white};
  height: ${({ theme }) => `calc(100vh - ${theme.spacing(8)})`};
  width: 100vw;
  overflow: hidden;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  ${flexAsColumn()};

  height: 100%;
  width: 100%;
  overflow-y: scroll;
  position: relative;

  ${({ theme, isPrivateContent })=> css`
    background-color: ${isPrivateContent
      ? theme.colors.secondary.lighterBlue
      : theme.palette.white};
  `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    align-items: unset;
    justify-content: unset;
  }
`;

const GeneralContent = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.breakpoints.extraLarge}px;
  width: 100%;
`;

const FormContent = styled.div`
  height: fit-content;
  padding: ${({ theme }) => `${theme.spacing(12)} ${theme.spacing(2)}`};
  width: 100%;

  > h3 {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    margin: auto;
    max-width: ${({ theme }) => theme.spacing(44)};
    padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(2)}`};
  }
`;

export {
  Content,
  ContentDistribution,
  FormContent,
  GeneralContent,
  LayoutDistribution
};
