import Session, { Geolocation, Token } from '../entities/Session';
import SignupError, { Details } from '../errors/Signup';
import { getAsErrorData, getAsRequestError, isRequestError } from '../errors/errorValidator';
import GatewayAPI from './GatewayAPI';
class SignupAPI extends GatewayAPI {
    async createAccount(email, password, advertising, trackingPublishingMedia) {
        const payload = {
            email,
            password,
            advertising,
            trackingPublishingMedia
        };
        try {
            const { data } = await this._client.post('/signup', payload);
            const expirationDate = new Date();
            expirationDate.setMinutes(expirationDate.getMinutes() + 5);
            const token = new Token(data.token, expirationDate);
            const geolocation = data.geolocalization ? new Geolocation(data.geolocalization) : null;
            return new Session(data.traderId, token, geolocation);
        }
        catch (err) {
            if (!(isRequestError(err))) {
                throw SignupError.getNetworkError();
            }
            const error = getAsRequestError(err);
            const { response } = error;
            if (response?.data) {
                const { code, metadata } = getAsErrorData(response.data);
                const signupError = new SignupError(code);
                if (signupError.isAlreadyRegistered() || signupError.isInvalidPasswordFormat()) {
                    signupError.details = new Details(metadata);
                }
                throw signupError;
            }
            throw SignupError.getNetworkError();
        }
    }
}
export default SignupAPI;
