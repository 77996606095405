/* eslint-disable @typescript-eslint/no-explicit-any */
import Money from '../../../types/money';
import Date from '../../../types/date';
export default class CrowdfundingInvestment {
    id;
    paymentPlanId;
    folio;
    date;
    property;
    numberOfBricks;
    totalInvested;
    hasBeenDeliverFunds;
    isActive;
    isCancelled;
    retailPricePerBrick;
    totalRetailPrice;
    volumeDiscount;
    presaleDiscount;
    capitalGain;
    constructor(data) {
        this.id = data.id;
        this.paymentPlanId = data.paymentPlanId;
        this.folio = data.folio;
        this.date = Date(data.createdAt);
        this.property = data.property;
        this.numberOfBricks = data.quantity;
        this.totalInvested = new Money(data.totalPaid);
        this.hasBeenDeliverFunds = data.fundsDelivered;
        this.isActive = !data.isCancelled;
        this.isCancelled = data.isCancelled;
        this.retailPricePerBrick = new Money(data.retailPricePerBrick);
        this.totalRetailPrice = new Money(data.totalRetailPrice);
        this.volumeDiscount = {
            amountPerBrick: new Money(data.volumeDiscountAmountPerBrick),
            percentPerBrick: data.volumeDiscountPercentagePerBrick,
            pricePerBrick: new Money(data.volumeDiscountPricePerBrick),
            totalSavings: new Money(data.volumeDiscountTotalSavings)
        };
        this.presaleDiscount = {
            amountPerBrick: new Money(data.presaleDiscountAmountPerBrick),
            prcentPerBrick: data.presaleDiscountPercentagePerBrick,
            pricePerBrick: new Money(data.presaleDiscountPricePerBrick),
            totalSavings: new Money(data.presaleDiscountTotalSavings)
        };
        this.capitalGain = {
            amount: new Money(data.totalCapitalGain),
            percent: data.totalCapitalGainPercentage
        };
    }
}
