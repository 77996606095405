import EnrollmentInvestor from '../EnrollmentInvestor';
import HundredBricksAPI from '../../HundredBricksAPI';
import { APIError } from '../../../api';
import CURPAlreadyRegistered from './CURPAlreadyRegistered';
import CURPAlreadyRejected from './CURPAlreadyRejected';
import InvalidCURP from './InvalidCURP';
import InvestorNotHasAnCURPRegistered from './InvestorNotHasAnCURPRegistered';
import NotHasSuggestedCURP from './NotHasSuggestedCURP';
import RegisterCURPError from './RegisterCURPError';
import SuggestedCURP from './SuggestedCURP';
import SuggestedCURPError from './SuggestedCURPError';
import UpdateCURPError from './UpdateCURPError';
class CURPAPI extends HundredBricksAPI {
    async suggested() {
        try {
            const { data } = await this.client.get('/v2/profile/curp/suggestion');
            return new SuggestedCURP(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVESTOR_HAS_NOT_CURP_SUGGESTED':
                        throw new NotHasSuggestedCURP({ cause: error });
                    default:
                        throw new SuggestedCURPError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new SuggestedCURPError('Unexpected error while getting a suggested CURP', { cause: error });
            }
            else {
                throw new SuggestedCURPError(`Unexpected error while getting a suggested CURP: ${error}`);
            }
        }
    }
    async register(curp) {
        try {
            const { data } = await this.client.post('/v2/profile/curp', { curp });
            return new EnrollmentInvestor(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_CURP':
                        throw new InvalidCURP(curp);
                    case 'CURP_ALREADY_REGISTERED':
                        throw new CURPAlreadyRegistered(curp, { cause: error });
                    default:
                        throw new RegisterCURPError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new RegisterCURPError('Unexpected error while registering investors CURP', { cause: error });
            }
            else {
                throw new RegisterCURPError(`Unexpected error while registering investors CURP: ${error}`);
            }
        }
    }
    async update(curp) {
        try {
            const { data } = await this.client.post('/v2/profile/curp/correct', { curp });
            return new EnrollmentInvestor(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'CURP_NOT_FOUND':
                        throw new InvestorNotHasAnCURPRegistered();
                    case 'CURP_ALREADY_REJECTED':
                        throw new CURPAlreadyRejected();
                    case 'CURP_ALREADY_REGISTERED':
                        throw new CURPAlreadyRegistered(curp, { cause: error });
                    default:
                        throw new UpdateCURPError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new UpdateCURPError('Unexpected error while updating investors CURP');
            }
            else {
                throw new UpdateCURPError(`Unexpected error while updating investors CURP: ${error}`);
            }
        }
    }
}
export default CURPAPI;
