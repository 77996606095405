import { useEffect, useCallback, useState } from 'react';

import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { TraderInvestmentAccount } from '@api/entities/Account';

import useAPI from '../../hooks/useAPI';
import useRequest from '../../hooks/useRequest';
import useProfile from '../profile/useProfile';
import useErrorReporter from '../errorReporter/useErrorReporter';

import insightService from './insightService';
import {
  CrowdfundingEventReporter,
  InvestorReporter,
  InvoiceEventReporter,
  LogInEventsReporter,
  PurchaseEventsReporter,
  SecondaryMarketEventReporter,
  SellEventsReporter,
  SignUpEventsReporter,
  UpgradeAccountEventsReporter
} from './reporters';

/** All the insights module/service should be deeply refactored. Please DO NOT maintain this code
  * anymore, use your time to build a better version of this module instead.
  *
  * Any required change on this file please contact Abiee before
  */

const dropInvestorSession = () => insightService.anonymizeInvestorIdentity();

const getSummaryAccountValue = apis => async () => {
  const { apiAccountValue, apiCrowdfundingInvestment, apiSecondaryMarket } = apis;

  const [accountValue, crowdfundingNumberOfBricks, secondaryMarketNumberOfBricks] = (
    await Promise.all([
      apiAccountValue.getAccountValueSummary(),
      apiCrowdfundingInvestment.getNumberOfBricks(),
      apiSecondaryMarket.getNumberOfBricks()
    ])
  );

  return new TraderInvestmentAccount(
    accountValue,
    crowdfundingNumberOfBricks,
    secondaryMarketNumberOfBricks
  );
};

const useInsightsService = () => {
  const api = useAPI();
  const { investments } = useHundredBricksAPI();
  const { profile, legacyProfile } = useProfile();
  const errorReporter = useErrorReporter();

  // eslint-disable-next-line react/hook-use-state
  const [investorReporter] = useState(new InvestorReporter(insightService));

  const apisAccountValue = {
    apiAccountValue: investments,
    apiCrowdfundingInvestment: api.crowdfundingInvestment,
    apiSecondaryMarket: api.secondaryMarket
  };

  const [
    traderInvestmentAccount, , ,
    getTradingAccountProperties
  ] = useRequest(getSummaryAccountValue(apisAccountValue));

  const [
    responseFiscalRegime, , ,
    getFiscalRegimeProperties
  ] = useRequest(api.legacy.invoicing.getFiscalRegime);

  const reportInsight = useCallback((func, ...theArgs) => {
    try {
      func(...theArgs);
    } catch (error) {
      errorReporter.critical(error);
    }
  }, [errorReporter]);

  const setInvestorSession = () => {
    if (profile?.id) {
      reportInsight(investorReporter.setSession, profile.id);
    }
  };

  const setInvestorsProperties = () => {
    if (profile.canBuy()) {
      reportInsight(getTradingAccountProperties);
      reportInsight(getFiscalRegimeProperties);
    }
  };

  useEffect(() => {
    reportInsight(
      investorReporter.setProfile,
      profile,
      responseFiscalRegime,
      legacyProfile?.hasAutomaticInvoicing
    );

    reportInsight(investorReporter.setHistoricalProperties, profile, traderInvestmentAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, responseFiscalRegime, traderInvestmentAccount]);

  useEffect(() => {
    if (traderInvestmentAccount && Object.keys(traderInvestmentAccount).length > 0) {
      reportInsight(investorReporter.setAccountData, traderInvestmentAccount);
    }
  }, [investorReporter, reportInsight, traderInvestmentAccount]);

  return ({
    reportInsight,
    setInvestorSession,
    setInvestorsProperties,
    crowdfundingEvent: new CrowdfundingEventReporter(insightService),
    authenticationEvent: new LogInEventsReporter(insightService),
    purchaseEvent: new PurchaseEventsReporter(insightService),
    sellEvent: new SellEventsReporter(insightService),
    secondaryMarketEvent: new SecondaryMarketEventReporter(insightService),
    signUp: new SignUpEventsReporter(insightService),
    invoiceEvent: new InvoiceEventReporter(insightService),
    upgradeAccountEvent: new UpgradeAccountEventsReporter(insightService)
  });
};

export { dropInvestorSession };

export default useInsightsService;
