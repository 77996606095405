/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useEffect } from 'react';
import TargetDateOutOfRangeError from './TargetDateOutOfRangeError';
const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;
const MILLISECONDS_IN_SECOND = 1_000;
const MILLISECONDS_IN_MINUTE = 60_000;
const calculateTimeLeft = (targetDate) => {
    const currentDate = new Date();
    if (targetDate.getTime() < currentDate.getTime()) {
        throw new TargetDateOutOfRangeError();
    }
    const localTargetDate = new Date(targetDate.getTime() + targetDate.getTimezoneOffset() * MILLISECONDS_IN_MINUTE);
    const difference = localTargetDate.getTime() - currentDate.getTime();
    const days = Math.floor(difference / (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR * HOURS_IN_DAY));
    const hours = Math.floor((difference / (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR)) % HOURS_IN_DAY);
    const minutes = Math.floor((difference / (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE)) % MINUTES_IN_HOUR);
    const seconds = Math.floor((difference / MILLISECONDS_IN_SECOND) % SECONDS_IN_MINUTE);
    return {
        days,
        hours,
        minutes,
        seconds
    };
};
const useCounter = (targetDate) => {
    const [timeLeft, setTimeLeft] = useState();
    const [error, setError] = useState();
    useEffect(() => {
        try {
            setTimeLeft(calculateTimeLeft(targetDate));
        }
        catch (e) {
            if (e instanceof TargetDateOutOfRangeError) {
                setError(e);
            }
            else {
                setError(new Error('An error occurred while calculating the time period'));
            }
        }
    }, []);
    useMemo(() => {
        let interval;
        if (!error) {
            interval = setInterval(() => {
                setTimeLeft(calculateTimeLeft(targetDate));
            }, MILLISECONDS_IN_SECOND);
        }
        return () => clearInterval(interval);
    }, [targetDate]);
    return [timeLeft, error];
};
export default useCounter;
