import HundredBricksAPI from '@lib/hundredBricksAPI/HundredBricksAPI';
import { APIError } from '@lib/api';
import BalanceInvalidPropertyId from './BalanceInvalidPropertyId';
import BalancePropertyIsNotRegistered from './BalancePropertyIsNotRegistered';
import CurrentBalanceInvalidPropertyId from './CurrentBalanceInvalidPropertyId';
import CurrentBalancePropertyIsNotRegistered from './CurrentBalancePropertyIsNotRegistered';
import CurrentFundsBalance from './CurrentFundsBalance';
import CurrentFundsBalanceError from './CurrentFundsBalanceError';
import HistoricalFundsBalance from './HistoricalFundsBalance';
import HistoricalFundsBalanceError from './HistoricalFundsBalanceError';
import InvalidPropertyId from './InvalidPropertyId';
import PropertyIsNotRegistered from './PropertyIsNotRegistered';
import RevolvingFundIsNotSet from './RevolvingFundIsNotSet';
import RevolvingFunds from './RevolvingFunds';
import RevolvingFundsError from './RevolvingFundsError';
import RevolvingFundsInvalidQueryParam from './RevolvingFundsInvalidQueryParam';
class RevolvingFundsAPI extends HundredBricksAPI {
    async getRevolvingFunds(propertyId, params) {
        try {
            const { data } = await this.client.get(`v2/properties/${propertyId}/fund/movements`, { params });
            return new RevolvingFunds(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                const { perPage, page, type, startDate, endDate } = params;
                switch (error.code) {
                    case 'PAGE_CANNOT_BE_LESS_THAN_ONE':
                        throw new RevolvingFundsInvalidQueryParam({ page }, { cause: error });
                    case 'PER_PAGE_CANNOT_BE_LESS_THAN_ONE':
                        throw new RevolvingFundsInvalidQueryParam({ perPage }, { cause: error });
                    case 'INVALID_PAGE':
                        throw new RevolvingFundsInvalidQueryParam({ page }, { cause: error });
                    case 'INVALID_PER_PAGE':
                        throw new RevolvingFundsInvalidQueryParam({ perPage }, { cause: error });
                    case 'INVALID_MOVEMENT_TYPE':
                        throw new RevolvingFundsInvalidQueryParam({ type }, { cause: error });
                    case 'INVALID_PROPERTY_ID':
                        throw new InvalidPropertyId(propertyId, { cause: error });
                    case 'PROPERTY_NOT_FOUND':
                        throw new PropertyIsNotRegistered(propertyId, { cause: error });
                    case 'REVOLVING_FUND_IS_NOT_SET':
                        throw new RevolvingFundIsNotSet(propertyId, { cause: error });
                    case 'INVALID_START_DATE':
                        throw new RevolvingFundsInvalidQueryParam({ startDate }, { cause: error });
                    case 'INVALID_END_DATE':
                        throw new RevolvingFundsInvalidQueryParam({ endDate }, { cause: error });
                    case 'INVALID_DATE_RANGE':
                        throw new RevolvingFundsInvalidQueryParam({ startDate, endDate }, { cause: error });
                    default:
                        throw new RevolvingFundsError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new RevolvingFundsError('Unexpected error when obtaining revolving funds', { cause: error });
            }
            else {
                throw new RevolvingFundsError(`Unexpected error when obtaining revolving funds: '${error}'`);
            }
        }
    }
    async getHistoricalFundsBalance(propertyId) {
        try {
            const { data } = await this.client.get(`v2/properties/${propertyId}/fund/historicalBalance`);
            return new HistoricalFundsBalance({
                targetAmount: data.targetAmount,
                currentBalance: data.currentBalance,
                historicalBalance: data.historicalBalance
            });
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_ID':
                        throw new BalanceInvalidPropertyId(propertyId, { cause: error });
                    case 'PROPERTY_NOT_FOUND':
                        throw new BalancePropertyIsNotRegistered(propertyId, { cause: error });
                    default:
                        throw new HistoricalFundsBalanceError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new HistoricalFundsBalanceError('Unexpected error when obtaining historical funds balance', { cause: error });
            }
            else {
                throw new HistoricalFundsBalanceError(`Unexpected error when obtaining historical funds balance: '${error}'`);
            }
        }
    }
    async getCurrentFundsBalance(propertyId) {
        try {
            const { data } = await this.client.get(`v2/properties/${propertyId}/bricksFund`);
            return new CurrentFundsBalance(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_PROPERTY_ID':
                        throw new CurrentBalanceInvalidPropertyId(propertyId, { cause: error });
                    case 'PROPERTY_NOT_FOUND':
                        throw new CurrentBalancePropertyIsNotRegistered(propertyId, { cause: error });
                    default:
                        throw new CurrentFundsBalanceError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new CurrentFundsBalanceError('Unexpected error when obtaining current funds balance', { cause: error });
            }
            else {
                throw new CurrentFundsBalanceError(`Unexpected error when obtaining current funds balance: '${error}'`);
            }
        }
    }
}
export default RevolvingFundsAPI;
