import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Money } from '@types';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const PaymentPlanApproved = (props) => {
    const { notification } = props;
    const property = notification.params?.crowdfundingProperty;
    const agreedAmount = new Money(notification.params?.agreedAmount);
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Has adquirido un plan de pagos" }), _jsxs(Paragraph, { children: ["Has adquirido un plan de pagos por", _jsx(Bold, { children: ` ${agreedAmount.toString()} ` }), "en la propiedad", _jsx(Bold, { children: ` ${property.name}.` })] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default PaymentPlanApproved;
