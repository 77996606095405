import styled from 'styled-components';

import { Modal } from '@components';

const ModalWrapper = styled(Modal)`
  max-width: ${({ theme }) => theme.spacing(50)};

  p {
    text-align: center;
    margin: 0;
  }
`;

export default ModalWrapper;
