import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Placeholder = ({
  height,
  width,
  borderRadius,
  ...rest
}) => {
  return (
    <div styleName="placeholder" {...rest} style={{ width, height, borderRadius }}>
      <div styleName="overlay-bar" />
    </div>
  );
};

Placeholder.propTypes = {
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

Placeholder.defaultProps = {
  borderRadius: 0,
};

export default Placeholder;
