class FiscalData {
    investorId;
    investorName;
    taxId;
    fiscalRegime;
    fiscalZipCode;
    constructor(data) {
        this.investorId = data.investorId;
        this.investorName = data.name;
        this.taxId = data.taxId;
        this.fiscalRegime = data.fiscalRegime;
        this.fiscalZipCode = data.fiscalZipCode;
    }
}
export default FiscalData;
