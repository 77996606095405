class RentalUnit {
    rented;
    available;
    total;
    constructor(data) {
        this.rented = data.rented;
        this.available = data.available;
        this.total = data.total;
    }
}
export default RentalUnit;
