class Amenity {
    id;
    title;
    category;
    quantity;
    measurementUnit;
    status;
    createdAt;
    property;
    constructor(data) {
        this.id = data.id;
        this.title = data.title;
        this.category = data.type;
        this.quantity = data.quantity;
        this.measurementUnit = data.measurementUnit;
        this.status = data.status;
        this.createdAt = new Date(data.createdAt);
        this.property = data.property;
    }
}
export default Amenity;
