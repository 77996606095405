class BrickOnSale {
    numberOfBricks;
    numberOfBricksSellingByTrader;
    sellingPrice;
    revolvingFundAmountPerBrick;
    constructor(data) {
        this.numberOfBricks = data.numberOfBricks;
        this.numberOfBricksSellingByTrader = data.numberOfBricksSellingByTrader;
        this.sellingPrice = data.sellingPrice;
        this.revolvingFundAmountPerBrick = data.revolvingFundAmountPerBrick;
    }
}
export default BrickOnSale;
