import axios from 'axios';
const getAsRequestError = (error) => error;
const getAsErrorData = (errorData) => errorData;
const isRequestError = (error) => {
    if (axios.isAxiosError(error)) {
        return Boolean(error.response);
    }
    return false;
};
export { getAsErrorData, getAsRequestError, isRequestError };
