class AutomaticInvoicingStatus {
    name;
    pendingActivities;
    completedActivities;
    constructor(data) {
        this.name = data.status;
        this.pendingActivities = this.getActivities(data.activities.pending);
        this.completedActivities = this.getActivities(data.activities.completed);
    }
    isInactive() {
        return this.name === 'inactive';
    }
    isInProgress() {
        return this.name === 'in_progress';
    }
    isValidating() {
        return this.name === 'validating';
    }
    isWaitingForApproval() {
        return this.name === 'waiting_for_approval';
    }
    isRejected() {
        return this.name === 'rejected';
    }
    isCorrectingData() {
        return this.name === 'correcting_data';
    }
    isActive() {
        return this.name === 'active';
    }
    isActivateInProgress() {
        return this.isInProgress() || this.isCorrectingData() || this.isValidating();
    }
    isPendingToSetTaxId() {
        return this.pendingActivities.some(activity => activity === 'tax_id_updated');
    }
    isPendingToSetFiscalRegime() {
        return this.pendingActivities.some(activity => activity === 'fiscal_regime_updated');
    }
    isPendingToSetFiscalZipCode() {
        return this.pendingActivities.some(activity => activity === 'fiscal_zip_code_updated');
    }
    getActivities(activities) {
        const completedActivitiesArrayName = activities?.map(item => item.name);
        return [...new Set(completedActivitiesArrayName)];
    }
}
export default AutomaticInvoicingStatus;
