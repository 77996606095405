import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Percentage } from '@types';

import './style.scss';

const PercentageIndicator = props => {
  const { decimalPercentage } = props;
  const formattedDecimalPercentage = new Percentage(decimalPercentage).formatWithoutRounding();

  const percentageIndicatorContainerStyle = cx({
    'percentage-indicator-container': true,
    negative: decimalPercentage < 0
  });

  return (
    <div styleName={percentageIndicatorContainerStyle} tid="percentage-indicator-container">
      <div styleName="triangle" />
      <span tid="decimal-percentage">
        {formattedDecimalPercentage}
        %
      </span>
    </div>
  );
};

PercentageIndicator.propTypes = {
  decimalPercentage: PropTypes.number.isRequired
};

export default PercentageIndicator;
