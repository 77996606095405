import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from '@views/Login';
import ForgotPassword from '@views/ForgotPassword';
import UnusualAccessUnblock from '@views/UnusualAccessUnblock';
import PublicOutlet from './PublicOutlet';
const CreateAccount = lazy(() => import('../../views/Signup'));
const VerifyNewEmailRouter = lazy(() => import('../../views/Profile/UpdateEmail/VerifyNewEmailRouter'));
const PublicRoutes = () => {
    const location = useLocation();
    return (_jsxs(Routes, { children: [_jsxs(Route, { element: _jsx(PublicOutlet, {}), children: [_jsx(Route, { index: true, path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "/signup", element: _jsx(CreateAccount, {}) }), _jsx(Route, { path: "/forgot-password", element: _jsx(ForgotPassword, {}) }), _jsx(Route, { path: "/unusual-access-unblock", element: _jsx(UnusualAccessUnblock, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/login", state: { from: location } }) })] }), _jsx(Route, { path: "/update-email-process/*", element: _jsx(VerifyNewEmailRouter, {}) })] }));
};
export default PublicRoutes;
