import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useProfile } from '@lib/profile';
import { useUpgradingAccount } from '@hooks';
const BasicLevelOutlet = () => {
    const navigate = useNavigate();
    const { profile, investorBalance } = useProfile();
    const { pathname, state } = useLocation();
    const { renderInvestorPlansModal } = useUpgradingAccount();
    useEffect(() => {
        if (state?.from?.pathname) {
            navigate(state?.from.pathname);
        }
        if (pathname !== '/') {
            return;
        }
        if (profile?.status.isCorrectingData()) {
            navigate('/perfil');
        }
        else if (profile?.hasRejectedDocs()) {
            navigate('/perfil/documentos-rechazados');
        }
        else if (profile?.accountLevel?.isFrozen()) {
            renderInvestorPlansModal();
            navigate('/marketplace');
        }
        else if (investorBalance?.hasInvested()) {
            navigate('/marketplace');
        }
        else {
            navigate('/onboarding');
        }
    }, [pathname]);
    return (_jsx(Outlet, {}));
};
export default BasicLevelOutlet;
