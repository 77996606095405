import { Leaseholder } from '../../leaseholders';
class PresaleLeaseholder extends Leaseholder {
    photoURL;
    createdAt;
    updatedAt;
    constructor(leaseholderData) {
        super(leaseholderData);
        this.photoURL = leaseholderData.photoURL;
        this.createdAt = new Date(leaseholderData.createdAt);
        this.updatedAt = new Date(leaseholderData.updatedAt);
    }
}
export default PresaleLeaseholder;
