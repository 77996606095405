import React from 'react';

import { goToExternalLink } from '@utils';
import {
  ActionMediaContainer,
  ActionText,
  ContentMediaContainer,
  MediaContainer,
  TitleMediaContainer
} from '@components';

import { curpViewImage } from '../../../../../assets/images/EnrollmentStepImages';
import { DescriptionText, TitleText } from '../../EnrollmentMediaContainer.style';

const URL_TO_GET_CURP = 'https://www.gob.mx/curp/';
const CURP_IMAGE_ALT = 'Señalización de que la CURP en la INE se ubica al final de los datos';

const CurpMediaContainer = () => (
  <MediaContainer>
    <TitleMediaContainer>
      <TitleText>Encuentra tu CURP</TitleText>
      <p>en la parte del frente de tu INE o IFE</p>
    </TitleMediaContainer>

    <ContentMediaContainer>
      <img src={curpViewImage} alt={CURP_IMAGE_ALT} />
    </ContentMediaContainer>

    <ActionMediaContainer>
      <DescriptionText>
        ¿No conoces tu CURP?
        <br />
        puedes consultarla en el sitio web de
        {' '}
        <ActionText
          id="go-to-renapo"
          color="info"
          onClick={() => goToExternalLink(URL_TO_GET_CURP)}
        >
          RENAPO
        </ActionText>
        .
      </DescriptionText>
    </ActionMediaContainer>
  </MediaContainer>
);

export default CurpMediaContainer;
