import APIError from '../APIError';

const INVESTOR_NOT_FOUND = 'INVESTOR_NOT_FOUND';

class ProfileError extends APIError {
  constructor(code, name, details = null) {
    super(code, name || 'ProfileError', details);
  }

  isInvestorNotFound() {
    return this._code === INVESTOR_NOT_FOUND;
  }
}

export default ProfileError;
