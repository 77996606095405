import PropTypes from 'prop-types';
import styled from 'styled-components';

const HiddenComponent = styled.span`
  position: relative;
  top: ${({ theme }) => theme.spacing(0.5)};
  padding: 0 ${({ theme }) => theme.spacing(1)};
  min-height: ${({ theme }) => theme.spacing(2)};
  min-width: ${({ theme }) => theme.spacing(2)};
`;

HiddenComponent.propTypes = {
  id: PropTypes.string.isRequired
};

export default HiddenComponent;
