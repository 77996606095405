class FiscalRegime {
    name;
    status;
    approvedAt;
    rejectionReason;
    rejectedAt;
    proofUrl;
    proofUploadedAt;
    constructor(fiscalRegimeData) {
        this.name = fiscalRegimeData?.name;
        this.status = fiscalRegimeData?.status;
        this.approvedAt = fiscalRegimeData?.approvedAt;
        this.rejectionReason = fiscalRegimeData?.rejectionReason;
        this.rejectedAt = fiscalRegimeData?.rejectedAt;
        this.proofUrl = fiscalRegimeData?.proofUrl;
        this.proofUploadedAt = fiscalRegimeData?.proofUploadedAt;
    }
}
export default FiscalRegime;
