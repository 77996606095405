class Demographics {
    gender;
    birthdate;
    birthCountry;
    birthState;
    nationality;
    constructor(data) {
        this.gender = data?.gender;
        this.birthdate = data?.birthdate;
        this.birthCountry = data?.birthCountry;
        this.birthState = data?.birthState;
        this.nationality = data?.nationality;
    }
}
export default Demographics;
