const getBackgroundColorSentence = ({ theme, backgroundColor }) => {
  if (backgroundColor) {
    const [splitOne, splitTwo] = backgroundColor.split('-');

    if (splitTwo) return `background-color: ${theme.palette[splitOne][splitTwo]};`;

    return `background-color: ${theme.palette[splitOne]};`;
  }

  return null;
};

export default getBackgroundColorSentence;
