import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import Title from '../Title';

import {
  ActionButtons,
  ContentWrapper,
  TextModal
} from './SignW8BENContractModal.style';

const SignW8BENContractModal = ({ isOpen, navigateRoute, onClose }) => {
  const navigate = useNavigate();

  const handleNavigateToSignW8 = () => {
    navigate(navigateRoute);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ContentWrapper>
        <Title>
          ¡Firma el formulario W-8BEN para poder invertir en propiedades de Estados Unidos!
        </Title>

        <Icon
          name="icInternationalContract"
          alt="Firma tu formulario W8BEN"
          width={84}
          height={84}
        />

        <TextModal>
          No te preocupes, es un proceso sencillo y rápido que te permitirá convertirte en un
          inversionista inmobiliario internacional.
        </TextModal>
      </ContentWrapper>

      <ActionButtons>
        <Button
          id="cancel"
          color="secondary"
          onClick={onClose}
        >
          Cancelar
        </Button>

        <Button id="go-to-sign" onClick={handleNavigateToSignW8}>
          Ir a firmar
        </Button>
      </ActionButtons>
    </Modal>
  );
};

SignW8BENContractModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  navigateRoute: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SignW8BENContractModal;
