import React from 'react';

import {
  ActionMediaContainer,
  ContentMediaContainer,
  MediaContainer,
  TitleMediaContainer
} from '@components';

import { registerPhoneViewImage } from '../../../../../assets/images/EnrollmentStepImages';
import { DescriptionText, TitleText } from '../../EnrollmentMediaContainer.style';

const REGISTER_PHONE_IMAGE_ALT = 'Dinero de la alcancía pasando a inversiones';

const RegisterPhoneMediaContainer = () => (
  <MediaContainer>
    <TitleMediaContainer>
      <TitleText>Descubre la tranquilidad de invertir en 100 Ladrillos</TitleText>
    </TitleMediaContainer>

    <ContentMediaContainer>
      <img src={registerPhoneViewImage} alt={REGISTER_PHONE_IMAGE_ALT} />
    </ContentMediaContainer>

    <ActionMediaContainer>
      <DescriptionText>
        Nuestro compromiso es ayudarte a construir un futuro financiero sólido, disfruta de la
        tranquilidad de obtener ingresos recurrentes y de saber que tu patrimonio está
        creciendo mes a mes.
      </DescriptionText>
    </ActionMediaContainer>
  </MediaContainer>
);

export default RegisterPhoneMediaContainer;
