class Payment {
    id;
    amount;
    enabledAt;
    paydayLimit;
    paidAt;
    status;
    type;
    constructor(data) {
        this.id = data.id;
        this.amount = data.amount;
        this.status = data.status;
        this.enabledAt = new Date(data.enabledAt);
        this.paydayLimit = data?.paydayLimit ? new Date(data.paydayLimit) : null;
        this.paidAt = data.paidAt ? new Date(data.paidAt) : undefined;
        this.type = data.type;
    }
    isInitial() {
        return this.type === 'initial';
    }
    isMonthly() {
        return this.type === 'monthly';
    }
    isDisabled() {
        return this.status === 'disabled';
    }
    isReadyToPay() {
        return this.status === 'ready_to_pay';
    }
    isCancelled() {
        return this.status === 'cancelled';
    }
    isPaid() {
        return this.status === 'paid';
    }
    isRejected() {
        return this.status === 'rejected';
    }
    isInProcess() {
        return this.status === 'in_process';
    }
}
export default Payment;
