import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Money } from '@types';
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const WithdrawalRequest = (props) => {
    const { notification } = props;
    const requestedAmount = new Money(notification.params?.requestedAmount).toString();
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Hemos recibido tu solicitud de retiro de fondos" }), _jsxs(Paragraph, { children: ["Tu solicitud de retiro de", _jsx(Bold, { children: ` ${requestedAmount} ` }), "ha sido recibida. Estamos procesando tu solicitud y, tras la validaci\u00F3n, realizaremos la transferencia a tu cuenta bancaria. Te notificaremos cuando los fondos est\u00E9n en camino."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default WithdrawalRequest;
