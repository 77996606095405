import React from 'react';

import { Paragraph, Subtitle } from '@components/Styled';
import { notificationsInPhoneImage } from '@assets/images/NotificationImages';

import { NotificationContainer } from '../NotificationCenter.style';

const EmptyListNotification = () => (
  <NotificationContainer>
    <Subtitle
      level="2"
    >
      Ocurrió un error
    </Subtitle>

    <img
      src={notificationsInPhoneImage}
      alt="Mano haciendo clic sobre ícono de notificaciones en un celular"
    />

    <Paragraph align="center">
      Por favor, vuelve a intentarlo.
    </Paragraph>
  </NotificationContainer>
);

export default EmptyListNotification;
