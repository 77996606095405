class CrowdfundingProperties {
    list;
    constructor(crowdfundingProperties) {
        this.list = crowdfundingProperties;
    }
    getNumberOfProperties() {
        return this.list.length;
    }
    getPropertiesByStrategy(strategy) {
        return this.list.filter(property => property.investmentStrategy === strategy);
    }
}
export default CrowdfundingProperties;
