import APIError from '../APIError';

const INVALID_ID = 'INVALID_ID';
const INVALID_CROWDFUNDING_PROPERTY_ID = 'INVALID_CROWDFUNDING_PROPERTY_ID';
const CROWDFUNDING_PROPERTY_NOT_FOUND = 'CROWDFUNDING_PROPERTY_NOT_FOUND';

class CrowdfundingPropertyError extends APIError {
  constructor(code, details = null) {
    super(code, 'CrowdfundingPropertyError', details);
  }

  hasInvalidId() {
    return this._code === INVALID_ID || this._code === INVALID_CROWDFUNDING_PROPERTY_ID;
  }

  crowdfundingPropertyNotFound() {
    return this._code === CROWDFUNDING_PROPERTY_NOT_FOUND;
  }
}

export default CrowdfundingPropertyError;
