import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { useForm, useGeolocation, useRequest } from '@hooks';
import { renderSomethingWentWrong } from '@utils';
import { useErrorReporter } from '@lib/errorReporter';
import { useAuth } from '@lib/authentication';
import { useInsightsService } from '@lib/insightsService';
import { useProfile } from '@lib/profile';
import {
  ActionText,
  Button,
  Form,
  Modal,
  PasswordField,
  toast
} from '@components';
import {
  AuthenticationAttemptsLimitReached,
  DistanceExceededFromLastConnection,
  InvalidCredentials,
  InvestorHasAlreadyAnActiveSession,
  UnusualAccess
} from '@lib/hundredBricksAPI/authentication';

import {
  ModalTitle,
  ButtonContainer,
  LinksContainer,
  VPNErrorContainer,
  OptionContainer,
  ModalMessageContainer
} from './RenewSessionModal.style';
import RenewSessionMessage from './RenewSessionMessage';
import RenewSessionOptions from './RenewSessionOptions';

const passwordSchema = {
  password: {
    required: true
  }
};

const ERROR_INCORRECT_PASSWORD = 'La contraseña es inválida.';
const ERROR_LOCATION_BLOCKED = 'Ubicación desactivada. Activa los permisos de ubicación '
  + 'en tu navegador para acceder a tu cuenta.';

const RenewSessionModal = ({ isOpen, onClose }) => {
  const api = useHundredBricksAPI();
  const navigate = useNavigate();
  const form = useForm({ initialValues: { password: '' }, schema: passwordSchema });
  const errorReporter = useErrorReporter();
  const { authenticationEvent, reportInsight } = useInsightsService();
  const { getCurrentPosition } = useGeolocation();
  const { profile, isLoading: isLoadingProfile } = useProfile();
  const [responseSendEmail, , errorSendEmail, requestSendEmail] = useRequest(
    api.authentication.resendUnusualCodeAccess
  );

  const {
    renewSession,
    error: loginError,
    isLoading: isAuthenticating,
    logout,
    clearSession,
    token
  } = useAuth();

  const email = profile.email.address;
  const [tokenValue] = useState(token?.value);

  const onSubmit = (latitude, longitude) => {
    renewSession(email, form.values.password, latitude, longitude);
  };

  const handleSubmit = async () => {
    getCurrentPosition(coords => {
      const { latitude, longitude } = coords;

      onSubmit(latitude, longitude);
    }, () => {
      toast.danger(ERROR_LOCATION_BLOCKED);
    });
  };

  const handleForgotPassword = () => {
    clearSession();
    navigate('/forgot-password');
  };

  const resendEmailUnusualAccess = () => {
    requestSendEmail(email);
  };

  const handleLoginError = error => {
    reportInsight(authenticationEvent.logInError, error);

    if (error instanceof InvalidCredentials) {
      form.addError({
        field: 'password',
        message: ERROR_INCORRECT_PASSWORD
      });
    } else {
      errorReporter.critical(error);
      renderSomethingWentWrong();
    }
  };

  const hasModalError = loginError instanceof AuthenticationAttemptsLimitReached
    || loginError instanceof InvestorHasAlreadyAnActiveSession
    || loginError instanceof UnusualAccess;

  useEffect(() => {
    if (token && tokenValue !== token.value) {
      onClose();
      form.resetValues();
    }
  }, [token]);

  useEffect(() => {
    if (loginError && !hasModalError) {
      handleLoginError(loginError);
    }
  }, [loginError]);

  useEffect(() => {
    if (responseSendEmail) {
      toast.success('El correo se envió con éxito');
    }
  }, [responseSendEmail]);

  useEffect(() => {
    if (errorSendEmail) {
      toast.danger('Algo salió mal al enviar el correo, inténtalo de nuevo');
      errorReporter.critical(errorSendEmail);
    }
  }, [errorSendEmail]);

  return (
    <Modal isOpen={isOpen} preventClose>
      <ModalTitle>Por inactividad, tu sesión ha expirado</ModalTitle>

      {hasModalError
        ? (
          <ModalMessageContainer>
            <RenewSessionMessage error={loginError} />

            <OptionContainer>
              <RenewSessionOptions
                error={loginError}
                onConfirm={resendEmailUnusualAccess}
                onCancel={logout}
              />
            </OptionContainer>
          </ModalMessageContainer>
        )
        : (
          <div>
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
              <PasswordField
                id="password"
                focus
                label="Reactiva tu sesión para continuar explorando."
                placeholder="Escribe tu contraseña"
                value={form.values.password}
                {...form.fieldProps('password')}
              />

              {
                loginError instanceof DistanceExceededFromLastConnection && (
                  <VPNErrorContainer>
                    Tenemos un inconveniente al tratar de iniciar tu sesión.
                    ¿Tienes activa una VPN o un proxy?
                    Desactívala y vuelve a intentarlo.
                  </VPNErrorContainer>
                )
              }

              <ButtonContainer>
                <Button
                  type="submit"
                  isLoading={isAuthenticating || isLoadingProfile}
                >
                  Reactivar sesión
                </Button>
              </ButtonContainer>
            </Form>

            <LinksContainer>
              <ActionText
                color="info"
                onClick={handleForgotPassword}
                disabled={isAuthenticating || isLoadingProfile}
              >
                Restablece tu contraseña
              </ActionText>

              <ActionText
                color="info"
                onClick={logout}
                disabled={isAuthenticating || isLoadingProfile}
              >
                Cerrar sesión
              </ActionText>
            </LinksContainer>
          </div>
        )}
    </Modal>
  );
};

RenewSessionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RenewSessionModal;
