import { jsx as _jsx } from "react/jsx-runtime";
import { useProfile } from '@lib/profile';
import PendingLevelRoutes from './pendingLevel/PendingLevelRoutes';
import BasicLevelRoutes from './basicLevel/BasicLevelRoutes';
import LimitlessLevelRoutes from './limitlessLevel/LimitlessLevelRoutes';
const PrivateRoutes = () => {
    const { profile } = useProfile();
    if (profile?.accountLevel.isPendingToGetLevel()) {
        return _jsx(PendingLevelRoutes, {});
    }
    if (profile?.accountLevel.isBasic()) {
        return _jsx(BasicLevelRoutes, {});
    }
    return (_jsx(LimitlessLevelRoutes, {}));
};
export default PrivateRoutes;
