import codeAreaCountries from '../codeAreaCountries';
import regularExpressions from '../regularExpressions';

const regex = regularExpressions;
const PHONE_LENGTH = 10;
const formatPhone = phone => phone?.replace(regex.phoneFormat, '$1 $2 $3');

const formatPhoneNumber = () => {
  const destructuringPhoneFound = (country, number) => {
    if (country && number) {
      const codeAreaOption = codeAreaCountries.find(({ id: countryISO }) => countryISO === country);

      if (codeAreaOption) {
        const { code } = codeAreaOption;

        const phone = number.replace(code, '');

        return { countryCode: code, phone, codeAreaOption };
      }
    }

    return '';
  };

  const convertPhoneToHundredBricksFormat = phone => {
    // TODO return Error: given value is not valid phone
    if (typeof phone !== 'string' && (!phone || (phone && phone.length > PHONE_LENGTH))) return '';

    const number = phone.substring((phone.length - PHONE_LENGTH), phone.length);
    const code = phone.substring(0, (phone.length - PHONE_LENGTH));
    const isValidCodeArea = codeAreaCountries.find(({ code: codeCountry }) => codeCountry === code);

    if (!isValidCodeArea) {
      // TODO return Error: code area is not valid
      return '';
    }

    return `(${code}) ${formatPhone(number)}`;
  };

  return { destructuringPhoneFound, convertPhoneToHundredBricksFormat };
};

export default formatPhoneNumber;
