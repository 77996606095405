import { useContext } from 'react';
import ErrorReporterContext from './ErrorReporterContext';
class ErrorReporterNotInitialized extends Error {
    constructor() {
        super('Error reporter is not initialized');
    }
}
const useErrorReporter = () => {
    const context = useContext(ErrorReporterContext);
    if (context === undefined) {
        throw new ErrorReporterNotInitialized();
    }
    return context;
};
export default useErrorReporter;
