const LOCK_CODES = {
  ADMIN_SOFT_LOCK: 'ADMIN_SOFT_LOCK',
  ADMIN_SHADOW_LOCK: 'ADMIN_SHADOW_LOCK'
};

function AccountLockCodes() {
  const isSoftLockCode = lockCode => lockCode === LOCK_CODES.ADMIN_SOFT_LOCK;

  const isShadowLockCode = lockCode => lockCode === LOCK_CODES.ADMIN_SHADOW_LOCK;

  const isLockCodeValid = lockCode => Object.keys(LOCK_CODES).includes(lockCode);

  const getSoftLockCode = () => LOCK_CODES.ADMIN_SOFT_LOCK;

  const getShadowLockCode = () => LOCK_CODES.ADMIN_SHADOW_LOCK;

  return {
    isSoftLockCode,
    isShadowLockCode,
    isLockCodeValid,
    getSoftLockCode,
    getShadowLockCode
  };
}

export default AccountLockCodes;
