import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import Text from '../Text';
import Title from '../Title';

import { ActionsWrapper, InfoWrapper } from './RFCIdentifierModal.style';

const RFCIdentifierModal = ({ handleClose, isOpen }) => {
  const navigate = useNavigate();

  const goToCompleteRfc = () => navigate('/perfil');

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <Title>¡Completa tu RFC con homoclave!</Title>

      <Icon name="icRfc" alt="RFC con homoclave" height={84} width={84} />

      <InfoWrapper>
        <Text>
          Para futuros movimientos en propiedades terminadas es necesario que tu
          <strong> RFC </strong>
          este completo con
          <strong> homoclave. </strong>
          Asegúrate que sea tu
          <strong> RFC </strong>
          ya que una vez creada la factura, no habrá manera de repetirla. Con esto podrás:
        </Text>

        <ul>
          <li>
            - Recibir tus facturas de comisiones por administración (liberar rentas).
          </li>

          <li>
            - Recibir tus facturas de comisiones por venta de Ladrillos.
          </li>
        </ul>
      </InfoWrapper>

      <ActionsWrapper>
        <Button id="complete-after-button" color="secondary" onClick={handleClose}>
          Completar después
        </Button>

        <Button id="go-to-fill-rfc-button" onClick={goToCompleteRfc}>
          Ir a completar RFC
        </Button>
      </ActionsWrapper>
    </Modal>
  );
};

RFCIdentifierModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default RFCIdentifierModal;
