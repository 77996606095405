import HundredBricksAPI from '@lib/hundredBricksAPI/HundredBricksAPI';
import { APIError } from '@lib/api';
import RiskForm from './RiskForm';
import RiskFormAlreadySigned from './RiskFormAlreadySigned';
import SessionWithoutLocationCanNotSignRiskForm from './SessionWithoutLocationCanNotSignRiskForm';
import SignRiskFormError from './SignRiskFormError';
class DocumentsAPI extends HundredBricksAPI {
    async signRiskForm() {
        try {
            const { data } = await this.client.post('/v2/profile/documents/riskForm/sign');
            return new RiskForm(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                if (error.code === 'ALREADY_SIGNED') {
                    throw new RiskFormAlreadySigned({ cause: error });
                }
                if (error.code === 'GEOLOCATION_MISSING_IN_SESSION') {
                    throw new SessionWithoutLocationCanNotSignRiskForm({ cause: error });
                }
                throw new SignRiskFormError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new SignRiskFormError('Unexpected error while signing the riskform', { cause: error });
            }
            throw new SignRiskFormError(`Unexpected error while signing the riskform: ${error}`);
        }
    }
}
export default DocumentsAPI;
