import styled from 'styled-components';

import { typography } from './mixins';

// This component does not currently conform to the measurement and typography specifications that
// have been established in the Bricks UI project. Therefore, when updating the design standards
// within Bricks UI, it must be reflected and implemented in this component to maintain visual and
// functional coherence. It is important to review and adapt this component post-upgrade to ensure
// it complies with the new guidelines.

const Bold = styled.strong`
  ${typography};

  font-family: 'RobotoBold';
  font-weight: 700;
`;

export default Bold;
