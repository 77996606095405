class Identification {
    type;
    identificationNumber;
    status;
    errors;
    automatedValidationData;
    frontFileURL;
    backFileURL;
    fileURL;
    updatedAt;
    constructor(identificationData) {
        this.type = identificationData?.type;
        this.identificationNumber = identificationData?.identificationNumber;
        this.status = identificationData?.status;
        this.errors = identificationData?.errors || [];
        this.automatedValidationData = identificationData?.automatedValidationData;
        this.frontFileURL = identificationData?.frontFileURL;
        this.backFileURL = identificationData?.backFileURL;
        this.fileURL = identificationData?.fileURL;
        this.updatedAt = identificationData?.updatedAt;
    }
}
export default Identification;
