import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Money } from '@types';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const DispersedRents = (props) => {
    const { notification } = props;
    const netDispersed = new Money(notification.params?.netDispersed);
    const property = notification.params?.propertyName ?? '----';
    const bricksText = notification.params?.numberOfBricks === 1
        ? `${notification.params?.numberOfBricks} Ladrillo`
        : `${notification.params?.numberOfBricks ?? 0} Ladrillos`;
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: `${property} te ha pagado renta` }), _jsxs(Paragraph, { children: ["Has recibido", _jsx(Bold, { children: ` ${netDispersed.toString()} ` }), "por el pago de rentas de", _jsx(Bold, { children: ` ${bricksText} ` }), "en", _jsx(Bold, { children: ` ${property}` }), ". Recuerda que ya pagaron impuestos en EE.UU., por lo que ya est\u00E1n disponibles en tu cuenta. Visita la secci\u00F3n de \u201CMi patrimonio\u201D para ver el detalle."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default DispersedRents;
