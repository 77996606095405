import { SignedContract, UnsignedContract, SignedDocument } from '../entities';
import { SignDocumentsError, SignRequestError } from '../errors';

import GatewayAPI from './GatewayAPI';

class SignDocumentsAPI extends GatewayAPI {
  async obtainCommercialContractHTML() {
    try {
      const { data } = await this._client.get(
        '/profile/documents/commercialContract/unsigned/html'
      );

      return data;
    } catch (error) {
      return this._handleError(error, SignDocumentsError);
    }
  }

  async obtainCommercialContractPDF() {
    try {
      const { data } = await this._client.get(
        '/profile/documents/commercialContract/unsigned/pdf',
        { responseType: 'blob' }
      );

      return new UnsignedContract(data, 'COMMERCIAL_CONTRACT');
    } catch (error) {
      return this._handleError(error, SignDocumentsError);
    }
  }

  async obtainSignedCommercialContractPDF() {
    try {
      const { data } = await this._client.get('/profile/documents/commercialContract');

      const commercialContract = new SignedDocument(data);

      return new SignedContract(commercialContract, 'COMMERCIAL_CONTRACT');
    } catch (error) {
      return this._handleError(error, SignDocumentsError);
    }
  }

  async signCommercialContract(payload) {
    try {
      const { data } = await this._client.post(
        '/profile/documents/commercialContract/sign',
        payload
      );

      return data;
    } catch (error) {
      return this._handleError(error, SignDocumentsError);
    }
  }

  async signNewCommercialContractVersion(payload) {
    const commercialContractVersion = '15719-456-035759-03-02757-1023';

    try {
      const { data } = await this._client.post(
        `/profile/documents/commercialContracts/${commercialContractVersion}/sign`,
        payload
      );

      return data;
    } catch (error) {
      return this._handleError(error, SignRequestError);
    }
  }

  async obtainSignedKYCContractPDF() {
    try {
      const { data } = await this._client.get('/profile/documents/kycContract');

      const kyc = new SignedDocument(data);

      return new SignedContract(kyc, 'KYC');
    } catch (error) {
      return this._handleError(error, SignDocumentsError);
    }
  }

  async obtainW8ContractHTML() {
    try {
      const { data } = await this._client.get(
        '/profile/documents/w8Contract/unsigned/html'
      );

      return data;
    } catch (error) {
      return this._handleError(error, SignDocumentsError);
    }
  }

  async obtainW8ContractPDF() {
    try {
      const { data } = await this._client.get(
        '/profile/documents/w8Contract/unsigned/pdf',
        { responseType: 'blob' }
      );

      return new UnsignedContract(data, 'W8_BEN');
    } catch (error) {
      return this._handleError(error, SignDocumentsError);
    }
  }

  async signW8Contract(signature) {
    const payload = { signature };

    try {
      const { data } = await this._client.post(
        '/profile/documents/w8Contract/sign',
        payload
      );

      return data;
    } catch (error) {
      return this._handleError(error, SignDocumentsError);
    }
  }

  async obtainSignedW8ContractPDF() {
    try {
      const { data } = await this._client.get('/profile/documents/w8Contract');

      const w8BEN = new SignedDocument(data);

      return new SignedContract(w8BEN, 'W8_BEN');
    } catch (error) {
      return this._handleError(error, SignDocumentsError);
    }
  }

  _handleError = (error, APISignDocumentsError) => {
    if (!error.response) {
      throw APISignDocumentsError.getNetworkError();
    }

    const errorCode = error.response.data.code;
    const signDocumentsError = new APISignDocumentsError(errorCode);

    throw signDocumentsError;
  };
}

export default SignDocumentsAPI;
