import HundredBricksAPI from '@lib/hundredBricksAPI/HundredBricksAPI';
import EnrollmentInvestor from '../EnrollmentInvestor';
import { APIError } from '../../../api';
import CannotRegisterJobTitle from './CannotRegisterJobTitle';
import InvalidResourcesOwnerBirthday from './InvalidResourcesOwnerBirthday';
import RegisterRealOwnerError from './RegisterRealOwnerError';
class ResourcesAPI extends HundredBricksAPI {
    async registerJobTitle(jobTitle) {
        try {
            const { data } = await this.client.post('/v2/profile/jobTitle', { jobTitle });
            return new EnrollmentInvestor(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                throw new CannotRegisterJobTitle({ cause: error });
            }
            else if (error instanceof Error) {
                throw new CannotRegisterJobTitle('Unexpected error while registering job title', { cause: error });
            }
            else {
                throw new CannotRegisterJobTitle(`Unexpected error while registering job title: ${error}`);
            }
        }
    }
    async registerOwnership(isRealOwner, hasOwnResources, realOwnerName, realOwnerBirthdate) {
        try {
            const { data } = await this.client.post('/v2/profile/realOwner', {
                hasOwnResources,
                isRealOwner,
                realOwnerBirthdate,
                realOwnerName
            });
            return new EnrollmentInvestor(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_BIRTHDATE':
                        throw new InvalidResourcesOwnerBirthday(realOwnerBirthdate, { cause: error });
                    default:
                        throw new RegisterRealOwnerError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new RegisterRealOwnerError('Unexpected error while registering real owner', { cause: error });
            }
            else {
                throw new RegisterRealOwnerError(`Unexpected error while registering real owner: ${error}`);
            }
        }
    }
}
export default ResourcesAPI;
