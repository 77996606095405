import React from 'react';
import PropTypes from 'prop-types';

import TitleWrapper from './Title.style';

const Title = ({ children, ...props }) => (
  <TitleWrapper {...props}>{children}</TitleWrapper>
);

Title.propTypes = {
  children: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['h1', 'h3'])
};

Title.defaultProps = {
  variant: 'h3'
};

export default Title;
