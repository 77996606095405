import styled from 'styled-components';

const Aside = styled.aside`
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  background-color: white;
  z-index: 100;
  height: 100%;
  transition: 0.4s;
  width: 80%;
  position: fixed;
  border-right: 1px solid ${({ theme }) => theme.colors.neutral.gray[200]};
  flex-shrink: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    position: static;
    transform: unset;
    width: fit-content;
  }
`;

const Overlay = styled.button`
  all: unset;
  background-color: ${({ theme }) => theme.colors.neutral.gray[900]};
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: ${({ isOpen }) => (isOpen ? 99 : -1)};
  opacity: ${({ isOpen }) => (isOpen ? '20%' : '0')};
  transition: all 0.4s;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

export { Aside, Overlay };
