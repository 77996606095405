/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AccordionItem } from './components';
import './style.scss';

const Accordion = props => {
  const { children, defaultOpenSections, className } = props;
  const [openSections, setOpenSections] = useState([...defaultOpenSections]);
  const [sections, setSections] = useState([]);

  const calculateSections = () => {
    const hasChildren = Boolean(children);
    let sectionsData = [];

    if (hasChildren) {
      sectionsData = children.length
        ? [...children]
        : [children];
    }

    setSections(sectionsData);
  };

  const closeSection = section => {
    const sectionsData = openSections.filter(openSection => openSection !== section);

    setOpenSections(sectionsData);
  };

  const openSection = section => {
    setOpenSections([...openSections, section]);
  };

  const onSectionClick = section => {
    const isSectionOpened = openSections.includes(section);

    isSectionOpened
      ? closeSection(section)
      : openSection(section);
  };

  useEffect(() => {
    calculateSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <section styleName="accordion" className={className}>
      {
        sections.map(section => {
          const { id, title = '' } = section.props;
          const isOpen = openSections.includes(id);
          const handleClick = () => onSectionClick(id);

          return (
            <AccordionItem
              key={id}
              id={id}
              title={title}
              handleClick={handleClick}
              isOpen={isOpen}
              // eslint-disable-next-line react/forbid-component-props
              tid="accordion-item"
            >
              {section}
            </AccordionItem>
          );
        })
      }
    </section>
  );
};

Accordion.defaultProps = {
  defaultOpenSections: [],
  className: ''
};

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  defaultOpenSections: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
};

export default Accordion;
