import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Icon from '../Icon';
import Panel from '../Panel';
import RenderIf from '../RenderIf';

import './style.scss';

const SuccessPanel = props => {
  const {
    buttonText,
    description,
    onClick,
    showButton,
    icon,
    title
  } = props;
  const { alternateText, name: iconName } = icon;

  return (
    <Panel id="success" styleName="success-panel">
      <h1>{title}</h1>

      <Icon alt={alternateText} name={iconName} />

      <div styleName="description">
        {description}
      </div>

      <RenderIf condition={showButton}>
        <Button id="my-bricks-btn" onClick={onClick}>
          {buttonText}
        </Button>
      </RenderIf>
    </Panel>
  );
};

SuccessPanel.propTypes = {
  buttonText: PropTypes.string,
  description: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  showButton: PropTypes.bool,
  icon: PropTypes.shape({
    alternateText: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.node.isRequired
};

SuccessPanel.defaultProps = {
  buttonText: '',
  onClick: () => { },
  showButton: true
};

export default SuccessPanel;
