class SecondaryMarketProperty {
    id;
    name;
    description;
    petitionerId;
    estimatedAnnualLeasing;
    averagePrice;
    highestPrice;
    landSize;
    lowestPrice;
    numberOfAvailableBricks;
    numberOfBricksInProperty;
    numberOfMaximumBricksToPurchase;
    numberOfReservedBricks;
    numberOfSellingBricksByTrader;
    parkingLotSpaces;
    retailPrice;
    yearOfConstruction;
    hasTraderBricks;
    hasVariableIncome;
    genesisAt;
    stabilizationDate;
    address;
    assessments;
    propertyValueEstimate;
    rentalUnits;
    escrow;
    latestRentDispersionDate;
    leaseholders;
    listOfBricksOnSale;
    photos;
    highlights;
    riskLevel;
    type;
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.petitionerId = data.petitionerId;
        this.estimatedAnnualLeasing = data.estimatedAnnualLeasing;
        this.averagePrice = data.averagePrice;
        this.highestPrice = data.highestPrice;
        this.landSize = data.landSize;
        this.lowestPrice = data.lowestPrice;
        this.numberOfAvailableBricks = data.numberOfAvailableBricks;
        this.numberOfBricksInProperty = data.numberOfBricksInProperty;
        this.numberOfMaximumBricksToPurchase = data.numberOfMaximumBricksToPurchase;
        this.numberOfReservedBricks = data.numberOfReservedBricks;
        this.numberOfSellingBricksByTrader = data.numberOfSellingBricksByTrader;
        this.parkingLotSpaces = data.parkingLotSpaces;
        this.retailPrice = data.retailPrice;
        this.yearOfConstruction = data.yearOfConstruction;
        this.hasTraderBricks = data.hasTraderBricks;
        this.hasVariableIncome = data.hasVariableIncome;
        this.genesisAt = new Date(data.genesisAt);
        this.stabilizationDate = new Date(data.stabilizationDate);
        this.address = data.address;
        this.assessments = data.assessments;
        this.propertyValueEstimate = data.propertyValueEstimate;
        this.rentalUnits = data.rentalUnits;
        this.escrow = data.escrow;
        this.latestRentDispersionDate = data.latestRentDispersionDate;
        this.leaseholders = data.leaseholders;
        this.listOfBricksOnSale = data.listOfBricksOnSale;
        this.photos = data.photos;
        this.highlights = data.highlights;
        this.riskLevel = data.riskLevel;
        this.type = data.type;
    }
}
export default SecondaryMarketProperty;
