class TransactionalSummary {
    latestBrickSaleDate;
    numberOfBricksSold;
    totalTransactedAmount;
    constructor(data) {
        this.latestBrickSaleDate = data.latestBrickSaleDate;
        this.numberOfBricksSold = data.numberOfBricksSold;
        this.totalTransactedAmount = data.totalTransactedAmount;
    }
}
export default TransactionalSummary;
