import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Money } from '@types';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const OverduePayment = (props) => {
    const { notification } = props;
    const propertyName = notification.params?.crowdfundingPropertyName;
    const monthlyPayment = notification.params?.monthlyPayment;
    const monthlyPaymentAmount = new Money(monthlyPayment.amount);
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Tienes un pago pendiente de tu plan de pagos" }), _jsxs(Paragraph, { children: ["Tu pago para el plan de pagos en la propiedad", _jsx(Bold, { children: ` ${propertyName} ` }), "ha vencido. Te invitamos a realizar tu pago por el monto de", _jsx(Bold, { children: ` ${monthlyPaymentAmount.toString()}` }), "."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default OverduePayment;
