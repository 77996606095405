class Quotation {
    quantity;
    retailPricePerBrick;
    presaleDiscountPercentagePerBrick;
    presaleDiscountAmountPerBrick;
    presaleDiscountPricePerBrick;
    presaleDiscountTotalSavings;
    volumeDiscountPercentagePerBrick;
    volumeDiscountAmountPerBrick;
    volumeDiscountPricePerBrick;
    volumeDiscountTotalSavings;
    totalSavingsAmountPerBrick;
    totalRetailPrice;
    totalPresalePrice;
    totalWithVolumeDiscounts;
    paidPricePerBrick;
    totalPaid;
    capitalGainPerBrick;
    totalCapitalGain;
    totalCapitalGainPercentage;
    property;
    constructor(data) {
        this.quantity = data.quantity;
        this.retailPricePerBrick = data.retailPricePerBrick;
        this.presaleDiscountAmountPerBrick = data.presaleDiscountAmountPerBrick;
        this.presaleDiscountPercentagePerBrick = data.presaleDiscountPercentagePerBrick;
        this.presaleDiscountPricePerBrick = data.presaleDiscountPricePerBrick;
        this.presaleDiscountTotalSavings = data.presaleDiscountTotalSavings;
        this.volumeDiscountPercentagePerBrick = data.volumeDiscountPercentagePerBrick;
        this.volumeDiscountAmountPerBrick = data.volumeDiscountAmountPerBrick;
        this.volumeDiscountPricePerBrick = data.volumeDiscountPricePerBrick;
        this.volumeDiscountTotalSavings = data.volumeDiscountTotalSavings;
        this.totalSavingsAmountPerBrick = data.totalSavingsAmountPerBrick;
        this.totalRetailPrice = data.totalRetailPrice;
        this.totalPresalePrice = data.totalPresalePrice;
        this.totalWithVolumeDiscounts = data.totalWithVolumeDiscounts;
        this.paidPricePerBrick = data.paidPricePerBrick;
        this.totalPaid = data.totalPaid;
        this.capitalGainPerBrick = data.capitalGainPerBrick;
        this.totalCapitalGain = data.totalCapitalGain;
        this.totalCapitalGainPercentage = data.totalCapitalGainPercentage;
        this.property = data.property;
    }
}
export default Quotation;
