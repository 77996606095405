import React from 'react';
import PropTypes from 'prop-types';

import { TextWrapper } from './Text.style';

const Text = ({ children, ...props }) => {
  return (
    <TextWrapper {...props}>
      {children}
    </TextWrapper>
  );
};

Text.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

export default Text;
