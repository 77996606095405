import { APPROVAL_REQUESTS } from '@constants';
class ApprovalRequest {
    approvalRequests;
    constructor(data) {
        this.approvalRequests = data.pendingApprovalRequests ?? [];
    }
    hasRequests() {
        return this.approvalRequests.length > 0;
    }
    hasCURPRequest() {
        return this.approvalRequests.some(item => item === APPROVAL_REQUESTS.curp);
    }
    hasIdentificationRequest() {
        return this.approvalRequests.some(item => item === APPROVAL_REQUESTS.identification);
    }
}
export default ApprovalRequest;
