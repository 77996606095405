import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Date as DateParser } from '@types';
import { Paragraph, Subtitle } from '@components/Styled';
import { toast } from '@components';
import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { useRequest } from '@hooks';
import { SubmitButton, UnusualAccessWrapper } from './UnusualAccessLock.styled';
const UnusualAccessLock = ({ email }) => {
    const api = useHundredBricksAPI();
    const [response, , error, request] = useRequest(api.authentication.resendUnusualCodeAccess);
    const resendEmailUnusualAccess = async () => {
        request(email);
    };
    const getDateFormatted = () => {
        const formattedDetectedDate = DateParser(new Date().getTime());
        const formattedDetectedAtDate = formattedDetectedDate.format('DD MMMM YYYY');
        const formattedDetectedAtTime = formattedDetectedDate.format('hh:mm a');
        return `Identificamos un inicio de sesión el ${formattedDetectedAtDate}
      a las ${formattedDetectedAtTime},`;
    };
    useEffect(() => {
        if (response !== undefined) {
            toast.success('El correo se envió con éxito');
        }
    }, [response]);
    useEffect(() => {
        if (error) {
            toast.danger('Algo salió mal al enviar el correo, inténtalo de nuevo');
        }
    }, [error]);
    return (_jsxs(UnusualAccessWrapper, { children: [_jsx(Subtitle, { children: "Sesi\u00F3n inusual, cuenta bloqueada" }), _jsxs(Paragraph, { children: [getDateFormatted(), ' ', _jsx("br", {}), ' ', "en una ubicaci\u00F3n distinta a la habitual."] }), _jsxs(Paragraph, { children: ["Por seguridad hemos bloqueado esta cuenta, para desbloquearla", _jsx("br", {}), ' ', "sigue las instrucciones que hemos enviado a tu correo electr\u00F3nico", ' ', _jsx("br", {}), ' ', "o puedes contactar con soporte a cliente."] }), _jsx(Paragraph, { children: "soporte@100Ladrillos.com" }), _jsx("span", { children: "\u00BFA\u00FAn no llega tu correo? Intenta enviarlo nuevamente" }), _jsx(SubmitButton, { id: "resend-email", onClick: resendEmailUnusualAccess, children: "Enviar" })] }));
};
export default UnusualAccessLock;
