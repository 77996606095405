import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import {
  InvestmentLimitBodyWrapper,
  UpgradeAccountWrapper,
  UpgradeAccountIcon,
  UpgradeButton
} from '../../UpgradeAccount.styled';

const UpgradeAccountInconsistentData = ({ onRedirect }) => (
  <UpgradeAccountWrapper>
    <Title>Inconsistencias en los datos</Title>

    <UpgradeAccountIcon
      alt="Icono rechazo por inconsistencia en los datos"
      name="icUpgradeAccountModal"
    />

    <InvestmentLimitBodyWrapper>
      <Text>
        Revisamos tus datos y no coincidieron con la identificación.
      </Text>

      <Text>
        Revisa nuevamente tus datos y confírmalos, tendrás que volver a subir una
        identificación oficial.
      </Text>
    </InvestmentLimitBodyWrapper>

    <UpgradeButton id="go-to-verify-data" rounded onClick={onRedirect}>
      Ir a verificar mis datos
    </UpgradeButton>
  </UpgradeAccountWrapper>
);

UpgradeAccountInconsistentData.propTypes = {
  onRedirect: PropTypes.func.isRequired
};

export default UpgradeAccountInconsistentData;
