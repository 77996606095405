import PurchaseEventsReporter from './PurchaseEventReporter';

class CrowdfundingEventReporter extends PurchaseEventsReporter {
  startPurchase(property, purchaseQuotation) {
    const eventProperties = this._createCompleteEventProperties(
      property,
      purchaseQuotation?.quantity,
      purchaseQuotation?.retailPricePerBrick,
      purchaseQuotation?.totalPaid
    );
    eventProperties.propertyMarket = 'Crowdfunding';

    const event = this._event.startedPurchase(eventProperties);

    this._insightsService.sendEvent(event);
  }

  typingOneTimePassword(property, purchaseQuotation) {
    const eventProperties = this._createCompleteEventProperties(
      property,
      purchaseQuotation?.quantity,
      purchaseQuotation?.retailPricePerBrick,
      purchaseQuotation?.totalPaid
    );
    eventProperties.propertyMarket = 'Crowdfunding';

    const event = this._event.oneTimePasswordTyped(eventProperties);

    this._insightsService.sendEvent(event);
  }

  completePurchase(property, purchaseQuotation) {
    const eventProperties = this._createCompleteEventProperties(
      property,
      purchaseQuotation?.quantity,
      purchaseQuotation?.retailPricePerBrick,
      purchaseQuotation?.totalPaid
    );
    eventProperties.propertyMarket = 'Crowdfunding';

    const event = this._event.completedPurchase(eventProperties);

    this._insightsService.sendEvent(event);
  }
}

export default CrowdfundingEventReporter;
