import styled from 'styled-components';

const ActionButtonWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(1)};
  right: ${({ theme }) => theme.spacing(1)};
`;

const ButtonAlign = styled.div`
  display: flex;
  align-items: center;

  img {
    padding: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export { ActionButtonWrapper, ButtonAlign };
