class Photo {
    id;
    index;
    url;
    createdAt;
    tag;
    constructor(data) {
        this.id = data.id;
        this.index = data.index;
        this.url = data.url;
        this.createdAt = data.createdAt;
        this.tag = data.tag;
    }
}
export default Photo;
