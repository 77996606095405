import { createAPIClient } from '../api';
import AdvisorAPI from './advisor';
import AuthenticationAPI from './authentication';
import EnrollmentAPI from './enrollment';
import InvestmentsAPI from './investments/InvestmentsAPI';
import ProfileAPI from './profile/ProfileAPI';
import RevolvingFundsAPI from './property/revolvingFunds';
import { LocalStorageTokenStore } from './session';
import { PaymentPlansAPI, InvestmentsAPI as TradingInvestmentsAPI } from './trading';
import { CrowdfundingPropertiesAPI, OnSalePropertyAPI, PetitionerAPI, RebatesAPI } from './properties';
const useHundredBricksAPI = () => {
    const token = (() => {
        try {
            return LocalStorageTokenStore.find();
        }
        catch (error) {
            return undefined;
        }
    })();
    const marketplaceClient = createAPIClient(`${process.env.MARKETPLACE_BASE_URL}`, token);
    const tradingClient = createAPIClient(`${process.env.TRADING_BASE_URL}`, token);
    return {
        advisor: new AdvisorAPI(marketplaceClient),
        authentication: new AuthenticationAPI(marketplaceClient),
        enrollment: new EnrollmentAPI(marketplaceClient),
        investments: new InvestmentsAPI(marketplaceClient),
        profile: new ProfileAPI(marketplaceClient),
        properties: {
            petitioner: new PetitionerAPI(marketplaceClient),
            crowdfunding: new CrowdfundingPropertiesAPI(marketplaceClient),
            onSale: new OnSalePropertyAPI(marketplaceClient),
            revolvingFund: new RevolvingFundsAPI(marketplaceClient)
        },
        trading: {
            paymentPlans: new PaymentPlansAPI(tradingClient),
            rebates: new RebatesAPI(tradingClient),
            investments: new TradingInvestmentsAPI(tradingClient)
        }
    };
};
export default useHundredBricksAPI;
