import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../Button';
import Icon from '../Icon';
import RenderIf from '../RenderIf';

import { ActionButtonWrapper, ButtonAlign } from './ButtonViewProperties.styles';

const ButtonViewProperties = () => {
  const navigate = useNavigate();

  const canShowProperties = () => {
    const primarySteps = ['login', 'signup', 'verify-email'];

    const urlContainsPrimarySteps = primarySteps.some(
      step => {
        const url = window.location.href;

        return url?.includes(step);
      }
    );

    return !urlContainsPrimarySteps;
  };

  return (
    <ActionButtonWrapper>
      <RenderIf condition={canShowProperties()}>
        <Button
          id="button-view-properties"
          variant="text"
          color="info"
          onClick={() => { navigate('/marketplace'); }}
        >
          <ButtonAlign>
            <Icon
              name="icPropertiesEnrollment"
              alt="Ir a propiedades"
              width={24}
              height={24}
            />
            <p>Ver propiedades</p>
          </ButtonAlign>
        </Button>
      </RenderIf>
    </ActionButtonWrapper>
  );
};

export default ButtonViewProperties;
