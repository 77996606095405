import Money from '../../types/money';

const convertNumberToCurrencyString = number => {
  if (typeof number === 'string' || !number) {
    number = 0;
  }

  return new Money(number || 0).toString();
};

export default convertNumberToCurrencyString;
