import axios from 'axios';
import APIError from './APIError';
import HTTPError from './HTTPError';
import NetworkError from './NetworkError';
import Response from './Response';
import { BadGateway, BadRequest, Conflict, Forbidden, GatewayTimeout, HTTPClientError, InternalServerError, InvalidBaseURL, NotFound, Timeout, Unauthorized } from './errors';
class HTTPClient {
    axios;
    constructor(baseURL, token) {
        let url;
        try {
            url = new URL(baseURL);
        }
        catch (error) {
            throw new InvalidBaseURL(baseURL, { cause: error });
        }
        const headers = { 'Content-Type': 'application/json' };
        if (token) {
            headers.Authorization = token.buildAuthorizationStringHeader();
        }
        this.axios = axios.create({ baseURL: url.toString(), headers });
    }
    async get(endpoint, options = {}) {
        return this.request('get', endpoint, options);
    }
    async post(endpoint, payload = undefined, options = {}) {
        return this.request('post', endpoint, { ...options, payload });
    }
    async put(endpoint, payload = undefined, options = {}) {
        return this.request('put', endpoint, { ...options, payload });
    }
    async delete(endpoint, options = {}) {
        return this.request('delete', endpoint, options);
    }
    async patch(endpoint, payload = undefined, options = {}) {
        return this.request('patch', endpoint, { ...options, payload });
    }
    // this method is used for test
    getAxiosInstance() {
        return this.axios;
    }
    async request(method, endpoint, options) {
        let rawResponse;
        try {
            rawResponse = await this.axios.request({
                method,
                url: endpoint,
                data: options.payload,
                headers: options.headers,
                params: options.params
            });
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                throw this.buildErrorFromAxiosError(error);
            }
            else if (axios.isAxiosError(error) && !error.response) {
                throw this.buildNetworkError(error);
            }
            else if (error instanceof Error) {
                throw new HTTPClientError({ cause: error });
            }
            else {
                throw new HTTPClientError(error);
            }
        }
        return new Response(rawResponse.status, rawResponse.headers, rawResponse.data);
    }
    buildErrorFromAxiosError(error) {
        const { code } = error.response?.data;
        return code ? this.buildAPIError(error) : this.buildHTTPError(error);
    }
    buildAPIError(error) {
        const { code } = error.response?.data;
        switch (error.response?.status) {
            case 400:
                return new BadRequest(code, { cause: error });
            case 401:
                return new Unauthorized(code, { cause: error });
            case 403:
                return new Forbidden(code, { cause: error });
            case 404:
                return new NotFound(code, { cause: error });
            case 409:
                return new Conflict(code, { cause: error });
            case 500:
                return new InternalServerError(code, { cause: error });
            default:
                return new APIError(Number(error.response?.status), code, { cause: error });
        }
    }
    buildHTTPError(error) {
        const body = error.response?.data;
        switch (error.response?.status) {
            case 502:
                return new BadGateway(body, { cause: error });
            case 504:
                return new GatewayTimeout(body, { cause: error });
            default:
                return new HTTPError(Number(error.response?.status), body, { cause: error });
        }
    }
    buildNetworkError(error) {
        if (error.code === 'ECONNABORTED') {
            return new Timeout({ cause: error });
        }
        return new NetworkError({ cause: error });
    }
}
export default HTTPClient;
