import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../Button';
import QuotationDetails from '../QuotationDetails';

import './style.scss';

const ReservedBricksQuoter = props => {
  const {
    handleNewQuotation,
    history,
    reservation,
    isReleasingBricks,
    propertyId
  } = props;
  const {
    averagePricePerBrick,
    subtotalAmount,
    revolvingFundAmountPerBrick,
    totalRevolvingFundAmount,
    totalAmount,
    bricksOnSale: reservedBricks,
    id: reservationId
  } = reservation;

  const reservedBricksQuantity = reservedBricks.length;

  const goToReservedBricks = () => {
    const urlParams = `bricks=${reservedBricksQuantity}&reservationId=`
      + `${reservationId}&propertyId=${propertyId}`;

    history.push(`/marketplace/onsale/buy?${urlParams}`);
  };

  return (
    <div styleName="reserved-bricks-quoter">
      <p styleName="informative-text">
        Actualmente tienes una cotización, puedes seguir con la compra
        o realizar una nueva cotización
      </p>
      <span styleName="bricks-quantity">
        # Ladrillos:
        <span
          styleName="number"
        >
          {reservedBricksQuantity}
        </span>
      </span>
      <div>
        <QuotationDetails
          pricePerBrick={averagePricePerBrick}
          bricksQuantity={reservedBricksQuantity}
          revolvingFund={revolvingFundAmountPerBrick}
          subtotal={subtotalAmount}
          totalAmount={totalAmount}
          totalRevolvingFund={totalRevolvingFundAmount}
        />
      </div>
      <div styleName="actions">
        <Button
          id="buy-button"
          onClick={goToReservedBricks}
        >
          Comprar
        </Button>
        <Button
          id="new-quotation-button"
          tid="new-quotation-button"
          color="secondary"
          onClick={handleNewQuotation}
          isLoading={isReleasingBricks}
        >
          Cotizar de nuevo
        </Button>
      </div>
    </div>
  );
};

ReservedBricksQuoter.defaultProps = {
  isReleasingBricks: false
};

ReservedBricksQuoter.propTypes = {
  reservation: PropTypes.shape({
    id: PropTypes.string,
    averagePricePerBrick: PropTypes.number,
    subtotalAmount: PropTypes.number,
    revolvingFundAmountPerBrick: PropTypes.number,
    totalRevolvingFundAmount: PropTypes.number,
    totalAmount: PropTypes.number,
    bricksOnSale: PropTypes.arrayOf({})
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  handleNewQuotation: PropTypes.func.isRequired,
  isReleasingBricks: PropTypes.bool,
  propertyId: PropTypes.string.isRequired
};

export default ReservedBricksQuoter;
