import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Money } from '@types';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const DepositFunds = (props) => {
    const { notification } = props;
    const currency = new Money(notification.params?.depositAmount);
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Has agregado fondos a tu cuenta" }), _jsxs(Paragraph, { children: ["Tu transferencia por", _jsx(Bold, { children: ` ${currency.toString()} ` }), "ya se ha reflejado en tu cuenta, ahora tienes m\u00E1s recursos disponibles para invertir en excelentes oportunidades."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default DepositFunds;
