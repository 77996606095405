import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon } from '@components';

import './style.scss';

const Panel = ({ id, title, titleIcon, children, className }) => {
  const styleName = cx({
    panel: true,
    title: Boolean(title),
  });

  return (
    <section id={`${id}-panel`} styleName={styleName} className={className}>
      {title && (
        <header>
          {titleIcon && <Icon name={titleIcon} alt={titleIcon} />}
          <h1>{title}</h1>
        </header>
      )}
      <div>
        {children}
      </div>
    </section>
  );
};

Panel.defaultProps = {
  titleAbove: false,
  title: '',
  children: <div>Hello!</div>,
};

Panel.propTypes = {
  id: PropTypes.string.isRequired,
  titleAbove: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Panel;
