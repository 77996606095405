import scrollTo from './scrollTo';

const DEFAULT_PARAMETERS = {
  shouldScroll: false,
  offSet: 200,
  parentNode: 'content'
};

const focusInput = (ref, focusOptions) => {
  const { shouldScroll, offSet, parentNode } = { ...DEFAULT_PARAMETERS, ...focusOptions };

  try {
    if (shouldScroll) {
      scrollTo(ref.current.id, offSet, parentNode);
    }

    return ref.current.focus({ preventScroll: true });
  } catch (error) {
    throw new Error('not a Reference');
  }
};

export default focusInput;
