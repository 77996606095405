import { createContext } from 'react';
const PaymentPlansContext = createContext({
    plans: undefined,
    isLoading: false,
    error: undefined,
    hasPlans: false,
    reload() {
        throw new Error('Reload payment plans implementation not available');
    }
});
export default PaymentPlansContext;
