import styled from 'styled-components';

import { Text } from '@components/Styled';
import { flexAsRow } from '@components/Styled/mixins';

import Form from '../Form';

const TwoFactorForm = styled(Form)`
  color: ${({ theme }) => theme.palette.grayJ};
  align-items: center;
  justify-content: center;
  line-height: ${({ theme }) => theme.spacing(3)};
  text-align: center;
  word-spacing: 1px;

  p {
    margin: 0;
    text-align: center;
  }

  > div {
    align-items: center;
    width: auto;
  }
`;

const CodeSubmissionContact = styled(Text)`
  > span {
    font-family: ${({ theme }) => theme.typography.title.h3.fontFamily}
  }
`;

const CountdownText = styled(Text)`
  color: ${({ theme }) => theme.palette.grayG};
`;

const VerifyActions = styled.div`
  ${flexAsRow(2)};
`;

const ResendCountdownWrapper = styled(Text)`
  color: ${({ error, theme }) => error && theme.palette.danger.main};

  > span {
    font-family: ${({ theme }) => theme.typography.title.h3.fontFamily}
  }
`;

export {
  CodeSubmissionContact,
  CountdownText,
  ResendCountdownWrapper,
  TwoFactorForm,
  VerifyActions
};
