import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.scss';

const Tab = props => {
  const {
    title,
    isActive = false,
    setActiveTab,
    tabIndex,
  } = props;

  const tabItemStyles = cx({
    'tab-item': true,
    'active-tab': isActive,
  });

  const handleClickTab = () => {
    setActiveTab(tabIndex);
  };

  return (
    <li tid="tab-item" styleName={tabItemStyles}>
      <button
        id="tab-button"
        type="button"
        styleName="tab-button"
        onClick={handleClickTab}
      >
        {title}
      </button>
    </li>
  );
};

Tab.defaultProps = {
  isActive: false,
};

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  setActiveTab: PropTypes.func.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default Tab;
