import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Money } from '@types';
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const CrowdfundingRebateDispersion = (props) => {
    const { notification } = props;
    const property = notification.params?.property;
    const propertyName = property?.name || '----';
    const amountReceived = new Money(notification.params?.totalReceived);
    const bricksText = notification.params?.numberOfInvestments === 1
        ? `${notification.params?.numberOfInvestments} Ladrillo`
        : `${notification.params?.numberOfInvestments ?? 0} Ladrillos`;
    return (_jsxs(NotificationContainer, { children: [_jsxs(Subtitle, { level: "3", children: [`${propertyName} `, "te ha pagado ingresos, \u00A1incluso en fondeo!"] }), _jsxs(Paragraph, { children: ["\u00A1Has recibido un ingreso de", _jsx(Bold, { children: ` ${amountReceived.toString()} ` }), "de tus", _jsx(Bold, { children: ` ${bricksText} ` }), "en", _jsx(Bold, { children: ` ${propertyName}` }), ", a\u00FAn cuando tu propiedad contin\u00FAa en fondeo, ya est\u00E1s recibiendo ingresos por tus Ladrillos. Tu saldo disponible ha sido actualizado y est\u00E1 listo para que lo reinviertas o lo retires."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default CrowdfundingRebateDispersion;
