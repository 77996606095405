const UNAUTHORIZED = 'UNAUTHORIZED';
const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';
const INVALID_RESPONSE = 'INVALID_RESPONSE';

class APIError extends Error {
  constructor(code, name = 'APIError', details = null) {
    if (details) {
      super(`HTTPError(${name}): ${code}`, { cause: details });
    } else {
      super(`HTTPError(${name}): ${code}`);
    }

    this._code = code;
    this._name = name;
    this._details = details;
  }

  set code(code) {
    this._code = code;
  }

  get code() {
    return this._code;
  }

  set name(name) {
    this._name = name;
  }

  get name() {
    return this._name;
  }

  set details(details) {
    this._details = details;
  }

  get details() {
    return this._details;
  }

  isUnauthorized() {
    return this._code === UNAUTHORIZED;
  }

  isUnexpectedError() {
    return this._code === INVALID_RESPONSE
      || this._code === UNEXPECTED_ERROR;
  }

  static getNetworkError() {
    const derivedNetworkError = new this('NETWORK_ERROR', 'NetworkError');

    return derivedNetworkError;
  }
}

export default APIError;
