class Property {
  constructor(data = {}) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  getAddress() {
    const { address = {} } = this.data;

    return address;
  }

  getCoordinates() {
    const { address = {} } = this.data;
    const { coordinates = {} } = address;

    return coordinates;
  }

  getType() {
    const propertyTypes = new Map([
      ['OFFICE', 'Oficina'],
      ['COMMERCIAL', 'Comercial'],
      ['INDUSTRIAL', 'Industrial'],
      ['HOUSING', 'Habitacional'],
    ]);
    const { type = '' } = this.data;

    return propertyTypes.get(type.toUpperCase());
  }

  getStatus() {
    const propertyStatus = new Map([
      ['IN_CONSTRUCTION', 'En construcción'],
      ['FINISHED', 'Terminada'],
      ['IN_OPERATION', 'En operación'],
    ]);
    const { status } = this.data;

    return propertyStatus.get(status);
  }
}

export default Property;
