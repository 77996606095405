import { useEffect, useState } from 'react';

const useCountdown = (initialSeconds, onFinish) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prevValue => prevValue - 1);
      } else if (onFinish && typeof onFinish === 'function') {
        onFinish();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, initialSeconds]);

  useEffect(() => {
    setSeconds(initialSeconds);
  }, [initialSeconds]);

  return seconds;
};

export default useCountdown;
