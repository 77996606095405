import { CrowdfundingPropertyDeliveries, CrowdfundingPropertyLog, PropertyLog } from '@entities';

import NetworkError from '../errors/NetworkError';
import PropertyManagementError from '../errors/PropertyManagement';

import GatewayAPI from './GatewayAPI';

class PropertyManagementAPI extends GatewayAPI {
  async getPropertiesManagement(propertyId) {
    try {
      const { data } = await this._client.get(`properties/${propertyId}/managementLogs`);

      return data.items.map(log => (new PropertyLog(log)));
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getCrowdfundingPropertiesDeliveries(propertyId) {
    try {
      const { data } = await this._legacyClient.get(
        `crowdfundingProperties/${propertyId}/crowdfundingDeliveries`
      );

      return new CrowdfundingPropertyDeliveries(data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getCrowdfundingPropertiesLogs(propertyId) {
    try {
      const { data } = await this._client.get(
        `crowdfundingProperties/${propertyId}/managementLogs`
      );

      const propertyLogs = data.items.map(log => new PropertyLog(log));

      return new CrowdfundingPropertyLog(propertyLogs);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getLatestLogsPerProperties() {
    try {
      const { data } = await this._client.get('managementLogs/latestByProperty');

      return data.items.map(log => (new PropertyLog(log)));
    } catch (error) {
      return this._handleError(error);
    }
  }

  _handleError(error) {
    if (!error.response) {
      throw new NetworkError();
    }

    const { response } = error;
    const { code } = response.data;

    throw new PropertyManagementError(code);
  }
}

export default PropertyManagementAPI;
