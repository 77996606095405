import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Bold, Paragraph } from '@components/Styled';
import { UnusualAccess, AuthenticationAttemptsLimitReached } from '@lib/hundredBricksAPI/authentication';
const RenewSessionMessage = ({ error }) => {
    if (error instanceof AuthenticationAttemptsLimitReached) {
        const remainingLockTimeInMilliseconds = error.getRemainingTime();
        const remainingLockTimeInSeconds = Math.ceil(remainingLockTimeInMilliseconds / 1_000);
        const remainingLockTimeInMinutes = Math.ceil(remainingLockTimeInSeconds / 60);
        return (_jsxs(Fragment, { children: [_jsx(Paragraph, { children: "Tu cuenta est\u00E1 temporalmente bloqueada despu\u00E9s de varios intentos fallidos de inicio de sesi\u00F3n." }), _jsx("br", {}), _jsxs(Paragraph, { children: ["Por favor, int\u00E9ntalo de nuevo en", ' ', _jsx(Bold, { children: `${remainingLockTimeInMinutes} minutos` }), ", o cont\u00E1ctanos en", ' ', _jsx(Bold, { children: "soporte@100ladrillos.com" }), ' ', "si necesitas ayuda."] })] }));
    }
    if (error instanceof UnusualAccess) {
        return (_jsxs(Fragment, { children: [_jsx(Paragraph, { children: "Tu cuenta fue bloqueada temporalmente por un inicio de sesi\u00F3n desde una ubicaci\u00F3n inusual." }), _jsx("br", {}), _jsxs(Paragraph, { children: [' ', "Revisa tu correo para desbloquearla o cont\u00E1ctanos en", _jsx(Bold, { children: " soporte@100Ladrillos.com." })] })] }));
    }
    return (_jsx(Paragraph, { children: "Ya tienes una sesi\u00F3n activa en otro dispositivo. Para iniciar sesi\u00F3n aqu\u00ED, cierra la sesi\u00F3n en el otro dispositivo primero." }));
};
export default RenewSessionMessage;
