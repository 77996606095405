const FISCAL_REGIMES = [
    {
        id: 'resico',
        label: '626 - Régimen Simplificado de Confianza (RESICO)'
    },
    {
        id: 'tax_incorporation',
        label: '621 - Régimen de Incorporación Fiscal'
    },
    {
        id: 'lease',
        label: '606 - Régimen de Arrendamiento'
    },
    {
        id: 'moral_person',
        label: '601 - General de Ley Personas Morales'
    },
    {
        id: 'salaries',
        label: '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios'
    },
    {
        id: 'other_incomes',
        label: '608 - Demás Ingresos'
    },
    {
        id: 'dividend',
        label: '611 - Ingresos por Dividendos'
    },
    {
        id: 'business_activities',
        label: '612 - Personas Físicas con Actividades Empresariales y Profesionales'
    },
    {
        id: 'interest',
        label: '614 - Ingresos Por Intereses'
    },
    {
        id: 'prize_winnings',
        label: '615 - Régimen de los Ingresos Por Obtención de Premios'
    },
    {
        id: 'technological_platforms',
        label: '625 - Régimen de Actividades Empresariales con Ingreso A Través de las Plataformas'
            + ' Tecnológicas'
    }
];
export default FISCAL_REGIMES;
