import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.scss';

import { passwordValidator } from '@utils';

import getRestrictions from './restrictions';

const RestrictionItem = ({ text, isCompleted, tid }) => {
  const styleNameIcon = cx({
    'circle-bullet': true,
    active: isCompleted
  });

  return (
    <li styleName="restriction-item">
      <span tid={tid} styleName={styleNameIcon} />
      <span>{text}</span>
    </li>
  );
};

const RestrictionsPassword = ({ password, getValidation }) => {
  const result = passwordValidator(password);
  const restrictions = getRestrictions(result);
  const isValidRestrictions = restrictions.every(
    restriction => restriction?.isValid()
  );

  useEffect(() => {
    getValidation(isValidRestrictions);
  }, [password]);

  return (
    <Fragment>
      {
        !isValidRestrictions && (
          <p styleName="restriction-instruction">
            Por seguridad hace falta características en la contraseña.
          </p>
        )
      }
      <ul styleName="container-restrictions">
        {restrictions.map((restriction = {}) => {
          const isCompleted = restriction?.isValid();

          return (
            <RestrictionItem
              text={restriction.text}
              key={restriction.key}
              isCompleted={isCompleted}
              tid={restriction.key}
            />
          );
        })}
      </ul>
    </Fragment>
  );
};

RestrictionItem.propTypes = {
  text: PropTypes.string.isRequired,
  tid: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool
};

RestrictionItem.defaultProps = {
  isCompleted: false
};

RestrictionsPassword.propTypes = {
  password: PropTypes.string.isRequired,
  getValidation: PropTypes.func
};

RestrictionsPassword.defaultProps = {
  getValidation: () => { }
};

export default RestrictionsPassword;
