import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { useProfile } from '@lib/profile';
import { HelperText, Paragraph } from '@components/Styled';
import Money from '../../../../types/money';
import Sidebar from '../Sidebar';
import NavOptions from './NavOptions';
import { ValueHolder } from './PrivateSideNav.style';
const PrivateSideNav = ({ isOpen, onClose }) => {
    const { profile, investorBalance } = useProfile();
    return (_jsxs(Sidebar, { isOpen: isOpen, onClose: onClose, children: [profile?.canBuy() && (_jsxs(Fragment, { children: [_jsxs(ValueHolder, { children: [_jsx(HelperText, { children: "Valor de la cuenta" }), _jsx(Paragraph, { color: "info-main", children: Money.format(investorBalance?.amount) })] }), _jsxs(ValueHolder, { children: [_jsx(HelperText, { children: "Saldo disponible" }), _jsx(Paragraph, { color: "alternative6-main", children: Money.format(investorBalance?.available) })] })] })), _jsx(NavOptions, {})] }));
};
export default PrivateSideNav;
