const errorCodes = {
  cantResendToken: 'CANT_RESEND_TOKEN',
  cannotResendToken: 'CANNOT_RESEND_TOKEN',
  channelNotSupported: 'CHANNEL_NOT_SUPPORTED',
  codeValidationLimitReached: 'CODE_VALIDATION_LIMIT_REACHED',
  invalidCode: 'INVALID_CODE',
  investorExceedsOperationsAmountAllowed: 'INVESTOR_EXCEEDS_OPERATIONS_AMOUNT_ALLOWED',
  serverError: 'SERVER_ERROR',
  tokenNotFound: 'TOKEN_NOT_FOUND',
  investorHasAuthenticationFactorLock: 'INVESTOR_HAS_AUTHENTICATION_FACTOR_LOCKED',
  traderHasAuthenticationFactorLock: 'TRADER_HAS_AUTHENTICATION_FACTOR_LOCKED',
  unexpectedError: 'UNEXPECTED_ERROR',
  validationError: 'VALIDATION_ERROR'
};

export default errorCodes;
