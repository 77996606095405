import styled, { css } from 'styled-components';

export const TextWrapper = styled.p`

  ${({ theme }) => theme.typography.body2};
  line-height: 1.5;
  margin: 0;
  ${({ align = 'left' }) => {
    if (align === 'center') {
      return css`
        text-align: center;
      `;
    }
    return css`
      text-align: left;
    `;
  }}
  ${({ size = 'regular' }) => {
    if (size === 'small') {
      return css`
        margin-bottom: ${({ theme }) => theme.spacing(2)};
      `;
    }
    if (size === 'extraSmall') {
      return css`
        margin-bottom: ${({ theme }) => theme.spacing(1)};
      `;
    }
    return css`
      margin-bottom: ${({ theme }) => theme.spacing(3)};
    `;
  }};
`;
