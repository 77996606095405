import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Date } from '@types';

import './style.scss';

const Counter = ({ timestamp, row, className }) => {
  const [counter, setCounter] = useState('0 días 0h 0h 0s');

  const initClock = () => setInterval(() => {
    const expiration = new Date(timestamp).unix();
    const now = new Date().unix();
    const diffTime = expiration - now;
    const countdown = moment.duration(diffTime, 'seconds');
    let clockCounter = `${Math.floor(countdown.asDays())} días ${countdown.hours()}h ${countdown.minutes()}m ${countdown.seconds()}s`;

    if (diffTime < 0) {
      clearInterval(initClock());
      clockCounter = '0 días 0h 0m 0s';
    }

    setCounter(clockCounter);
  }, 500);

  useEffect(() => {
    const clock = initClock();

    return () => clearInterval(clock);
  });

  const styleName = cx({
    counter: true,
    row,
  });

  return (
    <div styleName={styleName} className={className}>
      {
        row
          ? (
            <small>
              Oferta vence en:
              {' '}
              {counter}
            </small>
          )
          : (
            <>
              <small>Oferta vence en:</small>
              <small>{counter}</small>
            </>
          )
      }
    </div>
  );
};

Counter.defaultProps = {
  timestamp: '',
  row: false,
  className: '',
};

Counter.propTypes = {
  timestamp: PropTypes.string,
  row: PropTypes.bool,
  className: PropTypes.string,
};

export default Counter;
