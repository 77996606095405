// eslint-disable-next-line max-classes-per-file
import APIError from '../../errors/APIError';
import GatewayAPI from '../GatewayAPI';
import NetworkError from '../../errors/NetworkError';

// TODO: Discuss where the errors should be declared
class LegacyTraderError extends APIError {
  constructor(...args) {
    super(...args);
    this.name = 'LegacyTraderError';
  }
}

// TODO: Add tests
// TODO: Replace data with entities or migrate to V2
class LegacyTraderAPI extends GatewayAPI {
  async getProfile() {
    try {
      const response = await this._client.get('/traders/me');

      /*
       * We are returning plain JSON as response because this
       * is a legacy implementation, so other
       * parts of the system depends on the raw data
       * sent by the server
      */
      return response.data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getRandomImages() {
    try {
      const response = await this._client.get('/traders/me/randomImages');

      return response.data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async setAntiphishing(imageId, phrase) {
    try {
      const response = await this._client.post(
        '/traders/me/setAntiphishing',
        {
          imageId,
          phrase
        }
      );

      return response.data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getDepositAccount() {
    try {
      const response = await this._client.get('/traders/me/depositAccount');

      return response.data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async sendUnblockCode(payload) {
    try {
      const { data } = await this._client.post('/unblockTrader/sendUnblockToken', payload);

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async unblockTrader(payload) {
    try {
      const { data } = await this._client.post('/unblockTrader', payload);

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async sendPasswordUpdateCode(payload) {
    try {
      const { data } = await this._client.post('/traders/me/updatePassword/sendToken', payload);

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async updatePassword(payload) {
    try {
      const { data } = await this._client.post('/traders/me/updatePassword', payload);

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  _handleError(error) {
    if (!error.response) {
      throw new NetworkError();
    }

    const { response } = error;
    const { code } = response.data;

    throw new LegacyTraderError(code);
  }
}

export default LegacyTraderAPI;
