import { BearerToken } from '@lib/api';
import NoSessionAvailable from './NoSessionAvailable';
class LocalStorageTokenStore {
    static save(token) {
        localStorage.setItem('token', token.value);
    }
    static find() {
        const storedToken = localStorage.getItem('token');
        if (!storedToken) {
            throw new NoSessionAvailable();
        }
        return new BearerToken(storedToken);
    }
    static clear() {
        localStorage.removeItem('token');
    }
}
export default LocalStorageTokenStore;
