import { jsx as _jsx } from "react/jsx-runtime";
import createErrorReporter from './createErrorReporter';
import ErrorReporterProvider from './ErrorReporterProvider';
const ErrorReporter = ({ children, driver }) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
    const { useProfile } = require('@lib/profile');
    const { profile } = useProfile();
    const reporter = createErrorReporter(driver);
    return (_jsx(ErrorReporterProvider, { trader: profile, reporter: reporter, children: children }));
};
export default ErrorReporter;
