import styled from 'styled-components';

import { Form } from '@components';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  justify-content: center;

  > * {
    text-align: center;
    margin: 0;
  }

  > p {
    color: ${({ theme }) => theme.palette.grayG};
  }
`;

const PhraseForm = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: center;
  text-align: center;

  > p {
    color: ${({ theme }) => theme.palette.grayG};
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}px){
    > button {
      width: auto;
    }
  }
`;

export { PhraseForm, TitleWrapper };
