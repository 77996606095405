import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Modal } from 'bricks-ui/packages/bricks-ui';

const ModalRenderer = ({ children, ...props }) => (
  createPortal(
    <Modal {...props}>
      {children}
    </Modal>,
    document.body
  )
);

ModalRenderer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export default ModalRenderer;
