import styled from 'styled-components';

import Text from '../../components/Text';
import Button from '../../components/Button';
import Icon from '../../components/Icon';

const UpgradeAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  gap: ${({ theme }) => theme.spacing(4.5)};
  max-width: 100%;
`;

const InvestmentLimitBodyWrapper = styled.div`
  max-width: 25rem;

  > p {
    text-align: center;
  }
`;

const InvestmentLimitText = styled(Text)`
  margin: 0;
`;

const UpgradeButton = styled(Button)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    width: 100%;
  }
`;

const KeepUpdateLimitlessAccountWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4.5)};
  justify-content: space-around;
  text-align: center;
  width: 100%;
`;

const UpgradeAccountIcon = styled(Icon)`
  width: ${({ theme }) => theme.spacing(12)};
  height: ${({ theme }) => theme.spacing(12)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    display: none;
  }
`;

const UpgradeAccountModalArea = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  > p {
    margin: 0;
    text-align: center;
  }
`;

export {
  InvestmentLimitBodyWrapper,
  InvestmentLimitText,
  UpgradeAccountModalArea,
  UpgradeAccountWrapper,
  UpgradeButton,
  UpgradeAccountIcon,
  KeepUpdateLimitlessAccountWrapper
};
