import styled from 'styled-components';

import Text from '../../../Text';

const TextWrapper = styled(Text)`
  text-align: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export { ActionsWrapper, TextWrapper };
