import regularExpressions from '../utils/regularExpressions';

class Percentage {
  constructor(amount) {
    this._amount = Number(amount * 100);
  }

  format() {
    if (!Number(this._amount)) {
      return 0;
    }

    if (Number.isInteger(this._amount)) {
      return this._amount;
    }

    if (String(this._amount).split('.')[1].charAt(0) === '0') {
      return Math.round(this._amount);
    }

    return parseFloat(this._amount).toFixed(2);
  }

  formatWithoutRounding() {
    const formattedValue = this._amount.toString();

    const matchResult = formattedValue.match(regularExpressions.onlyNumbersWithTwoDecimals);
    const formattedNumber = matchResult ? matchResult[0] : 0;

    return formattedNumber;
  }
}

export default Percentage;
