import React from 'react';

import {
  ActionMediaContainer,
  ContentMediaContainer,
  MediaContainer,
  TitleMediaContainer
} from '@components';

import { realOwnerViewImage } from '../../../../../assets/images/EnrollmentStepImages';
import { DescriptionText, TitleText } from '../../EnrollmentMediaContainer.style';

const REAL_OWNER_IMAGE_ALT = 'Un edificio entregando dinero en una bolsa a una persona';

const RealOwnerMediaContainer = () => (
  <MediaContainer>
    <TitleMediaContainer>
      <TitleText>¡Disfruta de tus rentas!</TitleText>
    </TitleMediaContainer>

    <ContentMediaContainer>
      <img src={realOwnerViewImage} alt={REAL_OWNER_IMAGE_ALT} />
    </ContentMediaContainer>

    <ActionMediaContainer>
      <DescriptionText>
        La mayoría de las propiedades generan rentas cada mes. ¡Es una excelente forma de obtener
        ingresos pasivos y hacer crecer tu patrimonio!
      </DescriptionText>
    </ActionMediaContainer>
  </MediaContainer>
);

export default RealOwnerMediaContainer;
