import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const PaymentPlanPaid = (props) => {
    const { notification } = props;
    const propertyName = notification.params?.crowdfundingPropertyName;
    const numberOfBricks = notification.params?.numberOfBricks;
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Has liquidado tu plan de pagos" }), _jsxs(Paragraph, { children: ["Felicidades, has finalizado tu plan de pagos en la propiedad", _jsx(Bold, { children: ` ${propertyName} ` }), "y has adquirido", _jsx(Bold, { children: ` ${numberOfBricks} Ladrillos.` })] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default PaymentPlanPaid;
