import InvestmentProfit from './InvestmentProfit';

// It should Refactor when update my account
class ActiveInvestmentProfit extends InvestmentProfit {
  constructor(data) {
    super(data);

    this._capitalGain = data.capitalGain;
  }

  get capitalGainAmount() {
    return this._capitalGain.amount;
  }

  get capitalGainPercentage() {
    return this._capitalGain.percentage;
  }
}

export default ActiveInvestmentProfit;
