import APIError from '../APIError';

const OPERATOR_NOT_FOUND = 'OPERATOR_NOT_FOUND';

class OperatorError extends APIError {
  constructor(code, details = null) {
    super(code, 'OperatorError', details);
  }

  operatorNotFound() {
    return this._code === OPERATOR_NOT_FOUND;
  }
}

export default OperatorError;
