import EnrollmentInvestor from '../EnrollmentInvestor';
import HundredBricksAPI from '../../HundredBricksAPI';
import { APIError } from '../../../api';
import InvalidEmailToken from './InvalidEmailToken';
import InvestorNotRegisted from './InvestorNotRegisted';
import ResendEmailTokenError from './ResendEmailTokenError';
import VerifyEmailError from './VerifyEmailError';
class EmailAPI extends HundredBricksAPI {
    async resendToken() {
        try {
            const { data } = await this.client.post('/v2/profile/email/sendVerificationToken');
            return data;
        }
        catch (error) {
            if (error instanceof APIError) {
                throw new ResendEmailTokenError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new ResendEmailTokenError('Unexpected email error', { cause: error });
            }
            else {
                throw new ResendEmailTokenError(`Unexpected email error: ${error}`);
            }
        }
    }
    async verify(code) {
        try {
            const { data } = await this.client.post('/v2/profile/email/verify', { code });
            return new EnrollmentInvestor(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_CODE':
                        throw new InvalidEmailToken({ cause: error });
                    case 'INVESTOR_NOT_FOUND':
                        throw new InvestorNotRegisted({ cause: error });
                    default:
                        throw new VerifyEmailError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new VerifyEmailError('Unexpected email error', { cause: error });
            }
            else {
                throw new VerifyEmailError(`Unexpected email error: ${error}`);
            }
        }
    }
}
export default EmailAPI;
