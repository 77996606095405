// It should Refactor when update my account
class InvestmentProfit {
  constructor(data) {
    this._investmentProfit = data;
    this._property = data.property;
  }

  get property() {
    return this._property;
  }

  get id() {
    return this._property.id;
  }

  get thumbnailURL() {
    return this._property.thumbnailURL;
  }

  get name() {
    return this._property.name;
  }

  get numberOfBricks() {
    return this._property.numberOfBricksOwned || this._property.numberOfBricksSold || 0;
  }

  get investedAmount() {
    return this._investmentProfit.investedAmount;
  }

  get isOnPresale() {
    return this._investmentProfit.isOnPresale;
  }
}

export default InvestmentProfit;
