import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Money } from '@types';
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const WithdrawalRequestRejected = (props) => {
    const { notification } = props;
    const amount = Money.format(notification.params?.requestedAmount);
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Tu retiro no pudo procesarse" }), _jsxs(Paragraph, { children: ["Tu solicitud de retiro de", _jsx(Bold, { children: ` ${amount} ` }), "no pudo procesarse, pero tus fondos ya est\u00E1n de vuelta en tu cuenta de 100 Ladrillos. Cont\u00E1ctanos para revisar los detalles y completar la operaci\u00F3n."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default WithdrawalRequestRejected;
