import LegacyEnrollmentProfile from './LegacyEnrollmentProfile';
class LegacyEnrollmentMoralProfile extends LegacyEnrollmentProfile {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(data, profile, enrollmentStatus) {
        super({ ...data }, profile, enrollmentStatus);
    }
    isLegalPerson() {
        return true;
    }
}
export default LegacyEnrollmentMoralProfile;
