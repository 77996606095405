/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import Calendar from 'react-calendar';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Date } from '@types';

import Icon from '../Icon';
import HiddenComponent from '../Styled/HiddenComponent.style';

import './style.scss';

const Input = ({
  id,
  type,
  label,
  multiline,
  cols,
  rows,
  iconName,
  iconAlt,
  showEyeIcon,
  error,
  message,
  className,
  name,
  handleChange,
  disabled,
  handleBlur,
  handleFocus,
  value,
  required,
  hasHiddenComponent,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const calendarContainer = useRef(null);

  const handleInputBlur = () => {
    setIsFocus(false);

    if (handleBlur) {
      handleBlur();
    }
  };

  const handleInputFocus = () => {
    setIsFocus(true);

    if (handleBlur) {
      handleFocus();
    }
  };

  const toggleShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const hideCalendar = () => {
    setShowCalendar(false);
    handleInputBlur();
  };

  const handleCalendarChange = date => {
    handleChange(date);
    hideCalendar();
  };

  useEffect(() => {
    if (type === 'date') {
      const handleClickOutside = event => {
        const isClickOutsideCalendar = calendarContainer.current
          && !calendarContainer.current.contains(event.target);

        if (isClickOutsideCalendar) {
          hideCalendar();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const inputStyleName = cx({
    'pseudo-input': true,
    isCalendar: type === 'date',
    focused: isFocus,
    disabled,
    error
  });

  const messageStyleName = cx({
    message: true,
    error
  });

  const passwordType = showPassword ? 'text' : 'password';

  if (multiline) {
    return <textarea name={id} id={id} cols={cols} rows={rows} {...props} />;
  }

  if (type === 'date') {
    const openCalendar = () => {
      setShowCalendar(true);
      handleInputFocus();
    };

    const dateValue = value ? new Date(value).format('DD MMMM YYYY') : '';
    const calendarValue = value ? new Date(value).toDate() : new Date().toDate();

    return (
      <div className={className} styleName="input-container" tid="input-calendar">
        <label
          id={`${id}Label`}
          htmlFor={id}
          tid="open-calendar"
          styleName="input-label"
          onClick={openCalendar}
        >
          {label}
          {hasHiddenComponent && <HiddenComponent id={`${id}Tooltip`} />}
          {required && <span styleName="required">*</span>}
        </label>

        <div
          styleName={inputStyleName}
          onClick={openCalendar}
          role="presentation"
        >
          <input
            id={id}
            styleName="input-entry"
            type="text"
            name={name}
            disabled={disabled}
            readOnly
            value={dateValue}
            required={required}
            aria-labelledby="input"
            {...props}
          />
          <div styleName="pseudo-input-icon">
            <Icon name="icCalendar" alt="Calendario" />
          </div>
        </div>
        {showCalendar && (
          <div tid="calendar-container" ref={calendarContainer}>
            <Calendar
              styleName="calendar"
              calendarType="iso8601"
              locale="es-MX"
              onClickDay={handleCalendarChange}
              value={calendarValue}
              tid="calendar"
            />
          </div>
        )}
        {message && <p styleName={messageStyleName}>{message}</p>}
      </div>
    );
  }

  return (
    <div className={className} styleName="input-container">
      <label
        id={`${id}Label`}
        htmlFor={id}
        styleName="input-label"
      >
        {label}
        {hasHiddenComponent && <HiddenComponent id={`${id}Tooltip`} />}
        {required && <span styleName="required">*</span>}
      </label>

      <div styleName={inputStyleName}>
        {iconName && (
          <div styleName="pseudo-input-icon">
            <Icon name={iconName} alt={iconAlt} />
          </div>
        )}
        {showEyeIcon && (
          <div styleName="eye-icon" onClick={toggleShowPassword} role="presentation">
            <Icon name={showPassword ? 'IcEyeOpen' : 'IcEyeClosed'} alt="Contraseña" />
          </div>
        )}
        <input
          id={id}
          styleName="input-entry"
          type={type === 'password' ? passwordType : type}
          name={name}
          onChange={handleChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          disabled={disabled}
          value={value}
          required={required}
          aria-labelledby="input"
          {...props}
        />
      </div>
      {message && <p styleName={messageStyleName}>{message}</p>}
    </div>
  );
};

Input.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  iconName: PropTypes.string,
  showEyeIcon: PropTypes.bool,
  iconAlt: PropTypes.string,
  value: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  cols: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiline: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hasHiddenComponent: PropTypes.bool
};

Input.defaultProps = {
  cols: 30,
  rows: 10,
  multiline: false,
  label: '',
  iconName: null,
  handleBlur: null,
  handleFocus: null,
  iconAlt: '',
  showEyeIcon: false,
  error: false,
  disabled: false,
  message: '',
  name: '',
  className: '',
  value: '',
  required: false,
  hasHiddenComponent: false
};

export default Input;
