import styled, { css } from 'styled-components';

const ContentMediaContainerWrapper = styled.div`
  ${({ children }) => {
    if (!children) return;

    return css`
      font-size: ${({ theme }) => theme.toRem(16)};
      align-self: center;
      justify-self: center;
      width: 95%;
      text-align: center;

      > img {
        height: ${({ theme }) => theme.spacing(32)};
      }
    `;
  }}
`;

export default ContentMediaContainerWrapper;
