const calculateTimePeriodBetweenDates = (startDate, endDate = new Date()) => {
    if (!startDate)
        return new Error('The start date cannot be undefined');
    if (endDate < startDate)
        return new Error('The end date cannot be less than start date');
    const daysInMonth = 30;
    const monthsInYear = 12;
    const daysInMilliseconds = 1_000 * 60 * 60 * 24;
    const differenceInMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());
    const days = Math.floor(differenceInMilliseconds / daysInMilliseconds);
    const months = Math.floor(days / daysInMonth);
    const years = Math.floor(months / monthsInYear);
    if (days === 0)
        return 'hoy';
    if (days === 1)
        return '1 día';
    if (days <= 30)
        return `${days} días`;
    if (days > 30 && days <= 365) {
        if (months === 1)
            return '1 mes';
        return `${months} meses`;
    }
    if (months >= 12) {
        if (years === 1)
            return '1 año';
        return `${years} años`;
    }
    return new Error('The dates could not be validated');
};
export default calculateTimePeriodBetweenDates;
