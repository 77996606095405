/* eslint-disable @typescript-eslint/no-unused-vars */
/* This error reporter is just for testing purposes should not be used on local or produciton
 * code. It is intentional to leave all methods in blank. Because this error reporter does
 * nothing, no test are needed
 */
class DummySegmenter {
    getTag(tag) {
        // warning its a dummy function
    }
    getTags() {
        // warning its a dummy function
    }
    setTag(tag, value) {
        // warning its a dummy function
    }
    deleteTag(tag) {
        // warning its a dummy function
    }
    clearTags() {
        // warning its a dummy function
    }
}
// eslint-disable-next-line import/no-unused-modules
export default DummySegmenter;
