import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const NewPropertyManagementLog = (props) => {
    const { notification } = props;
    const property = notification.params?.property;
    const propertyName = property?.name || '----';
    const logTitle = notification.params?.title ?? '----';
    return (_jsxs(NotificationContainer, { children: [_jsxs(Subtitle, { level: "3", children: ["Actualizaci\u00F3n de tu propiedad", ` ${propertyName}`] }), _jsxs(Paragraph, { children: ["Se ha publicado una nueva actualizaci\u00F3n titulada", _jsx(Bold, { children: ` ${logTitle}, ` }), "conoce las \u00FAltimas noticias sobre tu propiedad. Puedes encontrarla en la secci\u00F3n de \"Mi Patrimonio\" o directamente en el detalle de la propiedad", _jsx(Bold, { children: ` ${propertyName}.` })] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default NewPropertyManagementLog;
