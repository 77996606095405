import { APIError } from '@lib/api';
class HundredBricksAPIError extends Error {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(name, ...args) {
        if (args.length === 2) {
            super(`${name}: ${args[0]}`, args[1]);
        }
        else if (args.length === 1 && typeof args[0] === 'string') {
            super(`${name}: ${args[0]}`);
        }
        else {
            super(name, ...args);
        }
    }
    isUnauthorized() {
        if (!(this.cause instanceof APIError))
            return false;
        return this.cause.status === 401;
    }
}
export default HundredBricksAPIError;
