import styled, { css } from 'styled-components';

const SiderNavStyled = styled.nav`
  background-color: ${({ theme }) => theme.palette.background.hbBackgroundB};
  box-shadow: inset 0px -1px ${({ theme }) => theme.palette.grayC};
  border-radius: ${({ theme }) => theme.borderRadius()};
  padding: ${({ theme }) => theme.spacing(2)} 0;
  border: none;
  height: auto;
  max-width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${({ isOpenedMenu }) => isOpenedMenu && css`
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.background.hbBackgroundA};
  `}

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${({ theme }) => theme.spacing(2)};

    ${({ hasTitle }) => !hasTitle && css`
      justify-content: flex-end;
    `}

    .sider-nav-title {
      text-align: left;
    }
  }

  .nav-menu {
    position: relative;
  }

  .nav-menu-container {
    opacity: 0;
    transition: all 0.5s ease;
    position: absolute;
    background: white;
    left: -100%;
    right: 0;
    top: 0;

    ${({ hasSubtitle }) => hasSubtitle && css`
      top: -48px;
    `}
  }

  .active {
    left: 0;
    opacity: 1;
    z-index: 99;
    height: 90vh;
  }

  .kebab-menu {
    background-color: transparent;
    min-width: ${({ theme }) => theme.spacing(3)};
    display: flex;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:hover,
    &:focus {
      background: inherit;
      border: 0;
      outline: 0;
      box-shadow: none;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    background-color: ${({ theme }) => theme.palette.background.hbBackgroundA};
    padding: ${({ theme }) => theme.spacing(2)};
    border: 1px solid ${({ theme }) => theme.palette.grayC};
    max-width: 224px;

    .kebab-menu {
      display: none;
    }

    .nav-header {
      padding: 0;
    }

    .nav-menu-container {
      left: 0;
      opacity: 1;
      position: relative;
      top: 0;
    }

    .nav-subheader {
      display: none;
    }

    .nav-brand {
      .sider-nav-title {
        text-align: left;
      }
    }
  }
`;

const SiderNavTitle = styled.h3`
  ${({ theme }) => theme.typography.font()}

  font-size: ${({ theme }) => theme.toRem(18)};
  font-weight: 400;

  margin: 0;
  padding: 0;
  width: 100%;

  ${({ level }) => level === 'title' && css`
    text-align: center;
  `};

  ${({ level, theme }) => level === 'subtitle1' && css`
    font-size: ${theme.toRem(16)};
    text-align: center;
    padding: 0;
  `};

  ${({ level, theme }) => level === 'subtitle2' && css`
    color: ${theme.palette.grayH};
    font-size: ${theme.toRem(14)};
    display: flex;
    letter-spacing: 0.34px;
    align-items: center;
    text-transform: uppercase;
    padding: 0 0 0 ${theme.spacing(1)};
    height: ${theme.spacing(4)};
  `};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}px) {
    ${({ level }) => level === 'title' && css`
      text-align: left;
  `};
  }
`;

const SiderNavDivider = styled.hr`
  height: 1px;
  width: 100%;

  background-color: ${({ theme }) => theme.palette.grayC};
  border: 0;
  margin: ${({ theme }) => theme.spacing(2)} 0;
  padding: 0;
`;

export { SiderNavStyled, SiderNavTitle, SiderNavDivider };
