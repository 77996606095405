import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet } from 'react-router-dom';
import { useProfile } from '@lib/profile';
import PrivateLayout from '@layout/PrivateLayout';
import { GeneralContent } from '@layout/Layout.style';
const Movements = () => {
    const { profile } = useProfile();
    if (!profile?.isDoneRegistering()) {
        return (_jsx(Navigate, { replace: true, to: "/my-account/first-steps" }));
    }
    return (_jsx(PrivateLayout, { children: _jsx(GeneralContent, { children: _jsx(Outlet, {}) }) }));
};
export default Movements;
