// These are proposed new colors to be added to the design system
const colors = {
  bg_blue_50: '#F3FAFF',
  bg_lightblue_100: '#E1E9F4',
  bg_blue_100: '#8CB1F5',
  bg_blue_300: '#77C1FF',
  bg_blue_900: '#3C5A78',
  bg_orange_100: '#FCC15E',
  bg_orange_500: '#FFA500',
  bg_green_100: '#59CC8A',
  bg_green_800: '#50643C'
};

export default colors;
