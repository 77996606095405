class PaymentPlan {
    id;
    agreedAmount;
    averagePricePerBrick;
    capitalGain;
    capitalGainPerBrick;
    capRate;
    createdAt;
    crowdfundingProperty;
    debtAmount;
    endsAt;
    initialPaymentAmount;
    numberOfBricks;
    numberOfPayments;
    currentPaidAmount;
    payments;
    penalization;
    startsAt;
    status;
    updatedAt;
    constructor(data) {
        this.id = data.id;
        this.agreedAmount = data.agreedAmount;
        this.averagePricePerBrick = data.averagePricePerBrick;
        this.capitalGain = data.capitalGain;
        this.capitalGainPerBrick = data.capitalGainPerBrick;
        this.capRate = data.capRate;
        this.createdAt = new Date(data.createdAt);
        this.crowdfundingProperty = data.crowdfundingProperty;
        this.debtAmount = data.debtAmount;
        this.endsAt = new Date(data.endsAt);
        this.initialPaymentAmount = data.initialPaymentAmount;
        this.numberOfBricks = data.numberOfBricks;
        this.numberOfPayments = data.numberOfPayments;
        this.penalization = data.penalization;
        this.currentPaidAmount = data.currentPaidAmount;
        this.payments = data.payments;
        this.startsAt = new Date(data.startsAt);
        this.status = data.status;
        this.updatedAt = new Date(data.updatedAt);
    }
    isPendingApproval() {
        return this.status === 'pending_approval';
    }
    isActive() {
        return this.status === 'approved';
    }
    isPaid() {
        return this.status === 'paid';
    }
    isCancelled() {
        return this.status === 'cancelled';
    }
    isRejected() {
        return this.status === 'rejected';
    }
    getDeadline() {
        if (this.isRejected())
            return '0/0';
        const initialPaymentsNumber = 1;
        const deadline = this.payments.filter(payment => payment.isReadyToPay() || payment.isPaid()).length;
        const currentDeadline = `${deadline - initialPaymentsNumber}`;
        const finalDeadline = `${this.payments.length - initialPaymentsNumber}`;
        return `${currentDeadline}/${finalDeadline}`;
    }
}
export default PaymentPlan;
