import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import Text from '../Text';
import Title from '../Title';

import { ActionsWrapper, InfoWrapper } from './UserLeavingModal.style';

const UserLeavingModal = ({ handleCancel, handleConfirm, url }) => (
  <Modal isOpen onClose={handleCancel}>
    <Title>Estás saliendo de 100 Ladrillos</Title>

    <InfoWrapper>
      <Text>100 Ladrillos no es responsable del contenido de sitios externos</Text>

      <Icon name="icLeavingApp" alt="Saliendo de 100 Ladrillos" height={84} width={84} />

      <Text>
        {`¿Quieres ir a ${url}?`}
      </Text>
    </InfoWrapper>

    <ActionsWrapper>
      <Button id="cancel" onClick={handleCancel} color="secondary">
        Cancelar
      </Button>

      <Button id="continue" onClick={handleConfirm}>
        Continuar
      </Button>
    </ActionsWrapper>
  </Modal>
);

UserLeavingModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired
};

export default UserLeavingModal;
