import regularExpressions from '../regularExpressions';
const TYPES_OF_TEXT_OBFUSCATE = {
    email: 'email',
    phone: 'phone'
};
const getObfuscatedEmail = (email) => {
    const MAX_VISIBLE_CHARS = 2;
    const [username, domain] = email.split('@');
    const topLevelDomainIndex = domain.lastIndexOf('.');
    const [domainName, topLevelDomain] = [
        domain.slice(0, topLevelDomainIndex),
        domain.slice(topLevelDomainIndex + 1)
    ];
    const obfuscatedUsername = username.substring(0, MAX_VISIBLE_CHARS)
        + '*'.repeat(username.length - MAX_VISIBLE_CHARS);
    const obfuscatedDomainName = (domainName.substring(0, MAX_VISIBLE_CHARS) ?? domainName)
        + '*'.repeat(domainName.length - MAX_VISIBLE_CHARS);
    return `${obfuscatedUsername}@${obfuscatedDomainName}.${topLevelDomain}`;
};
const getObfuscatedPhone = (phone) => {
    const areaCode = phone.substring(0, 3);
    const firstDigit = phone.substring(3, 4);
    const lastDigits = phone.substring(8);
    return `${areaCode}-${firstDigit}**-**${lastDigits}`;
};
const obfuscate = (text, type) => {
    if (typeof text !== 'string')
        return '';
    const { allWhiteSpaces: allWhiteSpacesRegexp } = regularExpressions;
    const textWithoutSpaces = text.replace(allWhiteSpacesRegexp, '');
    if (type === TYPES_OF_TEXT_OBFUSCATE.email) {
        return getObfuscatedEmail(textWithoutSpaces);
    }
    if (type === TYPES_OF_TEXT_OBFUSCATE.phone) {
        return getObfuscatedPhone(textWithoutSpaces);
    }
    return '';
};
export default obfuscate;
