import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { Link } from 'react-router-dom';
import { Button, EmailField } from '@components';
import { HelperText, Paragraph, Subtitle } from '@components/Styled';
import { regularExpressions as regex } from '@utils';
import { VerificationWrapper } from './ForgotPassword.style';
const EmailHandler = ({ handleSendCode, isSendingCode }) => {
    const [email, setEmail] = useState('');
    const isValidEmail = () => regex.emailFormat.test(email);
    return (_jsxs(Fragment, { children: [_jsx(Subtitle, { children: "\u00BFOlvidaste tu contrase\u00F1a?" }), _jsxs(VerificationWrapper, { children: [_jsx(HelperText, { children: "Si olvidaste tu contrase\u00F1a, ingresa tu correo electr\u00F3nico. Te enviaremos un c\u00F3digo \u00FAnico de 6 d\u00EDgitos." }), _jsx(EmailField, { id: "fp-email", placeholder: "micorreo@gmail.com", label: "Correo electr\u00F3nico:", onChange: (event) => setEmail(event.target.value.toLowerCase()), value: email, inputProps: { autoComplete: 'off' }, InputLabelProps: { requiredIndicator: 'asterisk' }, required: true }), _jsx(Button, { id: "forgot-password-code-button", disabled: !isValidEmail(), isLoading: isSendingCode, onClick: () => handleSendCode(email), children: "Enviar c\u00F3digo" }), _jsxs(Paragraph, { children: ['¿Ya tienes cuenta? ', _jsx(Link, { to: "/login", children: "Inicia sesi\u00F3n" })] })] })] }));
};
export default EmailHandler;
