/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, LoadingOverlay, toast } from '@components';
import { usePrintAccountStatement, useRequestStatus } from '@hooks';

import fetchAccountStatement from './api';

import './style.scss';

const AccountStatementReport = () => {
  const { year, month } = useParams();

  const [reportContent, setReportContent] = useState('');
  const [reportContentStatus, setReportContentStatus] = useRequestStatus();
  const [openBrowserWindowToPrint] = usePrintAccountStatement();

  const handlePrintAccountStatement = () => {
    openBrowserWindowToPrint(
      `/movements/account-statement/${month}/${year}`,
      reportContent
    );
  };

  useEffect(() => {
    const getReportContent = async () => {
      setReportContentStatus('pending');

      try {
        const payload = { year, month };
        const { data: htmlContent } = await fetchAccountStatement(payload);

        setReportContent(htmlContent);
        setReportContentStatus('resolved');
      } catch (error) {
        toast.danger(
          'Algo salió mal al obtener tu estado de cuenta, inténtalo de nuevo'
        );
        setReportContentStatus('rejected');
      }
    };

    if (!reportContent) {
      getReportContent();
    }
  }, [
    month,
    reportContent,
    setReportContent,
    setReportContentStatus,
    year
  ]);

  return (
    <div styleName="account-statement-overflow">
      <div styleName="account-statement">
        {reportContentStatus.isPending && (
          <LoadingOverlay tid="loading-overlay" />
        )}

        {reportContentStatus.isResolved && (
          <div styleName="account-statement-actions">
            <Button
              id="print-button"
              tid="print-button"
              type="button"
              onClick={handlePrintAccountStatement}
            >
              Descargar
            </Button>
          </div>
        )}
        <div styleName="account-statement-content">
          <div
            id="report-content"
            tid="report-content"
            dangerouslySetInnerHTML={{ __html: reportContent }}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountStatementReport;
