import { EmailError } from '../errors/Profile';
import Email from '../entities/Enrollment/Email';
import { SecondFactorToken, ObfuscatedUserData } from '../entities';

import GatewayAPI from './GatewayAPI';

class UpdateEmailAPI extends GatewayAPI {
  async setEmail(newEmail) {
    try {
      const { data } = await this._client.post(
        '/profile/email/sendEmailVerification',
        { email: newEmail }
      );

      return new SecondFactorToken(data);
    } catch (error) {
      return this._handleError(error, EmailError);
    }
  }

  async verifyEmail(token) {
    try {
      const { data } = await this._client.post('/profile/email/verifyNewEmail', { token });
      const { email, phone } = data;

      return new ObfuscatedUserData(email, phone);
    } catch (error) {
      return this._handleError(error, EmailError);
    }
  }

  async sendUpdateEmailToken(token, channel) {
    try {
      const { data } = await this._client.post(
        '/profile/email/sendUpdateEmailToken',
        { token, channel }
      );

      return new SecondFactorToken(data);
    } catch (error) {
      return this._handleError(error, EmailError);
    }
  }

  async verifyOldEmail(token, code) {
    try {
      const { data } = await this._client.post('/profile/updateNewEmail', { token, code });

      return new Email(data);
    } catch (error) {
      return this._handleError(error, EmailError);
    }
  }

  _handleError = (error, APIProfileError) => {
    if (!error.response) {
      throw APIProfileError.getNetworkError();
    }

    const errorCode = error.response.data.code;
    const profileError = new APIProfileError(errorCode);

    throw profileError;
  };
}

export default UpdateEmailAPI;
