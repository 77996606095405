// WARNING: This class was created just for legacy support purposes, it not intened to be used
// on new code. Please limit the maintenance effort here, this code should be removed as soon as
// the code dependencies on Legacy are low.
class LegacyProfile {
    legacyProfileData;
    rawData;
    email;
    phone;
    userId;
    isLegacy;
    lockCode;
    phrase;
    imageId;
    firstLastName;
    secondLastName;
    names;
    isEmailVerified;
    curp;
    taxId;
    clabe;
    advertising;
    canBuy;
    lastSessionDate;
    lastSessionGeolocalization;
    createdAt;
    updatedAt;
    hasAutomaticInvoicing;
    constructor(profile, legacyProfileData) {
        this.rawData = profile.rawData;
        this.email = profile.email.address;
        this.legacyProfileData = legacyProfileData;
        this.phone = legacyProfileData.phone;
        this.userId = legacyProfileData.userId;
        this.isLegacy = false;
        this.lockCode = legacyProfileData.lockCode;
        this.phrase = legacyProfileData.phrase;
        this.imageId = legacyProfileData.imageId;
        this.firstLastName = legacyProfileData.firstLastName;
        this.secondLastName = legacyProfileData.secondLastName;
        this.names = legacyProfileData.names;
        this.isEmailVerified = legacyProfileData.isEmailVerified;
        this.curp = profile.curp;
        this.taxId = profile.taxId.value;
        this.clabe = legacyProfileData.clabe;
        this.advertising = legacyProfileData.advertising;
        this.canBuy = legacyProfileData.canBuy;
        this.lastSessionDate = legacyProfileData.lastSessionDate;
        this.lastSessionGeolocalization = legacyProfileData.lastSessionGeolocalization;
        this.createdAt = legacyProfileData.createdAt;
        this.updatedAt = legacyProfileData.updatedAt;
        this.hasAutomaticInvoicing = Boolean(legacyProfileData.automaticInvoicing);
    }
    isLegalPerson() {
        return false;
    }
    isPhysicalInvestor() {
        return true;
    }
}
export default LegacyProfile;
