class CrowdfundingPropertyLog {
    propertyLogs;
    constructor(crowdfundingPropertyLogs) {
        this.propertyLogs = crowdfundingPropertyLogs;
    }
    get logs() {
        return this.propertyLogs;
    }
    get lastLog() {
        let crowdfundingPropertyLogsLength = 0;
        if (this.propertyLogs.length > 1) {
            crowdfundingPropertyLogsLength = this.propertyLogs.length - 1;
        }
        return this.propertyLogs[crowdfundingPropertyLogsLength] || null;
    }
    hasLogs() {
        return this.propertyLogs.length > 0;
    }
}
export default CrowdfundingPropertyLog;
