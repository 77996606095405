class HTTPError extends Error {
    status;
    body;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(status, ...args) {
        if (args.length === 2) {
            super(`HTTPError(${status})`, args[1]);
            this.body = args[0];
        }
        else if (args.length === 1 && typeof args[0] === 'string') {
            super(`HTTPError(${status})`);
            this.body = args[0];
        }
        else {
            super(`HTTPError(${status})`, ...args);
        }
        this.status = status;
    }
}
export default HTTPError;
