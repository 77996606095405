import { APIError } from '@lib/api';
import HundredBricksAPI from '../HundredBricksAPI';
import Advisor from './Advisor';
import AdvisorMeeting from './AdvisorMeeting';
import AdvisorURLError from './AdvisorURLError';
import InvestorIsMissingAdvisor from './InvestorIsMissingAdvisor';
import MeetingNotFound from './MeetingNotFound';
import ObtainAdvisorError from './ObtainAdvisorError';
class AdvisorAPI extends HundredBricksAPI {
    async obtain() {
        try {
            const { data } = await this.client.get('/v2/advisor');
            return new Advisor(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                if (error.code === 'ADVISOR_NOT_FOUND') {
                    throw new InvestorIsMissingAdvisor();
                }
                throw new ObtainAdvisorError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new ObtainAdvisorError('An error occurred while getting investor\'s advisor', { cause: error });
            }
            else {
                throw new ObtainAdvisorError(`Unexpected error getting investor's advisor: '${error}'`);
            }
        }
    }
    async getMeetingUrl() {
        try {
            const { data } = await this.client.get('/v2/advisor/meeting');
            return new AdvisorMeeting(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                if (error.code === 'MEETING_NOT_FOUND') {
                    throw new MeetingNotFound();
                }
                throw new AdvisorURLError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new AdvisorURLError('An error occurred while getting advisor\'s meeting url', { cause: error });
            }
            else {
                throw new AdvisorURLError(`An error occurred while getting advisor's meeting url: '${error}'`);
            }
        }
    }
}
export default AdvisorAPI;
