import styled from 'styled-components';

import { flexAsRow } from '@components/Styled/mixins';

const Badge = styled.div`
  ${flexAsRow(0.5)};

  align-items: center;

  > p {
    font-size: ${({ theme }) => theme.toRem(18)};
  }
`;

export { Badge };
