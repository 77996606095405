import styled from 'styled-components';

import { flexAsRow } from '@components/Styled/mixins';

const HorizontalBanner = styled.div`
  ${flexAsRow()};

  align-items: center;
  background-color: white;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)};
  position: sticky;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;

  > button:last-of-type {
    &:hover {
      background-color: transparent;
    }

    > svg {
      fill: ${({ theme }) => theme.palette.warning.dark};
      aspect-ratio: 1;
      height: ${({ theme }) => theme.spacing(2)};
      width: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

const BannerMessage = styled.div`
  ${flexAsRow(2)};

  align-items: center;

  > img {
    filter: hue-rotate(190deg) brightness(1.5);
  }

  > p {
    color: ${({ theme }) => theme.palette.warning.dark};
  }
`;

export { BannerMessage, HorizontalBanner };
