/* eslint-disable react/prop-types */
import React from 'react';

import { regularExpressions as regex } from '@utils';

import TextField from '../TextField';

const PASSPORT_NUMBER_LENGTH = 9;

const PassportNumberField = props => {
  const {
    onChange,
    inputProps,
    ...restProps
  } = props;

  const handleChange = event => {
    const inputEvent = event;
    const { value } = event.target;

    inputEvent.target.value = value.replace(regex.onlyLettersAndNumbers, '').toUpperCase();

    onChange(inputEvent);
  };

  return (
    <TextField
      {...restProps}
      onChange={handleChange}
      inputProps={{
        minLength: PASSPORT_NUMBER_LENGTH,
        maxLength: PASSPORT_NUMBER_LENGTH,
        ...inputProps,
      }}
    />
  );
};

export default PassportNumberField;
