import regularExpressions from './regularExpressions';

const formatNumbers = (value, previousValue) => {
  const {
    onlyNumbers: onlyNumbersRegexp,
    avoidLeadingZeros: avoidLeadingZerosRegexp
  } = regularExpressions;
  const isInvalidValue = typeof value !== 'string';

  if (isInvalidValue) {
    return 0;
  }

  let formattedValue = 0;

  formattedValue = value.trim()
    .replace(avoidLeadingZerosRegexp, '')
    .replace(onlyNumbersRegexp, '');

  if (formattedValue.length === 2 && !previousValue) {
    formattedValue = String(value).slice(0, -1);

    return Number(formattedValue);
  }

  return Number(formattedValue);
};

export default formatNumbers;
