import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from 'react';
import { useAPI, useRequest } from '@hooks';
import { useErrorReporter } from '@lib/errorReporter';
import { RenderIf, Icons, IconButton } from '@components';
import { NumberOfNewNotifications } from './NotificationCenter.style';
import NotificationsPanel from './NotificationsPanel';
const NotificationsButton = () => {
    const { notifications } = useAPI();
    const [unseenNotificationsQuantity, setUnseenNotificationsQuantity] = useState(0);
    const errorReporter = useErrorReporter();
    const [unseenNotifications, , unseenNotificationsError, obtainUnseenNotificationsCount] = useRequest(notifications.getUnseenNotificationsCount);
    const [showNotifications, setShowNotifications] = useState(false);
    const { NotificationBellIcon: BellIcon } = Icons;
    useEffect(() => {
        if (!showNotifications) {
            obtainUnseenNotificationsCount();
        }
        else {
            setUnseenNotificationsQuantity(0);
        }
    }, [showNotifications]);
    useEffect(() => {
        if (unseenNotifications) {
            setUnseenNotificationsQuantity(unseenNotifications.quantity);
        }
    }, [unseenNotifications]);
    useEffect(() => {
        if (unseenNotificationsError) {
            errorReporter.critical(unseenNotificationsError);
        }
    }, [errorReporter, unseenNotificationsError]);
    return (_jsxs(Fragment, { children: [_jsxs(IconButton, { "aria-label": "Notificaciones", id: "notifications-open-button", size: "large", onClick: () => { setShowNotifications(prevState => !prevState); }, children: [_jsx(BellIcon, {}), _jsx(RenderIf, { condition: unseenNotificationsQuantity > 0 && !showNotifications, children: _jsx(NumberOfNewNotifications, { children: unseenNotificationsQuantity > 99 ? '99+' : unseenNotificationsQuantity }) })] }), _jsx(RenderIf, { condition: showNotifications, children: _jsx(NotificationsPanel, { onClose: () => { setShowNotifications(false); } }) })] }));
};
export default NotificationsButton;
