import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'bricks-ui/packages/bricks-ui';

import UserLeavingModal from '../components/UserLeavingModal';

const goToExternalLink = url => {
  const container = document.getElementById('leavingAppModal');
  const rootElement = ReactDOM.createRoot(container);

  const closeModal = () => {
    rootElement.unmount();
  };

  const handleConfirm = () => {
    closeModal();
    window.open(url, '_blank');
  };

  return rootElement.render(
    <ThemeProvider theme="marketplace">
      <UserLeavingModal
        handleCancel={closeModal}
        handleConfirm={handleConfirm}
        url={url}
      />
    </ThemeProvider>
  );
};

export default goToExternalLink;
