import { css } from 'styled-components';

import {
  getBackgroundColorSentence,
  getColorSentence,
  getHeightSentence,
  getTextAlignSentence,
  getWidthSentence
} from '../sentences';

// This mixin does not currently conform to the measurement and typography specifications that
// have been established in the Bricks UI project. Therefore, when updating the design standards
// within Bricks UI, it must be reflected and implemented in this mixin to maintain visual and
// functional coherence. It is important to review and adapt this mixin post-upgrade to ensure
// it complies with the new guidelines.

const typography = () => css`
  ${getBackgroundColorSentence};
  ${getColorSentence};
  ${getHeightSentence};
  ${getTextAlignSentence};
  ${getWidthSentence};

  font-family: 'Roboto';
  font-size: ${({ theme }) => theme.toRem(14)};
  letter-spacing: 0.015em;
  line-height: 1.45em;
  margin: 0;
  padding: 0;
`;

export default typography;
