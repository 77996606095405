class OnSaleProperties {
    list;
    count;
    pagination;
    constructor(propertiesData) {
        this.list = propertiesData.items;
        this.count = propertiesData.count;
        this.pagination = propertiesData.pagination;
    }
}
export default OnSaleProperties;
