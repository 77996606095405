import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-key */
import { DataTable } from 'bricks-ui/packages/bricks-ui';
import { Fragment } from 'react';
import { Icon } from '@components';
import { Date, Money } from '@types';
import { Paragraph } from '@components/Styled';
import { ConceptContainer, MovementType, MovementTypeContainer, StatusContainer } from './MyMovementsDataTable.style';
const typeTextMap = new Map([
    ['deposit', 'Depósito'],
    ['charge', 'Cargo'],
    ['withdrawal', 'Retiro']
]);
const statusTextMap = new Map([
    ['approved', 'Aprobado'],
    ['rejected', 'Rechazado'],
    ['pending', 'Pendiente'],
    ['transferring', 'Transfiriendo']
]);
const statusIconMap = new Map([
    ['approved', _jsx(Icon, { name: "icApproved", alt: "Movimiento aprobado" })],
    ['rejected', _jsx(Icon, { name: "icRejected", alt: "Movimiento rechazado" })],
    ['pending', _jsx(Icon, { name: "icPending", alt: "Movimiento pendiente" })],
    ['transferring', _jsx(Icon, { name: "icPending", alt: "Transfiriendo Movimiento" })]
]);
const Type = ({ type }) => (_jsxs(MovementTypeContainer, { children: [_jsx(MovementType, { type: type }), _jsx("span", { children: typeTextMap.get(type) })] }));
const MyMovementsDataTable = ({ movements }) => {
    const COLUMNS = [
        {
            field: 'type',
            label: 'Tipo',
            render: ({ type }) => _jsx(Type, { type: type })
        },
        {
            align: 'center',
            field: 'date',
            label: 'Fecha',
            render: ({ date }) => (_jsxs(Fragment, { children: [_jsx(Paragraph, { children: Date(date).format('DD MMM YYYY /') }), _jsx(Paragraph, { children: Date(date).format('hh:mm') })] }))
        },
        {
            field: 'concept',
            label: 'Descripción',
            render: ({ concept }) => _jsx(ConceptContainer, { children: concept })
        },
        {
            align: 'center',
            field: 'status',
            label: 'Estado',
            render: ({ status }) => (_jsxs(StatusContainer, { children: [_jsx(Paragraph, { children: statusTextMap.get(status) }), statusIconMap.get(status)] }))
        },
        {
            align: 'right',
            field: 'amount',
            label: 'Monto',
            render: ({ amount }) => _jsxs("span", { children: [" ", new Money(amount).toString()] })
        },
        {
            align: 'right',
            field: 'balance',
            label: 'Saldo',
            render: ({ balance }) => _jsxs("span", { children: [" ", new Money(balance).toString(), " "] })
        }
    ];
    return (_jsx(DataTable, { columns: COLUMNS, rows: movements }));
};
export default MyMovementsDataTable;
