import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeProvider } from 'bricks-ui/packages/bricks-ui';
import ReactDOM from 'react-dom/client';
import ToastComponent from './ToastComponent';
const createToast = (type, props) => {
    const domElement = 'toast';
    const container = document.getElementById(domElement);
    if (container === null) {
        throw new Error(`Toast container not found, make sure there is a DOM element with the id '${domElement}'`);
    }
    const toastElement = ReactDOM.createRoot(container);
    const unmountToast = () => {
        toastElement.unmount();
    };
    toastElement.render(_jsx(ThemeProvider, { children: _jsx(ToastComponent, { ...props, type: type, onRemove: unmountToast }) }));
};
const build = (type, props) => {
    if (typeof props === 'string') {
        createToast(type, {
            description: props
        });
        return;
    }
    createToast(type, props);
};
const toast = {
    success: props => build('success', props),
    info: props => build('info', props),
    warning: props => build('warning', props),
    danger: props => build('danger', props)
};
export default toast;
