import HundredBricksAPI from '../../HundredBricksAPI';
import { APIError } from '../../../api';
import AutomaticInvoiceValidationResult from './AutomaticInvoiceValidationResult';
import AutomaticInvoicingStatus from './AutomaticInvoicingStatus';
import AutomaticInvoicingStatusError from './AutomaticInvoicingStatusError';
import BeginAutomaticInvoicingCorrectionError from './BeginAutomaticInvoicingCorrectionError';
import DisableAutomaticInvoicingError from './DisableAutomaticInvoicingError';
import EnableAutomaticInvoicingError from './EnableAutomaticInvoicingError';
import FiscalData from './FiscalData';
import FiscalDataAreIncomplete from './FiscalDataAreIncomplete';
import FiscalRegime from './FiscalRegime';
import FiscalRegimeAlreadyInProcess from './FiscalRegimeAlreadyInProcess';
import FiscalRegimeIsInProcess from './FiscalRegimeIsInProcess';
import InvalidFileType from './InvalidFileType';
import InvalidFiscalRegime from './InvalidFiscalRegime';
import InvalidFiscalZipCode from './InvalidFiscalZipCode';
import InvalidTaxId from './InvalidTaxId';
import InvestorCouldNotUploadRegimeProof from './InvestorCouldNotUploadRegimeProof';
import InvestorDoesNotHaveFiscalRegime from './InvestorDoesNotHaveFiscalRegime';
import InvestorDoesNotHaveFiscalZipCode from './InvestorDoesNotHaveFiscalZipCode';
import InvestorDoesNotHaveRFC from './InvestorDoesNotHaveRFC';
import InvestorFiscalRegimeDoesNotMatch from './InvestorFiscalRegimeDoesNotMatch';
import InvestorFiscalZipCodeDoesNotMatch from './InvestorFiscalZipCodeDoesNotMatch';
import InvestorHasAnInvalidFiscalRegime from './InvestorHasAnInvalidFiscalRegime';
import InvestorHasFiscalRegimeInProcess from './InvestorHasFiscalRegimeInProcess';
import InvestorNameDoesNotMatch from './InvestorNameDoesNotMatch';
import InvestorTaxIdDoesNotMatch from './InvestorTaxIdDoesNotMatch';
import RegimeFileIsEmpty from './RegimeFileIsEmpty';
import RegimeFileIsNotSended from './RegimeFileIsNotSended';
import RegimeFileIsTooBig from './RegimeFileIsTooBig';
import RegimeProofEmpty from './RegimeProofEmpty';
import RegimeProofInvalidFileType from './RegimeProofInvalidFileType';
import RegimeProofIsOversized from './RegimeProofIsOversized';
import RegisterFiscalRegimeError from './RegisterFiscalRegimeError';
import RegisterZipCodeError from './RegisterZipCodeError';
import SetFiscalRegimeError from './SetFiscalRegimeError';
import SetFiscalZipCodeError from './SetFiscalZipCodeError';
import SetTaxIdError from './SetTaxIdError';
import StartEnablingAutomaticInvoicingError from './StartEnablingAutomaticInvoicingError';
import UploadRegimeProofError from './UploadRegimeProofError';
import ValidateFiscalDataError from './ValidateFiscalDataError';
import ZipCodeHasInvalidFormat from './ZipCodeHasInvalidFormat';
class InvoicingAPI extends HundredBricksAPI {
    /**
     * Returns an instance of AutomaticInvoicingStatus
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `AutomaticInvoicingStatus`
     */
    async getAutomaticInvoicingStatus() {
        try {
            const { data } = await this.client.get('v2/invoicing/automation/status');
            return new AutomaticInvoicingStatus(data);
        }
        catch (error) {
            if (error instanceof APIError || error instanceof Error) {
                throw new AutomaticInvoicingStatusError('An unexpected error occurred while requesting automatic invoicing status', { cause: error });
            }
            throw new AutomaticInvoicingStatusError(`An unexpected error occurred while requesting automatic invoicing status: '${error}'`);
        }
    }
    /**
     * Returns an instance of AutomaticInvoicingStatus
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `AutomaticInvoicingStatus`
     */
    async startEnablingAutomaticInvoicing() {
        try {
            const { data } = await this.client.post('v2/invoicing/automation/start');
            return new AutomaticInvoicingStatus(data);
        }
        catch (error) {
            if (error instanceof APIError || error instanceof Error) {
                throw new StartEnablingAutomaticInvoicingError('An unexpected error occurred while requesting start enable automatic invoicing', { cause: error });
            }
            throw new StartEnablingAutomaticInvoicingError(`An unexpected error occurred while requesting start enable automatic invoicing: '${error}'`);
        }
    }
    /**
     * Returns an instance of AutomaticInvoicingStatus
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `AutomaticInvoicingStatus`
     */
    async beginAutomaticInvoicingCorrection() {
        try {
            const { data } = await this.client.post('v2/invoicing/automation/beginCorrection');
            return new AutomaticInvoicingStatus(data);
        }
        catch (error) {
            if (error instanceof APIError || error instanceof Error) {
                throw new BeginAutomaticInvoicingCorrectionError(
                // eslint-disable-next-line max-len
                'An unexpected error occurred while requesting to begin the automatic invoicing correction', { cause: error });
            }
            throw new BeginAutomaticInvoicingCorrectionError(
            // eslint-disable-next-line max-len
            `An unexpected error occurred while requesting to begin the automatic invoicing correction: '${error}'`);
        }
    }
    /**
     * Returns an instance of FiscalData
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `FiscalData`
     */
    async registerTaxId(value) {
        try {
            const { data } = await this.client.post('v2/invoicing/fiscalData/taxId', { value });
            return new FiscalData(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                if (error.code === 'INVALID_TAX_ID_FORMAT') {
                    throw new InvalidTaxId({ cause: error });
                }
                else {
                    throw new SetTaxIdError('An error occurred while setting investor\'s taxid', { cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new SetTaxIdError('An Unexpected error occurred while setting investor\'s taxid', { cause: error });
            }
            else {
                throw new SetTaxIdError(`An Unexpected error occurred while setting investor's taxid: '${error}'`);
            }
        }
    }
    /**
     * Returns an instance of FiscalData
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `FiscalData`
     */
    async setTaxId(taxId) {
        try {
            const { data } = await this.client.post('v2/invoicing/automation/taxId', { taxId });
            return new FiscalData(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_TAX_ID_FORMAT':
                        throw new InvalidTaxId({ cause: error });
                    default:
                        throw new SetTaxIdError('An error occurred while setting investor\'s taxid', { cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new SetTaxIdError('An Unexpected error occurred while setting investor\'s taxid', { cause: error });
            }
            else {
                throw new SetTaxIdError(`An Unexpected error occurred while setting investor's taxid: '${error}'`);
            }
        }
    }
    /**
     * Returns an instance of FiscalData
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `FiscalData`
     */
    async registerZipCode(value) {
        try {
            const { data } = await this.client.post('v2/invoicing/fiscalData/fiscalZipCode', { value });
            return new FiscalData(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_FISCAL_ZIP_CODE':
                        throw new ZipCodeHasInvalidFormat({ cause: error });
                    default:
                        throw new RegisterZipCodeError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new RegisterZipCodeError('An error occurred while registering zip code', { cause: error });
            }
            else {
                throw new RegisterZipCodeError(`An error occurred while registering zip code: '${error}'`);
            }
        }
    }
    /**
     * Returns an instance of FiscalData
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `FiscalData`
     */
    async setFiscalZipCode(fiscalZipCode) {
        try {
            const { data } = await this.client.post('v2/invoicing/automation/fiscalZipCode', { fiscalZipCode });
            return new FiscalData(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_FISCAL_ZIP_CODE':
                        throw new InvalidFiscalZipCode({ cause: error });
                    default:
                        throw new SetFiscalZipCodeError('An error occurred while setting investor\'s fiscal zip code', { cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new SetFiscalZipCodeError('An Unexpected error occurred while setting investor\'s fiscal zip code', { cause: error });
            }
            else {
                throw new SetFiscalZipCodeError(`An Unexpected error occurred while setting investor's fiscal zip code: '${error}'`);
            }
        }
    }
    /**
     * Returns an instance of FiscalData
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `FiscalData`
     */
    async setFiscalRegime(fiscalRegime, proofFile) {
        try {
            const formData = new FormData();
            formData.append('fiscalRegime', fiscalRegime);
            if (proofFile)
                formData.append('proofFile', proofFile);
            const { data } = await this.client.post('v2/invoicing/automation/fiscalRegime', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            return new FiscalData(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_FISCAL_REGIME':
                        throw new InvalidFiscalRegime({ cause: error });
                    case 'ALREADY_HAS_FISCAL_REGIME_IN_PROCESS':
                        throw new InvestorHasFiscalRegimeInProcess({ cause: error });
                    case 'MAXIMUM_FILE_SIZE_EXCEEDED':
                        throw new RegimeFileIsTooBig({ cause: error });
                    case 'INVALID_FILE_TYPE':
                        throw new InvalidFileType({ cause: error });
                    case 'EMPTY_FILE':
                        throw new RegimeFileIsEmpty({ cause: error });
                    case 'MISSING_FILE':
                        throw new RegimeFileIsNotSended(fiscalRegime, { cause: error });
                    default:
                        throw new SetFiscalRegimeError('An error occurred while setting investor\'s fiscal regime', { cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new SetFiscalRegimeError('An Unexpected error occurred while setting investor\'s fiscal regime', { cause: error });
            }
            else {
                throw new SetFiscalRegimeError(`An Unexpected error occurred while setting investor's fiscal regime: '${error}'`);
            }
        }
    }
    /**
     * Returns an instance of FiscalRegime
     *
     * @remarks
     * This method is to update fiscal regime out of automatic invoice process
     *
     * @returns Instance of `FiscalRegime`
     */
    async registerFiscalRegime(name) {
        try {
            const { data } = await this.client.post('v2/invoicing/fiscalData/fiscalRegime', { name });
            return new FiscalRegime(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'ALREADY_HAS_FISCAL_REGIME_IN_PROCESS':
                        throw new FiscalRegimeAlreadyInProcess({ cause: error });
                    case 'INVALID_FISCAL_REGIME':
                        throw new InvalidFiscalRegime({ cause: error });
                    default:
                        throw new RegisterFiscalRegimeError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new RegisterFiscalRegimeError('An error occurred while registering fiscal regime', { cause: error });
            }
            else {
                throw new RegisterFiscalRegimeError(`An error occurred while registering fiscal regime: '${error}'`);
            }
        }
    }
    /**
     * Returns an instance of FiscalRegime
     *
     * @remarks
     * This method is to update fiscal regime out of automatic invoice process
     *
     * @returns Instance of `FiscalRegime`
     */
    async registerResicoFiscalRegime(file) {
        await this.registerFiscalRegime('resico');
        try {
            const formData = new FormData();
            formData.append('file', file);
            const { data } = await this.client.post('v2/invoicing/fiscalData/fiscalRegime/proof', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
            return new FiscalRegime(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'MAXIMUM_FILE_SIZE_EXCEEDED':
                        throw new RegimeProofIsOversized({ cause: error });
                    case 'INVALID_FILE_TYPE':
                        throw new RegimeProofInvalidFileType({ cause: error });
                    case 'EMPTY_FILE':
                        throw new RegimeProofEmpty({ cause: error });
                    case 'INVALID_ACCOUNT_LEVEL':
                        throw new InvestorCouldNotUploadRegimeProof({ cause: error });
                    case 'INVALID_FISCAL_REGIME_STATUS':
                        throw new FiscalRegimeIsInProcess({ cause: error });
                    default:
                        throw new UploadRegimeProofError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new UploadRegimeProofError('An error occurred while uploading fiscal regime', { cause: error });
            }
            else {
                throw new UploadRegimeProofError(`An error occurred while uploading fiscal regime: '${error}'`);
            }
        }
    }
    /*
     * Returns an instance of AutomaticInvoicingStatus
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `AutomaticInvoicingStatus`
     */
    async disableAutomaticInvoicing() {
        try {
            const { data } = await this.client.post('v2/invoicing/automation/disable');
            return new AutomaticInvoicingStatus(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new DisableAutomaticInvoicingError('An error occurred while disabling automatic', { cause: error });
            }
            else {
                throw new DisableAutomaticInvoicingError(`An error occurred while disabling automatic: '${error}'`);
            }
        }
    }
    /**
     * Returns an instance of invoice data validation, provide information about if the investor has
     * his documents in order to enable automatic invoice
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `AutomaticInvoiceValidationResult`
     */
    async validateFiscalData() {
        try {
            const { data } = await this.client.get('v2/invoicing/fiscalData/validation');
            return new AutomaticInvoiceValidationResult(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                if (error.code === 'INCOMPLETE_FISCAL_DATA') {
                    throw new FiscalDataAreIncomplete({ cause: error });
                }
                else {
                    throw new ValidateFiscalDataError('An unexpected error occurred while verifying fiscal data', { cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new ValidateFiscalDataError('An unexpected error occurred while verifying fiscal data', { cause: error });
            }
            else {
                throw new ValidateFiscalDataError(`An unexpected error occurred while verifying fiscal data: '${error}'`);
            }
        }
    }
    /**
     * Returns an instance of Enrollment investor but with automatic invoicing added
     *
     * @remarks
     * This method is part of HundredBricksAPI.
     *
     * @returns Instance of `AutomaticInvoicingInvestor`
     */
    async enableAutomaticInvoicing() {
        try {
            const { data } = await this.client.post('v2/invoicing/automation/enable');
            return new AutomaticInvoicingStatus(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'TAX_ID_NOT_FOUND':
                        throw new InvestorDoesNotHaveRFC({ cause: error });
                    case 'FISCAL_REGIME_NOT_FOUND':
                        throw new InvestorDoesNotHaveFiscalRegime({ cause: error });
                    case 'FISCAL_ZIP_CODE_NOT_FOUND':
                        throw new InvestorDoesNotHaveFiscalZipCode({ cause: error });
                    case 'INVALID_FISCAL_REGIME':
                        throw new InvestorHasAnInvalidFiscalRegime({ cause: error });
                    case 'FISCAL_REGIME_DOES_NOT_MATCH_WITH_TAX_SYSTEM':
                        throw new InvestorFiscalRegimeDoesNotMatch({ cause: error });
                    case 'FISCAL_ZIP_CODE_DOES_NOT_MATCH_WITH_TAX_SYSTEM':
                        throw new InvestorFiscalZipCodeDoesNotMatch({ cause: error });
                    case 'NAME_DOES_NOT_MATCH_WITH_TAX_SYSTEM':
                        throw new InvestorNameDoesNotMatch({ cause: error });
                    case 'TAX_ID_DOES_NOT_MATCH_WITH_TAX_SYSTEM':
                        throw new InvestorTaxIdDoesNotMatch({ cause: error });
                    default:
                        throw new EnableAutomaticInvoicingError('Unexpected automatic invoicing error', { cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new EnableAutomaticInvoicingError('Unexpected automatic invoicing error', { cause: error });
            }
            else {
                throw new EnableAutomaticInvoicingError(`Unexpected automatic invoicing error: '${error}'`);
            }
        }
    }
}
export default InvoicingAPI;
