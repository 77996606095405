import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import SessionTimeManager from '@lib/SessionTimeManager';
import LastSessionInformer from './LastSessionInformer';
import Antiphishing from './AntiphishingModal';
import PendingPaymentPlanModal from './PendingPaymentPlanModal';
import PendingDocumentsToast from './PendingDocumentsToast';
import RejectedDocsModal from './RejectedDocsModal';
const Notifications = () => (_jsxs(Fragment, { children: [_jsx(SessionTimeManager, {}), _jsx(PendingDocumentsToast, {}), _jsx(Antiphishing, {}), _jsx(RejectedDocsModal, {}), _jsx(LastSessionInformer, {}), _jsx(PendingPaymentPlanModal, {})] }));
export default Notifications;
