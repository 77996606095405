import isEmpty from 'lodash.isempty';
class IncomePeriodsHistory {
    incomeDispersions;
    totals;
    pagination;
    count;
    constructor(data) {
        this.incomeDispersions = data.incomeDispersions;
        this.totals = data.totals;
        this.pagination = data.pagination;
        this.count = data.count;
    }
    hasDispersions() {
        return !isEmpty(this.incomeDispersions);
    }
}
export default IncomePeriodsHistory;
