class EnrollmentInvestor {
    id;
    consecutiveNumber;
    accountLevel;
    isEmailVerified;
    fullName;
    name;
    firstLastName;
    secondLastName;
    email;
    phone;
    taxId;
    jobTitle;
    gender;
    birthdate;
    birthCountry;
    birthState;
    nationality;
    isRealOwner;
    realOwnerName;
    realOwnerBirthdate;
    hasOwnResources;
    identification;
    address;
    addressProof;
    transactionalProfile;
    annualUpdateLog;
    status;
    advertising;
    curp;
    constructor(investorData) {
        this.id = investorData.id;
        this.consecutiveNumber = investorData.consecutiveNumber;
        this.accountLevel = investorData.accountLevel;
        this.isEmailVerified = investorData.isEmailVerified;
        this.fullName = investorData.fullName;
        this.name = investorData.name;
        this.firstLastName = investorData.firstLastName;
        this.secondLastName = investorData.secondLastName;
        this.email = investorData.email;
        this.phone = investorData.phone;
        this.curp = investorData.curp;
        this.taxId = investorData.taxId;
        this.jobTitle = investorData.jobTitle;
        this.gender = investorData.gender;
        this.birthdate = investorData.birthdate;
        this.birthCountry = investorData.birthCountry;
        this.birthState = investorData.birthState;
        this.nationality = investorData.nationality;
        this.isRealOwner = investorData.isRealOwner;
        this.realOwnerName = investorData.realOwnerName;
        this.realOwnerBirthdate = investorData.realOwnerBirthdate;
        this.hasOwnResources = investorData.hasOwnResources;
        this.identification = investorData.identification;
        this.address = investorData.address;
        this.addressProof = investorData.addressProof;
        this.transactionalProfile = investorData.transactionalProfile;
        this.annualUpdateLog = investorData.annualUpdateLog;
        this.status = investorData.status;
        this.advertising = investorData.advertising;
    }
}
export default EnrollmentInvestor;
