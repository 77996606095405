import { AccountProfitError, NetworkError } from '../errors';
import { AccountProfit } from '../entities/Account';

import GatewayAPI from './GatewayAPI';

export default class AccountProfitAPI extends GatewayAPI {
  async getAccountProfitSummary() {
    try {
      const { data } = await this._client.get('/accountProfits/summary');

      return new AccountProfit(data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getHistoricalAccountProfits() {
    try {
      const { data } = await this._client.get('/accountProfits/historical');

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getActiveAccountProfits() {
    try {
      const { data } = await this._client.get('/accountProfits/active');

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getSoldAccountProfits() {
    try {
      const { data } = await this._client.get('/accountProfits/sold');

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  _handleError = error => {
    if (!error.response) {
      throw new NetworkError();
    }

    const { response } = error;
    const { code } = response.data;

    throw new AccountProfitError(code);
  };
}
