import styled from 'styled-components';

import { typography } from './mixins';

// This component does not currently conform to the measurement and typography specifications that
// have been established in the Bricks UI project. Therefore, when updating the design standards
// within Bricks UI, it must be reflected and implemented in this component to maintain visual and
// functional coherence. It is important to review and adapt this component post-upgrade to ensure
// it complies with the new guidelines.

const Level1 = styled.h2`
  ${typography};

  font-family: 'RobotoMedium';
  font-weight: 500;
  line-height: 1.20em;
  font-size: 1.25rem;
  margin: 0.6em 0;
  padding: 0;
`;

const Level2 = styled.h3`
  ${typography};

  font-family: 'RobotoMedium';
  font-weight: 500;
  line-height: 1.20em;
  font-size: 1.15rem;
  margin: 0.6em 0;
  padding: 0;
`;

const Level3 = styled.h4`
  ${typography};

  font-family: 'RobotoMedium';
  font-weight: 500;
  line-height: 1.20em;
  font-size: 1rem;
  margin: 0.6em 0;
  padding: 0;
`;

export { Level1, Level2, Level3 };
