import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MovementsRouter from '@views/Movements/MovementsRouter';
import LimitlessLevelOutlet from './LimitlessLevelOutlet';
const AccountRouter = lazy(() => import('../../../views/MyAccount/AccountRouter'));
const InvestmentCancellation = lazy(() => import('../../../views/InvestmentCancellation'));
const InvoicingRouter = lazy(() => import('../../../views/Invoicing/InvoicingRouter'));
const MarketplaceRouter = lazy(() => import('../../../views/Marketplace/MarketplaceRouter'));
const MyBricksRouter = lazy(() => import('../../../views/MyBricks/MyBricksRouter'));
const OnboardingRouter = lazy(() => import('../../../views/Onboarding/OnboardingRouter'));
const ProfileRouter = lazy(() => import('../../../views/Profile/ProfileRouter'));
const RentsRouter = lazy(() => import('../../../views/Rents/RentsRouter'));
const SellRouter = lazy(() => import('../../../views/Sell/SellRouter'));
const SignW8BENContract = lazy(() => import('../../../views/SignDocuments/W8BENContract'));
const TransactionalProfile = lazy(() => import('../../../views/TransactionalProfile'));
const PaymentPlanRouter = lazy(() => import('../../../views/Profile/PaymentPlan/PaymentPlanRouter'));
const MyPaymentPlansRouter = lazy(() => import('../../../views/MyPaymentPlans/MyPaymentPlansRouter'));
const UpdateAddress = lazy(() => import('../../../views/Profile/UpdateAddress'));
const UpdateCommercialContract = lazy(() => import('../../../views/Profile/UpdateCommercialContract'));
const UpdateEmailRouter = lazy(() => import('../../../views/Profile/UpdateEmail/UpdateEmailRouter'));
const UpdatePhoneRouter = lazy(() => import('../../../views/Profile/UpdatePhone/UpdatePhoneRouter'));
const UpdateIdentificationRouter = lazy(() => import('../../../views/Profile/UpdateIdentification/UpdateIdentificationRouter'));
const ValuationMethodologiesRouter = lazy(() => import('../../../views/ValuationMethodologies/ValuationMethodologiesRouter'));
const VerifyNewEmailRouter = lazy(() => import('../../../views/Profile/UpdateEmail/VerifyNewEmailRouter'));
const LimitlessLevelRoutes = () => (_jsx(Routes, { children: _jsxs(Route, { path: "/", element: _jsx(LimitlessLevelOutlet, {}), children: [_jsx(Route, { path: "/valuation-methodologies/*", element: _jsx(ValuationMethodologiesRouter, {}) }), _jsx(Route, { path: "/sign-w8-ben-contract", element: _jsx(SignW8BENContract, {}) }), _jsx(Route, { path: "/update-transactional-profile", element: _jsx(TransactionalProfile, {}) }), _jsx(Route, { path: "/payment-plan/*", element: _jsx(PaymentPlanRouter, {}) }), _jsx(Route, { path: "/automatic-invoicing/*", element: _jsx(InvoicingRouter, {}) }), _jsx(Route, { path: "/onboarding/*", element: _jsx(OnboardingRouter, {}) }), _jsx(Route, { path: "/marketplace/*", element: _jsx(MarketplaceRouter, {}) }), _jsx(Route, { path: "/my-account/*", element: _jsx(AccountRouter, {}) }), _jsx(Route, { path: "/movements/*", element: _jsx(MovementsRouter, {}) }), _jsx(Route, { path: "/perfil/*", element: _jsx(ProfileRouter, {}) }), _jsx(Route, { path: "/profile/rents/*", element: _jsx(RentsRouter, {}) }), _jsx(Route, { path: "/sell/*", element: _jsx(SellRouter, {}) }), _jsx(Route, { path: "/sign-new-commercial-contract-version", element: _jsx(UpdateCommercialContract, {}) }), _jsx(Route, { path: "/update-address", element: _jsx(UpdateAddress, {}) }), _jsx(Route, { path: "/update-phone/*", element: _jsx(UpdatePhoneRouter, {}) }), _jsx(Route, { path: "/update-email/*", element: _jsx(UpdateEmailRouter, {}) }), _jsx(Route, { path: "/update-email-process/*", element: _jsx(VerifyNewEmailRouter, {}) }), _jsx(Route, { path: "/update-identification/*", element: _jsx(UpdateIdentificationRouter, {}) }), _jsx(Route, { path: "/investment-cancellation", element: _jsx(InvestmentCancellation, {}) }), _jsx(Route, { path: "/my-payment-plans/*", element: _jsx(MyPaymentPlansRouter, {}) }), _jsx(Route, { path: "/my-bricks/*", element: _jsx(MyBricksRouter, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { replace: true, to: "/" }) })] }) }));
export default LimitlessLevelRoutes;
