/* eslint-disable no-console */
class ConsoleInsightsService {
  constructor(driver) {
    this._driver = driver;
  }

  sendEvent(event) {
    this._driver(event.name, event.properties);
  }

  anonymizeInvestorIdentity() {
    this._driver('reset Identity');
  }

  identifyInvestor(id) {
    this._driver(id);
  }

  setEventProperties(properties) {
    this._driver({ eventProperties: properties });
  }

  setProfileProperties(profileProperties) {
    this._driver(profileProperties);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export, import/no-unused-modules
export default new ConsoleInsightsService(console.log);
