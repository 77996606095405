import styled, { css } from 'styled-components';

const ActionMediaContainerWrapper = styled.div`
  ${({ children }) => {
    if (!children) return;

    return css`
      align-self: initial;
      background: ${({ theme }) => theme.palette.background.hbBackgroundA};
      border-radius:  ${({ theme }) => theme.toRem(4)};
      font-size: ${({ theme }) => theme.typography.paragraph};
      justify-self: center;
      padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(4)}`};
      width: 90%;
    `;
  }}
`;

export default ActionMediaContainerWrapper;
