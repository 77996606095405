import { Developer, Photo, PropertyAddress } from '../entities/Marketplace';
import { CrowdfundingProperty } from '../entities/Marketplace/Property';
import {
  CrowdfundingPropertyError,
  NetworkError,
  VolumeDiscountsError
} from '../errors';
import {
  CrowdfundingPeriod,
  PresalePrice,
  VolumeDiscount
} from '../entities/Marketplace/Property/CrowdfundingProperty';

import GatewayAPI from './GatewayAPI';

class CrowdfundingPropertyAPI extends GatewayAPI {
  async getCrowdfundingProperty(propertyId) {
    try {
      const { data } = await this._client.get(`/crowdfundingProperties/${propertyId}`);

      const adaptedData = this._adaptCrowdfundingPropertyData(data);

      const address = new PropertyAddress(adaptedData.address);
      const crowdfundingPeriods = new CrowdfundingPeriod(adaptedData?.crowdfundingPeriods);
      const developer = new Developer(adaptedData?.developer);
      const presalePrice = new PresalePrice(adaptedData?.presalePrice);
      const photos = adaptedData?.photos.map(photo => new Photo(photo));

      const crowdfundingPropertyData = {
        ...adaptedData,
        address,
        crowdfundingPeriods,
        developer,
        presalePrice,
        photos
      };

      return new CrowdfundingProperty(crowdfundingPropertyData);
    } catch (error) {
      return this._handleError(error, CrowdfundingPropertyError);
    }
  }

  async getVolumeDiscounts(propertyId) {
    try {
      const { data } = await this._client.get(
        `/crowdfundingProperties/${propertyId}/volumeDiscounts`
      );

      return data.items.map(discount => new VolumeDiscount(discount));
    } catch (error) {
      return this._handleError(error, VolumeDiscountsError);
    }
  }

  _adaptCrowdfundingPropertyData(data) {
    const { address, country } = data;

    const adaptedData = { ...data, address: { ...address, country } };

    return adaptedData;
  }

  _handleError(error, APIError) {
    if (!error.response) {
      throw new NetworkError();
    }

    const { code } = error.response.data;

    throw new APIError(code);
  }
}

export default CrowdfundingPropertyAPI;
