class Developer {
    id;
    photoUri;
    name;
    description;
    websiteLink;
    constructor(data) {
        this.id = data.id;
        this.photoUri = data.photoUri;
        this.name = data.name;
        this.description = data.description;
        this.websiteLink = data.websiteLink;
    }
}
export default Developer;
