import { APIClient } from './api';

function getBaseURLFromTarget(env) {
  switch (env) {
    case 'production':
      return 'https://api.100ladrillos.com/marketplace/api/v1';
    case 'staging':
      return 'https://api-staging.100ladrillos.com/marketplace/api/v1';
    case 'test':
      return 'http://api.test.100ladrillos.com/marketplace/api/v1';
    case 'development':
      return 'https://api-dev.100ladrillos.com/marketplace/api/v1';
    default:
      return 'http://localhost:9000/marketplace/api/v1';
  }
}

export default function createLegacyAPIClient(token = null) {
  return new APIClient(getBaseURLFromTarget(process.env.TARGET_ENV), token);
}
