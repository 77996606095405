import styled from 'styled-components';

const ScrollViewWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 9rem);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({ theme }) => `${theme.spacing(6)}`};
  margin-bottom: ${({ theme }) => `${theme.spacing(6)}`};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding-bottom: 0;
    margin-bottom: 0;
    height: calc(100vh - 9rem);
  }
`;

export { ScrollViewWrapper };
