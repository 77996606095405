import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import icLocation from '@assets/icons/ic-location-grey.svg';
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import ActionText from '../ActionText';
import Button from '../Button';
import Modal from '../Modal';
import { InvestorLocationModalWrapper, DescriptionContainer, HelpContainer, ButtonsContainer } from './InvestorLocationModal.style';
const HELP_URL = 'https://ayuda.100ladrillos.com/hc/es/articles/30942603032845--Por-qu%C3%A9-'
    + 'solicitamos-que-compartas-tu-ubicaci%C3%B3n-al-iniciar-sesi%C3%B3n-en-100-Ladrillos';
const InvestorLocationModal = (props) => {
    const { isOpen, onConfirm, onCancel, isLoading = false } = props;
    return (_jsx(Modal, { isOpen: isOpen, size: "xl", preventClose: true, children: _jsxs(InvestorLocationModalWrapper, { children: [_jsxs(DescriptionContainer, { children: [_jsx("img", { src: icLocation, alt: "Icono de ubicaci\u00F3n" }), _jsx(Subtitle, { children: "Comp\u00E1rtenos tu ubicaci\u00F3n" }), _jsxs("article", { children: [_jsx(Paragraph, { children: "Para proteger tu cuenta y cumplir con las regulaciones, necesitamos acceder a tu ubicaci\u00F3n actual. Esto nos ayuda a brindarte una experiencia segura y personalizada." }), _jsx(Paragraph, { children: _jsx(Bold, { children: "Cuando tu navegador lo solicite, por favor selecciona \u201CPermitir\u201D para compartir tu ubicaci\u00F3n con nosotros." }) })] }), _jsxs(ButtonsContainer, { children: [onCancel && (_jsx(Button, { id: "login-investorLocationModal-cancelButton", color: "secondary", rounded: true, fullWidth: true, onClick: onCancel, children: "Cancelar" })), _jsx(Button, { id: "login-investorLocationModal-confirmButton", rounded: true, fullWidth: true, isLoading: isLoading, onClick: onConfirm, children: "Continuar" })] })] }), _jsxs(HelpContainer, { children: [_jsx(Bold, { children: "\u00BFTienes dudas?" }), _jsx(ActionText, { color: "info", onClick: () => window.open(HELP_URL, '_blank'), children: "Descubre por qu\u00E9 solicitamos tu ubicaci\u00F3n al iniciar sesi\u00F3n." })] })] }) }));
};
export default InvestorLocationModal;
