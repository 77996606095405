import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useCountdown } from '@hooks';
import {
  Button,
  Icon,
  Modal,
  Text
} from '@components';

import { InfoWrapper, ModalTitle } from './SessionExpiringSoonModal.style';

const INITIAL_COUNTER_TIME = 60;
const SESSION_TIME_EXPIRED = 0;

const SessionExpiringSoonModal = ({ onContinueBrowsing, onSessionExpired }) => {
  const countdown = useCountdown(INITIAL_COUNTER_TIME);

  useEffect(() => {
    if (countdown <= SESSION_TIME_EXPIRED) {
      onSessionExpired();
    }
  }, [countdown, onSessionExpired]);

  return (
    <Modal isOpen preventClose>
      <ModalTitle>¡Tu sesión está a punto de expirar!</ModalTitle>

      <InfoWrapper>
        <Text>
          Por inactividad, tu sesión expirará en
          <strong>{` ${countdown} segundos`}</strong>
        </Text>
      </InfoWrapper>

      <Button id="refresh-session-button" onClick={onContinueBrowsing}>
        Seguir explorando
      </Button>
    </Modal>
  );
};

SessionExpiringSoonModal.propTypes = {
  onContinueBrowsing: PropTypes.func.isRequired,
  onSessionExpired: PropTypes.func.isRequired
};

export default SessionExpiringSoonModal;
