import { useContext } from 'react';
import PaymentPlansContextIsNotInitialized from './PaymentPlansContextIsNotInitialized';
import PaymentPlansContext from './PaymentPlansContext';
const usePaymentPlans = () => {
    const context = useContext(PaymentPlansContext);
    if (!context) {
        throw new PaymentPlansContextIsNotInitialized();
    }
    return context;
};
export default usePaymentPlans;
