import APIError from '../APIError';

const INVESTOR_DOES_NOT_HAVE_A_LIMITLESS_ACCOUNT = 'INVESTOR_DOES_NOT_HAVE_A_LIMITLESS_ACCOUNT';
const W8_CONTRACT_NOT_SIGNED = 'W8_CONTRACT_NOT_SIGNED';

class InternationalPurchaseError extends APIError {
  constructor(code, details = null) {
    super(code, 'InternationalPurchaseError', details);
  }

  hasInvestorRestrictedAccount() {
    return this._code === INVESTOR_DOES_NOT_HAVE_A_LIMITLESS_ACCOUNT;
  }

  isW8ContractNotSigned() {
    return this._code === W8_CONTRACT_NOT_SIGNED;
  }
}

export default InternationalPurchaseError;
