import styled from 'styled-components';

import { flexAsColumn, flexAsRow, gradientBlueAndPurple } from '@components/Styled/mixins';

const FreeComisionsContent = styled.div`
  ${flexAsColumn()};

  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  background: ${gradientBlueAndPurple};
  border-radius: ${({ theme }) => theme.spacing(1)};

  svg {
    width: ${({ theme }) => theme.toRem(64)};
    height: fit-content;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    ${flexAsRow(2)};

    svg {
      width: ${({ theme }) => theme.toRem(144)};
      height: fit-content;
    }
  }
`;

export { FreeComisionsContent };
