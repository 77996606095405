import APIError from '../APIError';

const INVALID_DATA = 'INVALID_DATA';
const ALREADY_REGISTERED = 'ALREADY_REGISTERED';
const INVALID_GEOLOCALIZATION = 'INVALID_GEOLOCALIZATION';
const INVALID_PASSWORD_FORMAT = 'INVALID_PASSWORD_FORMAT';
const INVALID_USER_CONNECTION = 'INVALID_USER_CONNECTION';

class SignupError extends APIError {
  constructor(code, details = null) {
    super(code, 'SignupError', details);
  }

  hasActiveVPN() {
    return this._code === INVALID_USER_CONNECTION;
  }

  isInvalidData() {
    return this._code === INVALID_DATA;
  }

  isAlreadyRegistered() {
    return this._code === ALREADY_REGISTERED;
  }

  isInvalidGeolocalization() {
    return this._code === INVALID_GEOLOCALIZATION;
  }

  isInvalidPasswordFormat() {
    return this._code === INVALID_PASSWORD_FORMAT;
  }
}

export default SignupError;
