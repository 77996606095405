import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ContentDistribution } from './Layout.style';
import Layout from './Layout';
import PublicNavbar from './components/Navbar/PublicNavbar';
import PublicSidenav from './components/Sidebar/PublicSideNav';
const PublicLayout = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(prevState => !prevState);
    };
    return (_jsxs(Layout, { children: [_jsx(PublicNavbar, { onOpenMenu: toggleSidebar }), _jsxs(ContentDistribution, { children: [_jsx(PublicSidenav, { isOpen: isSidebarOpen, onClose: toggleSidebar }), children] })] }));
};
export default PublicLayout;
