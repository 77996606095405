import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useProfile } from '@lib/profile';
const SigningDocumentsOutlet = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { profile } = useProfile();
    useEffect(() => {
        if (pathname !== '/') {
            return;
        }
        if (!profile?.status.isReadyToSignRiskForm()) {
            navigate('/sign-documents');
        }
        else {
            navigate('/onboarding');
        }
    }, [pathname]);
    return (_jsx(Outlet, {}));
};
export default SigningDocumentsOutlet;
