import autoBind from 'auto-bind';
class HundredBricksAPI {
    client;
    constructor(client) {
        this.client = client;
        autoBind(this);
    }
    getClient() {
        return this.client;
    }
}
export default HundredBricksAPI;
