import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../Link';

import './style.scss';

const FooterLink = ({ redirectLink, description, styleLink }) => (
  <Link
    href={redirectLink}
    styleName={styleLink || ''}
    target="_blank"
    rel="noreferrer noopener"
  >
    {description}
  </Link>
);

FooterLink.propTypes = {
  redirectLink: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  styleLink: PropTypes.string
};

FooterLink.defaultProps = {
  styleLink: ''
};

export default FooterLink;
