import HundredBricksAPI from '../../HundredBricksAPI';
import PropertyValuation from './PropertyValuation';
import PropertyValuationError from './PropertyValuationError';
class PreferencesAPI extends HundredBricksAPI {
    async getPropertyValuation() {
        try {
            const { data } = await this.client.get('/v2/preferences/propertyValuationType');
            return new PropertyValuation(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new PropertyValuationError('Unexpected property valuation type error', { cause: error });
            }
            else {
                throw new PropertyValuationError(`Unexpected property valuation type error: '${error}'`);
            }
        }
    }
    async setPropertyValuation(payload) {
        try {
            const { data } = await this.client
                .post('/v2/preferences/propertyValuationType', payload);
            return new PropertyValuation(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new PropertyValuationError('Unexpected property valuation type error', { cause: error });
            }
            else {
                throw new PropertyValuationError(`Unexpected property valuation type error: '${error}'`);
            }
        }
    }
}
export default PreferencesAPI;
