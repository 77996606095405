class Operator {
    id;
    name;
    description;
    highlights;
    websiteLink;
    photoURL;
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.websiteLink = data.websiteLink;
        this.photoURL = data.photoURL;
        this.highlights = data.highlights;
    }
}
export default Operator;
