import React from 'react';
import PropTypes from 'prop-types';

import { PageTitle } from '@components';

const View = props => {
  const {
    id,
    title,
    className,
    children,
  } = props;

  return (
    <section id={id} title={title} className={className}>
      <PageTitle>
        <title>{title}</title>
      </PageTitle>
      {children}
    </section>
  );
};

View.defaultProps = {
  id: 'view',
  title: 'View',
  className: '',
};

View.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default View;
