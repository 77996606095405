import React from 'react';
import PropTypes from 'prop-types';

import { Money } from '@types';

import './style.scss';
import { Bold, Paragraph } from '@components/Styled';

const QuotationDetails = props => {
  const {
    pricePerBrick,
    bricksQuantity,
    revolvingFund,
    subtotal,
    totalAmount,
    totalRevolvingFund,
  } = props;
  const formattedPricePerBrick = new Money(pricePerBrick).toString();
  const formattedSubtotal = new Money(subtotal).toString();
  const formattedRevolvingFund = new Money(revolvingFund).toString();
  const formattedTotalRevolvingFund = new Money(totalRevolvingFund).toString();
  const formattedTotalAmount = new Money(totalAmount).toString();

  return (
    <table tid="quotation-details" styleName="quotation-details">
      <tbody>
        <tr id="secondaryMarket-quoterPricePerBrick">
          <td styleName="cell">
            <Paragraph styleName="label">
              <Paragraph>Precio ladrillo</Paragraph>
              <Bold tid="price-per-brick">
                {`(${formattedPricePerBrick} x ${bricksQuantity}):`}
              </Bold>
            </Paragraph>
          </td>
          <td styleName="cell">
            <Bold tid="subtotal">
              {formattedSubtotal}
            </Bold>
          </td>
        </tr>
        <tr id="secondaryMarket-quoterRevolvingFund">
          <td styleName="cell">
            <Paragraph styleName="label">
              <Paragraph>Fondo revolvente</Paragraph>
              <Bold tid="revolving-fund">
                {`(${formattedRevolvingFund} x ${bricksQuantity}):`}
              </Bold>
            </Paragraph>
          </td>
          <td styleName="cell">
            <Bold tid="total-revolving-fund">
              {formattedTotalRevolvingFund}
            </Bold>
          </td>
        </tr>
        <tr id="secondaryMarket-quoterTotal" styleName="total-row">
          <td styleName="cell">
            <Bold>
              Total inversión:
            </Bold>
          </td>
          <td styleName="cell">
            <Bold tid="total-amount">
              {formattedTotalAmount}
            </Bold>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

QuotationDetails.propTypes = {
  pricePerBrick: PropTypes.number.isRequired,
  bricksQuantity: PropTypes.number.isRequired,
  revolvingFund: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  totalRevolvingFund: PropTypes.number.isRequired,
};

export default QuotationDetails;
