import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icons from '../Icons';
import RenderIf from '../RenderIf';
import { Bold, Paragraph } from '../Styled';

import './style.scss';
import { FreeComisionsContent } from './InformativeTable.style';

const InformativeTableColumn = props => {
  const { isMoney, content } = props;
  const columnStyle = cx({
    'money-column': isMoney
  });

  return <td styleName={columnStyle}>{content}</td>;
};

const InformativeTableRow = props => {
  const { columns = [], ...otherProps } = props;

  return (
    <tr {...otherProps}>
      {columns.map(column => <InformativeTableColumn key={column.id} {...column} />)}
    </tr>
  );
};

const InformativeTable = props => {
  const {
    className,
    rows = [],
    totalRows = [],
    restrictedUntilDate,
    bricksWithoutFee
  } = props;

  const { HandshakeIcon } = Icons;

  return (
    <Fragment>
      <RenderIf condition={!restrictedUntilDate && bricksWithoutFee}>
        <FreeComisionsContent>
          <HandshakeIcon
            padding={0}
            alt="Icono de apoyo para inversionistas"
          />

          <Paragraph align="left">
            Estamos comprometidos con alinear nuestros objetivos con los tuyos. Por esta
            razón, si estás considerando ofertar tu(s) Ladrillo(s) a un precio menor al de
            adquisición,
            <Bold> no te cobraremos comisión de venta</Bold>
            .
          </Paragraph>
        </FreeComisionsContent>
      </RenderIf>

      <table styleName="informative-table" className={className}>
        <tbody>
          {rows.map(row => (<InformativeTableRow key={row.id} {...row} />))}
        </tbody>

        {totalRows.length > 0 && (
          <tfoot>
            {totalRows.map(row => (
              <InformativeTableRow key={row.id} {...row} styleName="total-row" />
            ))}
          </tfoot>
        )}
      </table>
    </Fragment>
  );
};

InformativeTableColumn.defaultProps = {
  isMoney: false
};

InformativeTableColumn.propTypes = {
  content: PropTypes.node.isRequired,
  isMoney: PropTypes.bool
};

InformativeTableRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.node.isRequired,
    isMoney: PropTypes.bool
  })).isRequired
};

InformativeTable.defaultProps = {
  className: '',
  restrictedUntilDate: null,
  bricksWithoutFee: null
};

InformativeTable.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.node.isRequired,
      isMoney: PropTypes.bool
    })).isRequired
  })).isRequired,
  totalRows: PropTypes.arrayOf(PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.node.isRequired,
      isMoney: PropTypes.bool
    })).isRequired
  })).isRequired,
  restrictedUntilDate: PropTypes.string,
  bricksWithoutFee: PropTypes.number
};

export default InformativeTable;
