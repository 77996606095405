import styled, { css } from 'styled-components';

import {
  getBackgroundColorSentence,
  getColorSentence,
  getHeightSentence,
  getTextAlignSentence,
  getWidthSentence
} from './sentences';

const FONT_WEIGHT_VARIANTS = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700
};

// This component has been declared obsolete and its use is discouraged. It is recommended to stop
// using it in future developments or in the process of refactoring existing code. Alternatively,
// the Paragraph component can be used, which offers similar or improved functionality.

const Text = styled.p`
  ${getBackgroundColorSentence};
  ${getColorSentence};
  ${getHeightSentence};
  ${getTextAlignSentence};
  ${getWidthSentence};
  margin: 0;
  padding: 0;

  ${({ fontWeight }) => {
    if (fontWeight) {
      return css`
        font-weight: ${FONT_WEIGHT_VARIANTS[fontWeight]};
      `;
    }

    return css`
      font-weight: ${FONT_WEIGHT_VARIANTS.regular};
    `;
  }};
`;

export default Text;
