class ConsoleSegmenter {
    getTag(tag) {
        console.log(tag);
    }
    getTags() {
        console.log('tags gotten');
    }
    setTag(tag, value) {
        console.log(tag, value);
    }
    deleteTag(tag) {
        console.log(tag);
    }
    clearTags() {
        console.log('tags cleared');
    }
}
export default ConsoleSegmenter;
