import styled from 'styled-components';

import Text from '../Text';

const ContentWrapper = styled.div`
  max-width: ${({ theme }) => theme.spacing(54)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  justify-content: center;
  align-items: center;

  &, > * {
    text-align: center;
  }
`;

const TextModal = styled(Text)`
  margin-bottom: 0;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: center;
  width: 100%;

  > button {
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    > button {
      width: auto;
    }
  }
`;

export {
  ActionButtons,
  ContentWrapper,
  TextModal
};
