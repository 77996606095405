import styled from 'styled-components';

const MediaContainerWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: grid;
    grid-area: supportContainer;
    grid-row: inherit;
    grid-template-rows: ${({ gridRowsNumber }) => gridRowsNumber};
    grid-template-columns: 1fr;
    height: 100%;
    width: 40%;
    flex-shrink: 0;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export default MediaContainerWrapper;
