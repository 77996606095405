import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NotificationSkeleton } from './NotificationCenter.style';
/**
 * This implementation works only for the notification center, if we were to replicate
 * skeletons for loading in other modules, we should consider creating a correct abstraction
 * in order to handle it correctly. Right now, as we lack places that use skeletons, we did not
 * want to predict the future and create an unmantainable component. We should at least have 3 use
 * cases for us to have a better grasp of a correct abstraction of the skeletons.
 */
const Skeleton = () => (_jsxs(NotificationSkeleton, { children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] }));
export default Skeleton;
