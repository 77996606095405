import React from 'react';
import PropTypes from 'prop-types';

import { BackButton } from '@components';

import './style.scss';

const TitleSection = props => {
  const {
    titleText,
    subtitle,
    hasBackButton,
    handleBackButtonClick,
  } = props;

  return (
    <div styleName="title-section-container">
      {hasBackButton && (
        <BackButton
          id="return"
          text="Regresar"
          handleClick={handleBackButtonClick}
        />
      )}
      <h1 className="h1" tid="title-text">{titleText}</h1>
      {subtitle && <p className="subtitle" tid="subtitle">{subtitle}</p>}
    </div>
  );
};

TitleSection.defaultProps = {
  subtitle: '',
  hasBackButton: true,
  handleBackButtonClick: () => {},
};

TitleSection.propTypes = {
  hasBackButton: PropTypes.bool,
  titleText: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  handleBackButtonClick: PropTypes.func,
};

export default TitleSection;
