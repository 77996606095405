class Progress {
    id;
    legalDeliveryProgress;
    leasingProgress;
    constructionProgress;
    soldBricksPercent;
    numberOfBricks;
    numberOfSoldBricks;
    crowdfundingProperty;
    constructor(progressData) {
        this.id = progressData.id;
        this.legalDeliveryProgress = progressData.legalDeliveryProgress;
        this.leasingProgress = progressData.leasingProgress;
        this.constructionProgress = progressData.constructionProgress;
        this.soldBricksPercent = progressData.soldBricksPercent;
        this.numberOfBricks = progressData.numberOfBricks;
        this.numberOfSoldBricks = progressData.numberOfSoldBricks;
        this.crowdfundingProperty = progressData.crowdfundingProperty;
    }
}
export default Progress;
