import styled, { css } from 'styled-components';

import { flexAsRow } from '@components/Styled/mixins';

const Badge = styled.div`
  ${flexAsRow()};

  width: fit-content;
  align-items: center;
  justify-content: center;

  ${({ theme, backgroundColor }) => css`
    background-color: ${backgroundColor};
    padding: ${theme.spacing(1)};
    height: ${theme.spacing(3)};
    border-radius: ${theme.borderRadius()};
  `};
`;

export { Badge };
