import { useReducer } from 'react';

const STATUS = {
  idle: 'idle',
  pending: 'pending',
  rejected: 'rejected',
  resolved: 'resolved'
};

const statusReducer = (state, action) => {
  switch (action) {
    case STATUS.pending: {
      return STATUS.pending;
    }
    case STATUS.rejected: {
      return STATUS.rejected;
    }
    case STATUS.resolved: {
      return STATUS.resolved;
    }
    default:
      return state;
  }
};

const useRequestStatus = () => {
  const [status, setStatus] = useReducer(statusReducer, STATUS.idle);

  return [{
    status,
    isIdle: status === STATUS.idle,
    isPending: status === STATUS.pending,
    isResolved: status === STATUS.resolved,
    isRejected: status === STATUS.rejected
  }, setStatus];
};

export default useRequestStatus;
