const notifications = {
    accountLimitExceeded: {
        title: 'Has superado el límite de depósito mensual',
        description: 'Mejora tu cuenta para que puedas seguir disfrutando de los '
            + 'beneficios de la inversión inmobiliaria.'
    },
    accountStatementReady: {
        title: 'Tu estado de cuenta está listo',
        description: 'El resumen de tus inversiones del mes pasado ya se encuentra '
            + 'disponible, dale un vistazo.'
    },
    automaticRentReleaseFailed: {
        title: 'Hubo un error en la facturación automática',
        description: 'No pudimos completar la facturación de tus rentas. Por favor, '
            + 'contáctanos para resolverlo juntos. ¡Estamos para ayudarte!'
    },
    automaticRentReleaseSuccessful: {
        title: 'Facturación automática completada con éxito',
        description: 'Todo en orden, hemos facturado con éxito las rentas de tus '
            + 'propiedades y tu dinero ya está disponible.'
    },
    basicLevelReached: {
        title: 'Ya tienes todo listo para comenzar a invertir',
        description: 'Hemos validado tu información y tu cuenta ya está lista para '
            + 'poner a trabajar tu dinero. Comienza explorando algunas propiedades.'
    },
    changeEmailVerification: {
        title: '¿Estás cambiando tu correo electrónico?',
        description: 'Hemos detectado que estás realizando un cambio en tu correo '
            + 'electrónico. Si este cambio es correcto, continúa con las instrucciones que hemos enviado '
            + 'a tu correo electrónico anterior. Si no has solicitado un cambio, contacta a nuestro '
            + 'equipo de soporte.'
    },
    changePhoneConfirmation: {
        title: 'Has cambiado tu teléfono con éxito',
        description: 'Hemos realizado con éxito el cambio de tu número de teléfono. '
            + 'A partir de ahora, utilizaremos este nuevo número para enviarte los códigos necesarios '
            + 'para autorizar tus transacciones dentro de 100 Ladrillos.'
    },
    curpRejected: {
        title: 'La CURP ha sido rechazada',
        description: 'La CURP que nos proporcionaste no es válida, pero no te '
            + 'preocupes, para continuar puedes proporcionarnos una CURP válida en tu perfil.'
    },
    depositFunds: {
        title: 'Has agregado fondos a tu cuenta',
        description: 'Tu transferencia ya se ha reflejado en tu cuenta, ahora tienes '
            + 'más recursos disponibles para invertir en excelentes oportunidades.'
    },
    identificationRejected: {
        title: 'Tu identificación no pudo ser validada',
        description: 'No hemos podido validar tu identificación. Ayúdanos subiendo '
            + 'nuevamente tu identificación presionando "Ir a verificar mis datos".'
    },
    limitlessLevelReached: {
        title: 'Tu cuenta está lista para invertir sin límites',
        description: 'Se ha completado la validación de tu documentación y hemos '
            + 'mejorado tu cuenta para invertir sin límites.'
    },
    readyToSign: {
        title: 'Tienes documentos por firmar',
        description: 'Debido a los cambios que realizaste en tus datos, necesitamos '
            + 'que vuelvas a firmar tus documentos. Por favor, accede a tu perfil y completa la firma.'
    },
    successfulEmailUpdate: {
        title: 'Has cambiado tu correo electrónico con éxito',
        description: 'Esto significa que ahora iniciarás sesión con tu nuevo correo y '
            + 'recibirás todas nuestras comunicaciones, noticias y actualizaciones importantes en esta '
            + 'nueva dirección.'
    }
};
export default notifications;
