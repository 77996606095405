class BricksSummary {
    portfolio;
    selling;
    total;
    constructor(data) {
        this.portfolio = data.inPortfolioBricks;
        this.selling = data.sellingBricks;
        this.total = data.totalBricks;
    }
}
export default BricksSummary;
