const usePrintAccountStatement = () => {
  const addBackgroundOverlay = windowToPrint => {
    const overlay = windowToPrint.document.createElement('div');
    const body = windowToPrint.document.getElementsByTagName('body')[0];

    overlay.setAttribute('id', 'overlay');
    overlay.style.position = 'absolute';
    overlay.style.top = 0;
    overlay.style.left = 0;
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.borderRadius = '4px';
    overlay.style.background = 'rgba(0,0,0,0.6)';

    body.style.position = 'relative';
    body.append(overlay);
  };

  const addMediaPrintStyles = windowToPrint => {
    const styleTag = windowToPrint.document.getElementsByTagName('style')[0];
    const mediaPrintStyles = windowToPrint.document.createTextNode(
      '@media print { #overlay { display: none; background: transparent; }'
      + ' body, html {margin: 0; padding: 0;} }',
    );

    styleTag.type = 'text/css';
    styleTag.append(mediaPrintStyles);
  };

  const openBrowserWindowToPrint = (url, htmlContent) => {
    const windowToPrint = window.open(url);

    windowToPrint.document.write(htmlContent);
    windowToPrint.document.close(); // This is necessary for IE >= 10
    windowToPrint.document.title = 'Estado de operación';

    addBackgroundOverlay(windowToPrint);
    addMediaPrintStyles(windowToPrint);

    windowToPrint.focus(); // This is necessary for IE >= 10
    windowToPrint.print();
    windowToPrint.close();
  };

  return [openBrowserWindowToPrint];
};

export default usePrintAccountStatement;
