class SoldInvestment {
    sellPrice;
    averageAcquisitionPrice;
    revolvingFundAmount;
    totalFeesPaid;
    soldAt;
    property;
    bricks;
    constructor(data) {
        this.averageAcquisitionPrice = data.averageAcquisitionPrice;
        this.sellPrice = data.sellPrice;
        this.bricks = data.bricks;
        this.property = data.property;
        this.revolvingFundAmount = data.revolvingFundAmount;
        this.totalFeesPaid = data.totalFeesPaid;
        this.soldAt = new Date(data.soldAt);
    }
    getNumberOfBricks() {
        return this.bricks.length;
    }
}
export default SoldInvestment;
