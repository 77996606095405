import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAPI, useRequest } from '@hooks';
import { useProfile } from '@lib/profile';

const useRejectedDocs = () => {
  const navigate = useNavigate();
  const api = useAPI();
  const { profile, enrollment } = useProfile();

  const [
    latestIdentification,
    isLoadingLatestIdentification, ,
    getLatestIdentification
  ] = useRequest(api.identification.getLatestIdentification);

  const isInvestorLimitless = profile.accountLevel.isLimitless();
  const rejectionMotive = latestIdentification?.rejectionMotive;
  const hasIdentificationRequest
    = profile.pendingCorrectionRequests.hasIdentificationRequest();
  const hasRejectedDocs = Boolean(profile?.hasRejectedDocs()
    || (latestIdentification?.isRejected() && !isLoadingLatestIdentification));

  const redirectToUpdateDocs = () => {
    if (enrollment.isCorrectingData()) {
      navigate('/confirm-account-data');
    } else {
      navigate('/perfil/documentos-rechazados');
    }
  };

  useEffect(() => {
    if (isInvestorLimitless && hasIdentificationRequest) {
      getLatestIdentification();
    }
  }, [isInvestorLimitless]);

  return { hasRejectedDocs, redirectToUpdateDocs, rejectionMotive };
};

export default useRejectedDocs;
