import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { renderSomethingWentWrong } from '@utils';
import { useAuth } from '@lib/authentication';
import { useErrorReporter } from '@lib/errorReporter';
import { useForm } from '@hooks';
import { useInsightsService } from '@lib/insightsService';
import { useProfile } from '@lib/profile';
import {
  AdaptiveArea,
  Button,
  Form,
  Icon,
  InvestorLocationModal,
  LegacyLink,
  PasswordField,
  RenderIf,
  Title
} from '@components';
import {
  AuthenticationAttemptsLimitReached,
  DistanceExceededFromLastConnection,
  InvalidCredentials,
  InvestorHasAlreadyAnActiveSession,
  UnusualAccess
} from '@lib/hundredBricksAPI/authentication';

import useGeolocation from '../../../hooks/useGeolocation';
import { CurrentSessionModal, FailedAttemptsLock, LocationBlockedModal } from '../components';

import {
  AlternativeAction,
  AntiphishingData,
  AntiphishingField,
  AntiphishingImage,
  AntiphishingWarning,
  ResponsiveLineBreak,
  Text,
  VPNErrorMessage
} from './Steps.styles';

const FORGOT_PASSWORD_URL = '/forgot-password';

const ERROR_INCORRECT_PASSWORD = 'La contraseña es invalida.';

const stepTwoSchema = {
  password: {
    required: true
  }
};

const StepTwo = props => {
  const {
    email,
    antiphishingData,
    onCancel,
    onSuccess,
    displayUnusualAccess
  } = props;

  const {
    names,
    firstLastName,
    secondLastName,
    imageUrl,
    phrase
  } = antiphishingData;

  const { isLoading: isLoadingProfile } = useProfile();
  const { authenticationEvent, reportInsight } = useInsightsService();
  const { getCurrentPosition, getGeolocationStatus, isGettingLocation } = useGeolocation();
  const errorReporter = useErrorReporter();
  const form = useForm({ initialValues: { password: '' }, schema: stepTwoSchema });
  const [lockedByFailedAttemptsData, setLockedByFailedAttemptsData] = useState();
  const [showAlreadyActiveSessionModal, setShowAlreadyActiveSessionModal] = useState(false);
  const [showVPNMessage, setShowVPNMessage] = useState(false);
  const [displayLocationBlockedModal, setDisplayLocationBlockedModal] = useState(false);
  const [showInvestorLocationModal, setShowInvestorLocationModal] = useState(false);
  const [isApprovingLocation, setIsApprovingLocation] = useState(false);
  const [isProcessingToLogin, setIsProcessingToLogin] = useState(false);

  const {
    login,
    isAuthenticated,
    isLoading: isAuthenticating,
    error: loginError
  } = useAuth();

  const handleLogin = async () => {
    getCurrentPosition(coords => {
      const { latitude, longitude } = coords;

      login(email, form.values.password, latitude, longitude);
    }, () => {
      setIsProcessingToLogin(false);
      setDisplayLocationBlockedModal(true);
    });

    setIsApprovingLocation(false);
  };

  const acceptShareLocation = () => {
    setShowInvestorLocationModal(false);
    handleLogin();
  };

  const cancelShareLocation = () => {
    setShowInvestorLocationModal(false);
    setIsApprovingLocation(false);
    setIsProcessingToLogin(false);
  };

  const onSubmit = async () => {
    setShowVPNMessage(false);
    setIsApprovingLocation(true);
    setIsProcessingToLogin(true);

    const geolocationStatus = await getGeolocationStatus();

    if (geolocationStatus === 'prompt') {
      setShowInvestorLocationModal(true);
    } else if (geolocationStatus === 'denied') {
      setDisplayLocationBlockedModal(true);
      setIsApprovingLocation(false);
      setIsProcessingToLogin(false);
    } else if (geolocationStatus === 'granted') {
      handleLogin();
    }
  };

  const handleLoginError = error => {
    reportInsight(authenticationEvent.logInError, error);

    if (error instanceof InvalidCredentials) {
      form.addError({
        field: 'password',
        message: ERROR_INCORRECT_PASSWORD
      });
    } else if (error instanceof AuthenticationAttemptsLimitReached) {
      setLockedByFailedAttemptsData({
        remainingTimeInMilliseconds: error.getRemainingTime()
      });
    } else if (error instanceof InvestorHasAlreadyAnActiveSession) {
      setShowAlreadyActiveSessionModal(true);
    } else if (error instanceof UnusualAccess) {
      displayUnusualAccess();
    } else if (error instanceof DistanceExceededFromLastConnection) {
      setShowVPNMessage(true);
    } else {
      errorReporter.critical(error);
      renderSomethingWentWrong();
    }
  };

  useEffect(() => {
    if (isAuthenticated && onSuccess) {
      onSuccess();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (loginError) {
      setIsProcessingToLogin(false);
      handleLoginError(loginError);
    }
  }, [loginError]);

  if (lockedByFailedAttemptsData) {
    const timeToUnlock = lockedByFailedAttemptsData.remainingTimeInMilliseconds;

    return (
      <FailedAttemptsLock
        email={email}
        remainingLockTimeInMilliseconds={timeToUnlock}
      />
    );
  }

  function obfuscate(value) {
    if (!value) {
      return '';
    }

    return String(value).charAt(0) + '*'.repeat(value.length - 1);
  }

  return (
    <Fragment>
      <Title>Iniciar sesión</Title>

      <RenderIf condition={!!imageUrl}>
        <AntiphishingImage src={imageUrl} alt="100 ladrillos" />

        <AntiphishingWarning>
          <Icon name="icWarning" alt="warning" />
          <Text>Si no reconoces la imagen, comunícate con nuestro servicio a cliente.</Text>
        </AntiphishingWarning>
      </RenderIf>

      <RenderIf condition={!!phrase}>
        <AntiphishingData>
          <Text>Frase</Text>

          <AntiphishingField>{phrase}</AntiphishingField>
        </AntiphishingData>
      </RenderIf>

      <RenderIf condition={!!names}>
        <AntiphishingData>
          <Text>Nombre</Text>

          <AntiphishingField>
            {`${names} ${obfuscate(firstLastName)} ${obfuscate(secondLastName)}`.toLowerCase()}
          </AntiphishingField>
        </AntiphishingData>
      </RenderIf>

      <RenderIf condition={!names}>
        <AntiphishingData>
          <Text>Correo electrónico</Text>

          <AntiphishingField>{email}</AntiphishingField>
        </AntiphishingData>
      </RenderIf>

      <Form id="login-password" className="login-password" onSubmit={form.handleSubmit(onSubmit)}>
        <PasswordField
          id="password"
          focus
          label="¿Cuál es tu contraseña?"
          value={form.values.password}
          {...form.fieldProps('password')}
        />

        <RenderIf condition={showVPNMessage}>
          <VPNErrorMessage>
            Tenemos un inconveniente al tratar de iniciar tu sesión.
            ¿Tienes activa una VPN o un proxy?
            Desactívala y vuelve a intentarlo.
          </VPNErrorMessage>
        </RenderIf>

        <AlternativeAction>
          {'¿Olvidaste tu contraseña? '}
          <ResponsiveLineBreak />
          <LegacyLink to={FORGOT_PASSWORD_URL}>
            Restablece tu contraseña
          </LegacyLink>
          .
        </AlternativeAction>

        <AdaptiveArea>
          <Button
            id="cancel"
            color="secondary"
            onClick={onCancel}
          >
            Anterior
          </Button>
          <Button
            id="login"
            type="submit"
            disabled={!form.isValid() || isApprovingLocation || isProcessingToLogin}
            isLoading={isAuthenticating
              || isLoadingProfile
              || isApprovingLocation
              || isProcessingToLogin}
          >
            Iniciar sesión
          </Button>
        </AdaptiveArea>
      </Form>

      <CurrentSessionModal
        isOpen={showAlreadyActiveSessionModal}
        onClose={() => setShowAlreadyActiveSessionModal(false)}
      />

      <InvestorLocationModal
        isOpen={showInvestorLocationModal}
        isLoading={isGettingLocation}
        onConfirm={acceptShareLocation}
        onCancel={cancelShareLocation}
      />

      <LocationBlockedModal
        isOpen={displayLocationBlockedModal}
        onConfirm={() => setDisplayLocationBlockedModal(false)}
      />
    </Fragment>
  );
};

StepTwo.propTypes = {
  email: PropTypes.string.isRequired,
  antiphishingData: PropTypes.shape({
    names: PropTypes.string,
    firstLastName: PropTypes.string,
    secondLastName: PropTypes.string,
    imageUrl: PropTypes.string,
    phrase: PropTypes.string
  }),
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  displayUnusualAccess: PropTypes.func.isRequired
};

StepTwo.defaultProps = {
  antiphishingData: {}
};

export default StepTwo;
