class RFC {
    taxId;
    constructor(taxId) {
        this.taxId = taxId;
    }
    get value() {
        return this.taxId;
    }
}
export default RFC;
