import React from 'react';
import { SelectField as BuiSelectField } from 'bricks-ui/packages/bricks-ui';

import './style.scss';

const SelectField = props => (
  <div styleName="select-field">
    <BuiSelectField {...props} />
  </div>
);

export default SelectField;
