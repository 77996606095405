/* eslint-disable react/prop-types */
import React from 'react';

import { regularExpressions as regex } from '@utils';

import TextField from '../TextField';

const NATIONAL_ID_LENGTH = 18;

const NationalIDField = props => {
  const {
    onChange,
    inputProps,
    ...restProps
  } = props;

  const handleChange = event => {
    const inputEvent = event;
    const { value } = event.target;

    inputEvent.target.value = value.replace(regex.onlyLettersAndNumbersUSFormat, '').toUpperCase();

    onChange(inputEvent);
  };

  return (
    <TextField
      {...restProps}
      onChange={handleChange}
      inputProps={{
        minLength: NATIONAL_ID_LENGTH,
        maxLength: NATIONAL_ID_LENGTH,
        ...inputProps
      }}
    />
  );
};

export default NationalIDField;
