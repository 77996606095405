import PurchaseEventsReporter from './PurchaseEventReporter';

class SecondaryMarketEventReporter extends PurchaseEventsReporter {
  propertyDetailTabClick(property, tabTitle) {
    const properties = {
      tabTitle,
      propertyName: property?.name ?? null,
      propertyMarket: 'Secondary Market',
      propertyID: property?.id ?? null
    };

    const event = this._event.propertyDetailTabClick(properties);

    this._insightsService.sendEvent(event);
  }

  startPurchase(property, purchaseQuotation) {
    const properties = this._createCompleteEventProperties(
      property,
      purchaseQuotation?.brickIds?.length,
      purchaseQuotation?.pricePerBrick,
      purchaseQuotation?.totalAmount
    );
    properties.propertyMarket = 'Secondary Market';

    const event = this._event.startedPurchase(properties);

    this._insightsService.sendEvent(event);
  }

  typingOneTimePassword(property, purchaseReservation) {
    const properties = this._createCompleteEventProperties(
      property,
      purchaseReservation?.numberOfBricks,
      purchaseReservation?.averagePricePerBrick,
      purchaseReservation?.totalAmount
    );
    properties.propertyMarket = 'Secondary Market';

    const event = this._event.oneTimePasswordTyped(properties);

    this._insightsService.sendEvent(event);
  }

  completePurchase(property, purchaseReservation) {
    const properties = this._createCompleteEventProperties(
      property,
      purchaseReservation?.numberOfBricks,
      purchaseReservation?.averagePricePerBrick,
      purchaseReservation?.totalAmount
    );
    properties.propertyMarket = 'Secondary Market';

    const event = this._event.completedPurchase(properties);

    this._insightsService.sendEvent(event);
  }
}

export default SecondaryMarketEventReporter;
