import styled, { css } from 'styled-components';

const commonLinkStyle = css`
  ${({ theme }) => theme.typography.font()};

  background-color: ${({ theme }) => theme.palette.grayB};
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 800;
`;

const SiderMenuItemStyled = styled.li`
  ${({ theme }) => theme.typography.font()};

  height: 3em;
  background-color: transparent;
  list-style: none;
  margin: 0;

  .link {
    color: inherit;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius()};
    display: flex;
    height: 100%;
    padding: 0 0 0 ${({ theme }) => theme.spacing(3)};
    text-decoration: none;

    ${({ isActive }) => isActive && css`${commonLinkStyle}`};

    &:hover {
      ${commonLinkStyle}
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    height: 2.3em;
  }
`;

export { SiderMenuItemStyled };
