import api from '../../api';

const calculateQuotation = (propertyId, bricksQuantity) => api.get(
  `/properties/${propertyId}/quotations/calculate?bricksQuantity=${bricksQuantity}`
);

const fetchReservation = () => api.get('/traders/me/bricks/reserved');

const releaseReservedBricks = () => api.post('/properties/bricks/releaseReserved');

const reserveBricks = (propertyId, payload) => api.post(
  `/properties/${propertyId}/bricks/reserve`,
  payload
);

export {
  calculateQuotation,
  fetchReservation,
  releaseReservedBricks,
  reserveBricks
};
