import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import useProfile from '../../lib/profile/useProfile';
import ActionText from '../ActionText';
import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import Title from '../Title';
import RenderIf from '../RenderIf';
import withUpgradeAccountButton from '../../hoc/withUpgradeAccountButton';

import {
  ActionWrapper,
  Banner,
  IconWrapper,
  InfoWrapper,
  LimitText
} from './UpgradeAccountBanner.style';

const UpgradeAccountBanner = props => {
  const navigate = useNavigate();
  const { enrollment } = useProfile();

  const {
    isCorrectingData,
    isRestricted,
    isUpgradingAccount,
    isValidatingDocuments
  } = props;

  const UpgradeButton = withUpgradeAccountButton(Button);

  const handleUpgradeRedirection = () => {
    if (isUpgradingAccount) {
      if (enrollment?.completedActivities.length > 0) {
        navigate('/upgrade-account');
        return;
      }

      navigate('/confirm-account-data');
      return;
    }

    navigate('/upgrade-account/benefits');
  };

  return (
    <Banner>
      <IconWrapper>
        <Icon
          name="icUpgradeAccountModal"
          alt="Imagen conviértete en un usuario sin límites"
          height={120}
          width={120}
        />
      </IconWrapper>
      <InfoWrapper>
        <RenderIf condition={isRestricted || isCorrectingData || isUpgradingAccount}>
          <Text>
            Mejora tu cuenta para que puedas seguir disfrutando de los beneficios de la inversión
            {' '}
            inmobiliaria.
            ¿Por qué tengo un
            {' '}
            <ActionText
              id="upgrade-redirection"
              color="info"
              onClick={handleUpgradeRedirection}
            >
              límite de inversión
            </ActionText>
            ?
          </Text>

          <LimitText>
            Has superado el límite permitido.
          </LimitText>
        </RenderIf>

        <RenderIf condition={isValidatingDocuments}>
          <Text>
            Has superado el límite permitido
          </Text>
        </RenderIf>
      </InfoWrapper>

      <ActionWrapper>
        <RenderIf condition={isRestricted}>
          <Title>¡Invierte sin límites!</Title>

          <Text>Solo necesitas contar con una identificación vigente.</Text>

          <UpgradeButton id="upgrade-account-button" rounded />
        </RenderIf>

        <RenderIf condition={isValidatingDocuments}>
          <Title>Estamos verificando tu información</Title>
          <Text>
            Este proceso puede tomar hasta un día hábil. No te preocupes, nosotros te vamos a
            {' '}
            mandar un correo cuando estés listo.
          </Text>
        </RenderIf>

        <RenderIf condition={isUpgradingAccount}>
          <Title>Termina de mejorar tu cuenta</Title>

          <Text>
            Al mejorar tu cuenta, podrás invertir y poner a la venta tus Ladrillos sin límites.
          </Text>

          <UpgradeButton id="keep-upgrade-account" rounded />
        </RenderIf>

        <RenderIf condition={isCorrectingData}>
          <Title>Inconsistencias en los datos</Title>

          <Text>
            Revisamos tus datos y encontramos inconsistencias con la identificación.
            Revisa nuevamente tus datos, tendrás que volver a subir una
            identificación oficial.
          </Text>

          <UpgradeButton id="redirect-verify-data-button" rounded />
        </RenderIf>
      </ActionWrapper>
    </Banner>
  );
};

UpgradeAccountBanner.propTypes = {
  isCorrectingData: PropTypes.bool.isRequired,
  isRestricted: PropTypes.bool.isRequired,
  isValidatingDocuments: PropTypes.bool.isRequired,
  isUpgradingAccount: PropTypes.bool.isRequired
};

export default UpgradeAccountBanner;
