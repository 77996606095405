import styled from 'styled-components';

import { flexAsRow } from '../Styled/mixins';

const CompleteProfileContainer = styled.div`
  ${flexAsRow(2)};

  width: 90%;
  align-items: center;
  margin: auto;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  text-align: left;

  > img {
    filter: hue-rotate(190deg) brightness(1.5);
  }
`;

export { CompleteProfileContainer };
