import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { capitalize } from '@utils';
import { SelectField, Button } from '@components';
import { Date } from '@types';

import './style.scss';

const AccountStatement = props => {
  const [period, setPeriod] = useState({});

  const { dates = [] } = props;
  const isPeriodSelected = Object.keys(period).length > 0;

  const handleDateChange = date => {
    setPeriod(date);
  };

  const getPeriodOptions = () => dates.map(date => {
    const formattedDate = new Date(date).format('MMMM YYYY');

    return {
      label: capitalize(formattedDate),
      id: date
    };
  });

  const getActiveDateYear = () => {
    const date = new Date(period.id);

    return date.format('YYYY');
  };

  const getActiveDateMonth = () => {
    const date = new Date(period.id);

    return date.format('MM');
  };

  const handleGenerateReport = async () => {
    const year = getActiveDateYear();
    const month = getActiveDateMonth();

    window.open(`/movements/account-statement/${month}/${year}`, '_blank');
  };

  return (
    <div styleName="account-statement">
      <label
        htmlFor="account-statement-period"
        aria-labelledby="account-statement-period"
        styleName="title"
      >
        Estado de operación
      </label>

      <SelectField
        id="account-statement-period"
        tid="account-statement-period"
        options={getPeriodOptions()}
        selectedOption={period}
        handleOptionClick={handleDateChange}
        iconName="icUser"
      />

      <Button
        id="generate-button"
        tid="generate-button"
        color="primary"
        type="button"
        disabled={!isPeriodSelected}
        onClick={handleGenerateReport}
      >
        Ir a ver
      </Button>

    </div>
  );
};

AccountStatement.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default AccountStatement;
