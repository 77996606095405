class Session {
    traderId;
    token;
    geolocation;
    constructor(traderId, token, geolocation) {
        this.traderId = traderId;
        this.token = token;
        this.geolocation = geolocation;
    }
}
export default Session;
