import EnrollmentError from './EnrollmentError';

const CURP_ALREADY_REGISTERED = 'CURP_ALREADY_REGISTERED';
const CURP_ALREADY_REJECTED = 'CURP_ALREADY_REJECTED';
const CURP_NOT_FOUND = 'CURP_NOT_FOUND';
const INVALID_CURP = 'INVALID_CURP';
const INVALID_CURP_STATUS = 'INVALID_CURP_STATUS';
const INVESTOR_DOES_NOT_HAVE_PENDING_CORRECTIONS = 'INVESTOR_DOES_NOT_HAVE_PENDING_CORRECTIONS';
const INVESTOR_HAS_NOT_CURP_SUGGESTED = 'INVESTOR_HAS_NOT_CURP_SUGGESTED';
const INVESTOR_NOT_FOUND = 'INVESTOR_NOT_FOUND';

class CURPError extends EnrollmentError {
  constructor(code, details = null) {
    super(code, 'CURPError', details);
  }

  isInvalidCURP() {
    return this._code === INVALID_CURP;
  }

  isCURPAlreadyRegistered() {
    return this._code === CURP_ALREADY_REGISTERED;
  }

  hasNotCurpSuggested() {
    return this._code === INVESTOR_HAS_NOT_CURP_SUGGESTED;
  }

  investorNotFound() {
    return this._code === INVESTOR_NOT_FOUND;
  }

  isCurpAlreadyRejected() {
    return this._code === CURP_ALREADY_REJECTED;
  }

  isInvalidCurpStatus() {
    return this._code === INVALID_CURP_STATUS;
  }

  curpNotFound() {
    return this._code === CURP_NOT_FOUND;
  }

  isInvestorDoesNotHavePendingCorrections() {
    return this._code === INVESTOR_DOES_NOT_HAVE_PENDING_CORRECTIONS;
  }
}

export default CURPError;
