import styled from 'styled-components';

const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  text-align: center;

  > p {
    margin: 0;
    text-align: center;
  }
`;

export { ActionButtonsWrapper, InfoWrapper };
