import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@components';
import './style.scss';
import { useAuth } from '@lib/authentication';

const SECONDS_UNIT = 1_000;
const MINUTES_UNIT = 60;

const FailedAttemptsLock = ({ email = '', remainingLockTimeInMilliseconds = 0 }) => {
  const { clearSession, isAuthenticated } = useAuth();

  const remainingLockTimeInSeconds = Math.ceil(remainingLockTimeInMilliseconds / SECONDS_UNIT);
  const remainingLockTimeInMinutes = Math.ceil(remainingLockTimeInSeconds / MINUTES_UNIT);

  if (isAuthenticated) {
    clearSession();
  }

  return (
    <div styleName="failed-attempts-lock">
      <Icon name="icFailedAttemptsLock" alt="Cuenta bloqueada" />
      <p>
        Esta cuenta
        <span styleName="key-word" tid="trader-email">
          {` ${email} `}
        </span>
        está temporalmente bloqueada por más de
        <span styleName="key-word"> 3 intentos </span>
        de inicio de sesión
        <span styleName="key-word"> inválidos.</span>
      </p>
      <span>Inténtalo en: </span>
      <span styleName="timeout-for-unlock" tid="timeout-for-unlock">
        {`${remainingLockTimeInMinutes} minutos`}
      </span>
      <p>
        Para cualquier duda o aclaración ponte en contacto con nosotros:
        <span styleName="customer-email">soporte@100ladrillos.com</span>
      </p>
    </div>
  );
};

FailedAttemptsLock.propTypes = {
  email: PropTypes.string.isRequired,
  remainingLockTimeInMilliseconds: PropTypes.number.isRequired
};

export default FailedAttemptsLock;
