import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '../Icon';
import { Paragraph } from '../Styled';
import { Badge } from './PropertyCountryBadge.style';
const countryCodes = {
    MX: {
        icon: 'icMexicoFlag',
        name: 'México'
    },
    US: {
        icon: 'icUSAFlag',
        name: 'Estados Unidos'
    }
};
const PropertyCountryBadge = ({ countryCode }) => (_jsxs(Badge, { children: [_jsx(Icon, { name: countryCodes[countryCode].icon, alt: `Ícono de bandera de ${countryCodes[countryCode].name}`, height: "24", width: "24" }), _jsx(Paragraph, { children: countryCodes[countryCode].name })] }));
export default PropertyCountryBadge;
