class Petitioner {
    id;
    name;
    description;
    photoURL;
    website;
    createdAt;
    updatedAt;
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.photoURL = data.photoURL;
        this.website = data.website;
        this.createdAt = new Date(data.createdAt);
        this.updatedAt = new Date(data.updatedAt);
    }
}
export default Petitioner;
