import { css } from 'styled-components';

const flexAsColumn = (gapSize = null) => ({ theme }) => {
  const gap = gapSize ? `gap: ${theme.spacing(gapSize)};` : '';

  return css`
    display: flex;
    flex-direction: column;
    ${gap}
  `;
};

export default flexAsColumn;
