import { useState, useEffect, useCallback } from 'react';

const usePagination = () => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);

  const setPagination = useCallback(totalRecords => {
    setTotalRecords(totalRecords);
  }, []);

  const goToPage = useCallback(page => {
    const skip = (page - 1) * limit;

    setCurrentPage(page);
    setSkip(skip);
  }, [limit]);

  const goToNextPage = () => {
    if (currentPage !== totalPages) {
      goToPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage !== 1) {
      goToPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const calculatePages = () => {
      const totalPages = Math.ceil(totalRecords / limit);

      setTotalPages(totalPages);
    };

    calculatePages();
  }, [totalRecords, limit]);

  useEffect(() => {
    goToPage(1);
  }, [goToPage]);

  return {
    setPagination,
    setLimit,
    goToPage,
    goToNextPage,
    goToPreviousPage,
    currentPage,
    totalPages,
    skip,
    limit,
  };
};

export default usePagination;
