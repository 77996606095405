import Money from '../../../../types/money';
import EventsReporter from '../EventsReporter';
import Event from '../../entities/Event';

class SellEventsReporter extends EventsReporter {
  sellStarted(propertyId, propertyName) {
    const name = 'Marketplace Sell - Bricks - Clicked - Button - Sell';
    const properties = { propertyId, propertyName };

    const event = new Event(name, properties);

    this._insightsService.sendEvent(event);
  }

  sellQuote(property, numberOfBricksToSell, pricePerBrick) {
    const name = 'Marketplace Sell - Preview Quoter - Completed - Form - Quoter ';
    const properties = this._createCompleteEventProperties(
      property,
      numberOfBricksToSell,
      pricePerBrick
    );

    const event = new Event(name, properties);

    this._insightsService.sendEvent(event);
  }

  sellIntention(property, numberOfBricksToSell, pricePerBrick) {
    const name = 'Marketplace Sell - Preview Sell Details - Clicked - Button - Accept';
    const properties = this._createCompleteEventProperties(
      property,
      numberOfBricksToSell,
      pricePerBrick
    );

    const event = new Event(name, properties);

    this._insightsService.sendEvent(event);
  }

  typingOneTimePassword(property, numberOfBricksToSell, pricePerBrick) {
    const name = 'Marketplace Sell - Preview Sell Confirm - Typed - Input - OTP';
    const properties = this._createCompleteEventProperties(
      property,
      numberOfBricksToSell,
      pricePerBrick
    );

    const event = new Event(name, properties);

    this._insightsService.sendEvent(event);
  }

  orderPlaced(property, numberOfBricksToSell, pricePerBrick) {
    const name = 'Marketplace Sell - Preview Sell Confirm - Clicked - Button - Accept';
    const properties = this._createCompleteEventProperties(
      property,
      numberOfBricksToSell,
      pricePerBrick
    );

    const event = new Event(name, properties);

    this._insightsService.sendEvent(event);
  }

  _createCompleteEventProperties(property, numberOfBricksToSell, pricePerBrick) {
    const bricksPrice = new Money(pricePerBrick).toString();
    const grandTotal = new Money(pricePerBrick * numberOfBricksToSell).toString();

    const properties = {
      propertyName: property?.name ?? null,
      propertyId: property?.id ?? null,
      bricksAmount: numberOfBricksToSell ?? null,
      bricksPrice: bricksPrice ?? null,
      grandTotal: grandTotal ?? null
    };

    return properties;
  }
}

export default SellEventsReporter;
