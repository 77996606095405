import APIClient from './APIClient';
import AccountProfitAPI from './AccountProfitAPI';
import AddressAPI from './AddressAPI';
import AuthenticationAPI from './AuthenticationAPI';
import BricksAPI from './BricksAPI';
import CrowdfundingInvestmentAPI from './CrowdfundingInvestmentAPI';
import CrowdfundingPropertyAPI from './CrowdfundingPropertyAPI';
import EnrollmentAPI from './EnrollmentAPI';
import ForeignLeaseIncomesAPI from './ForeignLeaseIncomesAPI';
import IdentificationAPI from './IdentificationAPI';
import InvoicingAPI from './InvoicingAPI';
import LegacyEnrollmentAPI from './legacy/LegacyEnrollmentAPI';
import LegacyTraderAPI from './legacy/LegacyTraderAPI';
import NotificationsAPI from './NotificationsAPI';
import OperatorAPI from './OperatorAPI';
import ProfileAPI from './ProfileAPI';
import PropertyManagementAPI from './PropertyManagementAPI';
import RentReleaseSummaryAPI from './RentReleaseSummaryAPI';
import SecondaryMarketAPI from './SecondaryMarketAPI';
import SecondaryMarketPropertyAPI from './SecondaryMarketPropertyAPI';
import SignDocumentsAPI from './SignDocumentsAPI';
import SignupAPI from './SignupAPI';
import UpdateDataAPI from './UpdateDataAPI';
import UpdateEmailAPI from './UpdateEmailAPI';
import UpdatePhoneAPI from './UpdatePhoneAPI';
import WithdrawalAPI from './WithdrawalAPI';
export { AccountProfitAPI, AddressAPI, AuthenticationAPI, BricksAPI, CrowdfundingInvestmentAPI, CrowdfundingPropertyAPI, EnrollmentAPI, ForeignLeaseIncomesAPI, IdentificationAPI, InvoicingAPI, LegacyEnrollmentAPI, LegacyTraderAPI, NotificationsAPI, OperatorAPI, ProfileAPI, PropertyManagementAPI, RentReleaseSummaryAPI, SecondaryMarketAPI, SecondaryMarketPropertyAPI, SignDocumentsAPI, SignupAPI, UpdateDataAPI, UpdateEmailAPI, UpdatePhoneAPI, WithdrawalAPI };
export default APIClient;
