import { RentReleaseSummaryError, NetworkError } from '../errors';

import GatewayAPI from './GatewayAPI';

class RentReleaseSummaryAPI extends GatewayAPI {
  async getRentReleaseSummary() {
    try {
      const { data } = await this._client.get('/traders/me/rents/releaseData');

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  _handleError = error => {
    if (!error.response) {
      throw new NetworkError();
    }

    const { response } = error;
    const { code } = response.data;

    throw new RentReleaseSummaryError(code);
  };
}

export default RentReleaseSummaryAPI;
