import CapitalGainDetail from './CapitalGainDetail';
class CapitalGainSummary {
    investments;
    estimationDetail;
    totalInvestedAmount;
    totalValuationAmount;
    totalCapitalGain;
    constructor(data) {
        this.totalInvestedAmount = data.totalInvestedAmount;
        this.totalValuationAmount = data.totalValuationAmount;
        this.totalCapitalGain = data.totalCapitalGain;
        this.estimationDetail = data.estimationDetail;
        this.investments = data.investments.map(investment => new CapitalGainDetail(investment));
    }
}
export default CapitalGainSummary;
