import APIError from '../APIError';

const INVALID_PROPERTY_ID = 'INVALID_PROPERTY_ID';
const PROPERTY_NOT_FOUND = 'PROPERTY_NOT_FOUND';

class PropertyManagementLogsError extends APIError {
  constructor(code, details = null) {
    super(code, 'PropertyManagementLogsError', details);
  }

  hasInvalidPropertyId() {
    return this._code === INVALID_PROPERTY_ID;
  }

  isPropertyNotFound() {
    return this._code === PROPERTY_NOT_FOUND;
  }
}

export default PropertyManagementLogsError;
