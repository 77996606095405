import React, { useState, useEffect, Fragment } from 'react';
import isEmpty from 'lodash.isempty';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { Paragraph, Subtitle } from '@components/Styled';
import { renderSomethingWentWrong, renderSuccessToast } from '@utils';
import { TraderIsNotBlocked } from '@lib/hundredBricksAPI/authentication';
import { useAuth } from '@lib/authentication';
import { useErrorReporter } from '@lib/errorReporter';
import { useForm, useRequest } from '@hooks';
import { LocationBlockedModal } from '@views/Login/components';
import {
  Button,
  Form,
  InvestorLocationModal,
  OneTimePassword,
  PasswordField
} from '@components';

import useGeolocation from '../../hooks/useGeolocation';

import { UnblockLayout, ErrorMessage } from './UnusualAccessUnblock.style';

const unblockSchema = {
  password: {
    required: true
  }
};

const UnusualAccessUnblock = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const form = useForm({ schema: unblockSchema });
  const api = useHundredBricksAPI();
  const errorReporter = useErrorReporter();
  const { getCurrentPosition, getGeolocationStatus } = useGeolocation();
  const [verificationCode, setVerificationCode] = useState();
  const {
    error,
    isLoading,
    token,
    unlockUnusualAccess
  } = useAuth();

  const [
    unblockCodeResponse,
    isSendingUnblockCode,
    unblockCodeError,
    sendUnblockCode
  ] = useRequest(api.authentication.sendUnusualCodeAccess);

  const [displayLocationBlockedModal, setDisplayLocationBlockedModal] = useState(false);
  const [showInvestorLocationModal, setShowInvestorLocationModal] = useState(false);
  const [isApprovingLocation, setIsApprovingLocation] = useState(false);

  const handleSendEmailUnblockCode = () => {
    sendUnblockCode(searchParams.get('traderId'), 'email');
    form.removeError('code');
  };

  const handleUnblockInvestor = async () => {
    getCurrentPosition(coords => {
      const { latitude, longitude } = coords;

      unlockUnusualAccess(
        {
          code: verificationCode,
          traderId: searchParams.get('traderId'),
          password: form.values.password,
          latitude,
          longitude
        }
      );
    }, () => {
      setIsApprovingLocation(false);
      setDisplayLocationBlockedModal(true);
    });
  };

  const handleChangePassword = oneTimeCode => {
    setVerificationCode(oneTimeCode);
  };

  const acceptShareLocation = () => {
    setShowInvestorLocationModal(false);
    handleUnblockInvestor();
  };

  const cancelShareLocation = () => {
    setShowInvestorLocationModal(false);
    setIsApprovingLocation(false);
  };

  const onSubmit = async () => {
    setIsApprovingLocation(true);

    const geolocationStatus = await getGeolocationStatus();

    if (geolocationStatus === 'prompt') {
      setShowInvestorLocationModal(true);
    } else if (geolocationStatus === 'denied') {
      setDisplayLocationBlockedModal(true);
      setIsApprovingLocation(false);
    } else if (geolocationStatus === 'granted') {
      handleUnblockInvestor();
    }
  };

  const handleErrors = apiError => {
    switch (apiError.code) {
      case 'CANT_RESEND_TOKEN':
        form.addError({
          field: 'code',
          message: 'Ocurrió un problema al tratar de mandar el código de verificación.'
        });
        break;
      case 'INCORRECT_PASSWORD':
        form.addError({
          field: 'password',
          message: 'La contraseña es inválida'
        });
        break;
      case 'INVALID_CODE':
        form.addError({
          field: 'code',
          message: 'El código es inválido'
        });
        break;
      default:
        renderSomethingWentWrong();
        errorReporter.critical(apiError);
        break;
    }
  };

  useEffect(() => {
    if (unblockCodeResponse && !unblockCodeError) {
      renderSuccessToast('Se ha enviado el código exitosamente.');
    } else if (unblockCodeError && !(unblockCodeError instanceof TraderIsNotBlocked)) {
      handleErrors(unblockCodeError.cause);
    }
  }, [unblockCodeResponse, unblockCodeError]);

  useEffect(() => {
    if (token && !error) {
      navigate('/marketplace');
    } else if (error) {
      handleErrors(error.cause);
      setIsApprovingLocation(false);
    }
  }, [token, error]);

  const hasURLError = (
    error?.code === 'INVALID_ID' || error?.code === 'TRADER_NOT_FOUND'
  );

  return (
    <UnblockLayout>
      <Subtitle>Sesión inusual</Subtitle>

      {
        !(unblockCodeError instanceof TraderIsNotBlocked) && (
          <Fragment>
            <div>
              <Paragraph>
                Esta cuenta fue temporalmente bloqueada por inicio
                de sesión en una ubicación
                <span>
                  {' distinta a la habitual ¿Quieres desbloquear la cuenta?.'}
                </span>

                <br />

                <span>
                  Envía el código de seguridad a tu correo para desbloquearla.
                </span>
              </Paragraph>

              <Button
                color="secondary"
                isLoading={isSendingUnblockCode}
                disabled={!isEmpty(unblockCodeResponse)}
                onClick={handleSendEmailUnblockCode}
              >
                Enviar código
              </Button>
            </div>

            <OneTimePassword
              error={{ message: form.errors.code }}
              resendOptions={['email']}
              onChange={handleChangePassword}
              onSendCode={handleSendEmailUnblockCode}
            />
            <Form onSubmit={onSubmit}>
              <PasswordField
                id="password"
                label="¿Cuál es tu contraseña?"
                inputProps={{ 'aria-label': 'password' }}
                name="password"
                value={form.values.password}
                {...form.fieldProps('password')}
              />

              <Button
                type="submit"
                disabled={!form.isValid() || hasURLError || isApprovingLocation}
                isLoading={isLoading || isApprovingLocation}
              >
                Ir a iniciar sesión
              </Button>
            </Form>
          </Fragment>
        )
      }

      {hasURLError && (
        <ErrorMessage>
          <Paragraph>Al parecer algo cambió en la URL</Paragraph>
          <Paragraph>Abre de nuevo el enlace desde tu correo</Paragraph>
        </ErrorMessage>
      )}

      {
        (unblockCodeError instanceof TraderIsNotBlocked) && (
          <Fragment>
            <Paragraph>
              Tu cuenta no se encuentra bloqueada. Por favor, inicia sesión para continuar.
            </Paragraph>

            <Button
              type="button"
              onClick={() => { navigate('/login'); }}
            >
              Ir a iniciar sesion
            </Button>
          </Fragment>
        )
      }

      <InvestorLocationModal
        isOpen={showInvestorLocationModal}
        onConfirm={acceptShareLocation}
        onCancel={cancelShareLocation}
      />

      <LocationBlockedModal
        isOpen={displayLocationBlockedModal}
        onConfirm={() => setDisplayLocationBlockedModal(false)}
      />
    </UnblockLayout>
  );
};

export default UnusualAccessUnblock;
