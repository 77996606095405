import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Money } from '@types';
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const WithdrawalRequestApproved = (props) => {
    const { notification } = props;
    const currency = new Money(notification.params?.requestedAmount).toString();
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Tu retiro se ha procesado con \u00E9xito" }), _jsxs(Paragraph, { children: ["Tu retiro de", _jsx(Bold, { children: ` ${currency} ` }), "ha sido completado con \u00E9xito. La transferencia est\u00E1 en camino a tu cuenta bancaria y puede tardar hasta 48 horas en reflejarse."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default WithdrawalRequestApproved;
