class YearlyPeriod {
    initialInvestment;
    estimatedCapitalGain;
    estimatedLeasing;
    investmentValue;
    investmentAnualAverage;
    investmentProfit;
    accumulatedEstimatedCapitalGain;
    accumulatedEstimatedLeasing;
    constructor(data) {
        this.initialInvestment = data.initialInvestment;
        this.estimatedCapitalGain = data.estimatedCapitalGain;
        this.estimatedLeasing = data.estimatedLeasing;
        this.investmentValue = data.investmentValue;
        this.investmentAnualAverage = data.investmentAnualAverage;
        this.investmentProfit = data.investmentProfit;
        this.accumulatedEstimatedCapitalGain = data.accumulatedEstimatedCapitalGain;
        this.accumulatedEstimatedLeasing = data.accumulatedEstimatedLeasing;
    }
}
export default YearlyPeriod;
