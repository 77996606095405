import HundredBricksAPI from '../HundredBricksAPI';
import AccountValueAPI from './accountValue/AccountValueAPI';
import PreferencesAPI from './preferences';
import CapitalGainAPI from './capitalGain';
import BricksAPI from './bricks/BricksAPI';
class InvestmentsAPI extends HundredBricksAPI {
    accountValueAPI;
    bricksAPI;
    capitalGainAPI;
    preferencesAPI;
    constructor(client) {
        super(client);
        this.accountValueAPI = new AccountValueAPI(client);
        this.bricksAPI = new BricksAPI(client);
        this.capitalGainAPI = new CapitalGainAPI(client);
        this.preferencesAPI = new PreferencesAPI(client);
    }
    async getAccountValueSummary() {
        return this.accountValueAPI.getSummary();
    }
    async getStandardCapitalGain() {
        return this.capitalGainAPI.getStandardCapitalGain();
    }
    async getLiquidityCapitalGain() {
        return this.capitalGainAPI.getLiquidityCapitalGain();
    }
    async getRealEstateCapitalGain() {
        return this.capitalGainAPI.getRealEstateCapitalGain();
    }
    async getPreferredPropertyValuation() {
        return this.preferencesAPI.getPropertyValuation();
    }
    async setPreferredPropertyValuation(payload) {
        return this.preferencesAPI.setPropertyValuation(payload);
    }
    async getStandardCapitalGainDetails() {
        return this.capitalGainAPI.getStandardCapitalGainDetails();
    }
    async getLiquidityCapitalGainDetails() {
        return this.capitalGainAPI.getLiquidityCapitalGainDetails();
    }
    async getRealEstateCapitalGainDetails() {
        return this.capitalGainAPI.getRealEstateCapitalGainDetails();
    }
    async getBricksInPreSale() {
        return this.bricksAPI.getBricksInPreSale();
    }
    async getPortfolioBricksByProperty(propertyId) {
        return this.bricksAPI.getPortfolioBricksByProperty(propertyId);
    }
}
export default InvestmentsAPI;
