class LocalStoragePlatformID {
  set(profile) {
    if (!profile) return;

    localStorage.setItem('platformID', profile.id);
  }

  clear() {
    localStorage.removeItem('platformID');
  }
}

export default LocalStoragePlatformID;
