import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MovementsRouter from '@views/Movements/MovementsRouter';
import { useProfile } from '@lib/profile';
import SigningDocumentsOutlet from './SigningDocumentsOutlet';
const EnrollmentRouter = lazy(() => import('../../../views/Enrollment/EnrollmentRouter'));
const SignDocumentsRouter = lazy(() => import('../../../views/SignDocuments/SignDocumentsRouter'));
const OnboardingRouter = lazy(() => import('../../../views/Onboarding/OnboardingRouter'));
const ProfileRouter = lazy(() => import('../../../views/Profile/ProfileRouter'));
const MarketplaceRouter = lazy(() => import('../../../views/Marketplace/MarketplaceRouter'));
const AccountRouter = lazy(() => import('../../../views/MyAccount/AccountRouter'));
const PendingLevelRoutes = () => {
    const { profile } = useProfile();
    if (profile?.status.isPendingToVerifyEmail()) {
        return (_jsxs(Routes, { children: [_jsx(Route, { path: "/enrollment/*", element: _jsx(EnrollmentRouter, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/enrollment/verify-email" }) })] }));
    }
    if (profile?.status.isEnrolling()) {
        return (_jsxs(Routes, { children: [_jsx(Route, { path: "/enrollment/*", element: _jsx(EnrollmentRouter, {}) }), _jsx(Route, { path: "/onboarding/*", element: _jsx(OnboardingRouter, {}) }), _jsx(Route, { path: "/marketplace/*", element: _jsx(MarketplaceRouter, {}) }), _jsx(Route, { path: "/my-account/*", element: _jsx(AccountRouter, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/onboarding" }) })] }));
    }
    if (profile?.status.isSigningDocuments()) {
        return (_jsx(Routes, { children: _jsxs(Route, { path: "/", element: _jsx(SigningDocumentsOutlet, {}), children: [_jsx(Route, { path: "/sign-documents/*", element: _jsx(SignDocumentsRouter, {}) }), _jsx(Route, { path: "/onboarding/*", element: _jsx(OnboardingRouter, {}) }), _jsx(Route, { path: "/marketplace/*", element: _jsx(MarketplaceRouter, {}) }), _jsx(Route, { path: "/my-account/*", element: _jsx(AccountRouter, {}) }), _jsx(Route, { path: "/movements/*", element: _jsx(MovementsRouter, {}) }), _jsx(Route, { path: "/perfil/*", element: _jsx(ProfileRouter, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/" }) })] }) }));
    }
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/onboarding/*", element: _jsx(OnboardingRouter, {}) }), _jsx(Route, { path: "/marketplace/*", element: _jsx(MarketplaceRouter, {}) }), _jsx(Route, { path: "/my-account/*", element: _jsx(AccountRouter, {}) }), _jsx(Route, { path: "/movements/*", element: _jsx(MovementsRouter, {}) }), _jsx(Route, { path: "/perfil/*", element: _jsx(ProfileRouter, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/onboarding" }) })] }));
};
export default PendingLevelRoutes;
