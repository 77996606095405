// It should Refactor when update my account
class TraderInvestmentAccount {
  constructor(accountValue, crowdfundingBricks, secondaryMarketBricks) {
    this._accountValue = accountValue;
    this._crowdfundingBricks = crowdfundingBricks.numberOfBricks;
    this._secondaryMarketBricks = secondaryMarketBricks.numberOfBricks;
    this._capitalGain = accountValue.capitalGain;
  }

  get accountValue() {
    return this._accountValue;
  }

  get crowdfundingBricks() {
    return this._crowdfundingBricks;
  }

  get firstCapitalGain() {
    return this._capitalGain.amount;
  }

  get incomeToRelease() {
    return this._accountValue.incomeToRelease;
  }

  get investmentAmount() {
    return this._accountValue.crowdfunded + this._accountValue.investedInBricks;
  }

  get secondaryMarketBricks() {
    return this._secondaryMarketBricks;
  }

  hasAnyKindOfBricks() {
    return this._crowdfundingBricks > 0 || this._secondaryMarketBricks > 0;
  }

  hasInvestment() {
    return this._accountValue.amount > 0 || this.hasAnyKindOfBricks();
  }
}

export default TraderInvestmentAccount;
