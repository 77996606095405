class Address {
    street;
    neighborhood;
    internalNumber;
    externalNumber;
    zipCode;
    city;
    state;
    municipality;
    country;
    constructor(addressData) {
        this.street = addressData?.street;
        this.neighborhood = addressData?.neighborhood;
        this.internalNumber = addressData?.internalNumber;
        this.externalNumber = addressData?.externalNumber;
        this.zipCode = addressData?.zipCode;
        this.city = addressData?.city;
        this.state = addressData?.state;
        this.municipality = addressData?.municipality;
        this.country = addressData?.country;
    }
}
export default Address;
