import styled, { css } from 'styled-components';

import { flexAsColumn, flexAsRow, grid } from '@components/Styled/mixins';
import { TextField } from '@components';

const WithdrawFundsWrapper = styled.section`
  ${flexAsColumn(2)};

  margin: 0 auto;
  max-width: ${({ theme }) => theme.spacing(120)};
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(2)}`};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: ${({ theme }) => theme.spacing(4)};
  }
`;

const PanelWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.borderRadius(2)};
  box-shadow: 0 0 6px 0 ${({ theme }) => theme.palette.grayD};
  gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  overflow: hidden;
  padding-bottom: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(2)};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    ${grid()};

    gap: ${({ theme }) => `${theme.spacing(3)} 0`};
    justify-items: center;
    align-items: center;

    ${({ showTwoFactor }) => (showTwoFactor
    ? css`grid-template-columns: repeat(2, 1fr);`
    : css`
        grid-template-columns: 1fr;
    `)}
  }
`;

const FundsWrapper = styled.div`
  ${flexAsRow()};

  background-color: ${({ theme }) => theme.palette.grayA};
  flex-wrap: wrap;
  font-family: ${({ theme }) => theme.typography.title.h3.fontFamily};
  grid-column: span 2;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)};
  position: relative;
  text-align: center;
  width: 100%;

  > img {
    left: ${({ theme }) => theme.spacing(2)};
    position: absolute;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    align-items: center;
    justify-content: flex-start;
    gap: ${({ theme }) => theme.spacing(2)};

    > img {
      left: inherit;
      position: inherit;
    }
  }
`;

const NumberField = styled(TextField)`
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const AvailableBalance = styled.div`
  ${flexAsColumn(1)};

  > :last-child {
    color: ${({ theme }) => theme.palette.alternative6.dark};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    flex-direction: row;

    > p {
      font-size: ${({ theme }) => theme.typography.title.h2.fontSize};
    }
  }
`;

const WithdrawForm = styled.div`
  ${flexAsColumn(2)};

  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)};
  text-align: left;

  > h2 {
    color: ${({ theme }) => theme.palette.grayF};
    font-size: ${({ theme }) => theme.typography.title.h3.fontSize};
    text-align: center;
  }

  > div {
    width: 100%;
  }

  > button {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    max-width: ${({ theme }) => theme.spacing(50)};
    padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
  }
`;

const AddAccountInformation = styled.div`
  ${flexAsColumn()};

  grid-column: span 2;
  padding: ${({ theme }) => `0 ${theme.spacing(2)}`};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
  }
`;

const TwoFactorContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(2)}`};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export {
  AddAccountInformation,
  AvailableBalance,
  FundsWrapper,
  NumberField,
  PanelWrapper,
  TwoFactorContainer,
  WithdrawForm,
  WithdrawFundsWrapper
};
