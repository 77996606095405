import styled from 'styled-components';

import { flexAsColumn, flexAsRow } from '@components/Styled/mixins';

const ContentWrapper = styled.div`
  ${flexAsColumn(4)}

  align-items: center;
  text-align: center;
`;

const ActionsWrapper = styled.div`
  ${flexAsRow(2)}
`;

export { ActionsWrapper, ContentWrapper };
