import Percentage from '../../types/percentage';

const formatPercentageWithoutRounding = percentage => {
  let currentPercentage = percentage;

  if (typeof percentage === 'string' || !percentage) {
    currentPercentage = 0;
  }

  return `${new Percentage(currentPercentage).formatWithoutRounding()}%`;
};

export default formatPercentageWithoutRounding;
