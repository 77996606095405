import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import isEmpty from 'lodash.isempty';
import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { useErrorReporter } from '@lib/errorReporter';
import { useProfile } from '@lib/profile';
import { useRequest } from '@hooks';
import PaymentPlansContext from './PaymentPlansContext';
const PaymentPlansProvider = ({ children }) => {
    const errorReporter = useErrorReporter();
    const { profile } = useProfile();
    const { trading } = useHundredBricksAPI();
    const [paymentPlans, isLoadingPaymentPlans, paymentPlansError, getPaymentPlans] = useRequest(trading.paymentPlans.getPaymentPlans);
    const loadPaymentPlans = () => getPaymentPlans({ page: 1, perPage: 20 });
    useEffect(() => {
        if (profile?.accountLevel.isLimitless()) {
            loadPaymentPlans();
        }
    }, [profile]);
    useEffect(() => {
        if (paymentPlansError) {
            errorReporter.critical(paymentPlansError);
        }
    }, [paymentPlansError]);
    const paymentPlansContext = useMemo(() => ({
        plans: paymentPlans,
        reload: loadPaymentPlans,
        isLoading: isLoadingPaymentPlans,
        error: paymentPlansError,
        hasPlans: Boolean(!isLoadingPaymentPlans
            && paymentPlans
            && !isEmpty(paymentPlans?.items))
    }), [paymentPlans, isLoadingPaymentPlans, paymentPlansError]);
    return (_jsx(PaymentPlansContext.Provider, { value: paymentPlansContext, children: children }));
};
export default PaymentPlansProvider;
