import { BricksSummary, BricksToSell } from '../entities/BricksSummary';
import NetworkError from '../errors/NetworkError';
import BricksError from '../errors/Bricks';

import GatewayAPI from './GatewayAPI';

class BricksAPI extends GatewayAPI {
  async getSummary() {
    try {
      const response = await this._legacyClient.get('/traders/me/bricks/summary');

      return new BricksSummary(response.data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getAvailableBricksToSellByProperty() {
    try {
      const response = await this._legacyClient
        .get('/traders/me/bricks/properties/availableToSell/summary');

      return new BricksToSell(response.data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  _handleError(error) {
    if (!error.response) {
      throw new NetworkError();
    }

    const { response } = error;
    const { code } = response.data;

    throw new BricksError(code);
  }
}

export default BricksAPI;
