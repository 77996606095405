import { PROPERTY_VALUATIONS } from '@constants';
class PropertyValuation {
    type;
    constructor(data) {
        this.type = data.propertyValuationType;
    }
    isStandard() {
        return this.type === PROPERTY_VALUATIONS.standard;
    }
    isLiquidity() {
        return this.type === PROPERTY_VALUATIONS.liquidity;
    }
    isRealEstate() {
        return this.type === PROPERTY_VALUATIONS.realEstate;
    }
}
export default PropertyValuation;
