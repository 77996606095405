import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useForm } from '@hooks';

import AdaptiveArea from '../AdaptiveArea';
import Button from '../Button';
import CodeField from '../CodeField';
import Form from '../Form';
import RenderIf from '../RenderIf';
import ResendOptions from '../TwoFactorConfirmation/ResendOptions';

import { ExpirationLabel } from './OneTimePassword.style';

const CODE_LENGTH = 6;
const RESEND_DELAY_TIME = 60;
const AUTHENTICATION_FACTOR_LOCK_TIME = 60 * 5;

const oneTimePasswordSchema = {
  code: {
    required: {
      message: 'El código de verificación es requerido.'
    },
    length: {
      min: CODE_LENGTH,
      max: CODE_LENGTH,
      message: `El código debe tener ${CODE_LENGTH} caracteres.`
    }
  }
};

const OneTimePassword = props => {
  const {
    className,
    disabled,
    error,
    isLoading,
    onCancel,
    onChange,
    onSendCode,
    onVerifyCode,
    resendOptions,
    sendButtonText,
    timesBlock
  } = props;

  const form = useForm({ schema: oneTimePasswordSchema });

  const hasAuthenticationFactorLock = timesBlock >= 3;

  const handleResend = resendChannel => onSendCode(resendChannel);

  const onSubmit = () => onVerifyCode(form.values.code);

  useEffect(() => {
    if (timesBlock >= 3) {
      form.addError({
        field: 'code',
        message: 'Por 3 intentos inválidos el uso del código está bloqueado.'
      });
    } else if (error.message) {
      form.addError({
        field: 'code',
        message: error.message
      });
    }
  }, [error, timesBlock]);

  useEffect(() => {
    if (form.values.code && onChange) {
      onChange(form.values.code);
    }
  }, [form.values.code]);

  return (
    <Form className={className} onSubmit={form.handleSubmit(onSubmit)}>
      <ExpirationLabel>Tu código expira en 2 minutos.</ExpirationLabel>

      <CodeField
        id="oneTimePassword-code"
        disabled={hasAuthenticationFactorLock || disabled || isLoading}
        focus
        groupEvery={CODE_LENGTH / 2}
        value={form.values.code}
        {...form.fieldProps('code')}
      />

      {!disabled && (
        <ResendOptions
          countdown={(
            hasAuthenticationFactorLock ? AUTHENTICATION_FACTOR_LOCK_TIME : RESEND_DELAY_TIME
          )}
          onResend={handleResend}
          resendOptions={resendOptions}
        />
      )}

      <AdaptiveArea>
        <RenderIf condition={Boolean(onCancel)}>
          <Button
            id="return"
            color="secondary"
            onClick={onCancel}
            disabled={isLoading}
          >
            Anterior
          </Button>
        </RenderIf>

        <Button
          id="verify"
          type="submit"
          isLoading={isLoading}
          disabled={!form.isValid() || timesBlock >= 3}
        >
          {sendButtonText || 'Verificar'}
        </Button>
      </AdaptiveArea>
    </Form>
  );
};

OneTimePassword.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({ message: PropTypes.string, timeBlock: PropTypes.string }),
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSendCode: PropTypes.func.isRequired,
  onVerifyCode: PropTypes.func,
  resendOptions: PropTypes.arrayOf(PropTypes.string),
  sendButtonText: PropTypes.string,
  timesBlock: PropTypes.number
};

OneTimePassword.defaultProps = {
  className: undefined,
  disabled: false,
  error: {},
  isLoading: false,
  onCancel: null,
  onChange: null,
  onVerifyCode: () => {},
  resendOptions: [],
  sendButtonText: null,
  timesBlock: 0
};

export default OneTimePassword;
