class Rebate {
    crowdfundingProperty;
    investmentType;
    income;
    netFees;
    taxFees;
    totalFees;
    totalReceived;
    constructor(rebateData) {
        this.crowdfundingProperty = rebateData.crowdfundingProperty;
        this.investmentType = rebateData.investmentType;
        this.income = rebateData.income;
        this.netFees = rebateData.netFees;
        this.taxFees = rebateData.taxFees;
        this.totalFees = rebateData.totalFees;
        this.totalReceived = rebateData.totalReceived;
    }
}
export default Rebate;
