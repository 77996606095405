/* eslint-disable react/prop-types */
import React from 'react';

import { regularExpressions as regex } from '@utils';

import TextField from '../TextField';

const NameField = props => {
  const {
    onChange,
    inputProps,
    ...restProps
  } = props;

  const handleChange = event => {
    const inputEvent = event;
    const { value } = event.target;

    inputEvent.target.value = value.replace(regex.onlyValidNameSymbols, '');

    onChange(inputEvent);
  };

  return (
    <TextField
      {...restProps}
      onChange={handleChange}
      inputProps={inputProps}
    />
  );
};

export default NameField;
