import { SIGN_REQUESTS } from '@constants';
class SignRequest {
    list;
    constructor(data) {
        this.list = data.pendingSignRequests ?? [];
    }
    hasRequests() {
        return this.list.length > 0;
    }
    hasCommercialContractRequest() {
        return this.list.some(item => item === SIGN_REQUESTS.commercialContract);
    }
}
export default SignRequest;
