import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import Text from '../Text';
import Title from '../Title';
import convertNumberToCurrencyString from '../../utils/convertNumberToCurrencyString';

import { ActionButtonsWrapper, InfoWrapper } from './NotEnoughFundsModal.style';

const NotEnoughFundsModal = ({ availableAmount, handleClose, isOpen }) => {
  const navigate = useNavigate();

  const handleRedirection = () => navigate('/movements/add-funds');

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Title>¡Fondos insuficientes!</Title>

      <Icon name="icAddFundsColor" alt="Fondos insuficientes" height={84} width={84} />

      <InfoWrapper>
        <Text>Tu saldo disponible es: </Text>

        <strong>
          {convertNumberToCurrencyString(availableAmount)}
        </strong>
      </InfoWrapper>

      <ActionButtonsWrapper>
        <Button
          id="keep-looking"
          color="secondary"
          onClick={handleClose}
        >
          Seguir navegando
        </Button>

        <Button
          id="add-funds"
          color="primary"
          onClick={handleRedirection}
        >
          Agregar fondos
        </Button>
      </ActionButtonsWrapper>
    </Modal>
  );
};

NotEnoughFundsModal.propTypes = {
  availableAmount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

NotEnoughFundsModal.defaultProps = {
  availableAmount: ''
};

export default NotEnoughFundsModal;
