import React from 'react';
import styled from 'styled-components';

import InputText from '../TextField';

const TextField = styled(props => <InputText {...props} />)`
    input{
        text-transform: lowercase;

        &::placeholder{
            text-transform: initial;
        }
    }
`;

export default TextField;
