import Rebate from './Rebate';
class RebatesDispersion {
    items;
    totalIncomes;
    totalNetFees;
    totalTaxFees;
    totalFees;
    totalReceived;
    constructor(rebatesDispersionData) {
        this.items = rebatesDispersionData.items.map(rebateData => new Rebate(rebateData));
        this.totalIncomes = rebatesDispersionData.totalIncomes;
        this.totalNetFees = rebatesDispersionData.totalNetFees;
        this.totalTaxFees = rebatesDispersionData.totalTaxFees;
        this.totalFees = rebatesDispersionData.totalFees;
        this.totalReceived = rebatesDispersionData.totalReceived;
    }
}
export default RebatesDispersion;
