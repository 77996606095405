import { PROPERTY_STRATEGIES_NAME, PROPERTY_TYPES } from '@constants';
const DAYS_IN_MILLISECONDS = 1_000 * 60 * 60 * 24;
const NUMBER_OF_DAYS_FOR_A_NEW_PROPERTY = 30;
class OnSaleProperty {
    id;
    name;
    propertyType;
    investmentStrategy;
    description;
    hasVariableIncome;
    numberOfBricksOwned;
    estimatedCapitalGain;
    estimatedAnnualProfit;
    bricksOnSaleByInvestor;
    maximumBricksToBuy;
    capRate;
    thumbnailURL;
    createdAt;
    photos;
    annualLeasing;
    annualIncome;
    rents;
    bricks;
    prices;
    address;
    rentalUnits;
    constructor(property) {
        this.id = property.id;
        this.name = property.name;
        this.description = property.description;
        this.propertyType = property.type;
        this.investmentStrategy = property.investmentStrategy;
        this.hasVariableIncome = property.hasVariableIncome;
        this.numberOfBricksOwned = property.numberOfBricksOwned;
        this.estimatedAnnualProfit = property.estimatedAnnualProfit;
        this.estimatedCapitalGain = property.estimatedCapitalGain;
        this.bricksOnSaleByInvestor = property.bricksOnSaleByInvestor;
        this.maximumBricksToBuy = property.maximumBricksToBuy;
        this.capRate = property.capRate;
        this.thumbnailURL = property.thumbnailURL;
        this.createdAt = new Date(property.createdAt);
        this.photos = property.photos;
        this.annualIncome = property.annualIncome;
        this.annualLeasing = property.annualLeasing;
        this.bricks = property.bricks;
        this.prices = property.prices;
        this.address = property.address;
        this.rentalUnits = property.rentalUnits;
        this.rents = {
            ...property.rents,
            latestRentDispersionDate: property.rents.latestRentDispersionDate
                ? new Date(property.rents.latestRentDispersionDate)
                : undefined
        };
    }
    getPropertyTypeName() {
        return PROPERTY_TYPES[this.propertyType];
    }
    getStrategyName() {
        return PROPERTY_STRATEGIES_NAME[this.investmentStrategy];
    }
    hasAvailableBricks() {
        return Boolean(this.bricks.available > 0);
    }
    isNew() {
        const differenceInDays = this.getDiferenceBetweenDatesInDays(this.createdAt);
        return Boolean(differenceInDays <= NUMBER_OF_DAYS_FOR_A_NEW_PROPERTY);
    }
    getDiferenceBetweenDatesInDays = (creationDate) => {
        const currentDate = new Date();
        const differenceInMilliseconds = Number(currentDate) - Number(creationDate);
        return Math.trunc(differenceInMilliseconds / DAYS_IN_MILLISECONDS);
    };
}
export default OnSaleProperty;
