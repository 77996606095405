import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';

import './style.scss';

const InternalLink = ({ to, children, ...props }) => (
  <RouterLink to={to} {...props} styleName="internal-link">
    {children}
  </RouterLink>
);

const ExternalLink = ({ to, children, ...props }) => (
  <a href={to} target="_blank" rel="noopener noreferrer" styleName="external-link" {...props}>
    {children}
  </a>
);

const ButtonLink = props => {
  const { onClick, children } = props;

  return (
    <button
      id="button-link"
      onClick={onClick}
      type="button"
      styleName="button-link base-link"
    >
      {children}
    </button>
  );
};

const LegacyLink = ({
  to,
  external,
  isBaseLink,
  elementType,
  ...props
}) => {
  const styleName = cx({
    'base-link': isBaseLink
  });

  if (elementType === 'button') {
    return <ButtonLink {...props} />;
  }

  if (external) {
    return <ExternalLink to={to} styleName={styleName} {...props} />;
  }

  return <InternalLink to={to} styleName={styleName} {...props} />;
};

LegacyLink.defaultProps = {
  external: false,
  isBaseLink: false,
  elementType: '',
  to: ''
};

LegacyLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
  isBaseLink: PropTypes.bool,
  elementType: PropTypes.string
};

InternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

ExternalLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

ButtonLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default LegacyLink;
