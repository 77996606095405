class ForeignLeaseIncome {
    countryCode;
    taxes;
    totalFees;
    netAmount;
    releasedAmount;
    grossAmount;
    constructor(data) {
        this.countryCode = data.countryCode;
        this.taxes = data.taxes;
        this.totalFees = data.totalFees;
        this.netAmount = data.netAmount;
        this.releasedAmount = data.releasedAmount;
        this.grossAmount = data.grossAmount;
    }
}
export default ForeignLeaseIncome;
