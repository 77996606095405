import { createGlobalStyle } from 'styled-components';

import AzeretMono from '@assets/fonts/AzeretMono-VariableFont_wght.ttf';
import AzeretMonoItalic from '@assets/fonts/AzeretMono-Italic-VariableFont_wght.ttf';
import NunitoSans from '@assets/fonts/NunitoSans-VariableFont_opsz,wdth,wght.ttf';
import NunitoSansItalic from '@assets/fonts/NunitoSans-Italic-VariableFont_opsz,wdth,wght.ttf';
import Poppins from '@assets/fonts/Poppins-VariableFont_wght.ttf';
import PoppinsItalic from '@assets/fonts/Poppins-Italic-VariableFont_wght.ttf';

const Globals = createGlobalStyle`
  @font-face {
    font-family: 'AzeretMono';
    font-style: normal;
    font-weight: 100 900;
    src: url(${AzeretMono});
  }

  @font-face {
    font-family: 'AzeretMono';
    font-style: italic;
    font-weight: 100 900;
    src: url(${AzeretMonoItalic});
  }

  @font-face {
    font-family: 'NunitoSans';
    font-style: normal;
    font-weight: 100 900;
    src: url(${NunitoSans});
  }

  @font-face {
    font-family: 'NunitoSans';
    font-style: italic;
    font-weight: 100 900;
    src: url(${NunitoSansItalic});
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100 900;
    src: url(${Poppins});
  }

  @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100 900;
    src: url(${PoppinsItalic});
  }

  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
    font-size: 16px;
  }
`;

export default Globals;
