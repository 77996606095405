import EnrollmentInvestor from '../EnrollmentInvestor';
import HundredBricksAPI from '../../HundredBricksAPI';
import { APIError } from '../../../api';
import CannotResendPhoneToken from './CannotResendPhoneToken';
import CannotSendPhoneToken from './CannotSendPhoneToken';
import CountryCallingCodeIsNotSupported from './CountryCallingCodeIsNotSupported';
import CountryCodeIsNotSupported from './CountryCodeIsNotSupported';
import InvalidPhoneToken from './InvalidPhoneToken';
import PhoneNumberIsAlreadyVerified from './PhoneNumberIsAlreadyVerified';
import PhoneNumberIsNotRegistered from './PhoneNumberIsNotRegistered';
import RegisterPhoneError from './RegisterPhoneError';
import ResendPhoneTokenError from './ResendPhoneTokenError';
import VerifyPhoneError from './VerifyPhoneError';
class PhoneAPI extends HundredBricksAPI {
    async register(number, country) {
        try {
            const { data } = await this.client.post('/v2/profile/phone', { number, country });
            return data;
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'COUNTRY_CODE_NOT_SUPPORTED':
                        throw new CountryCodeIsNotSupported(country, { cause: error });
                    case 'COUNTRY_CALLING_CODE_NOT_SUPPORTED':
                        throw new CountryCallingCodeIsNotSupported(country, { cause: error });
                    case 'CANNOT_RESEND_CODE':
                        throw new CannotSendPhoneToken({ cause: error });
                    default:
                        throw new RegisterPhoneError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new RegisterPhoneError('Unexpected error while registering phone', { cause: error });
            }
            else {
                throw new RegisterPhoneError(`Unexpected error while registering phone: ${error}`);
            }
        }
    }
    async sendToken(channel) {
        try {
            const { data } = await this.client.post('/v2/profile/phone/sendVerificationToken', { channel });
            return data;
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'NO_PHONE_NUMBER_REGISTERED_YET':
                        throw new PhoneNumberIsNotRegistered({ cause: error });
                    case 'CANNOT_RESEND_CODE':
                        throw new CannotResendPhoneToken({ cause: error });
                    case 'PHONE_NUMBER_IS_ALREADY_VERIFIED':
                        throw new PhoneNumberIsAlreadyVerified({ cause: error });
                    default:
                        throw new ResendPhoneTokenError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new ResendPhoneTokenError('Unexpected error while sending token', { cause: error });
            }
            else {
                throw new ResendPhoneTokenError(`Unexpected error while sending token: ${error}`);
            }
        }
    }
    async verify(code) {
        try {
            const { data } = await this.client.post('/v2/profile/phone/verify', { code });
            return new EnrollmentInvestor(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_CODE':
                        throw new InvalidPhoneToken({ cause: error });
                    default:
                        throw new VerifyPhoneError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new VerifyPhoneError('Unexpected error while verifying phone', { cause: error });
            }
            else {
                throw new VerifyPhoneError(`Unexpected error while verifying phone: ${error}`);
            }
        }
    }
}
export default PhoneAPI;
