import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Money } from '@types';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const RecurrentPaymentOfPaymentPlan = (props) => {
    const { notification } = props;
    const propertyName = notification.params?.crowdfundingPropertyName;
    const paidAmount = new Money(notification.params?.paidAmount);
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Has realizado un pago a tu plan de pagos" }), _jsxs(Paragraph, { children: ["Has realizado un pago de tu plan de pagos en la propiedad", _jsx(Bold, { children: ` ${propertyName} ` }), "por un monto de", _jsx(Bold, { children: ` ${paidAmount.toString()}` }), "."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default RecurrentPaymentOfPaymentPlan;
