import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Modal,
  Text,
  TextField,
  Title
} from '@components';
import { useAPI, useRequest } from '@hooks';
import { renderSomethingWentWrong, renderSuccessToast } from '@utils';

import ImageGrid from './ImageGrid';
import { PhraseForm, TitleWrapper } from './AntiphishingModal.style';

const MINIMUM_PHRASE_LENGTH = 4;
const MAXIMUM_PHRASE_LENGTH = 50;

const AntiphishingModal = ({ isOpen, onClose }) => {
  const { legacy } = useAPI();

  const [
    randomImagesResponse,
    isLoadingRandomImages,
    randomImagesError,
    getRandomImages
  ] = useRequest(legacy.traders.getRandomImages);

  const [
    antiphishingResponse,
    isLoadingAntiphishing,
    antiphishingError,
    setAntiphishing
  ] = useRequest(legacy.traders.setAntiphishing);

  const [imageId, setImageId] = useState(null);
  const [phrase, setPhrase] = useState('');

  const handlePhraseChange = ({ target }) => {
    setPhrase(target.value);
  };

  const handleSubmit = () => {
    setAntiphishing(imageId, phrase);
  };

  const isValidPhrase = (phrase.length >= MINIMUM_PHRASE_LENGTH)
    && (phrase.length <= MAXIMUM_PHRASE_LENGTH);

  useEffect(() => {
    if (antiphishingResponse && !antiphishingError) {
      renderSuccessToast('Imagen y frase listas, ¡Gracias!');

      onClose();
    } else if (antiphishingError) {
      renderSomethingWentWrong();
    }
  }, [antiphishingResponse, antiphishingError]);

  useEffect(() => {
    getRandomImages();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <TitleWrapper>
        <Title>Selecciona una imagen</Title>

        <Text>
          Por seguridad y protección de tu cuenta selecciona
          una imagen, la cual, te mostraremos cada vez que inicies sesión.
        </Text>
      </TitleWrapper>

      <ImageGrid
        images={randomImagesResponse}
        isLoading={isLoadingRandomImages}
        error={randomImagesError}
        onSelectImage={setImageId}
        selectedImage={imageId}
      />

      <PhraseForm onSubmit={handleSubmit}>
        <Text>
          Escribe una frase que puedas relacionar con la imagen que seleccionaste.
          (Mínimo 4 caracteres y máximo 50 caracteres)
        </Text>

        <TextField
          id="phrase-input"
          onChange={handlePhraseChange}
          inputProps={{
            maxLength: MAXIMUM_PHRASE_LENGTH,
            minLength: MINIMUM_PHRASE_LENGTH
          }}
          value={phrase}
        />

        <Button
          id="submit-btn"
          disabled={!isValidPhrase || !imageId || isLoadingAntiphishing}
          isLoading={isLoadingAntiphishing}
          type="submit"
        >
          Guardar
        </Button>
      </PhraseForm>
    </Modal>
  );
};

AntiphishingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AntiphishingModal;
