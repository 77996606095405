import styled from 'styled-components';

const WithdrawalInfo = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(2)}`} ;
  gap: ${({ theme }) => theme.spacing(2)};

  > h3, > p {
    text-align: center;
  }
`;

const MovementInfo = styled.section`
  background-color: white;
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.grayC};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(3)};
  width: 100%;
  max-width: ${({ theme }) => theme.spacing(50)};
  padding: ${({ theme }) => theme.spacing(3)} 0;

  > hr {
    all: unset;
    border: 1px solid ${({ theme }) => theme.palette.grayB};
    margin: 0;
    width: 100%;
  }
`;

export { MovementInfo, WithdrawalInfo };
