import { PropertyLog } from '../entities';
import { Photo, PropertyAddress } from '../entities/Marketplace';
import { SecondaryMarketProperty } from '../entities/Marketplace/Property';
import {
  Assessment,
  BrickOnSale,
  Escrow,
  Leaseholder,
  PropertyValueEstimate,
  RentalUnit,
  TransactionalSummary
} from '../entities/Marketplace/Property/SecondaryMarketProperty';
import {
  NetworkError,
  TransactionalSummaryError,
  SecondaryMarketPropertyError,
  PropertyManagementLogsError
} from '../errors';

import GatewayAPI from './GatewayAPI';

class SecondaryMarketPropertyAPI extends GatewayAPI {
  async getSecondaryMarketProperty(propertyId) {
    try {
      const { data } = await this._legacyClient.get(`/properties/${propertyId}`);

      const address = new PropertyAddress(data.address);
      const assessments = new Assessment(data.assessments);
      const escrow = new Escrow(data.escrow);
      const leaseholders = data.leaseholders.map(leaseholder => new Leaseholder(leaseholder));
      const photos = data.photos.map(photo => new Photo(photo));
      const propertyValueEstimate = new PropertyValueEstimate(data.propertyValueEstimate);
      const rentalUnits = new RentalUnit(data.rentalUnits);
      const listOfBricksOnSale = data.listOfBricksOnSale.map(
        brickOnSale => new BrickOnSale(brickOnSale)
      );
      const latestRentDispersionDate = data.latestRentDispersionDate
        ? new Date(data.latestRentDispersionDate)
        : undefined;

      const secondaryMarketPropertyData = {
        ...data,
        address,
        assessments,
        escrow,
        latestRentDispersionDate,
        leaseholders,
        listOfBricksOnSale,
        photos,
        propertyValueEstimate,
        rentalUnits
      };

      return new SecondaryMarketProperty(secondaryMarketPropertyData);
    } catch (error) {
      return this._handleError(error, SecondaryMarketPropertyError);
    }
  }

  async getPropertyTransactionalSummary(propertyId) {
    try {
      const { data } = await this._client.get(
        `/properties/${propertyId}/brickInvestments/summary`
      );

      const propertyTransactionalSummaryData = {
        ...data,
        latestBrickSaleDate: data?.latestBrickSaleDate
          ? new Date(data.latestBrickSaleDate)
          : null
      };

      return new TransactionalSummary(propertyTransactionalSummaryData);
    } catch (error) {
      return this._handleError(error, TransactionalSummaryError);
    }
  }

  async getCrowdfundingManagementLogs(propertyId) {
    try {
      const { data } = await this._legacyClient.get(
        `/properties/${propertyId}/crowdfundingManagementLogs`
      );

      return data.items
        ? data.items.map(managementLog => new PropertyLog(managementLog))
        : [];
    } catch (error) {
      return this._handleError(error, PropertyManagementLogsError);
    }
  }

  _handleError(error, APIError) {
    if (!error.response) {
      throw new NetworkError();
    }

    const { code } = error.response.data;

    throw new APIError(code);
  }
}

export default SecondaryMarketPropertyAPI;
