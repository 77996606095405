const formatTime = () => {
  const secondsToMinutesAndSeconds = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const paddedSeconds = remainingSeconds.toString().padStart(2, '0');
    const timeLabel = minutes ? 'minutos' : 'segundos';

    return `${minutes}:${paddedSeconds} ${timeLabel}`;
  };

  return { secondsToMinutesAndSeconds };
};

export default formatTime;
