import HundredBricksAPI from '../../HundredBricksAPI';
import { APIError } from '../../../api';
import InvalidPetitionerId from './InvalidPetitionerId';
import Petitioner from './Petitioner';
import PetitionerError from './PetitionerError';
import PetitionerIsNotRegistered from './PetitionerIsNotRegistered';
class PetitionerAPI extends HundredBricksAPI {
    async getPetitioner(petitionerId) {
        try {
            const { data } = await this.client.get(`v2/petitioners/${petitionerId}`);
            return new Petitioner(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_PETITIONER_ID':
                        throw new InvalidPetitionerId(petitionerId, { cause: error });
                    case 'PETITIONER_NOT_FOUND':
                        throw new PetitionerIsNotRegistered(petitionerId, { cause: error });
                    default:
                        throw new PetitionerError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new PetitionerError('Unexpected petitioner error', { cause: error });
            }
            else {
                throw new PetitionerError(`Unexpected petitioner error: '${error}'`);
            }
        }
    }
}
export default PetitionerAPI;
