class SecondFactorToken {
    expirationAt;
    constructor(data) {
        this.expirationAt = new Date(data.expiresAt);
    }
    isExpired() {
        const currentDate = new Date();
        return currentDate >= this.expirationAt;
    }
}
export default SecondFactorToken;
