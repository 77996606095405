const getColorSentence = ({ theme, color }) => {
  if (color) {
    const [splitOne, splitTwo] = color.split('-');

    if (splitTwo) return `color: ${theme.palette[splitOne][splitTwo]};`;

    return `color: ${theme.palette[splitOne]};`;
  }

  return '';
};

export default getColorSentence;
