class RealOwner {
    isRealOwner;
    hasOwnResources;
    realOwnerName;
    realOwnerBirthdate;
    constructor(data) {
        this.isRealOwner = data?.isRealOwner;
        this.hasOwnResources = data?.hasOwnResources;
        this.realOwnerName = data?.realOwnerName;
        this.realOwnerBirthdate = data?.realOwnerBirthdate;
    }
}
export default RealOwner;
