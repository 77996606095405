import InvestmentProfit from './InvestmentProfit';

// It should Refactor when update my account
class HistoricalInvestmentProfit extends InvestmentProfit {
  constructor(data) {
    super(data);

    this._accountProfit = data.accountProfit;
    this._capitalGainAmount = data.capitalGainAmount;
    this._leaseIncomeAmount = data.leaseIncomeAmount;
    this._salesProfitAmount = data.salesProfitAmount;
    this._rebateAmount = data.rebateAmount;
  }

  get accountProfitAmount() {
    return this._accountProfit.amount;
  }

  get accountProfitPercentage() {
    return this._accountProfit.percentage;
  }

  get leaseIncomeAmount() {
    return this._leaseIncomeAmount;
  }

  get capitalGainMoreSalesProfit() {
    return this._capitalGainAmount + this._salesProfitAmount;
  }

  get rebatesIncomeAmount() {
    return this._rebateAmount;
  }
}

export default HistoricalInvestmentProfit;
