class BricksToSell {
    bricksByProperty;
    constructor(properties) {
        this.bricksByProperty = properties;
    }
    hasAvailableBricks() {
        return this.bricksByProperty.some(property => property.numberOfBricks > 0);
    }
}
export default BricksToSell;
