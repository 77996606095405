import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import icAlert from '@assets/icons/ic-alert.svg';
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { ActionText, Button } from '@components';
import { CHROME, EDGE, FIREFOX, SAFARI } from '@assets/images/Logos';
import { DesktopMessageWrapper, DisclaimerContent, LocationBlockedModalWrapper, MobileMessageWrapper, NavigatorsSettingsImages } from './LocationBlockedContent.style';
const LOCATION_JUSTIFICATION_LINK = 'https://ayuda.100ladrillos.com/hc/es/articles/30942603032845'
    + '--Por-qu%C3%A9-solicitamos-que-compartas-tu-ubicaci%C3%B3n-al-iniciar-sesi%C3%B3n-en-100-'
    + 'Ladrillos';
const LocationBlockedContent = ({ onConfirm }) => (_jsxs(LocationBlockedModalWrapper, { children: [_jsxs("div", { children: [_jsx("img", { src: icAlert, alt: "Icono de alerta" }), _jsx(Subtitle, { children: "Permiso de ubicaci\u00F3n bloqueado" })] }), _jsxs("div", { children: [_jsx(MobileMessageWrapper, { children: _jsxs(Paragraph, { children: [_jsx(Bold, { children: "Es posible que tengas desactivada la ubicaci\u00F3n de tu celular desde el centro de control" }), ' o que ', _jsx(Bold, { children: "hayas seleccionado \u201CNo compartir ubicaci\u00F3n\u201D" }), ". Para acceder a tu cuenta en 100 Ladrillos, es necesario activar los permisos de ubicaci\u00F3n desde la configuraci\u00F3n de tu dispositivo y de tu navegador."] }) }), _jsx(DesktopMessageWrapper, { children: _jsx(Paragraph, { children: "Debido a que has seleccionado \u201CNo compartir ubicaci\u00F3n\u201D, para acceder a tu cuenta en 100 Ladrillos, es necesario activar los permisos de ubicaci\u00F3n desde la configuraci\u00F3n de tu navegador." }) }), _jsx(Bold, { children: "\u00BFC\u00F3mo activar el permiso de ubicaci\u00F3n?" }), _jsx(Paragraph, { children: "Elige tu navegador para ver c\u00F3mo activar los permisos de ubicaci\u00F3n en este sitio." }), _jsx(NavigatorsSettingsImages, { children: [
                        { img: CHROME, hash: 'h_01JA5XPT96PQ6A66RTT2T88NWZ' },
                        { img: SAFARI, hash: 'h_01JA5XR7Q6ZEQJTJHY2M546ASW' },
                        { img: FIREFOX, hash: 'h_01JA5YCBXZSN0KY8XFFTDS5XK9' },
                        { img: EDGE, hash: 'h_01JA5YFNKG55BQ32P28C7QZF7Y' }
                    ].map(({ img, hash }) => (_jsx(ActionText, { onClick: () => window.open(`${LOCATION_JUSTIFICATION_LINK}#${hash}`, '_blank'), children: _jsx("img", { src: img, alt: `Icono de ${img}` }) }, hash))) })] }), _jsx(Button, { rounded: true, onClick: onConfirm, children: " Continuar " }), _jsxs(DisclaimerContent, { children: [_jsx(Bold, { children: "\u00BFTienes dudas?" }), _jsx(ActionText, { color: "info", onClick: () => window.open(LOCATION_JUSTIFICATION_LINK, '_blank'), children: "Descubre por qu\u00E9 solicitamos tu ubicaci\u00F3n al iniciar sesi\u00F3n." })] })] }));
export default LocationBlockedContent;
