import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from 'bricks-ui/packages/bricks-ui';
import { CrossIcon } from 'bricks-ui/packages/bricks-ui-icons';
import Icon from '../Icon';
import ActionText from '../ActionText';
import { Paragraph } from '../Styled';
import { BannerMessage, HorizontalBanner } from './CompleteEnrollment.style';
const CompleteEnrollmentBanner = (props) => {
    const [isVisible, setIsVisible] = useState(true);
    const navigate = useNavigate();
    const dismissBanner = () => {
        setIsVisible(false);
    };
    if (!isVisible) {
        return null;
    }
    return (_jsxs(HorizontalBanner, { ...props, children: [_jsxs(BannerMessage, { children: [_jsx(Icon, { alt: "Informaci\u00F3n", name: "icInformation", height: 20, width: 20 }), _jsxs(Paragraph, { children: ['Completa tu perfil para empezar a comprar ladrillos en nuestra plataforma. ', _jsx(ActionText, { color: "info", onClick: () => navigate('/enrollment'), children: "Completa tu perfil." })] })] }), _jsx(IconButton, { onClick: dismissBanner, children: _jsx(CrossIcon, {}) })] }));
};
export default CompleteEnrollmentBanner;
