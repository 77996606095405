import styled, { css } from 'styled-components';

const AVAILABLE_COLORS = {
  success: css`
    background: ${({ theme }) => theme.palette.alternative6.lighter};
    color: ${({ theme }) => theme.palette.white};
    fill: ${({ theme }) => theme.palette.white};
  `,
  danger: css`
    background: ${({ theme }) => theme.palette.danger.darker};
    color: ${({ theme }) => theme.palette.white};
    fill: ${({ theme }) => theme.palette.white};
  `,
  warning: css`
    background: ${({ theme }) => theme.palette.warning.main};
    color: ${({ theme }) => theme.palette.white};
    fill: ${({ theme }) => theme.palette.white};
  `,
  info: css`
    background: ${({ theme }) => theme.palette.info.main};
    color: ${({ theme }) => theme.palette.white};
    fill: ${({ theme }) => theme.palette.white};
  `
};

const ToastContent = styled.div`
  ${({ type }) => AVAILABLE_COLORS[type]};

  align-items: center;
  animation-duration: 300ms;
  animation-name: slideUp;
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  min-height: ${({ theme }) => theme.spacing(7)};
  padding: ${({ theme }) => theme.spacing(1.5)};
  padding-right: ${({ theme }) => theme.spacing(6)};
  position: fixed;
  right: ${({ theme }) => theme.spacing(2)};
  top: ${({ theme }) => theme.spacing(10)};
  width: calc(100% - ${({ theme }) => theme.spacing(4)});
  z-index: 1000;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    width: ${({ theme }) => theme.spacing(60)};
    right: ${({ theme }) => theme.spacing(4)};
  }

  @keyframes slideUp {
    from {
      opacity: 0;
      transform: scale(0.85) translateY(-100%);
    }
    to {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
`;

const Close = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: ${({ theme }) => theme.spacing(0.5)};
  top: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(0.5)};
`;

export {
  Close,
  ToastContent
};
