import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Button from '../Button';

import './style.scss';

const ErrorPanel = props => {
  const { errorPanelAction } = props;

  return (
    <div styleName="error-panel">
      <Icon name="icSadFace" alt="Error en esta sección" styleName="error-image" />
      <p styleName="error-message">
        <span>Lo sentimos, algo salió mal mientras</span>
        <span>cargábamos la información.</span>
        <span styleName="pronounced-text">Intenta actualizando esta sección.</span>
      </p>
      <Button
        tid="error-panel-button"
        id="error-panel-button"
        onClick={errorPanelAction}
        color="secondary"
      >
        Actualizar sección
      </Button>
    </div>
  );
};

ErrorPanel.propTypes = {
  errorPanelAction: PropTypes.func.isRequired
};

export default ErrorPanel;
