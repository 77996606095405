import styled, { css } from 'styled-components';

const Step = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.palette.background.hbBackgroundA};
  padding: 0  0 0 ${({ theme }) => theme.spacing(3)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StepContent = styled.div`
  padding: 0;
  flex: 1;
`;

const StepHeader = styled.h3`
  ${({ theme }) => theme.typography.heading(3)};

  display: block;
  margin: 0;
  padding: 0;
`;

const StepperIndicator = styled.div`
  position: relative;
  box-sizing: border-box;
  min-height: 1.7em;

  & * {
    box-sizing: border-box;
  }

  .step-number,
  .indicator-content {
    transition: border-color 0.4s ease-in-out, color 0.4s ease-in-out;
  }

  .step-number {
    ${({ theme }) => theme.typography.font()}

    position: absolute;
    left: 0;
    top: 0;

    align-items: center;
    background-color: ${({ theme }) => theme.palette.background.hbBackgroundA};
    display: flex;
    justify-content: center;
    width: 1.7em;
    height: 1.7em;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.palette.grayC};
  }

  .indicator-content {
    ${({ theme }) => theme.typography.font()};

    color: ${({ theme }) => theme.palette.disabled.contrast};
    word-break: break-word;
    margin-left: calc(((1.7em / 2) - 1px));
    border-left: 2px solid ${({ theme }) => theme.palette.grayC};
    padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(1)} 40px ${theme.spacing(3)}`};
  }

  ${({ isLastStepIndicator }) => isLastStepIndicator && css`
    .indicator-content {
      border: none;
      padding-bottom: 0;
    }
  `};

  ${({ isCompleted }) => isCompleted && css`
    .step-number {
      border-color: ${({ theme }) => theme.palette.alternative1.darker};
    }
  `};

  ${({ isActive }) => isActive && css`
    .step-number {
      border-color: ${({ theme }) => theme.palette.alternative1.darker};
    }

    .indicator-content {
      ${({ theme }) => theme.typography.font()};

      font-weight: 700;
    }
  `};
`;

const StepperLayout = styled.div`
  background-color: ${({ theme }) => theme.palette.background.hbBackgroundA};
  border: 1px solid ${({ theme }) => theme.palette.grayC};
  border-radius: ${({ theme }) => theme.borderRadius()};
  box-shadow: 0 0 6px 0 rgba(${({ theme }) => theme.getRgbColor(theme.palette.grayB)}, 0.05);
  box-sizing: border-box;
  display: flex;
  min-height: 520px;
  max-width: ${({ theme }) => theme.breakpoints.extraLarge}px;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(3)}`};
  width: 100%;

  & * {
    box-sizing: border-box;
  }

  .stepper-indicator-list {
    width: 208px;
    border-right: 1px solid ${({ theme }) => theme.palette.grayC};
  }

  .indicators-wrapper {
    position: relative;
  }
`;

export {
  Step,
  StepContent,
  StepHeader,
  StepperIndicator,
  StepperLayout
};
