import styled from 'styled-components';

import { Text } from '@components';

const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.palette.danger.main};
  margin: 0;
  text-align: center;
`;

const ImageGridWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(3)};
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  margin: ${({ theme }) => theme.spacing(2)} 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px){
    gap: ${({ theme }) => theme.spacing(4)};
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ImageButton = styled.button`
  border-radius: ${({ theme }) => theme.borderRadius()};
  border: none;
  border: ${({ isActive, theme }) => (isActive &&
    `${theme.spacing(0.5)} solid ${theme.palette.primary.lighter}`
  )};
  cursor: pointer;
  height: ${({ theme }) => theme.spacing(14)};
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: all 200ms ease;
  width: ${({ theme }) => theme.spacing(14)};

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const GifLoader = styled.span`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;

  > img {
    height: ${({ theme }) => theme.spacing(4)};
    width: ${({ theme }) => theme.spacing(4)};
  }
`;

export {
  ErrorText,
  GifLoader,
  ImageButton,
  ImageGridWrapper
};
