class CurrentFundsBalance {
    totalNumberOfBricks;
    currentBalancePerBrick;
    currentBalance;
    constructor(data) {
        this.totalNumberOfBricks = data.totalNumberOfBricks;
        this.currentBalancePerBrick = data.currentBalancePerBrick;
        this.currentBalance = data.currentBalance;
    }
}
export default CurrentFundsBalance;
