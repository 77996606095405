import React from 'react';
import styled from 'styled-components';

import Card from '../Card';

const CardComponent = Card;

const CardWrapper = styled(props => <CardComponent {...props} />)`
  height: 80vh;
  margin: ${({ theme }) => theme.spacing(4)} 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    margin: ${({ theme }) => theme.spacing(4)};
  }
`;

export default CardWrapper;
