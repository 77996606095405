class Contract {
    type;
    file;
    constructor(type, file) {
        this.type = type;
        this.file = file;
    }
    get contract() {
        throw new Error('This is an abstract class, it should not implement methods');
    }
    get isSigned() {
        throw new Error('This is an abstract class, it should not implement methods');
    }
}
export default Contract;
