const DATE_OPTIONS = {
  dateStyle: 'long'
};

const formatDate = () => {
  const ISO8601 = date => {
    if (!date) return undefined;

    return date.toISOString().slice(0, 10);
  };

  const dateToSpanish = date => {
    if (!date) return undefined;

    return date.toLocaleDateString('es-MX', DATE_OPTIONS);
  };

  return { dateToSpanish, ISO8601 };
};

export default formatDate;
