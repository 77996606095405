import { useMemo, useState } from 'react';

import { regularExpressions as regex } from '@utils';

const isEmptyObject = object => Object.keys(object).length === 0;

const getObjectType = value => {
  let objectType = Object.prototype.toString.call(value);

  objectType = objectType.replace(regex.squareBrackets, '');

  const [, typeData] = objectType.split(' ');

  return typeData;
};

const isInRange = (value, minValue, maxValue) => {
  let inRange = true;

  if (minValue && value < minValue) {
    inRange = false;
  }

  if (maxValue && value > maxValue) {
    inRange = false;
  }

  return inRange;
};

const getValue = event => {
  if (event.target.type === 'checkbox') {
    return event.target.checked;
  }

  return event.target.value;
};

const hasSchemaRules = rules => rules && !isEmptyObject(rules);

const hasSpecialRule = specialRule => specialRule && typeof specialRule === 'function';

const isRequiredValueEmpty = (requiredRule, value) => {
  if (!requiredRule) {
    return false;
  }

  if (!value) {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (getObjectType(value) === 'Date' && !Number.isNaN(value)) {
    return false;
  }

  if (getObjectType(value) === 'Object' && isEmptyObject(value)) {
    return true;
  }

  return false;
};

const hasInvalidFormat = (formatRule, value) => formatRule && !formatRule.pattern.test(value);

const hasInvalidLength = (lengthRule, value) => lengthRule
  && !isInRange(value.replace(/ /g, '').length, lengthRule.min, lengthRule.max);

const useForm = ({ initialValues = {}, schema }) => {
  if (typeof schema !== 'object') {
    throw new Error(`Invalid schema. Expected object, got ${typeof schema}`);
  }

  const [activeSchema, setActiveSchema] = useState(schema);
  const [form, setForm] = useState({ values: initialValues, errors: {} });

  const isCompleted = () => Object.entries(activeSchema).every(
    ([field, rules]) => !isRequiredValueEmpty(rules.required, form.values[field])
  );

  const hasErrors = () => Object.keys(form.errors).length > 0;

  const validateField = (value, rules) => {
    if (hasSchemaRules(rules)) {
      const {
        format,
        length,
        required,
        validator
      } = rules;

      if (hasSpecialRule(validator)) {
        return validator(value, form.values, form.errors);
      }

      if (isRequiredValueEmpty(required, value)) {
        return required.message;
      }

      if (hasInvalidLength(length, value)) {
        return length.message;
      }

      if (hasInvalidFormat(format, value)) {
        return format.message;
      }
    }

    return '';
  };

  const validateForm = () => {
    const errors = {};

    Object.entries(activeSchema).forEach(([field, rules]) => {
      const value = form.values[field];

      const message = validateField(value, rules);

      if (message) {
        errors[field] = message;
      }
    });

    return errors;
  };

  const addError = ({ field, message }) => {
    const { values, errors } = form;

    setForm({ values, errors: { ...errors, [field]: message } });

    validateField(field, form.values[field]);
  };

  const removeError = field => {
    const { values, errors } = form;

    delete errors[field];

    setForm({ values, errors });

    validateField(field, form.values[field]);
  };

  const resetValues = formValues => {
    const newInitialValues = formValues && !isEmptyObject(formValues) ? formValues : initialValues;

    const values = { ...form.values };
    const errors = { ...form.errors };

    Object.entries(newInitialValues).forEach(([field, value]) => {
      values[field] = value;

      if (errors[field]) {
        delete errors[field];
      }
    });

    setForm({ values: { ...values }, errors: { ...errors } });
  };

  const isValid = () => isCompleted() && isEmptyObject(validateForm()) && !hasErrors();

  const handleChange = event => {
    const { name: field } = event.target;
    const { values, errors } = form;

    if (errors && errors[field]) {
      delete errors[field];
    }

    setForm({ values: { ...values, [field]: getValue(event) }, errors });
  };

  const handleBlur = event => {
    const { name: field } = event.target;
    const { values, errors } = form;

    const value = getValue(event);
    const message = validateField(value, activeSchema[field]);

    if (message) {
      errors[field] = message;
    } else {
      delete errors[field];
    }

    setForm({ values: { ...values, [field]: value }, errors });
  };

  const handleSubmit = onSubmit => async event => {
    event.preventDefault();

    const errors = validateForm();

    if (!isEmptyObject(errors)) {
      setForm({ ...form, errors });
      return;
    }

    await onSubmit(form.values);
  };

  const fieldProps = field => {
    const props = {
      name: field,
      required: !!activeSchema[field]?.required,
      error: !!form.errors[field],
      onBlur: handleBlur,
      onChange: handleChange
    };

    if (props.error) {
      props.helperText = form.errors[field];
    }

    return props;
  };

  const updateSchema = newSchema => {
    setActiveSchema(newSchema);
  };

  const useFormInterface = {
    values: form.values,
    errors: form.errors,
    isValid,
    isCompleted,
    fieldProps,
    addError,
    removeError,
    resetValues,
    handleSubmit,
    updateSchema
  };

  return useMemo(() => useFormInterface, [form, activeSchema]);
};

export default useForm;
