import { InvalidStatusCode } from './errors';
class Response {
    status;
    headers;
    data;
    constructor(status, headers, data) {
        if (status < 100 || status >= 600) {
            throw new InvalidStatusCode(status);
        }
        this.status = status;
        this.headers = headers;
        this.data = data;
    }
}
export default Response;
