import Event from '../../entities/Event';
import EventsReporter from '../EventsReporter';
class InvoiceEventReporter extends EventsReporter {
    startingAutomaticInvoice(rfc = false, fiscalRegime = false, fiscalZipCode = false) {
        const event = new Event('Invoicing - Auto Invoice - Activation - Start', { rfc, fiscalRegime, fiscalZipCode });
        this._insightsService.sendEvent(event);
    }
    automaticInvoiceStep(pageName) {
        const event = new Event(`Invoicing - Auto Invoice - Page View - ${pageName} Page`);
        this._insightsService.sendEvent(event);
    }
    automaticInvoiceFails(failureReason) {
        const event = new Event('Invoicing - Auto Invoice - Activation - Fail', { failureReason });
        this._insightsService.sendEvent(event);
    }
}
export default InvoiceEventReporter;
