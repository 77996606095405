class NetworkError extends Error {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args) {
        if (args.length === 2) {
            super(`NetworkError: ${args[0]}`, args[1]);
        }
        else if (args.length === 1 && typeof args[0] === 'string') {
            super(`NetworkError: ${args[0]}`);
        }
        else {
            super('NetworkError', ...args);
        }
    }
}
export default NetworkError;
