import styled from 'styled-components';

const TransactionTemplateWrapper = styled.div`
  align-items: center;
  background: linear-gradient(251.48deg, #F0E1FF -5.41%, #D3EFEE 108.54%);
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4.5)};
  justify-content: space-around;
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(3)};
  text-align: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    width: 50%;
  }
`;

export { TransactionTemplateWrapper };
