import styled, { css } from 'styled-components';

import Button from '../Button';

const UpgradeAccountButton = styled(Button)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => css`{
    position: relative;

    ::before, ::after {
      background-color: ${theme.palette.warning.light};
      border-radius: ${theme.borderRadius(4)};
      content: '';
      display: block;
      height: ${theme.spacing(1)};
      position: absolute;
      right: 0;
      top: 0;
      width: ${theme.spacing(1)};
    }

    ::after {
      animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    }

    @keyframes ping {
      75%, 100% {
        transform: scale(2);
        opacity: 0;
      }
    }
  }`}
  
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

export { UpgradeAccountButton };
