import { useEffect } from 'react';
import { LocalStoragePlatformID } from '@lib/storage';
import { useProfile } from '@lib/profile';
const Segmenter = (props) => {
    const { profile, investorBalance } = useProfile();
    const { investorSegmenter } = props;
    const idStorage = new LocalStoragePlatformID();
    useEffect(() => {
        if (profile?.id) {
            investorSegmenter.setTag('id', profile.id);
            idStorage.set(profile);
        }
        else {
            investorSegmenter.clearTags();
            idStorage.clear();
        }
    }, [investorSegmenter, profile?.id]);
    useEffect(() => {
        profile?.segmentations.forEach(segment => {
            investorSegmenter.setTag(segment, true);
        });
    }, [profile?.segmentations]);
    useEffect(() => {
        if (investorBalance) {
            investorSegmenter.setTag('hasFunds', investorBalance.available > 0);
            investorSegmenter.setTag('isBuyingForTheFirstTime', investorBalance.investedInBricks === 0);
            investorSegmenter.setTag('hasBricks', investorBalance.investedInBricks > 0 || investorBalance.crowdfunded > 0);
        }
    }, [investorBalance]);
    return null;
};
export default Segmenter;
