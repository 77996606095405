import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import Title from '../Title';
import {
  ActionButtons,
  ContentWrapper,
  TextModal
} from '../SignW8BENContractModal/SignW8BENContractModal.style';

const UpgradeAccountToW8ContractModal = ({ isOpen, onClose }) => {
  /** The dependency below is causing dependency cycles, for this reason the dependency is loaded
    * via require. Please, always avoid the use of require() calls in any other place under this
    * codebase unless you have a good reason to do so.
    *
    * We need to move the api module to the @lib module in order to remove the dependency cycle
    */
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const { useProfile } = require('@lib/profile');

  const { profile, enrollment } = useProfile();
  const navigate = useNavigate();

  const handleNavigateToUpgradeAccount = () => {
    if (profile.status.isRestricted()) {
      navigate('/upgrade-account/benefits');
      return;
    }

    if (profile.status.isUpgradingAccount()) {
      if (enrollment?.completedActivities.length) {
        navigate('/upgrade-account');
        return;
      }

      navigate('/confirm-account-data');
      return;
    }

    if (profile.status.isValidatingDocuments()) {
      navigate('/perfil/account-status');
      return;
    }

    navigate('/perfil');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ContentWrapper>
        <Title>
          ¡Mejora tu cuenta para poder invertir en propiedades de Estados Unidos!
        </Title>

        <Icon
          name="icUpgradeAccountModal"
          alt="Mejora tu cuenta"
          width={80}
          height={80}
        />

        <TextModal>
          Para poder invertir en Ladrillos de propiedades en Estados Unidos, es
          necesario que mejores tu cuenta.
        </TextModal>

        <TextModal>
          Una vez que hayas mejorado tu cuenta, firma tu formulario W-8BEN y estarás listo para
          convertirte en un inversionista inmobiliario internacional.
        </TextModal>
      </ContentWrapper>

      <ActionButtons>
        <Button id="upgrade-account-button" onClick={handleNavigateToUpgradeAccount}>
          Mejorar mi cuenta
        </Button>
      </ActionButtons>
    </Modal>
  );
};

UpgradeAccountToW8ContractModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default UpgradeAccountToW8ContractModal;
