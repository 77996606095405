import styled from 'styled-components';

export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};

  > div {
    width: 100%;
  }
`;
