import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Paragraph, Subtitle } from '@components/Styled';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const SalesOrder = (props) => {
    const { notification } = props;
    const property = notification.params?.property;
    const propertyName = property?.name || '----';
    const brickText = notification.params?.numberOfBricks === 1
        ? 'Ladrillo'
        : 'Ladrillos';
    return (_jsxs(NotificationContainer, { children: [_jsxs(Subtitle, { level: "3", children: ["Has puesto a la venta", ` ${notification.params?.numberOfBricks} ${brickText} `, "en", ` ${propertyName}`] }), _jsx(Paragraph, { children: `Tu oferta de ${brickText} estará activa durante 15 días.` }), _jsx(Paragraph, { children: "Si se vende, te notificaremos con todos los detalles de la transacci\u00F3n. Si no se vende en este plazo, la oferta caducar\u00E1, pero siempre podr\u00E1s crear una nueva si as\u00ED lo deseas." }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default SalesOrder;
