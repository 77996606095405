import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, Modal } from 'bricks-ui/packages/bricks-ui';
import { useNavigate } from 'react-router-dom';

import PendingSignRequest from '../../contracts/PendingSignRequest';
import RenderIf from '../../components/RenderIf';
import useProfile from '../../lib/profile/useProfile';
import KeepOnUpdateLimitlessAccount from '../../components/KeepOnUpdateLimitlessAccount';

import {
  UpgradeAccountInconsistentData,
  UpgradeAccountTransaction,
  UpgradeAccountValidatingDocuments
} from './components';
import { KeepUpdateLimitlessAccountWrapper } from './UpgradeAccount.styled';

const bodyInformationToPurchase = 'Mejora tu cuenta para que puedas invertir sin límites.';

const bodyInformationToSell = 'Actualmente has alcanzado los límites de inversión permitidos. '
  + 'Mejora tu cuenta para que puedas vender los Ladrillos que desees.';

const useUpgradingAccount = () => {
  const navigate = useNavigate();
  const { profile, enrollment } = useProfile();

  const renderInvestorPlansModal = (modalType = 'purchase') => {
    const container = document.getElementById('leavingAppModal');
    const modalElement = ReactDOM.createRoot(container);

    const closeModal = () => {
      modalElement.unmount();
    };

    const goToMarketplace = () => {
      closeModal();
      navigate('/marketplace');
    };

    const handleUpgradeRedirection = () => {
      closeModal();
      navigate('/upgrade-account/benefits');
    };

    const handleKeepUpgradeRedirection = () => {
      closeModal();
      if (enrollment?.completedActivities.length) {
        navigate('/upgrade-account');
        return;
      }

      navigate('/confirm-account-data');
    };

    const goToConfirmAccountData = () => {
      closeModal();
      navigate('/confirm-account-data');
    };

    const goToSignNewCommercialContract = () => {
      closeModal();
      navigate('/sign-new-commercial-contract-version');
    };

    modalElement.render(
      <ThemeProvider>
        <Modal onClose={closeModal} isOpen>
          {profile?.pendingSignRequests?.hasRequests() ? (
            <PendingSignRequest
              onCancel={closeModal}
              onConfirm={goToSignNewCommercialContract}
            />
          ) : (
            <Fragment>
              <RenderIf condition={profile.status.isRestricted()}>
                <RenderIf condition={modalType === 'purchase'}>
                  <UpgradeAccountTransaction
                    title="Alcanzaste el límite de inversión"
                    bodyInformation={bodyInformationToPurchase}
                    onRequestUpgrade={handleUpgradeRedirection}
                  />
                </RenderIf>

                <RenderIf condition={modalType === 'sell'}>
                  <UpgradeAccountTransaction
                    title="Para vender Ladrillos es necesario mejorar tu cuenta"
                    bodyInformation={bodyInformationToSell}
                    onRequestUpgrade={handleUpgradeRedirection}
                  />
                </RenderIf>
              </RenderIf>

              <RenderIf condition={profile.status.isUpgradingAccount()}>
                <KeepUpdateLimitlessAccountWrapper>
                  <KeepOnUpdateLimitlessAccount
                    onRequestUpgrade={handleKeepUpgradeRedirection}
                    profile={profile}
                    showIllustration
                  />
                </KeepUpdateLimitlessAccountWrapper>
              </RenderIf>

              <RenderIf condition={profile.status.isCorrectingData()}>
                <UpgradeAccountInconsistentData
                  onRedirect={goToConfirmAccountData}
                />
              </RenderIf>

              <RenderIf condition={profile.status.isValidatingDocuments()}>
                <UpgradeAccountValidatingDocuments
                  onClose={closeModal}
                  onRedirect={goToMarketplace}
                />
              </RenderIf>
            </Fragment>
          )}
        </Modal>
      </ThemeProvider>
    );
  };

  return { renderInvestorPlansModal };
};

export default useUpgradingAccount;
