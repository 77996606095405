import EnrollmentError from './EnrollmentError';

const INVALID_BIRTHDATE = 'INVALID_BIRTHDATE';
const INVALID_BIRTH_COUNTRY = 'INVALID_BIRTH_COUNTRY';
const INVALID_BIRTH_STATE = 'INVALID_BIRTH_STATE';
const INVALID_CURP = 'INVALID_CURP';
const INVALID_GENDER = 'INVALID_GENDER';
const INVALID_NATIONALITY = 'INVALID_NATIONALITY';
const CURP_ALREADY_REGISTERED = 'CURP_ALREADY_REGISTERED';
const INVESTOR_INVALID_STATE = 'INVESTOR_INVALID_STATE';
const INVESTOR_IS_UNDERAGE = 'INVESTOR_IS_UNDERAGE';

class CorrectDataError extends EnrollmentError {
  constructor(code, details = null) {
    super(code, 'CorrectDataError', details);
  }

  isInvalidBirthdate() {
    return this._code === INVALID_BIRTHDATE;
  }

  isInvalidBirthCountry() {
    return this._code === INVALID_BIRTH_COUNTRY;
  }

  isInvalidBirthState() {
    return this._code === INVALID_BIRTH_STATE;
  }

  isInvalidCurp() {
    return this._code === INVALID_CURP;
  }

  isInvalidGender() {
    return this._code === INVALID_GENDER;
  }

  isInvalidNationality() {
    return this._code === INVALID_NATIONALITY;
  }

  isCURPAlreadyRegistered() {
    return this._code === CURP_ALREADY_REGISTERED;
  }

  isInvalidInvestorState() {
    return this._code === INVESTOR_INVALID_STATE;
  }

  isInvestorUnderAge() {
    return this._code === INVESTOR_IS_UNDERAGE;
  }
}

export default CorrectDataError;
