// Docs for IntersectionObserver browser API https://developer.mozilla.org/es/docs/Web/API/Intersection_Observer_API

const EXPECTED_INTERSECTION_RATIO = 1;
const DEFAULT_ROOT = null;
const DEFULT_ROOT_MARGIN = '0px';
const DEFAULT_THRESHOLD = [0, 0.2, 0.4, 0.6, 0.8, 1];

function VisibilityObserver(handler, options = {}) {
  const {
    root,
    rootMargin,
    threshold,
    type = 'observing',
  } = options;
  const observerOptions = {
    root: root || DEFAULT_ROOT,
    rootMargin: rootMargin || DEFULT_ROOT_MARGIN,
    threshold: threshold || DEFAULT_THRESHOLD,
  };

  const onObserve = entries => {
    const [entry] = entries;
    const { target: element, intersectionRatio } = entry;
    const isElementVisible = intersectionRatio >= EXPECTED_INTERSECTION_RATIO;

    if (isElementVisible) {
      handler(element);
    }
  };

  const onIntersection = entries => {
    const entry = entries[0];
    const { isIntersecting } = entry;

    handler(isIntersecting);
  };

  const getTypeCallback = () => (type === 'intersecting' ? onIntersection : onObserve);

  this.observer = new IntersectionObserver(getTypeCallback(), observerOptions);
  return this.observer;
}

export default VisibilityObserver;
