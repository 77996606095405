/* eslint-disable max-classes-per-file */
class LegacyStatus {
  constructor(name) {
    this._name = name;
  }

  get name() {
    return this._name;
  }
}

/** WARNING: This class was created just for legacy support purposes, it not intened to be used
  * on new code. Please limit the maintenance effort here, this code should be removed as soon as
  * the code dependencies on Legacy are low.
  */
class LegacyEnrollmentProfile {
  constructor(data, profile, enrollmentStatus) {
    this._data = data;
    this._profile = profile;
    this._enrollmentStatus = enrollmentStatus || new LegacyStatus(data.status);
  }

  // This logic was taken from legacy code, do not try to understand this
  isInValidation() {
    if (this.isRealOwner) {
      return this.isLegacyProfileCompleted() && this.isRealOwner;
    }

    return this.isLegacyProfileCompleted() && !this.isRealOwner && this.isRealOwner !== null;
  }

  isTransactionalProfileCompleted() {
    return this.isLegacyProfileCompleted() && this.transactionalProfile?.isCompleted;
  }

  isLegacyProfileCompleted() {
    if (!['production', 'staging'].includes(process.env.TARGET_ENV)) {
      /** We should avoid to use the of this method on this file or any other file around. This
        * method was created to enable compatibility with the previus enrollment process, now that
        * the legacy enrollment process does not exists anymore, this should be removed.
        */

      // eslint-disable-next-line no-console
      console.warn('DEPRECATED: Please avoid use of legacy profile completed');
    }

    return Boolean(this.status) && !(this.status === 'incomplete' || this.status === 'registering');
  }

  get status() {
    return this._enrollmentStatus.name;
  }

  get id() {
    return this._profile.id;
  }

  get fullName() {
    return this._profile.fullName;
  }

  get names() {
    return this._data.names;
  }

  get firstLastName() {
    return this._data.firstLastName;
  }

  get secondLastName() {
    return this._data.secondLastName;
  }

  get isNaturalPerson() {
    return this._data.isNaturalPerson;
  }

  get taxId() {
    return this._data.taxId;
  }

  get email() {
    return this._profile.email?.address;
  }

  get alternateEmail() {
    return this._data.alternateEmail;
  }

  get phone() {
    return this._profile.phone?.number;
  }

  get phoneNumber() {
    return this._data.phone;
  }

  get alternatePhone() {
    return this._data.alternatePhone;
  }

  get address() {
    return this._data.address;
  }

  get addressProof() {
    return this._data.addressProof;
  }

  get riskForm() {
    return this._data.riskForm;
  }

  get transactionalProfile() {
    return this._data.transactionalProfile;
  }

  get consecutiveNumber() {
    return this._data.consecutiveNumber;
  }

  get clabe() {
    return this._data.clabe;
  }

  get curp() {
    return this._profile.curp;
  }

  get curpValue() {
    return this._data.curp;
  }

  get jobTitle() {
    return this._data.jobTitle;
  }

  get maritalStatus() {
    return this._data.maritalStatus;
  }

  get hasForeignFiscalObligations() {
    return this._data.hasForeignFiscalObligations;
  }

  get foreignFiscalObligationsCountry() {
    return this._data.foreignFiscalObligationsCountry;
  }

  get foreignFiscalObligationsTaxpayerId() {
    return this._data.foreignFiscalObligationsTaxpayerId;
  }

  get isRealOwner() {
    return this._profile.realOwner.isRealOwner;
  }

  get realOwnerName() {
    return this._profile.realOwner.realOwnerName;
  }

  get birthdate() {
    return this._profile.generalData.birthdate;
  }

  get birthCountry() {
    return this._profile.generalData.birthCountry;
  }

  get birthState() {
    return this._profile.generalData.birthState;
  }

  get gender() {
    return this._profile.generalData.gender;
  }

  get nationality() {
    return this._profile.generalData.nationality;
  }

  get identification() {
    return this._data.identification;
  }

  get kyc() {
    return this._data.kyc;
  }

  get commercialContract() {
    return this._data.commercialContract;
  }

  get hasOwnResources() {
    return this._data.hasOwnResources;
  }

  get fiscalRegime() {
    return this._data.fiscalRegime;
  }

  get createdAt() {
    return this._data.createdAt;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }

  isLegalPerson() {
    return false;
  }
}

export default LegacyEnrollmentProfile;
