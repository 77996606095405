const SUBSCRIBER_NUMBER_LENGTH = 10;
class Phone {
    number;
    country;
    isVerified;
    constructor(data) {
        this.number = data?.number;
        this.country = data?.country;
        this.isVerified = data?.isVerified;
    }
    get countryLada() {
        if (!this.number)
            return null;
        // If phone number is 10 digits, then we assume that country lada is not set
        if (!this.hasNumber() || this.number.length <= SUBSCRIBER_NUMBER_LENGTH) {
            return null;
        }
        // Return first n characters, removing last 10 phone number digits
        return this.number.slice(0, this.number.length - SUBSCRIBER_NUMBER_LENGTH);
    }
    get subscriberNumber() {
        if (!this.number)
            return null;
        if (!this.hasNumber()) {
            return null;
        }
        if (this.number.length <= SUBSCRIBER_NUMBER_LENGTH) {
            return this.number;
        }
        // Return last 10 characters, removing first n digits from country code
        return this.number.slice(this.number.length - SUBSCRIBER_NUMBER_LENGTH);
    }
    hasNumber() {
        return !!this.number;
    }
}
export default Phone;
