import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Money } from '@types';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const InvoicedIncomeRelease = (props) => {
    const { notification } = props;
    const amountReceived = new Money(notification.params?.amountReceived);
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Ingresos liberados con factura" }), _jsxs(Paragraph, { children: ["Has liberado", _jsx(Bold, { children: ` ${amountReceived.toString()} ` }), "de ingresos con factura. \u00A1Tu dinero ya est\u00E1 disponible en tu cuenta para transferirlos a tu banco o reinvertirlos en m\u00E1s Ladrillos!."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default InvoicedIncomeRelease;
