import React, {
  useEffect,
  useState,
  useRef,
  useCallback
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Icon from '../Icons';
import RenderIf from '../RenderIf';
import Button from '../IconButton';

import StyledLightbox from './Lightbox.style';

const KEY_CODES = {
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ESCAPE: 'Escape'
};

const Lightbox = props => {
  const {
    children,
    autoPlay,
    displayArrows,
    displayProgress,
    slides,
    enableKeyboard,
    onClose,
    intervalTime,
    isOpen,
    ...rest
  } = props;

  const [direction, setDirection] = useState('left');
  const [isDragging, setDragging] = useState(false);
  const [startClientX, setStartX] = useState(0);
  const [distanceSwiped, setDistanceSwiped] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const wrapper = useRef();

  const totalSlides = slides.length > 0 ? slides.length : React.Children.count(children);
  const carouselTotalSlides = totalSlides - 1;
  const hasNextSlide = currentSlide < carouselTotalSlides;
  const hasPreviousSlide = currentSlide > 0;

  const handleClose = () => {
    setCurrentSlide(0);
    onClose();
  };

  const nextSlide = () => {
    if (hasNextSlide) {
      setCurrentSlide(current => current + 1);
    }
  };

  const loopSlides = () => {
    setCurrentSlide(current => (current + 1) % carouselTotalSlides);
  };

  const previousSlide = () => {
    if (hasPreviousSlide) {
      setCurrentSlide(current => current - 1);
    }
  };

  const next = () => {
    nextSlide();
    setDirection('left');
  };

  const prev = () => {
    previousSlide();
    setDirection('right');
  };

  const handleKeydown = useCallback(event => {
    const { key } = event;

    if (key === KEY_CODES.ESCAPE) {
      handleClose();
    }
  }, []);

  useEffect(() => {
    let interval = null;

    if (autoPlay) {
      interval = setInterval(loopSlides, intervalTime);
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (enableKeyboard) {
      document.addEventListener('keydown', handleKeydown);
    }

    return () => document.addEventListener('keydown', handleKeydown);
  }, [handleKeydown, enableKeyboard]);

  const startSwipe = event => {
    const startingClientX = event.touches ? event.touches[0].pageX : event.clientX;
    setStartX(startingClientX);
    setDragging(true);
  };

  const endSwipe = () => {
    const width = ((wrapper && wrapper.current.clientWidth) || 0) / slides.length;

    if (Math.abs(distanceSwiped) / width > 0.2) {
      if (distanceSwiped < 0) {
        next();
      } else {
        prev();
      }
    }

    setDragging(false);
    setStartX(0);
    setDistanceSwiped(0);
  };

  const swipe = event => {
    const clientX = event.touches ? event.touches[0].pageX : event.clientX;

    if (!isDragging) {
      return;
    }

    const distance = clientX - startClientX;

    if (!autoPlay && !hasNextSlide && distance < 0) {
      return;
    }

    if (!autoPlay && !hasPreviousSlide && distance > 0) {
      return;
    }

    setDistanceSwiped(distance);
  };

  const classSlide = `showing-${direction}`;

  const renderChildrens = () => React.Children.map(children, (child, i) => {
    const className = `slide ${currentSlide === i ? classSlide : ''}`;

    if (React.isValidElement(child)) {
      return React.cloneElement(<div />, {
        className
      }, child);
    }

    return child;
  });

  return isOpen && ReactDOM.createPortal(
    <StyledLightbox
      {...rest}
      dir="ltr"
      onMouseDown={startSwipe}
      onMouseMove={swipe}
      onMouseUp={endSwipe}
      onTouchStart={startSwipe}
      onTouchMove={swipe}
      onTouchEnd={endSwipe}
      onTouchCancel={endSwipe}
      ref={wrapper}
      role="dialog"
      aria-label="Visor de fotos"
    >
      <div className="lightbox-container">
        <div className="lightbox-close">
          <Button disableFocus onClick={handleClose} aria-label="Cerrar">
            <Icon.CrossIcon color="white" />
          </Button>
        </div>
        <RenderIf condition={displayArrows}>
          <div className="arrows">
            <Button disableFocus onClick={prev} aria-label="Anterior">
              <RenderIf condition={hasPreviousSlide}>
                <Icon.LeftArrowIcon color="white" aria-hidden="true" role="img" />
              </RenderIf>
            </Button>
            <RenderIf condition={hasNextSlide}>
              <Button disableFocus onClick={next} aria-label="Siguiente">
                <Icon.RightArrowIcon color="white" aria-hidden="true" role="img" />
              </Button>
            </RenderIf>
          </div>
        </RenderIf>
        <div className="ligthbox-inner">
          <div className="slides">
            <RenderIf condition={totalSlides}>
              {slides?.map((slide, i) => (
                <figure
                  key={slide.id}
                  className={`slide ${currentSlide === i ? classSlide : ''}`}
                >
                  <img className="img" src={slide} alt="Imagen de propiedad" />
                </figure>
              ))}
            </RenderIf>
            <RenderIf condition={Boolean(children)}>
              {renderChildrens()}
            </RenderIf>
          </div>
        </div>
        <RenderIf condition={displayProgress}>
          <h3
            className="ligthbox-progress"
            tabIndex="-1"
            aria-live="polite"
            aria-atomic="true"
          >
            <span aria-label="Actual">{currentSlide + 1}</span>
            /
            <span aria-label="Total">{totalSlides}</span>
          </h3>
        </RenderIf>
      </div>
    </StyledLightbox>,
    document.body
  );
};

Lightbox.propTypes = {
  /**
   * To indicate if arrows of navitagion is displayed
   */
  displayArrows: PropTypes.bool,

  /**
   * To display current slide of total slides
   */
  displayProgress: PropTypes.bool,

  /**
   * Change the slide automatically and going after the last item will move back to the first slide.
   */
  autoPlay: PropTypes.bool,

  /**
   * Allow use keyboard to close Carousel
   */
  enableKeyboard: PropTypes.bool,

  /**
   * Set to true for open lightbox
   */
  isOpen: PropTypes.bool,

  /**
   * Array of images to display on carousel
   */
  slides: PropTypes.arrayOf(PropTypes.string),

  /**
   * Function to close lightbox
   */
  onClose: PropTypes.func,

  /**
   * Time interval to change slides automatically if in autoplay
   */
  intervalTime: PropTypes.number,

  /**
   * The content of component
   */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Lightbox.defaultProps = {
  autoPlay: false,
  displayArrows: true,
  displayProgress: false,
  slides: [],
  enableKeyboard: false,
  onClose: () => { },
  children: null,
  intervalTime: 2000,
  isOpen: false
};

export default Lightbox;
