import { NotificationsError } from '@api/errors';
import { Notification, Notifications, Pagination, UnseenNotifications } from '@api/entities';
import { getAsErrorData, getAsRequestError, isRequestError } from '../errors/errorValidator';
import GatewayAPI from './GatewayAPI';
class NotificationsAPI extends GatewayAPI {
    async getNotifications(params) {
        try {
            const { data } = await this._client.get('/notifications', { params });
            return new Notifications({
                count: data.count,
                pagination: new Pagination(data.pagination),
                items: data.items.map(notification => new Notification(notification))
            });
        }
        catch (error) {
            throw this._handleError(error);
        }
    }
    async getUnseenNotificationsCount() {
        try {
            const { data } = await this._client.get('/notifications/unseen/count');
            return new UnseenNotifications(data.unseenNotificationsCount);
        }
        catch (error) {
            throw this._handleError(error);
        }
    }
    _handleError(error) {
        if (!(isRequestError(error))) {
            throw NotificationsError.getNetworkError();
        }
        const { response } = getAsRequestError(error);
        const { code } = getAsErrorData(response?.data);
        throw new NotificationsError(code);
    }
}
export default NotificationsAPI;
