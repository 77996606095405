const restrictions = result => [
  {
    text: 'Mínimo 6 caracteres.',
    key: 'minimum-length',
    isValid: result.hasMimimumLength
  },
  {
    text: 'Mínimo 1 número.',
    key: 'at-least-number',
    isValid: result.hasAtLeastANumber
  },
  {
    text: 'Mínimo 1 minúscula.',
    key: 'at-least-lower-case',
    isValid: result.hasAtLeastOneLowerCase
  },
  {
    text: 'Mínimo 1 mayúscula.',
    key: 'at-least-upper-case',
    isValid: result.hasAtLeastOneUpperCase
  },
  {
    text: 'Mínimo 1 de estos caracteres especiales "!#$%&\'()*+,-./:;<=>?@[]^_`{|} ~.',
    key: 'at-least-special-character',
    isValid: result.hasAtLeastASpecialCharacter
  },
  {
    text: 'No tener el nombre de la institución financiera, ej. 100Ladrillos, 100ladrillos.',
    key: 'company-name',
    isValid: result.hasNot100Ladrillos
  },
  {
    text: 'No repetir un caracter más de 3 veces de forma consecutiva, ej.3333.',
    key: 'consecutive-characters',
    isValid: result.hasNotConsecutiveChars
  },
  {
    text: 'No tener más de 3 números o letras en forma secuencial, ej.1234.',
    key: 'sequentially-characteres',
    isValid: result.hasNotSequentialChars
  }
];

module.exports = restrictions;
