import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../Button';
import Icon from '../../../Icon';
import Modal from '../../../Modal';
import Title from '../../../Title';

import { ActionsWrapper, TextWrapper } from './UnavailableBricksModal.style';

const UnavailableBricksModal = ({ handleClose, handleConfirm, isOpen }) => (
  <Modal isOpen={isOpen} onClose={handleClose}>
    <Title>¡Oh no, alguien se te adelantó!</Title>

    <Icon name="icBrickWithCircularArrow" alt="Saliendo de 100 Ladrillos" height={84} width={84} />

    <TextWrapper>
      Alguien más compró Ladrillos, el precio promedio ha cambiado.
    </TextWrapper>

    <ActionsWrapper>
      <Button id="cancel" color="secondary" onClick={handleClose}>
        Cancelar
      </Button>

      <Button id="back-to-quote" onClick={handleConfirm}>
        Volver a cotizar
      </Button>
    </ActionsWrapper>
  </Modal>
);

UnavailableBricksModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default UnavailableBricksModal;
