import EnrollmentError from './EnrollmentError';

const INVALID_CODE = 'INVALID_CODE';
const NO_PHONE_NUMBER_REGISTERED_YET = 'NO_PHONE_NUMBER_REGISTERED_YET';
const PHONE_NUMBER_IS_ALREADY_VERIFIED = 'PHONE_NUMBER_IS_ALREADY_VERIFIED';
const CANNOT_RESEND_CODE = 'CANNOT_RESEND_CODE';

class PhoneError extends EnrollmentError {
  constructor(code, details = null) {
    super(code, 'PhoneError', details);
  }

  cannotResendCode() {
    return this._code === CANNOT_RESEND_CODE;
  }

  isInvalidCode() {
    return this._code === INVALID_CODE;
  }

  isPhoneRegisteredYet() {
    return this._code === NO_PHONE_NUMBER_REGISTERED_YET;
  }

  isPhoneAlreadyVerified() {
    return this._code === PHONE_NUMBER_IS_ALREADY_VERIFIED;
  }
}

export default PhoneError;
