import styled from 'styled-components';

import { flexAsColumn, flexAsRow } from '@components/Styled/mixins';

const InvestorLocationModalWrapper = styled.div`
  min-height: 100vh%;
  background-color: ${({ theme }) => theme.palette.white};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
   width: 100vh;
  }
`;

const DescriptionContainer = styled.div`
  ${flexAsColumn(1)};

  width: ${({ theme }) => theme.spacing(41)};
  min-height: calc(80vh - 10vh);
  place-items: center;
  text-align: center;
  margin: auto;
  padding-top: 5vh;

  & img {
    width: ${({ theme }) => theme.spacing(6)};
    height: ${({ theme }) => theme.spacing(6)};
  }

  & button {
    width: ${({ theme }) => theme.spacing(26.5)};
  }

  & article {
    margin-top: ${({ theme }) => theme.spacing(1)};

    & p {
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding-top: 15vh;
    width: ${({ theme }) => theme.spacing(62.5)};
  }
`;

const ButtonsContainer = styled.div`
  ${flexAsColumn(2)};

  flex-flow: wrap-reverse;
  
  & button {
    width: ${({ theme }) => theme.spacing(41)};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    ${flexAsRow(2)};

    margin-top: ${({ theme }) => theme.spacing(1)};

    & button {
      width: ${({ theme }) => theme.spacing(26.5)};
    }
  }
`;

const HelpContainer = styled.div`
  ${flexAsColumn()};

  min-height: 10vh;
  place-items: center;
  text-align: center;
  padding-top: ${({ theme }) => theme.spacing(2)};   
`;

export {
  InvestorLocationModalWrapper,
  DescriptionContainer,
  ButtonsContainer,
  HelpContainer
};
