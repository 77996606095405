import styled from 'styled-components';

import { flexAsColumn } from '@components/Styled/mixins';

const UnblockLayout = styled.section`
  ${flexAsColumn(4)}

  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(2)};

  > * {
    width: auto;
  }

  > div {
    ${flexAsColumn(2)}
    align-items: center;
  }

  > form {
    ${flexAsColumn(2)}
    align-items: center;

    > * {
      width: auto;
    }

    > div {
      ${flexAsColumn(1)}
      align-items: center;

      > button:last-of-type {
        display: none;
      }
    }
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.palette.danger.main};
`;

export {ErrorMessage, UnblockLayout};
