class Status {
    name;
    constructor(name) {
        this.name = name;
    }
    getName() {
        return this.name;
    }
    isPending() {
        return this.name === 'pending';
    }
    isInitializing() {
        return this.name === 'initializing';
    }
    isDispersing() {
        return this.name === 'dispersing';
    }
    isDispersionFailed() {
        return this.name === 'dispersion_failed';
    }
    isSuccess() {
        return this.name === 'success';
    }
}
export default Status;
