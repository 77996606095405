import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from '../../../Icon';

import './style.scss';

const AccordionItem = props => {
  const {
    children,
    handleClick,
    id,
    title,
    isOpen
  } = props;

  const itemHeaderStyle = cx({
    'item-header': true,
    open: isOpen
  });

  const itemBodyStyle = cx({
    'item-body': true,
    open: isOpen
  });

  const accordionControlStyle = cx({
    'accordion-control': true,
    open: isOpen
  });

  return (
    <section styleName="accordion-item">
      <button
        id={id}
        styleName={itemHeaderStyle}
        onClick={handleClick}
        type="button"
        tid="accordion-item-button"
      >
        <h3 tid="accordion-item-title">{title}</h3>
        <Icon name="icArrow" alt="Abrir / Cerrar" styleName={accordionControlStyle} />
      </button>
      <section styleName={itemBodyStyle} tid="accordion-item-content">
        {children}
      </section>
    </section>
  );
};

AccordionItem.propTypes = {
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default AccordionItem;
