import styled from 'styled-components';

import {
  getBackgroundColorSentence,
  getColorSentence,
  getHeightSentence,
  getTextAlignSentence,
  getWidthSentence
} from './sentences';

// This component has been declared obsolete and its use is discouraged. It is recommended to stop
// using it in future developments or in the process of refactoring existing code. Alternatively,
// the Title component can be used, which offers similar or improved functionality.

const Headline1 = styled.h1`
  ${getBackgroundColorSentence};
  ${getColorSentence};
  ${getHeightSentence};
  ${getTextAlignSentence};
  ${getWidthSentence};

  font-size: ${({ theme }) => theme.typography.title.h1.fontSize};
  font-weight: ${({ theme }) => theme.typography.title.h1.fontWeight};
  letter-spacing: ${({ theme }) => theme.typography.title.h1.letterSpacing};

  margin: 0;
  padding: 0;
`;

export default Headline1;
