import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Button } from '@components';
import { AuthenticationAttemptsLimitReached, InvestorHasAlreadyAnActiveSession } from '@lib/hundredBricksAPI/authentication';
const RenewSessionOptions = ({ error, onCancel, onConfirm }) => {
    if (error instanceof AuthenticationAttemptsLimitReached
        || error instanceof InvestorHasAlreadyAnActiveSession) {
        return (_jsx(Button, { type: "button", onClick: onCancel, children: " Salir " }));
    }
    return (_jsxs(Fragment, { children: [_jsx(Button, { type: "button", onClick: onConfirm, children: " Reenviar correo " }), _jsx(Button, { type: "button", color: "secondary", onClick: onCancel, children: " Salir " })] }));
};
export default RenewSessionOptions;
