import styled from 'styled-components';

const MyMovementsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(2)};

  > h2 {
    font-size: ${({ theme }) => theme.toRem(24)};
    margin: ${({ theme }) => theme.spacing(1)};
  }
`;

const MyMovementsDataTableContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(2)};

  tbody,
  section > div {
    background-color: ${({ theme }) => theme.palette.white};
  }
`;

export {
  MyMovementsDataTableContainer,
  MyMovementsHeaderContainer
};
