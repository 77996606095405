import styled from 'styled-components';

const StatusContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

const MovementTypeContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};

  div {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

const MovementType = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius(9)};
  height: ${({ theme }) => theme.toRem(12)};
  width: ${({ theme }) => theme.toRem(12)};
  background-color: ${({ type }) => {
    switch (type) {
      case 'withdrawal':
        return '#0477c0';
      case 'deposit':
        return '#3ab398';
      case 'charge':
        return '#a1062c';
      default:
        return '#0477c0';
    }
  }};
`;

const ConceptContainer = styled.div`
  width: ${({ theme }) => theme.toRem(190)};
  white-space: break-spaces;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: ${({ theme }) => theme.toRem(320)};
  }
`;

export {
  StatusContainer,
  MovementTypeContainer,
  MovementType,
  ConceptContainer
};
