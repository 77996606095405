import regularExpressions from '../regularExpressions';

const formatToTwoDecimals = value => {
  const { avoidAllPoints } = regularExpressions;
  const pointIndex = value.indexOf('.');
  const integerPart = value.slice(0, pointIndex);
  const decimalPart = value.slice(pointIndex + 1, (pointIndex + 3))
    .replace(avoidAllPoints, '');

  return `${integerPart}.${decimalPart}`;
};

const formatIntegerPartToZero = value => {
  const pointIndex = value.indexOf('.');
  const decimalPart = value.slice(pointIndex);

  return '0'.concat(decimalPart);
};

const formatMoney = value => {
  const { avoidLeadingZeros: avoidLeadingZerosRegexp } = regularExpressions;
  const moneyFormatRegex = /[^0-9.]/g;

  if (typeof value !== 'string') {
    return 0;
  }

  let formattedValue = value.trim()
    .replace(avoidLeadingZerosRegexp, '')
    .replace(moneyFormatRegex, '');

  if (Number.isNaN(formattedValue)) {
    return 0;
  }

  const hasDecimals = formattedValue.indexOf('.') >= 0;

  if (hasDecimals) {
    const firstDigit = formattedValue.charAt(0);

    if (firstDigit === '0' || firstDigit === '.') {
      formattedValue = formatIntegerPartToZero(formattedValue);
    }
    formattedValue = formatToTwoDecimals(formattedValue);
  }

  return formattedValue;
};

export default formatMoney;
