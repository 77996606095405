import Coordinates from '../Coordinates';
class Geolocation {
    ip;
    countryCode;
    countryName;
    regionCode;
    regionName;
    city;
    zip;
    coordinates;
    constructor(data) {
        this.ip = data.ip;
        this.countryCode = data.countryCode;
        this.countryName = data.countryName;
        this.regionCode = data.regionCode;
        this.regionName = data.regionName;
        this.city = data.city;
        this.zip = data.zip;
        this.coordinates = new Coordinates(data.latitude, data.longitude);
    }
}
export default Geolocation;
