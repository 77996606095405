import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import PropTypes from 'prop-types';

import config from '../../config';
import './style.scss';

const currentConfig = config[process.env.TARGET_ENV];
const { mapsApiKey } = currentConfig;

const getMapsUrl = () => {
  const baseUrl = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places';
  const withKey = `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&?v=3.exp&libraries=geometry,drawing,places`;

  return mapsApiKey ? withKey : baseUrl;
};

const MapWithMarker = withScriptjs(withGoogleMap(({ coordinates = {}, isMarkerShown }) => {
  const lat = Number(coordinates.latitude);
  const lng = Number(coordinates.longitude);

  return (
    <GoogleMap
      defaultZoom={17}
      zoom={17}
      defaultCenter={{ lat, lng }}
    >
      {isMarkerShown && (
        <Marker
          position={{ lat, lng }}
        />
      )}
    </GoogleMap>
  );
}));

const loadingElement = <div styleName="loadingElement" />;
const containerElement = <div styleName="containerElement" />;
const mapElement = <div styleName="mapElement" />;

const LegacyMap = ({ coordinates }) => (
  <MapWithMarker
    isMarkerShown
    coordinates={coordinates}
    googleMapURL={getMapsUrl()}
    loadingElement={loadingElement}
    containerElement={containerElement}
    mapElement={mapElement}
  />
);

LegacyMap.propTypes = {
  coordinates: PropTypes.shape().isRequired,
};

export default LegacyMap;
