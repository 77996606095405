import { Date as DateFormatter } from '@types';

import regularExpressions from '../regularExpressions';

const formatToDateType = date => {
  const replaceDate = date?.replace(regularExpressions.dateFormat, '$2');

  return date ? new DateFormatter(replaceDate).format('LL') : '';
};

export default formatToDateType;
