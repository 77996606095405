import styled from 'styled-components';

const ActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  justify-content: center;
  width: 100%;
`;

const RevokeWarningContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
  text-align: center;
  width: 100%;

  > img {
    margin: ${({ theme }) => theme.spacing(2)} 0;
  }

  > p {
    text-align: center;
  }
`;

export { ActionButtons, RevokeWarningContainer };
