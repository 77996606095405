/* eslint-disable @typescript-eslint/no-unused-vars */
class DummyInsightsService {
  sendEvent(_event) {
    // sendEvent its a dummy function
  }

  anonymizeInvestorIdentity() {
    // anonymizeInvestorIdentity its a dummy function
  }

  identifyInvestor(_id) {
    // identifyInvestor its a dummy function
  }

  setEventProperties(_properties) {
    // setEventProperties its a dummy function
  }

  setProfileProperties(_profileProperties) {
    // setProfileProperties its a dummy function
  }
}

// eslint-disable-next-line import/no-anonymous-default-export, import/no-unused-modules
export default new DummyInsightsService();
