/* eslint-disable @typescript-eslint/no-unused-vars */
/* This error reporter is just for testing purposes should not be used on local or produciton
 * code. It is intentional to leave all methods in blank. Because this error reporter does
 * nothing, no test are needed
 */
class DummyErrorReporter {
    warning(_error) {
        // warning its a dummy function
    }
    error(_error) {
        // error its a dummy function
    }
    critical(_erro) {
        // critical its a dummy function
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUser(_id, _email) { }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearUser() { }
}
export default DummyErrorReporter;
