import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Money } from '@types';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const MONTHS = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre'
];
const DirectRentRelease = (props) => {
    const { notification } = props;
    const releasedAmount = new Money(notification.params?.releasedAmount);
    const releaseDate = new Date(notification.params?.releaseRentDate);
    const releaseMonth = MONTHS[releaseDate.getMonth()];
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Los rendimientos de tus rentas ya est\u00E1n disponibles" }), _jsxs(Paragraph, { children: ["\u00A1Buenas noticias! Hemos liberado", _jsx(Bold, { children: ` ${releasedAmount} ` }), "de tus rentas no liberadas del mes de", ` ${releaseMonth}. `, "Recuerda que este monto podr\u00EDa ser mayor si facturaras tus rentas. Si tienes dudas sobre c\u00F3mo funciona la liberaci\u00F3n directa o nuestro servicio de facturaci\u00F3n autom\u00E1tica, te invitamos a visitar la secci\u00F3n de ayuda."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default DirectRentRelease;
