class APIError extends Error {
    status;
    code;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(status, ...args) {
        if (args.length === 2) {
            super(`APIError(${status}): ${args[0]}`, args[1]);
            this.code = args[0];
        }
        else if (args.length === 1 && typeof args[0] === 'string') {
            super(`APIError(${status}): ${args[0]}`);
            this.code = args[0];
        }
        else {
            super(`APIError(${status}): (missing error code)`, ...args);
        }
        this.status = status;
    }
}
export default APIError;
