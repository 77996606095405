/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import IconButton from '../IconButton';
import Icons from '../Icons';
import TextField from '../TextField';

const PasswordField = ({ inputProps, ...props }) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const type = isVisiblePassword ? 'text' : 'password';
  const Icon = isVisiblePassword ? Icons.VisibilityIcon : Icons.VisibilityOffIcon;

  const handleIconButtonClick = () => setIsVisiblePassword(!isVisiblePassword);

  return (
    <TextField
      {...props}
      type={type}
      inputProps={inputProps}
      endAdornment={(
        <IconButton
          id="icon-button"
          disableFocus
          onClick={handleIconButtonClick}
          aria-label="Mostrar contraseña"
          onPointerEnter={event => event.target.focus()}
        >
          <Icon />
        </IconButton>
      )}
    />
  );
};

export default PasswordField;
