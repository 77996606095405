import React, { Fragment, useState } from 'react';

import { useInsightsService } from '@lib/insightsService';
import { RenderIf } from '@components';
import UnusualAccessLock from '@views/UnusualAccessLock';

import { StepOne, StepTwo } from './Steps';

const Login = () => {
  const { authenticationEvent, reportInsight } = useInsightsService();
  const [step, setStep] = useState('email');
  const [email, setEmail] = useState('');
  const [hasUnusualAccess, setHasUnusualAccess] = useState(false);
  const [antiphishingData, setAntiphishingData] = useState({});

  const handleStepOneSuccess = (userEmail, antiphishingResponse) => {
    setStep('password');
    setEmail(userEmail);
    setAntiphishingData(antiphishingResponse);
  };

  const handleSuccessfulLogin = () => {
    reportInsight(authenticationEvent.successful);
  };

  if (hasUnusualAccess) {
    return (<UnusualAccessLock email={email} />);
  }

  return (
    <Fragment>
      <RenderIf condition={step === 'email'}>
        <StepOne onSuccess={handleStepOneSuccess} />
      </RenderIf>

      <RenderIf condition={step === 'password'}>
        <StepTwo
          email={email}
          antiphishingData={antiphishingData}
          onCancel={() => setStep('email')}
          onSuccess={handleSuccessfulLogin}
          displayUnusualAccess={() => setHasUnusualAccess(true)}
        />
      </RenderIf>
    </Fragment>
  );
};

export default Login;
