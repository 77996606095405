import styled from 'styled-components';

import { flexAsRow } from '@components/Styled/mixins';

const ButtonContainer = styled.div`
  ${flexAsRow(3)};

  justify-content: center;
  width: 100%;

  > button {
    width: 100%;
  }
`;

export { ButtonContainer };
