import NetworkError from '../errors/NetworkError';
import { PhoneError } from '../errors/Enrollment';

import GatewayAPI from './GatewayAPI';

class UpdatePhoneAPI extends GatewayAPI {
  async setNewPhone(number, country) {
    try {
      const { data } = await this._client.post('/profile/phone', { number, country });

      return data;
    } catch (error) {
      if (!error.response) {
        throw PhoneError.getNetworkError();
      }

      return this._handleError(error, PhoneError);
    }
  }

  async sendVerificationNewPhoneToken(channel) {
    try {
      const { data } = await this._client.post('/profile/phone/sendVerificationToken', { channel });

      return data;
    } catch (error) {
      return this._handleError(error, PhoneError);
    }
  }

  async verifyNewPhone(code) {
    try {
      const { data } = await this._client.post('/profile/phone/change', { code });

      return data;
    } catch (error) {
      return this._handleError(error, PhoneError);
    }
  }

  _handleError(error) {
    if (!error.response) {
      throw new NetworkError();
    }

    const { response } = error;
    const { code } = response.data;

    throw new PhoneError(code);
  }
}

export default UpdatePhoneAPI;
