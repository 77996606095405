import { CURP_STATUSES } from '@constants';
class CURP {
    value;
    status;
    rejectionMotive;
    constructor(curp) {
        this.value = curp?.value;
        this.status = curp?.status;
        this.rejectionMotive = curp?.rejectionMotive;
    }
    isApproved() {
        return this.status === CURP_STATUSES.approved;
    }
    isRejected() {
        return this.status === CURP_STATUSES.rejected;
    }
    hasFailedAutomaticValidation() {
        return this.status === CURP_STATUSES.automaticValidationFailed;
    }
}
export default CURP;
