import autoBind from 'auto-bind';
class GatewayAPI {
    _client;
    _legacyClient;
    constructor(client, legacyClient) {
        this._client = client;
        this._legacyClient = legacyClient;
        autoBind(this);
    }
}
export default GatewayAPI;
