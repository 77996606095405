import { SignRequest } from '../SignDocuments';
import EnrollmentProfile from './EnrollmentProfile';
import Status from './Status';
import ApprovalRequest from './ApprovalRequest';
import CorrectionRequest from './CorrectionRequest';
/**
  * This class should depend to the profile, and EnrollmentProfile
  * and MoralProfile should be created from Profile class.
  */
class MoralProfile extends EnrollmentProfile {
    constructor(profileData) {
        super({
            accountLevel: profileData.accountLevel,
            address: profileData.address,
            consecutiveNumber: profileData.consecutiveNumber,
            email: profileData.email,
            frozen: profileData.frozen,
            fullName: profileData.fullName,
            id: profileData.id,
            isEmailVerified: profileData.isEmailVerified,
            pendingApprovalRequests: new ApprovalRequest({ pendingApprovalRequests: [] }),
            pendingCorrectionRequests: new CorrectionRequest({ pendingCorrectionRequests: [] }),
            pendingSignRequests: new SignRequest({ pendingSignRequests: [] }),
            phone: profileData.phone,
            rawData: profileData,
            status: new Status({ name: 'unrestricted' }),
            taxId: profileData.taxId
        });
    }
    isDoneRegistering() {
        return true;
    }
    canBuy() {
        return true;
    }
    isLegalPerson() {
        return true;
    }
    isPhysicalInvestor() {
        return false;
    }
    hasIdentificationData() {
        return false;
    }
    hasRejectedDocs() {
        return false;
    }
    hasTransactionalProfile() {
        return false;
    }
}
export default MoralProfile;
