const getUrlParameter = (name, search) => {
  if (!search) return null;

  const decodedSearch = decodeURIComponent(search);
  const stringUrlParameterRegexp = `[?&]${name}=([^&]+)`;
  const urlParameterRegexp = new RegExp(stringUrlParameterRegexp);
  const matches = decodedSearch.match(urlParameterRegexp) || [];
  const parameter = matches.length ? matches[1] : '';

  return parameter;
};

export default getUrlParameter;
