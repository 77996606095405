import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Title from '../Title';
import CheckActivityList from '../CheckActivityList';
import RenderIf from '../RenderIf';
import { UpgradeButton } from '../../hooks/useUpgradingAccount/UpgradeAccount.styled';
import { Text } from '../Styled';
import Icon from '../Icon';

import {
  KeepOnUpdateLimitlessAccountWrapper,
  IconWrapper
} from './KeepOnUpdateLimitlessAccount.style';

const KeepOnUpdateLimitlessAccount = ({ onRequestUpgrade, profile, showIllustration }) => {
  const activityList = [
    {
      name: 'Confirmar tus datos',
      isCompleted: Boolean(profile.fullName)
    },
    {
      name: 'Subir tu Identificación oficial',
      isCompleted: Boolean(profile.identification.number)
    },
    {
      name: 'Escribir tu RFC',
      isCompleted: Boolean(profile.taxId.value)
    },
    {
      name: 'Llenar el cuestionario de inversionista',
      isCompleted: Boolean(profile.hasTransactionalProfile())
    },
    {
      name: 'Escribir tu domicilio',
      isCompleted: Boolean(profile.address.hasAddress())
    }
  ];

  return (
    <Fragment>
      <KeepOnUpdateLimitlessAccountWrapper>
        <Title>Continúa con la mejora de tu cuenta</Title>

        <RenderIf condition={showIllustration}>
          <IconWrapper>
            <Icon
              height="96"
              width="96"
              name="icUpgradeAccountModal"
              alt="Imagen conviértete en un usuario sin límites"
            />
          </IconWrapper>
        </RenderIf>

        <Text align="left">
          Al mejorar tu cuenta, podrás invertir y poner a la venta tus Ladrillos sin límites.
        </Text>

        <Text align="left">Completa los requisitos</Text>

        <CheckActivityList activityList={activityList} />
      </KeepOnUpdateLimitlessAccountWrapper>

      <UpgradeButton onClick={onRequestUpgrade}>
        Continuar mejorando mi cuenta
      </UpgradeButton>
    </Fragment>
  );
};

KeepOnUpdateLimitlessAccount.propTypes = {
  onRequestUpgrade: PropTypes.func.isRequired,
  showIllustration: PropTypes.bool,
  profile: PropTypes.shape({
    fullName: PropTypes.string,
    hasTransactionalProfile: PropTypes.func,
    identification: PropTypes.shape({ number: PropTypes.string }),
    taxId: PropTypes.shape({ value: PropTypes.string }),
    address: PropTypes.shape({ hasAddress: PropTypes.func })
  })
};

KeepOnUpdateLimitlessAccount.defaultProps = {
  showIllustration: false,
  profile: {
    fullName: null,
    hasTransactionalProfile: false,
    identification: { number: null },
    taxId: { value: null },
    address: { hasAddress: false }
  }
};

export default KeepOnUpdateLimitlessAccount;
