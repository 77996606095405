const downloadFile = (blob, fileName) => {
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  document.body.append(link);

  link.click();
  link.remove();

  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const downloadFileToResource = (file, fileName) => {
  downloadFile(new Blob([file]), `${fileName}.pdf`, 'application/pdf');
};

export default downloadFileToResource;
