const validateSelectField = value => {
  if (
    !Object.prototype.hasOwnProperty.call(value, 'id')
    && !Object.prototype.hasOwnProperty.call(value, 'label')
  ) {
    return 'El campo es requerido.';
  }

  return undefined;
};

export default validateSelectField;
