import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Money } from '@types';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const SoldBricks = (props) => {
    const { notification } = props;
    const currency = new Money(notification.params?.totalAmount);
    const property = notification.params?.propertyName ?? '----';
    const bricksText = notification.params?.totalBricks === 1
        ? `${notification.params?.totalBricks} Ladrillo`
        : `${notification.params?.totalBricks ?? 0} Ladrillos`;
    return (_jsxs(NotificationContainer, { children: [_jsxs(Subtitle, { level: "3", children: ["Has vendido", ` ${bricksText} en ${property}`] }), _jsxs(Paragraph, { children: ["Felicidades por la venta de", _jsx(Bold, { children: ` ${bricksText} ` }), "en", _jsx(Bold, { children: ` ${property}` }), ". El monto recibido por esta transacci\u00F3n es de", _jsx(Bold, { children: ` ${currency.toString()}` }), ". Revisa tu saldo en el Estado de Cuenta para ver todos los detalles de la transacci\u00F3n."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default SoldBricks;
