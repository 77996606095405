/* eslint-disable max-len */
import icAccounts from '@assets/icons/ic-accounts.svg';
import icActivationPaymentPlan from '@assets/icons/ic-activation-payment-plan.svg';
import icActiveSession from '@assets/icons/ic-active-session.svg';
import icActualRetailPrice from '@assets/icons/ic-actual-retail-price.svg';
import icAdd from '@assets/icons/ic-add.png';
import icAddFunds from '@assets/icons/ic-add-funds.svg';
import icAddFundsActive from '@assets/icons/ic-add-funds-active.svg';
import icAddFundsColor from '@assets/icons/ic-add-funds-color.svg';
import icAnnualLeasing from '@assets/icons/ic-annual-leasing.svg';
import icApproved from '@assets/icons/ic-approved.svg';
import icArrow from '@assets/icons/ic-arrow.svg';
import icArrowCircle from '@assets/icons/ic-arrow-circle.svg';
import icArrowOutlined from '@assets/icons/ic-arrow-outlined.svg';
import icArrowOutlinedBlue from '@assets/icons/ic-arrow-down-blue.svg';
import icArrowRound from '@assets/icons/ic-arrow-round.svg';
import icAutomatedInvoice from '@assets/icons/ic-automated-invoice.svg';
import icAvatar from '@assets/icons/ic-avatar.svg';
import icBack from '@assets/icons/ic-back.svg';
import icBackDark from '@assets/icons/ic-back-dark.svg';
import icBackFilled from '@assets/icons/ic-back-filled.svg';
import icBackWhite from '@assets/icons/ic-back-white.svg';
import icBank from '@assets/icons/ic-bank.svg';
import icBaseAnnualProfitability from '@assets/icons/ic-base-annual-profitability.svg';
import icBrickPrice from '@assets/icons/ic-brick-price.svg';
import icBricksBuild from '@assets/icons/ic-bricks-build.svg';
import icBricksPile from '@assets/icons/ic-bricks-pile.svg';
import icBricksSummary from '@assets/icons/ic-bricks-summary.svg';
import icBrickWithCircularArrow from '@assets/icons/ic-brick-with-circular-arrow.svg';
import icBuildingOne from '@assets/icons/ic-building-one.svg';
import icBuildingThree from '@assets/icons/ic-building-three.svg';
import icBuildingTwo from '@assets/icons/ic-building-two.svg';
import icBuy from '@assets/icons/ic-buy.svg';
import icBuyActive from '@assets/icons/ic-buy-active.svg';
import icCalendar from '@assets/icons/ic-calendar.svg';
import icCalendarGrey from '@assets/icons/ic-calendar-grey.svg';
import icCancelInvestmentModal from '@assets/icons/ic-cancel-investment-modal.svg';
import icCannotRemoveBricksOnSale from '@assets/icons/ic-cannot-remove-bricks-on-sale.svg';
import icCapitalGainTerm from '@assets/icons/ic-capital-gain-term.svg';
import icCheckmarkCircle from '@assets/icons/ic-checkmark-circle.svg';
import icCheckSmall from '@assets/icons/ic-check-small.svg';
import icCheckWhite from '@assets/icons/ic-check-white.svg';
import icClockExpiring from '@assets/icons/ic-clock-expiring.svg';
import icClose from '@assets/icons/ic-close.svg';
import icCodeMail from '@assets/icons/ic-code-mail.svg';
import icCompletedProperty from '@assets/icons/ic-completed-property.svg';
import icCompleteProfile from '@assets/icons/ic-complete-profile.svg';
import icConfirmPayPaymentPlan from '@assets/icons/ic-confirm-pay-payment-plan.svg';
import icCosts from '@assets/icons/ic-costs.svg';
import icCoupon from '@assets/icons/ic-coupon.svg';
import icCouponMoney from '@assets/icons/ic-coupon-money.svg';
import icCouponWithDolarIcon from '@assets/icons/ic-couponWithDolarIcon.svg';
import icCouponWithDolarIconActive from '@assets/icons/ic-couponWithDolarIcon-active.svg';
import icCrowdfundingPeriods from '@assets/icons/ic-crowdfunding-periods.svg';
import icCrown from '@assets/icons/ic-crown.svg';
import icDone from '@assets/icons/ic-done.svg';
import icDownload from '@assets/icons/ic-download.svg';
import icEdit from '@assets/icons/ic-edit.svg';
import IcEmail from '@assets/icons/ic-email.svg';
import icEmailVerification from '@assets/icons/ic-email-verification.svg';
import icEmptyCrowdfundingDeliveries from '@assets/icons/ic-empty-crowdfunding-deliveries.svg';
import icEmptyCurrentBricks from '@assets/icons/ic-empty-current-bricks.svg';
import icEmptyEscrow from '@assets/icons/ic-empty-escrow.svg';
import icEmptyInvoicingData from '@assets/icons/ic-empty-invoicing-data.svg';
import icEmptyLeaseholders from '@assets/icons/ic-empty-leaseholders.svg';
import icEmptyMyBricksPerProperty from '@assets/icons/ic-empty-my-bricks-per-property.svg';
import icEmptyOnSaleBricks from '@assets/icons/ic-empty-on-sale-bricks.svg';
import icEmptySoldBricks from '@assets/icons/ic-empty-sold-bricks.svg';
import icEmptyUnits from '@assets/icons/ic-empty-units.svg';
import icEnrollment from '@assets/icons/ic-enrollment.svg';
import icEnrollmentActive from '@assets/icons/ic-enrollment-active.svg';
import icEstimatedAnnualLeasing from '@assets/icons/ic-estimated-annual-leasing.svg';
import icEstimatedRent from '@assets/icons/ic-estimated-rent.svg';
import IcEyeClosed from '@assets/icons/ic-eye-closed.svg';
import IcEyeOpen from '@assets/icons/ic-eye-open.svg';
import icFailedAttemptsLock from '@assets/icons/ic-failed-attempts-lock.svg';
import icFailedVerification from '@assets/icons/ic-failed-verification.svg';
import icFile from '@assets/icons/ic-file.svg';
import icFirma from '@assets/icons/ic_firma.svg';
import icFirstSteps from '@assets/icons/ic-first-steps.svg';
import icFirstStepsActive from '@assets/icons/ic-first-steps-active.svg';
import icGallery from '@assets/icons/ic-gallery.svg';
import icHandshake from '@assets/icons/ic-handshake.svg';
import icHelp from '@assets/icons/ic-help.svg';
import icHome from '@assets/icons/ic-home.svg';
import icIdBack from '@assets/icons/ic-id-back.svg';
import icIdentificationValidationPending from '@assets/icons/ic-identification-validation-pending.svg';
import icIdFront from '@assets/icons/ic-id-front.svg';
import icIncompleteProfile from '@assets/icons/ic-incomplete-profile.svg';
import icInfo from '@assets/icons/ic-information-default.svg';
import icInformation from '@assets/icons/ic-information.svg';
import icInsufficientBricks from '@assets/icons/ic-insufficient-bricks.svg';
import icInsufficientFunds from '@assets/icons/ic-insufficient-funds.svg';
import icInternationalContract from '@assets/icons/ic-international-contract.svg';
import icIssueInvoice from '@assets/icons/ic-issue-invoice.svg';
import icLastTransaction from '@assets/icons/ic-last-transaction.svg';
import icLeavingApp from '@assets/icons/ic-leaving-app.svg';
import icLocation from '@assets/icons/ic-location.svg';
import icLocationGrey from '@assets/icons/ic-location-grey.svg';
import IcLocationNew from '@assets/icons/ic-location-new.svg';
import IcLocker from '@assets/icons/ic-locker.svg';
import icLogout from '@assets/icons/ic-logout.svg';
import icMaclovio from '@assets/icons/ic-maclovio.svg';
import icMail from '@assets/icons/icons-Mail.svg';
import icMailVerificated from '@assets/icons/ic-mail-verificated.svg';
import icManualInvoice from '@assets/icons/ic-manual-invoice.svg';
import icMenu from '@assets/icons/ic-menu.svg';
import icMexicoFlag from '@assets/icons/ic-mexico-flag.svg';
import icMoneyBag from '@assets/icons/ic-money-bag.svg';
import icMoneyBagTime from '@assets/icons/ic-money-bag-time.svg';
import icMyAccount from '@assets/icons/ic-my-account.svg';
import icMyAccountActive from '@assets/icons/ic-my-account-active.svg';
import icMyInvestments from '@assets/icons/ic-my-investments.svg';
import icMyMovements from '@assets/icons/ic-my-movements.svg';
import icMyMovementsActive from '@assets/icons/ic-my-movements-active.svg';
import icMyPaymentPlans from '@assets/icons/ic-my-payment-plans.svg';
import icMyPaymentPlansActive from '@assets/icons/ic-my-payment-plans-active.svg';
import icMyRents from '@assets/icons/ic-my-rents.svg';
import icNewPaymentPlan from '@assets/icons/ic-new-payment-plan.svg';
import icNewPlatform from '@assets/icons/ic-new-platform.svg';
import IcNext from '@assets/icons/ic-next.svg';
import icNextPage from '@assets/icons/ic-next-page.svg';
import icNoBeneficiary from '@assets/icons/ic-no-beneficiary.svg';
import icNoBricks from '@assets/icons/ic-no-bricks.svg';
import icNoInvestments from '@assets/icons/ic-no-investments.svg';
import icNotification from '@assets/icons/ic-notification-active.svg';
import icOccupancyPercentage from '@assets/icons/ic-occupancy-percentage.svg';
import icOnSaleBricks from '@assets/icons/ic-on-sale-bricks.svg';
import icOpenSection from '@assets/icons/ic-open-section.svg';
import icOperator from '@assets/icons/ic-operator.svg';
import icPassport from '@assets/icons/ic-passport.svg';
import icPayPaymentPlan from '@assets/icons/ic-pay-payment-plan.svg';
import icPending from '@assets/icons/ic-pending.svg';
import icPendingCheck from '@assets/icons/ic-pending-check.svg';
import icPercentage from '@assets/icons/ic-percentage.svg';
import icPersonalizeProfile from '@assets/icons/ic-personalize-profile.svg';
import icPhone from '@assets/icons/ic-phone.svg';
import icPhoneCode from '@assets/icons/ic-phone-code.svg';
import icPhoneVerificated from '@assets/icons/ic-phone-verificaded.svg';
import icPileOfBricks from '@assets/icons/ic-pile-of-bricks.svg';
import icPlayRed from '@assets/icons/ic-play-red.svg';
import icPlayWhite from '@assets/icons/ic-play-white.svg';
import icPreviousPage from '@assets/icons/ic-previous-page.svg';
import icProfile from '@assets/icons/ic-profile.svg';
import icProfileActive from '@assets/icons/ic-profile-active.svg';
import icProperties from '@assets/icons/ic-properties.svg';
import icPropertiesActive from '@assets/icons/ic-properties-active.svg';
import icPropertiesEnrollment from '@assets/icons/ic-properties-enrollment.svg';
import icPropertyAge from '@assets/icons/ic-property-age.svg';
import icPropertyAgeTwo from '@assets/icons/ic-property-age-two.svg';
import icPropertyManagementStatus from '@assets/icons/ic-property-management-status.svg';
import icPropertyParking from '@assets/icons/ic-property-parking.svg';
import icPropertySale from '@assets/icons/ic-property-sale.svg';
import icPropertySize from '@assets/icons/ic-property-size.svg';
import icPropertyType from '@assets/icons/ic-property-type.svg';
import icPropertyTypeTwo from '@assets/icons/ic-property-type-two.svg';
import icPropertyUnits from '@assets/icons/ic-property-units.svg';
import icReadyToBuy from '@assets/icons/ic-ready-to-buy.svg';
import icReadyToSign from '@assets/icons/icReadyToSign.svg';
import icRealOwner from '@assets/icons/ic-real-owner.svg';
import icRejected from '@assets/icons/ic-rejected.svg';
import icRejectedDocsUpdate from '@assets/icons/ic-rejected-docs-update.svg';
import icRejectPaymentPlan from '@assets/icons/ic-reject-payment-plan.svg';
import icRemoveBeneficiary from '@assets/icons/ic-remove-beneficiary.svg';
import icRemoveBricksOnSale from '@assets/icons/ic-remove-bricks-on-sale.svg';
import icRent from '@assets/icons/ic-rent.svg';
import icRfc from '@assets/icons/ic-rfc.svg';
import icSadFace from '@assets/icons/ic-sad-face.svg';
import icSadFaceShadow from '@assets/icons/ic-sad-face-shadow.svg';
import icSadFaceWhite from '@assets/icons/ic-sad-face-white.svg';
import icSatRegistration from '@assets/icons/ic-sat-registration.svg';
import icSavings from '@assets/icons/ic-savings.svg';
import icSell from '@assets/icons/ic-sell.svg';
import icSellActive from '@assets/icons/ic-sell-active.svg';
import icSettings from '@assets/icons/ic-settings.svg';
import icSignature from '@assets/icons/ic-signature.svg';
import icSingleBrick from '@assets/icons/ic-single-brick.svg';
import icSoldBricks from '@assets/icons/ic-sold-bricks.svg';
import icStatement from '@assets/icons/ic-statement.svg';
import icSuccess from '@assets/icons/ic-success.svg';
import icSuccessfulCheck from '@assets/icons/ic-successful-check.svg';
import icSuccessfulEmailUpdate from '@assets/icons/ic-successful-email-update.svg';
import icSuccessfulPhoneUpdate from '@assets/icons/ic-successful-phone-update.svg';
import icSupportWillContactYou from '@assets/icons/ic-support-will-contact-you.svg';
import icSystemsBussinesUser from '@assets/icons/SystemsBussines_User.svg';
import icTransactionalProfile from '@assets/icons/ic-transactional-profile.svg';
import icTrashGrey from '@assets/icons/ic-trash-grey.svg';
import icTrashRed from '@assets/icons/ic-trash-red.svg';
import icTwoFactorAuth from '@assets/icons/ic-two-factor-auth.svg';
import icUnexpectedError from '@assets/icons/ic-unexpected-error.svg';
import icUnusualAccess from '@assets/icons/ic-unusual-access.svg';
import icUpdateData from '@assets/icons/ic-update-data.svg';
import icUpdateInfo from '@assets/icons/ic-update-info.svg';
import icUpdateLater from '@assets/icons/ic-update-later.svg';
import icUpgradeAccountModal from '@assets/icons/ic-upgrade-account-modal.svg';
import icUpgradingAccountSuccess from '@assets/icons/ic-upgrading-account-success.svg';
import icUpToDateSat from '@assets/icons/ic-up-to-date-sat.svg';
import icUSAFlag from '@assets/icons/ic-usa-flag.svg';
import icUser from '@assets/icons/ic-user.svg';
import icUserGrey from '@assets/icons/ic-user-grey.svg';
import icValidation from '@assets/icons/ic-validation.svg';
import icVerifiedEmail from '@assets/icons/ic-verified-email.svg';
import icVolumePrice from '@assets/icons/ic-volume-price.svg';
import icWarning from '@assets/icons/ic-warning.svg';
import icWarningBlue from '@assets/icons/ic-warning-blue.svg';
import icWarningTriangle from '@assets/icons/ic-warning-triangle.svg';
import icWarningTriangleRed from '@assets/icons/ic-warning-triangle-red.svg';
import icWarningTriangleWhite from '@assets/icons/ic-warning-triangle-white.svg';
import icWarningWhite from '@assets/icons/ic-warning-white.svg';
import icWatch from '@assets/icons/ic-watch.svg';
import icWithdraw from '@assets/icons/ic-withdraw.svg';
import icWithdrawActive from '@assets/icons/ic-withdraw-active.svg';
import icWithdrawRed from '@assets/icons/ic-withdraw-red.svg';
import icWithdrawShadow from '@assets/icons/ic-withdraw-shadow.svg';
import icYields from '@assets/icons/ic-yields.svg';
import icEmptyCrowdfundingManagementLogs from
  '@assets/icons/ic-empty-crowdfunding-management-logs.svg';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  icAccounts,
  icActivationPaymentPlan,
  icActiveSession,
  icActualRetailPrice,
  icAdd,
  icAddFunds,
  icAddFundsActive,
  icAddFundsColor,
  icAnnualLeasing,
  icApproved,
  icArrow,
  icArrowCircle,
  icArrowOutlined,
  icArrowOutlinedBlue,
  icArrowRound,
  icAutomatedInvoice,
  icAvatar,
  icBack,
  icBackDark,
  icBackFilled,
  icBackWhite,
  icBank,
  icBaseAnnualProfitability,
  icBrickPrice,
  icBricksBuild,
  icBricksPile,
  icBricksSummary,
  icBrickWithCircularArrow,
  icBuildingOne,
  icBuildingThree,
  icBuildingTwo,
  icBuy,
  icBuyActive,
  icCalendar,
  icCalendarGrey,
  icCancelInvestmentModal,
  icCannotRemoveBricksOnSale,
  icCapitalGainTerm,
  icCheckmarkCircle,
  icCheckSmall,
  icCheckWhite,
  icClockExpiring,
  icClose,
  icCodeMail,
  icCompletedProperty,
  icCompleteProfile,
  icConfirmPayPaymentPlan,
  icCosts,
  icCoupon,
  icCouponMoney,
  icCouponWithDolarIcon,
  icCouponWithDolarIconActive,
  icCrowdfundingPeriods,
  icCrown,
  icDone,
  icDownload,
  icEdit,
  IcEmail,
  icEmailVerification,
  icEmptyCrowdfundingDeliveries,
  icEmptyCrowdfundingManagementLogs,
  icEmptyCurrentBricks,
  icEmptyEscrow,
  icEmptyInvoicingData,
  icEmptyLeaseholders,
  icEmptyMyBricksPerProperty,
  icEmptyOnSaleBricks,
  icEmptySoldBricks,
  icEmptyUnits,
  icEnrollment,
  icEnrollmentActive,
  icEstimatedAnnualLeasing,
  icEstimatedRent,
  IcEyeClosed,
  IcEyeOpen,
  icFailedAttemptsLock,
  icFailedVerification,
  icFile,
  icFirma,
  icFirstSteps,
  icFirstStepsActive,
  icGallery,
  icHandshake,
  icHelp,
  icHome,
  icIdBack,
  icIdentificationValidationPending,
  icIdFront,
  icIncompleteProfile,
  icInfo,
  icInformation,
  icInsufficientBricks,
  icInsufficientFunds,
  icInternationalContract,
  icIssueInvoice,
  icLastTransaction,
  icLeavingApp,
  icLocation,
  icLocationGrey,
  IcLocationNew,
  IcLocker,
  icLogout,
  icMaclovio,
  icMail,
  icMailVerificated,
  icManualInvoice,
  icMenu,
  icMexicoFlag,
  icMoneyBag,
  icMoneyBagTime,
  icMyAccount,
  icMyAccountActive,
  icMyInvestments,
  icMyMovements,
  icMyMovementsActive,
  icMyPaymentPlans,
  icMyPaymentPlansActive,
  icMyRents,
  icNewPaymentPlan,
  icNewPlatform,
  IcNext,
  icNextPage,
  icNoBeneficiary,
  icNoBricks,
  icNoInvestments,
  icNotification,
  icOccupancyPercentage,
  icOnSaleBricks,
  icOpenSection,
  icOperator,
  icPassport,
  icPayPaymentPlan,
  icPending,
  icPendingCheck,
  icPercentage,
  icPersonalizeProfile,
  icPhone,
  icPhoneCode,
  icPhoneVerificated,
  icPileOfBricks,
  icPlayRed,
  icPlayWhite,
  icPreviousPage,
  icProfile,
  icProfileActive,
  icProperties,
  icPropertiesActive,
  icPropertiesEnrollment,
  icPropertyAge,
  icPropertyAgeTwo,
  icPropertyManagementStatus,
  icPropertyParking,
  icPropertySale,
  icPropertySize,
  icPropertyType,
  icPropertyTypeTwo,
  icPropertyUnits,
  icReadyToBuy,
  icReadyToSign,
  icRealOwner,
  icRejected,
  icRejectedDocsUpdate,
  icRejectPaymentPlan,
  icRemoveBeneficiary,
  icRemoveBricksOnSale,
  icRent,
  icRfc,
  icSadFace,
  icSadFaceShadow,
  icSadFaceWhite,
  icSatRegistration,
  icSavings,
  icSell,
  icSellActive,
  icSettings,
  icSignature,
  icSingleBrick,
  icSoldBricks,
  icStatement,
  icSuccess,
  icSuccessfulCheck,
  icSuccessfulEmailUpdate,
  icSuccessfulPhoneUpdate,
  icSupportWillContactYou,
  icSystemsBussinesUser,
  icTransactionalProfile,
  icTrashGrey,
  icTrashRed,
  icTwoFactorAuth,
  icUnexpectedError,
  icUnusualAccess,
  icUpdateData,
  icUpdateInfo,
  icUpdateLater,
  icUpgradeAccountModal,
  icUpgradingAccountSuccess,
  icUpToDateSat,
  icUSAFlag,
  icUser,
  icUserGrey,
  icValidation,
  icVerifiedEmail,
  icVolumePrice,
  icWarning,
  icWarningBlue,
  icWarningTriangle,
  icWarningTriangleRed,
  icWarningTriangleWhite,
  icWarningWhite,
  icWatch,
  icWithdraw,
  icWithdrawActive,
  icWithdrawRed,
  icWithdrawShadow,
  icYields
};
