import styled from 'styled-components';

import { Title } from '@components';
import { flexAsRow } from '@components/Styled/mixins';

const ModalTitle = styled(Title)`
  margin: 0;
  text-align: center;
`;

const ModalMessageContainer = styled.div`
  > p:nth-child(1) {
    margin-top: 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  justify-content: right;
`;

const OptionContainer = styled.div`
  ${flexAsRow(2)};

  width: 100%;
  text-align: right;
  justify-content: right;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const LinksContainer = styled.div`
  ${flexAsRow()};

  width: 100%;
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing(4)};

  > button {
    width: 100%;
    white-space: nowrap;
  }

  > button:nth-child(1) {
    text-align: left;
    padding-left: 0;
  }

  > button:nth-child(2) {
    text-align: right;
    padding-right: 0;
  }
`;

const VPNErrorContainer = styled.p`
  background: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.danger.main};
  font-size: ${({ theme }) => theme.toRem(14)};
  line-height: ${({ theme }) => theme.toRem(20)};
  font-family: ${({ theme }) => theme.typography.font.fontFamily};
  margin: 0;
`;

export {
  ModalTitle,
  ModalMessageContainer,
  ButtonContainer,
  OptionContainer,
  LinksContainer,
  VPNErrorContainer
};
