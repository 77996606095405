class BearerToken {
    value;
    constructor(value) {
        this.value = value;
    }
    buildAuthorizationStringHeader() {
        return `Bearer ${this.value}`;
    }
}
export default BearerToken;
