import RevolvingFund from './RevolvingFund';
class RevolvingFunds {
    pagination;
    count;
    items;
    currentBalance;
    targetBalance;
    totalNumberOfBricks;
    currentBalancePerBrick;
    totalIncomes;
    totalExpenses;
    firstMovementDate;
    constructor(data) {
        this.items = data.items.map(item => new RevolvingFund(item));
        this.pagination = data.pagination;
        this.count = data.count;
        this.currentBalance = data.currentBalance;
        this.targetBalance = data.targetBalance;
        this.totalNumberOfBricks = data.totalNumberOfBricks;
        this.currentBalancePerBrick = data.currentBalancePerBrick;
        this.totalIncomes = data.totalIncomes;
        this.totalExpenses = data.totalExpenses;
        this.firstMovementDate = data.firstMovementDate
            ? new Date(data.firstMovementDate)
            : new Date();
    }
}
export default RevolvingFunds;
