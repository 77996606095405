import ProfileError from './ProfileError';

const CANNOT_RESEND_CODE = 'CANNOT_RESEND_CODE';
const INVALID_EMAIL_FORMAT = 'INVALID_EMAIL_FORMAT';
const EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS';
const EMAIL_IS_ALREADY_VERIFIED = 'EMAIL_IS_ALREADY_VERIFIED';
const CANNOT_RESEND_EMAIL = 'CANNOT_RESEND_EMAIL';
const CANNOT_BE_CHANGED_BY_THE_SAME_EMAIL = 'CANNOT_BE_CHANGED_BY_THE_SAME_EMAIL';
const INVALID_CODE = 'INVALID_CODE';

class EmailError extends ProfileError {
  constructor(code, details = null) {
    super(code, 'EmailError', details);
  }

  cannotResendEmail() {
    return this._code === CANNOT_RESEND_EMAIL;
  }

  cannotBeChangedByTheSameEmail() {
    return this._code === CANNOT_BE_CHANGED_BY_THE_SAME_EMAIL;
  }

  cannotResendCode() {
    return this._code === CANNOT_RESEND_CODE;
  }

  isInvalidEmailFormat() {
    return this._code === INVALID_EMAIL_FORMAT;
  }

  isEmailAlreadyExists() {
    return this._code === EMAIL_ALREADY_EXISTS;
  }

  isEmailAlreadyVerified() {
    return this._code === EMAIL_IS_ALREADY_VERIFIED;
  }

  isInvalidCode() {
    return this._code === INVALID_CODE;
  }
}

export default EmailError;
