import React from 'react';

import { Text, Headline2 } from '@components/Styled';
import { notificationsInPhoneImage } from '@assets/images/NotificationImages';

import { NotificationContainer } from '../NotificationCenter.style';

const EmptyListNotification = () => (
  <NotificationContainer>
    <Headline2>No tienes notificaciones</Headline2>

    <img
      src={notificationsInPhoneImage}
      alt="Mano haciendo clic sobre ícono de notificaciones en un celular"
    />

    <Text align="center">
      Aquí verás las notificaciones que te brindarán información relevante sobre tu patrimonio
    </Text>
  </NotificationContainer>
);

export default EmptyListNotification;
