import APIError from '../APIError';

const ALREADY_HAS_FISCAL_REGIME_IN_PROCESS = 'ALREADY_HAS_FISCAL_REGIME_IN_PROCESS';
const AUTOMATIC_INVOICING_ALREADY_SET = 'AUTOMATIC_INVOICING_ALREADY_SET';
const DO_NOT_ALLOW_FOR_TYPE_FISCAL_REGIME = 'DO_NOT_ALLOW_FOR_TYPE_FISCAL_REGIME';
const EMPTY_FILE = 'EMPTY_FILE';
const FISCAL_REGIME_NOT_FOUND = 'FISCAL_REGIME_NOT_FOUND';
const INCORRECT_FILE_EXTENSION = 'INCORRECT_FILE_EXTENSION';
const INVALID_FISCAL_REGIME_NAME = 'INVALID_FISCAL_REGIME_NAME';
const INVESTOR_NOT_FOUND = 'INVESTOR_NOT_FOUND';
const MAXIMUM_FILE_SIZE_EXCEEDED = 'MAXIMUM_FILE_SIZE_EXCEEDED';
const TRADER_NOT_FOUND = 'TRADER_NOT_FOUND';
const UNSUPPORTED_ZIP_CODE = 'UNSUPPORTED_ZIP_CODE';
const INVALID_TAX_ID_FORMAT = 'INVALID_TAX_ID_FORMAT';

class InvoicingError extends APIError {
  constructor(code, details = null) {
    super(code, 'InvoicingError', details);
  }

  isInValidation() {
    return this._code === ALREADY_HAS_FISCAL_REGIME_IN_PROCESS;
  }

  isAlreadySet() {
    return this._code === AUTOMATIC_INVOICING_ALREADY_SET;
  }

  isNotAllowedForFiscalRegimeType() {
    return this._code === DO_NOT_ALLOW_FOR_TYPE_FISCAL_REGIME;
  }

  isEmptyFile() {
    return this._code === EMPTY_FILE;
  }

  isRegimeNotFound() {
    return this._code === FISCAL_REGIME_NOT_FOUND;
  }

  hasWrongFileExtension() {
    return this._code === INCORRECT_FILE_EXTENSION;
  }

  isInvalidFiscalRegimeName() {
    return this._code === INVALID_FISCAL_REGIME_NAME;
  }

  isUnsupportedZipCode() {
    return this._code === UNSUPPORTED_ZIP_CODE;
  }

  isFileSizeExceeded() {
    return this._code === MAXIMUM_FILE_SIZE_EXCEEDED;
  }

  isTraderNotFound() {
    return this._code === TRADER_NOT_FOUND || this._code === INVESTOR_NOT_FOUND;
  }

  isInvalidTaxIdFormat() {
    return this._code === INVALID_TAX_ID_FORMAT;
  }
}

export default InvoicingError;
