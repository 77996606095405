class TransactionalProfile {
    jobTitle;
    numberOfMoves;
    maximumAmount;
    sourceOfResources;
    constructor(data) {
        this.jobTitle = data.jobTitle;
        this.numberOfMoves = data.numberOfMoves;
        this.maximumAmount = data.maximumAmount;
        this.sourceOfResources = data.sourceOfResources;
    }
}
export default TransactionalProfile;
