import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const LegacyCard = ({ id, className, children }) => (
  <section id={id} role="card" className={className} styleName="card">
    {children}
  </section>
);

LegacyCard.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default LegacyCard;
