import React from 'react';
import PropTypes from 'prop-types';

import { ScrollViewWrapper } from './ScrollView.styled';

const ScrollView = ({ children }) => (
  <ScrollViewWrapper id="scroll-view">
    {children}
  </ScrollViewWrapper>
);

ScrollView.propTypes = {
  children: PropTypes.node.isRequired
};

export default ScrollView;
