import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import isEmpty from 'lodash.isempty';
import { Paragraph } from '@components/Styled';
import NotificationFactory from './NotificationFactory';
import { TimedWindow, TimeChip } from './NotificationCenter.style';
const ONE_DAY = 24 * 60 * 60 * 1_000;
const ONE_WEEK = 7 * ONE_DAY;
const buildTimedNotificationsList = (notifications) => {
    const todaysNotifications = [];
    const thisWeeksNotifications = [];
    const restNotifications = [];
    notifications.forEach(notification => {
        const daysOfBeingNotified = notification.getDaysOfBeingNotified(new Date());
        if (daysOfBeingNotified < ONE_DAY) {
            todaysNotifications.push(notification);
        }
        else if (daysOfBeingNotified <= ONE_WEEK) {
            thisWeeksNotifications.push(notification);
        }
        else {
            restNotifications.push(notification);
        }
    });
    return [
        {
            timeStamp: 'Hoy',
            list: todaysNotifications
        },
        {
            timeStamp: 'Esta semana',
            list: thisWeeksNotifications
        },
        {
            timeStamp: 'Hace tiempo',
            list: restNotifications
        }
    ];
};
const TimedNotifications = ({ notifications }) => {
    const timedNotificationsList = buildTimedNotificationsList(notifications);
    return (_jsx("div", { children: timedNotificationsList?.map(({ timeStamp, list }) => (!isEmpty(list) && (_jsxs(TimedWindow, { children: [_jsx(TimeChip, { children: _jsx(Paragraph, { children: timeStamp }) }), list.map(notification => (_jsx(NotificationFactory, { notification: notification }, notification.id)))] }, timeStamp)))) }));
};
export default TimedNotifications;
