import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { flexAsRow } from '@components/Styled/mixins';
import { IconButton } from '@components';

const NavbarDistribution = styled.nav`
  ${flexAsRow()};
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.palette.grayC};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  height: ${({ theme }) => theme.spacing(8)};
  width: 100%;
  z-index: 100;

  > a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    padding-left: ${({ theme }) => theme.spacing(10)};

    > a {
      position: static;
      transform: unset;
    }
  }
`;

const MenuButton = styled.button`
  all: unset;
  aspect-ratio: 1;
  background-color: transparent;
  padding: ${({ theme }) => theme.spacing(1)};

  &:hover {
    background-color: transparent;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

const MenuItems = styled.div`
  ${flexAsRow(3)};
  align-items: center;

  > [hide-mobile="true"] {
    display: none;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    justify-content: space-between;

    > [hide-mobile="true"] {
      display: unset;
    }
  }
`;

const UserAvatar = styled(Link)`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    all: unset;

    ${flexAsRow(1)};

    cursor: pointer;
    justify-content: center;
    align-items: center;

    > img {
      width: ${({ theme }) => theme.spacing(4)};
      height: ${({ theme }) => theme.spacing(4)};
    }

    > p {
      margin: 0;
    }
  }
`;

const LogoutButton = styled(IconButton)`
  padding: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(0.5)};
`;

export {
  LogoutButton,
  MenuButton,
  MenuItems,
  NavbarDistribution,
  UserAvatar
};
