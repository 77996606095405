import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Money } from '@types';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const CanceledPresaleInvestment = (props) => {
    const { notification } = props;
    const currency = new Money(notification.params?.total);
    const property = notification.params?.propertyName ?? '----';
    const bricksText = (notification.params?.totalBricks) === 1
        ? `${notification.params?.totalBricks} Ladrillo`
        : `${notification.params?.totalBricks ?? 0} Ladrillos`;
    return (_jsxs(NotificationContainer, { children: [_jsx(Subtitle, { level: "3", children: "Se ha cancelado tu inversi\u00F3n en preventa" }), _jsxs(Paragraph, { children: ["Has cancelado la inversi\u00F3n de", ' ', _jsx(Bold, { children: bricksText }), ' en ', _jsx(Bold, { children: property }), ", con un total de inversi\u00F3n de", ' ', _jsx(Bold, { children: currency.toString() }), ". No se te cobr\u00F3 penalizaci\u00F3n alguna por esta acci\u00F3n."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default CanceledPresaleInvestment;
