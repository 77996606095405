import { useEffect, useState } from 'react';
const useGeolocation = () => {
    const [geolocationStatus, setGeoLocationStatus] = useState('getting');
    const [isGettingLocation, setIsGettingLocation] = useState(false);
    const getGeolocationStatus = async () => {
        setIsGettingLocation(true);
        const { state } = await navigator.permissions.query({ name: 'geolocation' });
        if (state === 'prompt')
            setGeoLocationStatus('prompt');
        if (state === 'denied')
            setGeoLocationStatus('denied');
        if (state === 'granted')
            setGeoLocationStatus('granted');
        setIsGettingLocation(false);
        return state;
    };
    const getCurrentPosition = (onPermisonsApproved, onPermisonsRejected) => {
        setIsGettingLocation(true);
        navigator.geolocation.getCurrentPosition(position => {
            onPermisonsApproved(position.coords);
            setGeoLocationStatus('granted');
            setIsGettingLocation(false);
        }, () => {
            onPermisonsRejected();
            setGeoLocationStatus('denied');
            setIsGettingLocation(false);
        });
    };
    useEffect(() => {
        getGeolocationStatus();
    }, []);
    return {
        geolocationStatus,
        getCurrentPosition,
        getGeolocationStatus,
        isGettingLocation
    };
};
export default useGeolocation;
