import PaginationError from './PaginationError';
class InvalidParamError extends PaginationError {
    queryParam;
    value;
    constructor(queryParam, value, options) {
        super(`The param ${queryParam} is invalid`, options);
        this.queryParam = queryParam;
        this.value = value;
    }
}
export default InvalidParamError;
