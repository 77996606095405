class AccountProfit {
    accountProfit;
    capitalGain;
    leaseIncomes;
    salesProfit;
    totalDeposits;
    totalInvestments;
    hasInvested;
    constructor(data) {
        this.accountProfit = { amount: data.accountProfitsAmount };
        this.capitalGain = { amount: data.capitalGainAmount };
        this.leaseIncomes = { amount: data.leaseIncomesAmount };
        this.salesProfit = { amount: data.salesProfitsAmount };
        this.totalDeposits = data.totalDeposits;
        this.totalInvestments = data.totalInvestments;
        this.hasInvested = data.hasInvested;
    }
    hasAccountProfit() {
        return Boolean(this.capitalGain.amount)
            || Boolean(this.salesProfit.amount)
            || Boolean(this.leaseIncomes.amount);
    }
    hasInvestment() {
        return this.hasAccountProfit()
            || Boolean(this.totalDeposits.amount)
            || Boolean(this.totalInvestments.amount);
    }
    hasTotalAccountProfit() {
        return this.accountProfit.amount > 0;
    }
    isZeroAccountProfit() {
        return this.accountProfit.amount === 0;
    }
}
export default AccountProfit;
