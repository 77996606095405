import styled from 'styled-components';

import Sidebar from '../Sidebar';

const PublicSidebar = styled(Sidebar)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

export { PublicSidebar };
