/* eslint-disable no-console */
class ConsoleErrorReporter {
    warning(error) {
        this.printAsWarning(error);
    }
    error(error) {
        this.printAsError(error);
    }
    critical(error) {
        this.printAsError(error);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUser(_id, _email) { }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearUser() { }
    printAsWarning(error) {
        console.warn('WARNING', error);
        if (error.cause && error.cause instanceof Error) {
            this.printChainedWarnings(error.cause);
        }
    }
    printChainedWarnings(error) {
        console.warn('Caused by', error);
        if (error.cause && error.cause instanceof Error) {
            this.printChainedWarnings(error.cause);
        }
    }
    printAsError(error) {
        console.error('ERROR', error);
        if (error.cause && error.cause instanceof Error) {
            this.printChainedErrors(error.cause);
        }
    }
    printChainedErrors(error) {
        console.error('Caused by', error);
        if (error.cause && error.cause instanceof Error) {
            this.printChainedErrors(error.cause);
        }
    }
}
export default ConsoleErrorReporter;
