import isEmpty from 'lodash.isempty';
import { HUNDRED_BRICKS_PLATFORM_SECTION_MODELS } from '@constants';
class LastModelVisualizedController {
    sectionName;
    constructor(sectionName) {
        this.sectionName = `lastModelVisualizedIn${sectionName.charAt(0).toUpperCase()
            + sectionName.slice(1)}`;
    }
    setModel(model) {
        localStorage.setItem(this.sectionName, model);
    }
    hasModel() {
        return Boolean(!isEmpty(localStorage.getItem(this.sectionName)));
    }
    isGridModel() {
        return Boolean(localStorage.getItem(this.sectionName) === HUNDRED_BRICKS_PLATFORM_SECTION_MODELS.grid);
    }
    isListModel() {
        return Boolean(localStorage.getItem(this.sectionName) === HUNDRED_BRICKS_PLATFORM_SECTION_MODELS.list);
    }
}
export default LastModelVisualizedController;
