import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';

import useHundredBricksAPI from '@lib/hundredBricksAPI/useHundredBricksAPI';
import { LocalStorageTokenStore } from '@lib/hundredBricksAPI/session';

import AuthenticationAPI from '../../api/v2/AuthenticationAPI';
import createAPIClient from '../../createAPIClient';
import SignupAPI from '../../api/v2/SignupAPI';
import {
  dropInvestorSession as insightsDropInvestorSession
} from '../insightsService/useInsightsService';

import AuthContext from './AuthContext';

const INITIAL_AUTH_DATA = {
  token: null,
  error: null,
  isAuthenticated: false,
  isLoading: false,
  lastUpdatedSession: null
};

const createSignupAPI = () => {
  const apiClient = createAPIClient(null, 'v2');

  return new SignupAPI(apiClient);
};

// TODO: Extart this and use it with the ProfileProvider
const createAuthenticationAPI = token => {
  const apiClient = createAPIClient(token, 'v1');

  return new AuthenticationAPI(apiClient);
};

const AuthProvider = ({ children }) => {
  const { authentication } = useHundredBricksAPI();
  /** The dependency below is causing dependency cycles, for this reason the dependency is loaded
    * via require. Please, always avoid the use of require() calls in any other place under this
    * codebase unless you have a good reason to do so.
    *
    * We need to use the hook useErrorReporter() instead
    */
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const createErrorReporter = require('../../createErrorReporter').default;

  const [authData, setAuthData] = useState(() => {
    try {
      return {
        ...INITIAL_AUTH_DATA,
        token: LocalStorageTokenStore.find()
      };
    } catch (e) {
      return INITIAL_AUTH_DATA;
    }
  });

  const errorReporter = createErrorReporter();

  const signup = useCallback(async (email, password, advertising, trackingPublishingMedia) => {
    setAuthData({ ...authData, isLoading: true });

    let error = null;
    let token = null;

    const api = createSignupAPI();

    try {
      const session = await api.createAccount(
        email,
        password,
        advertising,
        trackingPublishingMedia
      );

      token = session.token;
      LocalStorageTokenStore.save(token);
    } catch (signupError) {
      error = signupError;
      errorReporter.error(signupError);
    } finally {
      setAuthData({
        token,
        error,
        isAuthenticated: !error,
        isLoading: false,
        lastUpdatedSession: token ? Date.now() : null
      });
    }
  }, [authData, errorReporter]);

  // TODO: Add tests
  const login = useCallback(async (
    email,
    password,
    latitude,
    longitude
  ) => {
    setAuthData({ ...authData, isLoading: true });

    let error = null;
    let token = null;

    try {
      const session = await authentication.login(
        email,
        password,
        latitude,
        longitude
      );

      token = session.token;
      LocalStorageTokenStore.save(token);
    } catch (loginError) {
      error = loginError;
    } finally {
      setAuthData({
        token,
        error,
        isAuthenticated: !error,
        isLoading: false,
        lastUpdatedSession: token ? Date.now() : null
      });
    }
  }, [authData, errorReporter]);

  const renewSession = useCallback(async (email, password, longitude, latitude) => {
    setAuthData({ ...authData, isLoading: true });

    let error = null;
    let token = null;

    try {
      const session = await authentication.login(email, password, longitude, latitude);

      token = session.token;
      LocalStorageTokenStore.save(token);
    } catch (loginError) {
      error = loginError;
    } finally {
      setAuthData({
        ...authData,
        token,
        error,
        isLoading: false,
        lastUpdatedSession: token ? Date.now() : null
      });
    }
  }, [authData, errorReporter]);

  const changeSession = useCallback(async (latitude, longitude) => {
    setAuthData({ ...authData, isLoading: true });

    let error = null;
    let token = null;

    try {
      const session = await authentication.changeSession(latitude, longitude);

      token = session.token;
      LocalStorageTokenStore.save(token);
    } catch (loginError) {
      error = loginError;
    } finally {
      setAuthData({
        ...authData,
        token,
        error,
        isLoading: false,
        lastUpdatedSession: token ? Date.now() : null
      });
    }
  }, [authData, errorReporter]);

  const unlockUnusualAccess = useCallback(async ({
    code,
    traderId,
    password,
    latitude,
    longitude
  }) => {
    setAuthData({ ...authData, isLoading: true });

    let error = null;
    let token = null;

    try {
      const session = await authentication.unblockInvestor(
        {
          code,
          traderId,
          password,
          latitude,
          longitude
        }
      );

      token = session.token;
      LocalStorageTokenStore.save(token);
    } catch (unlockError) {
      error = unlockError;
    } finally {
      setAuthData({
        token,
        error,
        isAuthenticated: !error,
        isLoading: false,
        lastUpdatedSession: token ? Date.now() : null
      });
    }
  }, [authData, errorReporter]);

  const logout = useCallback(async () => {
    setAuthData({ ...authData, isLoading: true });

    let error = null;

    try {
      await authentication.logout();
    } catch (logoutError) {
      error = logoutError;
    } finally {
      LocalStorageTokenStore.clear();
      insightsDropInvestorSession();
      setAuthData({ ...INITIAL_AUTH_DATA, isAuthenticated: false });
    }
  }, [authData, errorReporter]);

  const updateSessionToken = token => {
    let updatedToken;

    if (token) {
      updatedToken = token;
    } else {
      updatedToken = authData.token;
    }

    setAuthData({
      ...authData,
      token: updatedToken,
      isAuthenticated: true,
      lastUpdatedSession: Date.now()
    });

    LocalStorageTokenStore.save(updatedToken);
  };

  // TODO: Add tests
  const refresh = useCallback(async () => {
    const api = createAuthenticationAPI(authData.token);

    try {
      const token = await api.refreshToken(authData.token);

      updateSessionToken(token);
    } catch (error) {
      if (error.name !== 'AuthenticationError') {
        errorReporter.error(error);
      }
    }
  }, [authData, errorReporter]);

  const clearSession = useCallback(() => {
    LocalStorageTokenStore.clear();
    setAuthData(INITIAL_AUTH_DATA);
  }, []);

  // TODO: Add tests
  useEffect(() => {
    const validateTokenIsStillAuthenticated = async () => {
      // TODO: Send a ping to the server to see if token still works
      //
      // GET /api/v2/ping
      //
      // {
      //   serverTime: '2022-01-01T00:12:00Z'
      // }
      //
      // POST /api/v2/session/refresh
      //
      // {
      //   serverTime: '2022-01-01T00:12:00Z'
      // }
      //
      // POST /api/v2/login
      //
      // {
      //   token: 'xxxxxxxxx',
      //   serverTime: '2022-01-01T00:12:00Z'
      //   expiredAt: '2022-01-01T00:12:05Z'
      // }
      let { token } = authData;

      if (!token) {
        clearSession();
        return;
      }

      setAuthData({
        ...authData,
        isLoading: true
      });

      let error = null;

      try {
        // TODO: Server should return the server time and wer should handle it
        await authentication.ping();

        updateSessionToken();
      } catch (pingError) {
        // When error is unauthorized then we asume that the token is not longer valid and sessinn
        // has been expired in the backend, so reporting error is not required
        if (pingError.name === 'AuthenticationError' && !pingError.isUnauthorized()) {
          error = pingError;

          errorReporter.error(error);
        }

        token = null;
        LocalStorageTokenStore.clear();
      } finally {
        setAuthData({
          token,
          error,
          isAuthenticated: Boolean(token),
          isLoading: false
        });
      }
    };

    validateTokenIsStillAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authInterface = useMemo(() => ({
    changeSession,
    clearSession, // TODO: Maybe remove this?
    login,
    logout,
    refresh,
    renewSession,
    signup,
    unlockUnusualAccess,
    updateSessionToken,
    ...authData
  }), [
    authData,
    changeSession,
    clearSession,
    login,
    logout,
    refresh,
    renewSession,
    signup,
    unlockUnusualAccess
  ]);

  return (
    <AuthContext.Provider value={authInterface}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export default AuthProvider;
