import React, { useState, useEffect } from 'react';

import { useProfile } from '@lib/profile';

import { LastSessionNotice } from './LastSessionInformer.style';

const SEVEN_SECONDS = 7000;

const LastSessionInformer = () => {
  const { profile, legacyProfile } = useProfile();
  const [investorId, setInvestorId] = useState(null);
  const [showLastSession, setShowLastSession] = useState(false);

  useEffect(() => {
    if (profile && investorId !== profile.id) {
      setInvestorId(profile.id);
      setShowLastSession(true);

      let timeoutId;

      const initTimeout = () => {
        timeoutId = setTimeout(() => {
          setShowLastSession(false);
        }, SEVEN_SECONDS);
      };

      initTimeout();

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }

    setInvestorId(null);
  }, [profile]);

  if (!profile || !legacyProfile) {
    return null;
  }

  const lastSessionDate = new Date(legacyProfile.lastSessionDate);

  if (showLastSession && legacyProfile.lastSessionDate !== null) {
    return (
      <LastSessionNotice>
        {'Último inicio de sesión '}

        <span data-heap-redact-text>{profile?.email?.address}</span>

        {' el '}

        <span>
          {lastSessionDate?.toLocaleDateString('es-MX', { dateStyle: 'medium' })}
        </span>

        {' a las '}

        <span>
          {lastSessionDate?.toLocaleTimeString([], {
            hour: '2-digit', minute: '2-digit', hour12: true
          })}
        </span>
      </LastSessionNotice>
    );
  }

  return null;
};

export default LastSessionInformer;
