import styled, { css } from 'styled-components';

const TitleMediaContainerWrapper = styled.div`
  ${({ children }) => {
    if (!children) return;

    return css`
      font-size: ${({ theme }) => theme.toRem(20)};
      justify-self: center;
      align-self: end;
      padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(4)}`};
      width: 95%;

      > h3 {
        margin: 0;
      }

      > p {
        text-align: center;
        font-size: ${({ theme }) => theme.toRem(24)};
        margin: 0;
      }
    `;
  }}
`;

export default TitleMediaContainerWrapper;
