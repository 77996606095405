import { useEffect, useState } from 'react';

import useRequest from '../useRequest';

/**
  * WARNING: Please do not use this hook anymore it is deprecated
  */
const useRequestWithReloadTrader = callback => {
  /** The dependency below is causing dependency cycles, for this reason the dependency is loaded
    * via require. Please, always avoid the use of require() calls in any other place under this
    * codebase unless you have a good reason to do so.
    *
    * Because this file should be removed, please do not try to improve this or do any refactor
    */
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const { useProfile } = require('@lib/profile');

  const request = useRequest(callback);
  const { reloadProfileData } = useProfile();
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestData, setRequestData] = useState({
    isLoading: false, data: null, error: null
  });

  const makeEnrollmentRequest = async (...args) => {
    const [, , , makeRequest] = request;

    setIsProfileUpdated(false);
    setIsLoading(true);
    makeRequest(...args);
  };

  useEffect(() => {
    const [data, , error] = request;

    const reloadProfile = async () => {
      await reloadProfileData();

      setRequestData({ data, error });
      setIsLoading(false);
      setIsProfileUpdated(true);
    };

    if (isProfileUpdated) { return; }

    if (data) {
      reloadProfile();
    } else if (error) {
      setRequestData({ data, error });
      setIsProfileUpdated(true);
      setIsLoading(false);
    }
  }, [request[0], request[2]]);

  return [
    requestData.data, isLoading, requestData.error, makeEnrollmentRequest
  ];
};

export default useRequestWithReloadTrader;
