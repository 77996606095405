import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, Suspense, useEffect, useState } from 'react';
import Notifications from '@views/Notifications';
import { LoadingOverlay } from '@components';
import { useAuth } from '@lib/authentication';
import { useProfile } from '@lib/profile';
import { useInsightsService } from '@lib/insightsService';
import { dropInvestorSession } from '@lib/insightsService/useInsightsService';
import PrivateRoutes from './private/PrivateRoutes';
import PublicRoutes from './public/PublicRoutes';
const AppRoutes = () => {
    const { setInvestorSession } = useInsightsService();
    const [isInitializing, setIsInitializing] = useState(true);
    const { token, isAuthenticated, isLoading: isLoadingAuthentication } = useAuth();
    const { profile, isLoading: isLoadingProfile } = useProfile();
    const isLoading = isLoadingAuthentication || isLoadingProfile;
    const hasAuthenticatedProfile = isAuthenticated && Boolean(profile);
    useEffect(() => {
        if (!isLoading && profile) {
            setIsInitializing(false);
            setInvestorSession();
        }
        else if (!isAuthenticated && !token) {
            setIsInitializing(false);
            dropInvestorSession();
        }
    }, [isLoading, profile]);
    if (isInitializing) {
        return _jsx(LoadingOverlay, {});
    }
    return (_jsx(Suspense, { fallback: _jsx(LoadingOverlay, {}), children: hasAuthenticatedProfile
            ? (_jsxs(Fragment, { children: [_jsx(Notifications, {}), _jsx(PrivateRoutes, {})] }))
            : (_jsx(PublicRoutes, {})) }));
};
export default AppRoutes;
