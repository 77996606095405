import styled from 'styled-components';

import { Text, Title } from '@components';

const TitleText = styled(Title)`
  text-align: center;
  font-size: ${({ theme }) => theme.toRem(24)};
`;

const DescriptionText = styled(Text)`
  text-align: center;
  font-size: ${({ theme }) => theme.toRem(16)};
  margin: 0;
`;

export { DescriptionText, TitleText };
