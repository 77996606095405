import styled from 'styled-components';

import Title from '../Title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const EditInputContainer = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin: 0;
  }
`;

const EditFieldButton = styled.button`
  aspect-ratio: 1;
  background-color: transparent;
  border: 0;
  border-radius: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(4)};
  margin-left: ${({ theme }) => theme.spacing(1)};
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    background-color: ${({ theme }) => theme.palette.grayC};
  }

  &:active {
    transform: scale(0.9);
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }

  &:focus-visible {
    outline: 1px auto ${({ theme }) => theme.palette.primary.lighter};
  }
`;

const FieldName = styled.label`
  color: ${({ theme }) => theme.palette.grayH};
`;

const FieldPlaceholder = styled(Title)`
  color: ${({ theme }) => theme.palette.grayE};
  font-size: ${({ theme }) => theme.toRem(14)};
  margin: 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${({ theme }) => theme.spacing(30)};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    width: 50%;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}px) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export {
  Container,
  EditInputContainer,
  EditFieldButton,
  FieldName,
  FieldPlaceholder,
  InputWrapper,
  ButtonWrapper
};
