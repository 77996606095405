import React from 'react';
import PropTypes from 'prop-types';

import MediaContainerWrapper from './MediaContainer.style';

const MediaContainer = props => {
  const { backgroundImage, children, gridRowsNumber } = props;

  return (
    <MediaContainerWrapper
      backgroundImage={backgroundImage}
      gridRowsNumber={gridRowsNumber}
    >
      {children}
    </MediaContainerWrapper>
  );
};

MediaContainer.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  gridRowsNumber: PropTypes.string
};

MediaContainer.defaultProps = {
  backgroundImage: '',
  gridRowsNumber: 'none',
  children: []
};

export default MediaContainer;
