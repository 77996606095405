import { APIClient } from './api';

function getBaseURLFromTarget(env) {
  switch (env) {
    case 'production':
      return 'https://api.100ladrillos.com/marketplace/api/';
    case 'staging':
      return 'https://api-staging.100ladrillos.com/marketplace/api/';
    case 'development':
      return 'https://api-dev.100ladrillos.com/marketplace/api/';
    case 'test':
      return 'http://api.test.100ladrillos.com/marketplace/api/';
    default:
      return 'http://localhost:9000/marketplace/api/';
  }
}

export default function createAPIClient(token = null, version = 'v2') {
  if (version === 'v1') {
    return new APIClient(`${getBaseURLFromTarget(process.env.TARGET_ENV)}v1`, token);
  }

  return new APIClient(`${getBaseURLFromTarget(process.env.TARGET_ENV)}v2`, token);
}
