import React from 'react';

import { MediaContainer } from '@components';

import backgroundUpgradeSuccess from '@assets/images/background-sonata-build.webp';

const UpgradeSuccessMediaContiner = () => (
  <MediaContainer backgroundImage={backgroundUpgradeSuccess} />
);

export default UpgradeSuccessMediaContiner;
