import React from 'react';

import {
  ActionMediaContainer,
  ContentMediaContainer,
  MediaContainer,
  TitleMediaContainer
} from '@components';

import { verifyPhoneViewImage } from '../../../../../assets/images/EnrollmentStepImages';
import { DescriptionText, TitleText } from '../../EnrollmentMediaContainer.style';

const VERIFY_PHONE_IMAGE_ALT = 'Personas celebrado el crecimiento de sus inversiones';

const VerifyPhoneMediaContainer = () => (
  <MediaContainer>
    <TitleMediaContainer>
      <TitleText>Te ayudamos a realizar una inversión inteligente</TitleText>
    </TitleMediaContainer>

    <ContentMediaContainer>
      <img src={verifyPhoneViewImage} alt={VERIFY_PHONE_IMAGE_ALT} />
    </ContentMediaContainer>

    <ActionMediaContainer>
      <DescriptionText>
        Los bienes inmuebles son una excelente opción para crear, crecer y proteger tu patrimonio
        en mediano y largo plazo.
      </DescriptionText>
    </ActionMediaContainer>
  </MediaContainer>
);

export default VerifyPhoneMediaContainer;
