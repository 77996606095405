import Address from '../Address';
import AccountLevel from './AccountLevel';
import CURP from './CURP';
import Demographics from './Demographics';
import Email from './Email';
import Identification from './Identification';
import AnnualUpdateLog from './AnnualUpdateLog';
import RFC from './RFC';
import RealOwner from './RealOwner';
import TransactionalProfile from './TransactionalProfile';
class Profile {
    accountLevel;
    address;
    consecutiveNumber;
    curp;
    demographics;
    email;
    firstLastName;
    fullName;
    id;
    identification;
    annualUpdateLog;
    name;
    phone;
    rawData;
    realOwner;
    secondLastName;
    segmentations;
    status;
    pendingApprovalRequests;
    pendingCorrectionRequests;
    pendingSignRequests;
    taxId;
    transactionalProfile;
    constructor(data) {
        this.rawData = data;
        this.id = data.id;
        this.consecutiveNumber = data.consecutiveNumber;
        this.name = data.name;
        this.firstLastName = data.firstLastName;
        this.secondLastName = data.secondLastName;
        this.fullName = data.fullName;
        this.status = data.status;
        this.pendingApprovalRequests = data.pendingApprovalRequests;
        this.pendingCorrectionRequests = data.pendingCorrectionRequests;
        this.pendingSignRequests = data.pendingSignRequests;
        this.accountLevel = new AccountLevel(data.accountLevel, data.frozen);
        this.annualUpdateLog = new AnnualUpdateLog(data.annualUpdateLog);
        this.phone = data.phone ?? {};
        this.email = new Email({ email: data.email, isEmailVerified: data.isEmailVerified });
        this.curp = new CURP(data.curp);
        this.taxId = new RFC(data.taxId);
        this.demographics = new Demographics(data);
        this.realOwner = new RealOwner(data);
        this.address = new Address(data.address ?? {});
        this.identification = new Identification(data.identification ?? {});
        this.transactionalProfile = new TransactionalProfile({
            jobTitle: data.jobTitle,
            ...(data.transactionalProfile ?? {})
        });
        this.segmentations = data.segmentations;
    }
    isDoneRegistering() {
        return !this.status.isRegistering() && !this.status.isIncomplete();
    }
    canBuy() {
        return this.accountLevel.hasLevel();
    }
    hasTransactionalProfile() {
        return (Boolean(this.transactionalProfile.maximumAmount)
            && Boolean(this.transactionalProfile.numberOfMoves)
            && Boolean(this.transactionalProfile.sourceOfResources));
    }
    hasRejectedDocs() {
        return (this.curp.isRejected() || this.identification.isRejected())
            && !this.identification.hasInconsistentData();
    }
    hasIdentificationData() {
        return Boolean(this.curp.value
            && this.address.hasAddress()
            && this.demographics.nationality
            && this.transactionalProfile.jobTitle);
    }
    isLegalPerson() {
        return false;
    }
    isPhysicalInvestor() {
        return true;
    }
}
export default Profile;
