import { PROPERTY_STRATEGIES_NAME, PROPERTY_TYPES } from '@constants';
const DAYS_IN_MILLISECONDS = 1_000 * 60 * 60 * 24;
const NUMBER_OF_BRICKS_TO_HAVE_LAST_BRICKS = 1000;
const NUMBER_OF_DAYS_FOR_A_NEW_PROPERTY = 30;
class CrowdfundingProperty {
    id;
    name;
    riskLevel;
    propertyType;
    investmentStrategy;
    status;
    description;
    highlights;
    operatorId;
    petitionerId;
    yearOfConstruction;
    landSize;
    numberOfUnits;
    backyardSize;
    commercialValue;
    retailPrice;
    isActive;
    createdAt;
    revolvingFundContributionPerBrick;
    thumbnailURL;
    numberOfBricksOwned;
    estimatedCapitalGain;
    capRate;
    estimatedAnnualProfit;
    photos;
    volumeDiscounts;
    address;
    crowdfundingPeriods;
    developer;
    bricks;
    funding;
    deliveryDate;
    estimatedLeasing;
    presaleDiscount;
    constructor(property) {
        this.id = property.id;
        this.name = property.name;
        this.thumbnailURL = property.thumbnailURL;
        this.capRate = property.capRate;
        this.estimatedAnnualProfit = property.estimatedAnnualProfit;
        this.estimatedCapitalGain = property.estimatedCapitalGain;
        this.propertyType = property.type;
        this.riskLevel = property.riskLevel;
        this.investmentStrategy = property.investmentStrategy;
        this.status = property.status;
        this.description = property.description;
        this.highlights = property.highlights;
        this.operatorId = property.operatorId;
        this.petitionerId = property.petitionerId;
        this.yearOfConstruction = property.yearOfConstruction;
        this.landSize = property.landSize;
        this.numberOfUnits = property.numberOfUnits;
        this.backyardSize = property.backyardSize;
        this.commercialValue = property.commercialValue;
        this.retailPrice = property.retailPrice;
        this.isActive = property.isActive;
        this.revolvingFundContributionPerBrick = property.revolvingFundContributionPerBrick;
        this.numberOfBricksOwned = property.numberOfBricksOwned;
        this.photos = property.photos;
        this.volumeDiscounts = property.volumeDiscounts;
        this.address = property.address;
        this.developer = property.developer;
        this.bricks = property.bricks;
        this.funding = property.funding;
        this.estimatedLeasing = property.estimatedLeasing;
        this.createdAt = new Date(property.createdAt);
        this.presaleDiscount = {
            ...property.presaleDiscount,
            expiresAt: new Date(property.presaleDiscount.expiresAt)
        };
        this.deliveryDate = {
            start: new Date(property.deliveryDate.start),
            end: new Date(property.deliveryDate.end)
        };
        this.crowdfundingPeriods = {
            release: {
                targetBricks: property.crowdfundingPeriods.release.targetBricks,
                startingDate: new Date(property.crowdfundingPeriods.release.startingDate),
                endingDate: new Date(property.crowdfundingPeriods.release.endingDate)
            },
            formal: {
                targetBricks: property.crowdfundingPeriods.formal.targetBricks,
                startingDate: new Date(property.crowdfundingPeriods.formal.startingDate),
                endingDate: new Date(property.crowdfundingPeriods.formal.endingDate)
            }
        };
    }
    getPropertyTypeName() {
        return PROPERTY_TYPES[this.propertyType];
    }
    getStrategyName() {
        return PROPERTY_STRATEGIES_NAME[this.investmentStrategy];
    }
    hasAvailableBricks() {
        return Boolean(this.bricks.available > 0);
    }
    hasLastBricks() {
        return Boolean(this.bricks.available > 0 && this.bricks.available < NUMBER_OF_BRICKS_TO_HAVE_LAST_BRICKS);
    }
    isNew() {
        const differenceInDays = this.getDiferenceBetweenDatesInDays(this.createdAt);
        return Boolean(differenceInDays <= NUMBER_OF_DAYS_FOR_A_NEW_PROPERTY);
    }
    hasVolumeDiscounts() {
        return Boolean(this.volumeDiscounts.length > 0);
    }
    hasPresaleDiscounts() {
        return Boolean(this.presaleDiscount.percent > 0);
    }
    getDiferenceBetweenDatesInDays = (creationDate) => {
        const currentDate = new Date();
        const differenceInMilliseconds = Number(currentDate) - Number(creationDate);
        return Math.trunc(differenceInMilliseconds / DAYS_IN_MILLISECONDS);
    };
}
export default CrowdfundingProperty;
