// eslint-disable-next-line import/no-namespace
import * as Sentry from '@sentry/react';
import isEmpty from 'lodash.isempty';
class SentryErrorReporter {
    dsn;
    environment;
    transport;
    constructor(dsn, environment, transport) {
        this.dsn = dsn;
        this.environment = environment;
        this.transport = transport;
        this.initializeSentry();
    }
    warning(error) {
        this.sendToSentry('warning', error);
    }
    error(error) {
        this.sendToSentry('error', error);
    }
    critical(error) {
        this.sendToSentry('fatal', error);
    }
    setUser(id, email) {
        Sentry.setUser({ id, email });
    }
    clearUser() {
        Sentry.setUser(null);
    }
    initializeSentry() {
        const config = {
            dsn: this.dsn,
            environment: this.environment,
            integrations: [new Sentry.Integrations.LinkedErrors()]
        };
        if (this.transport) {
            Sentry.init({
                ...config,
                /**
                 * Add Sentry transport when 100 Lladrillos use the transporter
                 * to do this, please check sentry documentation
                 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/transports/
               */
                transport: this.transport
            });
        }
        else {
            Sentry.init(config);
        }
    }
    sendToSentry(level, error) {
        Sentry.withScope(scope => {
            scope.setLevel(level);
            try {
                const metadata = this.extractErrorMetadata(error);
                if (!isEmpty(metadata)) {
                    Object.keys(metadata).forEach((key) => scope.setContext(key, metadata[key]));
                }
            }
            catch (metadataError) {
                Sentry.captureException(metadataError);
            }
            Sentry.captureException(error);
        });
    }
    extractErrorMetadata(error) {
        const result = {};
        const ignoredProperties = ['cause', 'message', 'name', 'stack'];
        const metadata = Object.getOwnPropertyNames(error)
            .filter(key => !ignoredProperties.includes(key))
            .reduce((filtered, key) => ({ ...filtered, [key]: error[key] }), {});
        result[error.constructor.name] = metadata;
        if (error.cause && error.cause instanceof Error) {
            return { ...result, ...this.extractErrorMetadata(error.cause) };
        }
        return result;
    }
}
export default SentryErrorReporter;
