import axios from 'axios';

import sessionStore from '../sessionStore';

// eslint-disable-next-line import/no-namespace
import * as errorCodes from './errorCodes';

const { UNAUTHORIZED_STATUS_CODE } = errorCodes;
const NEW_SESSION_EXPIRATION_HEADER = 'x-token-expiration-date';

class GatewayAPILegacy {
  constructor(url) {
    const axiosConfig = {
      baseURL: url
    };

    this.instance = axios.create(axiosConfig);

    this.instance.interceptors.response.use(res => {
      const newExpirationDate = res.headers[NEW_SESSION_EXPIRATION_HEADER];
      const toDate = new Date(newExpirationDate);
      const isDate = !Number.isNaN(toDate.getDay());

      if (isDate) {
        // Legacy API support
        const token = sessionStore.load();
        const expirationDate = new Date();

        // Increment static five minutes if the response has a expiration date header. We are
        // ignoring the header value due prossible user device missconfigurations
        expirationDate.setMinutes(expirationDate.getMinutes() + 5);
        token.updateExpirationDate(expirationDate);
        sessionStore.update(token);
        // Legacy API support
      }

      return Promise.resolve(res);
    }, error => {
      const { response = {} } = error;
      const { status } = response;

      if (status === UNAUTHORIZED_STATUS_CODE) {
        sessionStore.clear();
      }

      return Promise.reject(error);
    });
  }

  get(endpoint, params) {
    const headers = this._getAuthorization();
    return this.instance.get(endpoint, { params, ...headers });
  }

  post(endpoint, payload) {
    return this.instance.post(endpoint, payload, this._getAuthorization());
  }

  put(endpoint, payload) {
    return this.instance.put(endpoint, payload, this._getAuthorization());
  }

  delete(endpoint) {
    return this.instance.delete(endpoint, this._getAuthorization());
  }

  _getAuthorization() {
    try {
      const token = this._getToken();

      if (token.isAuthenticated()) {
        return { headers: { Authorization: token.authorizationString } };
      }
    } catch (error) {
      return {};
    }

    return {};
  }

  _getToken() {
    return sessionStore.load();
  }
}

export default GatewayAPILegacy;
