import { useMemo } from 'react';
import { useTheme } from 'styled-components';

const useColor = () => {
  const { palette } = useTheme();

  const colorInterface = useMemo(() => ({
    alternative5: palette.alternative5,
    alternative6: palette.alternative6,
    alternative7: palette.alternative7,
    alternative8: palette.alternative8,
    grayG: palette.grayG,
    info: palette.info,
    palette,
    warning: palette.warning
  }), [palette]);

  return {
    alternative5: colorInterface.alternative5,
    alternative6: colorInterface.alternative6,
    alternative7: colorInterface.alternative7,
    alternative8: colorInterface.alternative8,
    grayG: colorInterface.grayG,
    info: colorInterface.info,
    palette: colorInterface.palette,
    warning: colorInterface.warning
  };
};

export default useColor;
