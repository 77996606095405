import IdentificationError from '../errors/Identification';
import { EnrollmentProfile, Identification } from '../entities/Enrollment';

import GatewayAPI from './GatewayAPI';

const URL_BASE = '/profile/identification';

class IdentificationAPI extends GatewayAPI {
  async updateIdentification(identification) {
    const payload = new FormData();

    switch (identification.type) {
      case 'ine':
        payload.append('frontFile', identification.frontFile);
        payload.append('backFile', identification.backFile);
        break;

      case 'passport':
        payload.append('file', identification.file);
        break;

      default:
        throw new Error(`Invalid identification type: ${identification.type}.`);
    }

    try {
      payload.append('type', identification.type);
      payload.append('identificationNumber', identification.number);

      const { data } = await this._client.put(URL_BASE, payload, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      return new EnrollmentProfile(data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async getLatestIdentification() {
    try {
      const { data } = await this._client.get(`${URL_BASE}/latest`);

      return new Identification(data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async setIdentificationNumber(identificationNumber) {
    try {
      const { data } = await this._client.post(`${URL_BASE}Number`, identificationNumber);

      // return an entity
      return { identificationNumber: data.identificationNumber };
    } catch (error) {
      return this._handleError(error);
    }
  }

  async uploadIdentification(identificationType, identificationFiles) {
    const payload = new FormData();

    switch (identificationType) {
      case 'ine':
        payload.append('frontFile', identificationFiles.frontFile);
        payload.append('backFile', identificationFiles.backFile);
        break;

      case 'passport':
        payload.append('file', identificationFiles.file);
        break;

      default:
        throw new Error(`Invalid identification type: ${identificationType}.`);
    }

    try {
      payload.append('type', identificationType);

      const { data } = await this._client.post(URL_BASE, payload, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      // return an entity
      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  async correctIdentification(identificationType, identificationNumber, identificationFiles) {
    const payload = new FormData();

    switch (identificationType) {
      case 'ine':
        payload.append('frontFile', identificationFiles.frontFile);
        payload.append('backFile', identificationFiles.backFile);
        break;

      case 'passport':
        payload.append('file', identificationFiles.file);
        break;

      default:
        throw new Error(`Invalid identification type: ${identificationType}.`);
    }

    try {
      payload.append('type', identificationType);
      payload.append('identificationNumber', identificationNumber);

      const { data } = await this._client.post(`${URL_BASE}/correct`, payload, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      // return an entity
      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  _handleError = error => {
    if (!error.response) {
      throw IdentificationError.getNetworkError();
    }

    const errorCode = error.response.data.code;
    const identificationError = new IdentificationError(errorCode);

    throw identificationError;
  };
}

export default IdentificationAPI;
