import React from 'react';

import toast from '../components/toast';

const ERROR_MESSAGE = 'Haz alcanzado el monto límite en el que puedes operar, conviértete en'
      + ' usuario sin límites para continuar operando';

const renderExceedsAllowedOperationsAmount = () => toast.danger(
  <p>{ERROR_MESSAGE}</p>,
  {
    time: 5000,
    iconName: 'icWarningWhite',
    iconAlt: 'Mensaje de error'
  }
);

export default renderExceedsAllowedOperationsAmount;
