import ConsoleErrorReporter from './reporters/ConsoleErrorReporter';
import DummyErrorReporter from './reporters/DummyErrorReporter';
import SentryErrorReporter from './reporters/SentryErrorReporter';
class UnknownErrorReporterDriver extends Error {
    constructor(driver) {
        super(`Could not create error reporter due unknown driver '${driver}'`);
        this.name = 'UnknownErrorReporterDriver';
    }
}
const createErrorReporter = (driver) => {
    switch (driver) {
        case 'console':
            return new ConsoleErrorReporter();
        case 'sentry':
            return new SentryErrorReporter(process.env.SENTRY_DSN ?? '', process.env.SENTRY_ENVIRONMENT ?? '', process.env.SENTRY_TRANSPORT ?? '');
        case 'dummy':
            return new DummyErrorReporter();
        default:
            throw new UnknownErrorReporterDriver(driver);
    }
};
export default createErrorReporter;
