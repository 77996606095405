class OnSaleInvestments {
    investments;
    pagination;
    count;
    constructor(data) {
        this.investments = data.investments;
        this.pagination = data.pagination;
        this.count = data.count;
    }
}
export default OnSaleInvestments;
