class CrowdfundingProperty {
    id;
    name;
    riskLevel;
    type;
    status;
    description;
    yearOfConstruction;
    landSize;
    numberOfUnits;
    parkingLotSpaces;
    numberOfBathrooms;
    backyardSize;
    commercialValue;
    retailPrice;
    numberOfBricks;
    reservedBricks;
    bricksAvailable;
    targetAmount;
    vestedAmount;
    minimumBricksToBuy;
    maximumBricksToBuy;
    deliveryStartingDate;
    deliveryEndingDate;
    totalEstimatedLeasing;
    estimatedLeasingPerBrick;
    isActive;
    createdAt;
    revolvingFundContributionPerBrick;
    thumbnailURI;
    videoURL;
    photos;
    developer;
    crowdfundingPeriods;
    address;
    presalePrice;
    hasTraderBricks;
    operatorId;
    petitionerId;
    highlights;
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.riskLevel = data.riskLevel;
        this.type = data.type;
        this.status = data.status;
        this.description = data.description;
        this.yearOfConstruction = data.yearOfConstruction;
        this.landSize = data.landSize;
        this.numberOfUnits = data.numberOfUnits;
        this.parkingLotSpaces = data.parkingLotSpaces;
        this.numberOfBathrooms = data.numberOfBathrooms;
        this.backyardSize = data.backyardSize;
        this.commercialValue = data.commercialValue;
        this.retailPrice = data.retailPrice;
        this.numberOfBricks = data.numberOfBricks;
        this.reservedBricks = data.reservedBricks;
        this.bricksAvailable = data.bricksAvailable;
        this.targetAmount = data.targetAmount;
        this.vestedAmount = data.vestedAmount;
        this.minimumBricksToBuy = data.minimumBricksToBuy;
        this.maximumBricksToBuy = data.maximumBricksToBuy;
        this.deliveryStartingDate = data.deliveryStartingDate;
        this.deliveryEndingDate = data.deliveryEndingDate;
        this.totalEstimatedLeasing = data.totalEstimatedLeasing;
        this.estimatedLeasingPerBrick = data.estimatedLeasingPerBrick;
        this.isActive = data.isActive;
        this.createdAt = data.createdAt;
        this.revolvingFundContributionPerBrick = data.revolvingFundContributionPerBrick;
        this.videoURL = data.videoURL;
        this.thumbnailURI = data.thumbnailURI;
        this.photos = data.photos;
        this.crowdfundingPeriods = data.crowdfundingPeriods;
        this.developer = data.developer;
        this.address = data.address;
        this.presalePrice = data.presalePrice;
        this.hasTraderBricks = data.hasTraderBricks;
        this.operatorId = data.operatorId;
        this.petitionerId = data.petitionerId;
        this.highlights = data.highlights;
    }
}
export default CrowdfundingProperty;
