import RebatesError from './RebatesError';
class InvalidQueryParam extends RebatesError {
    constructor(queryParams, options) {
        const entries = Object.entries(queryParams);
        const message = 'The following query parameters are invalid'
            + `: ${entries.map(([key, value]) => `['${key}': ${value}]`).join(', ')}`;
        super(message, options);
    }
}
export default InvalidQueryParam;
