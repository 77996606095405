import { TagNotFound } from '../errors';
class UsetifulSegmenter {
    getTag(tag) {
        if (window.usetifulTags[tag]) {
            return [tag, JSON.parse(window.usetifulTags[tag])];
        }
        throw new TagNotFound(`${tag} was not found`);
    }
    getTags() {
        const tags = Object.entries(window.usetifulTags);
        const result = {};
        tags.forEach(([key, value]) => {
            result[key] = JSON.parse(value);
        });
        return result;
    }
    setTag(tag, value) {
        window.usetifulTags[tag] = String(value);
    }
    deleteTag(tag) {
        delete window.usetifulTags[tag];
    }
    clearTags() {
        window.usetifulTags = {};
    }
}
export default UsetifulSegmenter;
