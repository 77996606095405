/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { regularExpressions as regex } from '@utils';

import TextField from '../TextField';

const PHONE_LENGTH = 10;

const normalize = value => {
  const formattedValue = value?.replace(regex.onlyNumbers, '') || '';

  if (formattedValue.length === PHONE_LENGTH) {
    return formattedValue.replace(regex.phoneFormat, '$1 $2 $3');
  }

  return formattedValue;
};

const PhoneField = props => {
  const {
    value,
    inputProps,
    ...restProps
  } = props;

  const [phoneValue, setPhoneValue] = useState(value);

  useEffect(() => {
    setPhoneValue(normalize(value));
  }, [value]);

  return (
    <TextField
      {...restProps}
      value={phoneValue}
      inputProps={{
        maxLength: PHONE_LENGTH,
        ...inputProps
      }}
    />
  );
};

export default PhoneField;
