const getWidthSentence = ({ theme, width }) => {
  if (!width) {
    return null;
  }

  if (width.includes('%')) return `width: ${width};`;

  return `width: ${theme.spacing(width)};`;
};

export default getWidthSentence;
