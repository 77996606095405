import EventsReporter from '../EventsReporter';
import Event from '../../entities/Event';

class UpgradeAccountEventReporter extends EventsReporter {
  FAQClick(isFrozen) {
    const name = 'Account Benefits - Unrestricted Benefits - Clicked - Button - Upgrade Account';
    const properties = {
      'Triggered by': isFrozen ? 'Automatic' : 'User'
    };

    const event = new Event(name, properties);

    this._insightsService.sendEvent(event);
  }

  Completed(isFrozen) {
    const name = 'Upgrade Account - Address Info - Submitted - Button - Continue';
    const properties = {
      'Triggered by': isFrozen ? 'Automatic' : 'User'
    };

    const event = new Event(name, properties);

    this._insightsService.sendEvent(event);
  }

  clickNavbarButton(buttonTitle, currentPath) {
    const name = 'Navbar - Upgrade Account - Clicked - Button - Upgrade Account';

    const properties = {
      'Button Title': buttonTitle,
      path: currentPath
    };

    const event = new Event(name, properties);

    this._insightsService.sendEvent(event);
  }
}

export default UpgradeAccountEventReporter;
