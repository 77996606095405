import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { SiderMenuItemStyled } from './SiderMenuItem.style';

/**
 * This component should be changed to Bricks ui but the aproach
 * shoud be diferent, because we need remove the dependencie from
 * react-router-dom in bricks ui
 */
const SiderMenuItem = props => {
  const {
    to,
    label,
    isExternal,
    isActive,
    hideMenu
  } = props;

  let propsLink = {
    className: 'link',
    to
  };

  if (isExternal) {
    propsLink = {
      ...propsLink,
      target: '_blank'
    };
  }

  if (hideMenu) {
    propsLink = {
      ...propsLink,
      onClick: () => { hideMenu(); }
    };
  }

  return (
    <SiderMenuItemStyled isActive={isActive}>
      <Link {...propsLink}>
        {label}
      </Link>
    </SiderMenuItemStyled>
  );
};

SiderMenuItem.propTypes = {
  /**
   * To open page in another tab
   */
  isExternal: PropTypes.bool,
  /**
   * The link of navigation item
   */
  to: PropTypes.string.isRequired,
  /**
   * The text of navigation item
   */
  label: PropTypes.string.isRequired,
  /**
   * Show as selected
   */
  isActive: PropTypes.bool,
  /**
   * Callback when the item has toggle hide
   */
  hideMenu: PropTypes.func
};

SiderMenuItem.defaultProps = {
  isExternal: false,
  isActive: false,
  hideMenu: () => {}
};

export default SiderMenuItem;
