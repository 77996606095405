/* eslint-disable @typescript-eslint/no-explicit-any */
class HTTPClientError extends Error {
    // eslint-disable-next-line no-useless-constructor
    constructor(...args) {
        // This may looks weird, but we need to extend from Error and make the constructor overloading
        // in order to get the right types for TypeScript compiler
        super(...args);
    }
}
export default HTTPClientError;
