import { CrowdfundingInvestmentError, InternationalPurchaseError } from '../errors';
import { InvestmentSummary } from '../entities/Account';

import GatewayAPI from './GatewayAPI';

class CrowdfundingInvestmentAPI extends GatewayAPI {
  async getBreakdown() {
    try {
      const { data } = await this._client.get('/traders/crowdfundingInvestments/summary');

      return new InvestmentSummary(data);
    } catch (error) {
      return this._handleError(error, CrowdfundingInvestmentError);
    }
  }

  async getHistoricalCapitalGain() {
    try {
      const { data } = await this._client.get('/traders/crowdfundingInvestments/capitalGain');

      return data;
    } catch (error) {
      return this._handleError(error, CrowdfundingInvestmentError);
    }
  }

  async getNumberOfBricks() {
    try {
      const { data } = await this._client.get('/traders/crowdfundingInvestments/numberOfBricks');

      return data;
    } catch (error) {
      return this._handleError(error, CrowdfundingInvestmentError);
    }
  }

  async sendPresaleBricksPurchaseToken({ email, propertyId, sendingChannel }) {
    try {
      const { data } = await this._legacyClient.post(
        '/crowdfundingInvestments/sendPurchaseCode',
        { email, propertyId, sendingChannel }
      );

      return data;
    } catch (error) {
      return this._handleError(error, InternationalPurchaseError);
    }
  }

  async sendInvestmentCancellationToken(payload) {
    try {
      const { data } = await this._legacyClient.post(
        '/crowdfundingInvestments/sendCancellationToken',
        payload
      );

      return data;
    } catch (error) {
      return this._handleError(error, InternationalPurchaseError);
    }
  }

  async cancelInvestment(payload) {
    try {
      const { data } = await this._legacyClient.post(
        '/crowdfundingInvestments/cancelInvestment',
        payload
      );

      return data;
    } catch (error) {
      return this._handleError(error, InternationalPurchaseError);
    }
  }

  async sendPurchaseWithCouponToken(payload) {
    try {
      const { data } = await this._legacyClient.post(
        '/crowdfundingInvestments/sendPurchaseCode',
        payload
      );

      return data;
    } catch (error) {
      return this._handleError(error, InternationalPurchaseError);
    }
  }

  async createCrowdfundingInvestment(payload) {
    try {
      const { data } = await this._legacyClient.post('/crowdfundingInvestments', payload);

      return data;
    } catch (error) {
      return this._handleError(error, InternationalPurchaseError);
    }
  }

  _handleError = (error, APICrowdfundingError) => {
    if (!error.response) {
      throw APICrowdfundingError.getNetworkError();
    }

    const errorCode = error.response.data.code;
    const crowdfundingError = new APICrowdfundingError(errorCode);

    throw crowdfundingError;
  };
}

export default CrowdfundingInvestmentAPI;
