/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
const AuthContext = createContext({
    changeSession: () => { },
    clearSession: () => { },
    error: undefined,
    isAuthenticated: false,
    isLoading: false,
    lastUpdatedSession: new Date(),
    login: () => Promise.resolve(),
    logout: () => { },
    refresh: () => { },
    renewSession: () => Promise.resolve(),
    token: undefined,
    updateSessionToken: () => { }
});
export default AuthContext;
