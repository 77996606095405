import React, { cloneElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { formatDate, regularExpressions as regex } from '@utils';

import Title from '../Title';
import Icon from '../Icon';
import RenderIf from '../RenderIf';
import ActionText from '../ActionText';

import {
  Container,
  EditFieldButton,
  ButtonWrapper,
  EditInputContainer,
  FieldName,
  FieldPlaceholder,
  InputWrapper
} from './EditableInputField.style';

const getObjectType = value => {
  let objectType = Object.prototype.toString.call(value);

  objectType = objectType.replace(regex.squareBrackets, '');

  const [, typeData] = objectType.split(' ');

  return typeData;
};

const EditableInputField = props => {
  const {
    id,
    label,
    currentValue,
    initialValue,
    children,
    error,
    inputFieldProps,
    name,
    placeholder,
    onEditToggle
  } = props;

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [newValue, setNewValue] = useState(currentValue);
  const { dateToSpanish } = formatDate();

  const onCancel = () => {
    let value;

    if (initialValue !== null && typeof initialValue === 'object') {
      if (getObjectType(initialValue) === 'Date') {
        value = dateToSpanish(initialValue);
      } else {
        value = initialValue ? initialValue.id : '';
      }
    } else {
      value = initialValue;
    }

    setIsReadOnly(true);
    onEditToggle(id)(false);

    if (value || value === '') {
      setNewValue(value);

      inputFieldProps.onChange({ target: { value: initialValue, name } });
    }
  };

  const onConfirmEdit = () => {
    setIsReadOnly(true);
    onEditToggle(id)(false);
  };

  const handleBeginEdit = () => {
    setIsReadOnly(false);
    onEditToggle(id)(true);
  };

  const handleChange = ({ target: { value } }) => {
    // TODO: Do not have this type validation here
    if (typeof value === 'object') {
      if (getObjectType(value) === 'Date') {
        setNewValue(dateToSpanish(new Date(value)));
      } else {
        setNewValue(value.label);
      }
    } else {
      setNewValue(value);
    }
  };

  const cloneField = () => (
    cloneElement(children, { ...inputFieldProps, onChange: handleChange, value: newValue })
  );

  useEffect(() => setNewValue(currentValue), [currentValue]);

  return (
    <Container>
      <FieldName>
        {label}
      </FieldName>

      <EditInputContainer>
        <RenderIf condition={isReadOnly}>
          <RenderIf condition={Boolean(newValue)}>
            <Title>
              {newValue}
            </Title>
          </RenderIf>

          <RenderIf condition={!newValue}>
            <FieldPlaceholder>
              {placeholder}
            </FieldPlaceholder>
          </RenderIf>

          <EditFieldButton
            type="button"
            id={`edit-icon-button-${id}`}
            onClick={handleBeginEdit}
          >
            <Icon name="icEdit" alt={`Editar ${label}`} texto height={20} width={20} />
          </EditFieldButton>
        </RenderIf>

        <RenderIf condition={!isReadOnly}>
          <InputWrapper>
            {cloneField()}
            <ButtonWrapper>
              <ActionText
                id={`cancel-edit-input-${id}`}
                size="small"
                color="primary"
                onClick={onCancel}
                variant="text"
              >
                Cancelar
              </ActionText>

              <ActionText
                id={`edit-input-${id}`}
                size="small"
                variant="text"
                color="info"
                onClick={onConfirmEdit}
                disabled={error}
              >
                Confirmar
              </ActionText>
            </ButtonWrapper>
          </InputWrapper>
        </RenderIf>
      </EditInputContainer>
    </Container>
  );
};

EditableInputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  currentValue: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ]),
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  onEditToggle: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  inputFieldProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired
  }).isRequired
};

EditableInputField.defaultProps = {
  currentValue: '',
  initialValue: '',
  label: '',
  error: false,
  placeholder: 'placeholder'
};

export default EditableInputField;
