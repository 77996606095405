/* eslint-disable react/prop-types */
import React from 'react';

import TextField from './EmailField.styles';

const EmailField = props => {
  const {
    name,
    onChange,
    inputProps,
    ...restProps
  } = props;

  const handleChange = event => {
    const { value } = event.target;
    const valueTrim = value.trim();

    const nativeEvent = event.nativeEvent || event;
    const clonedEvent = new nativeEvent.constructor(nativeEvent.type, nativeEvent);

    Object.defineProperty(clonedEvent, 'target', {
      writable: true,
      value: { value: valueTrim, name }
    });

    onChange(clonedEvent);
  };

  return (
    <TextField
      {...restProps}
      type="email"
      onChange={handleChange}
      inputProps={{ name, ...inputProps }}
    />
  );
};

export default EmailField;
