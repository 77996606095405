import PropertyIsNotRegistered from '../PropertyIsNotRegistered';
import HundredBricksAPI from '../../HundredBricksAPI';
import { APIError } from '../../../api';
import { LeaseholdersError } from '../leaseholders';
import { AmenitiesError, Amenity } from '../amenities';
import { Quotation, QuotationError } from './quotation';
import { InvestmentSimulation, InvestmentSimulationError, MinimumBricksForSaleError, NumberOfBricksExceedsAvailable } from './investmentSimulation';
import { InvalidCouponId, InvestorWithInsufficientFunds, InvestorCoupon, InvestorCouponIsNotRegistered, InvestorCouponsError, PropertyWithInsufficientBricks } from './investorCoupons';
import { PresaleLeaseholder } from './presalelLeaseholders';
import { CrowdfundingProperties, CrowdfundingPropertiesError, CrowdfundingProperty } from './crowdfundingProperty';
import { Progress, ProgressError, PropertyProgressIsNotRegistered } from './progress';
class CrowdfundingPropertiesAPI extends HundredBricksAPI {
    async getCrowdfundingProperties() {
        try {
            const { data } = await this.client.get('v2/crowdfundingProperties');
            const crowdfundingProperties = data.items.map(property => (new CrowdfundingProperty(property)));
            return new CrowdfundingProperties(crowdfundingProperties);
        }
        catch (error) {
            if (error instanceof APIError) {
                throw new CrowdfundingPropertiesError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new CrowdfundingPropertiesError('Unexpected error when obtaining crowdfunding properties', { cause: error });
            }
            else {
                throw new CrowdfundingPropertiesError(`Unexpected error when obtaining crowdfunding properties: '${error}'`);
            }
        }
    }
    async getProgress(propertyId) {
        try {
            const { data } = await this.client.get(`v2/crowdfundingProperties/${propertyId}/progress`);
            return new Progress(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'PROGRESS_NOT_FOUND':
                        throw new PropertyProgressIsNotRegistered(propertyId, { cause: error });
                    default:
                        throw new ProgressError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new ProgressError('Unexpected property progress error', { cause: error });
            }
            else {
                throw new ProgressError(`Unexpected property progress error: '${error}'`);
            }
        }
    }
    async getLeaseholders(propertyId) {
        try {
            const { data } = await this.client.get(`v2/crowdfundingProperties/${propertyId}/leaseholders`);
            return data.items.map(leaseholder => new PresaleLeaseholder(leaseholder));
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'CROWDFUNDING_PROPERTY_NOT_FOUND':
                        throw new PropertyIsNotRegistered(propertyId, 'crowdfunding', { cause: error });
                    default:
                        throw new LeaseholdersError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new LeaseholdersError('Unexpected presale property leaseholders error', { cause: error });
            }
            else {
                throw new LeaseholdersError(`Unexpected presale property leaseholders error: '${error}'`);
            }
        }
    }
    async getAmenities(propertyId) {
        try {
            const { data } = await this.client.get(`v2/crowdfundingProperties/${propertyId}/amenities`);
            return data.items.map(amenity => this.buildPropertyAmenity(amenity));
        }
        catch (error) {
            if (error instanceof APIError) {
                throw new AmenitiesError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new AmenitiesError('Unexpected presale property amenities error', { cause: error });
            }
            else {
                throw new AmenitiesError(`Unexpected presale property amenities error: '${error}'`);
            }
        }
    }
    async getInvestorCoupons(propertyId) {
        try {
            const { data } = await this.client.get(`v1/crowdfundingProperties/${propertyId}/promotionalCoupons`);
            return data.map(coupon => new InvestorCoupon(coupon));
        }
        catch (error) {
            if (error instanceof APIError) {
                throw new InvestorCouponsError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new InvestorCouponsError('Unexpected error when obtaining crowdfunding investor coupons', { cause: error });
            }
            else {
                throw new InvestorCouponsError(`Unexpected error when obtaining crowdfunding investor coupons: '${error}'`);
            }
        }
    }
    async validateInvestorCoupon(couponId) {
        try {
            const { data } = await this.client.get(`v1/promotionalCoupons/${couponId}/validate`);
            return new InvestorCoupon(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_ID':
                        throw new InvalidCouponId(couponId, { cause: error });
                    case 'PROMOTIONAL_COUPON_NOT_FOUND':
                        throw new InvestorCouponIsNotRegistered({ cause: error });
                    case 'INSUFFICIENT_FUNDS':
                        throw new InvestorWithInsufficientFunds({ cause: error });
                    case 'INSUFFICIENT_BRICKS':
                        throw new PropertyWithInsufficientBricks({ cause: error });
                    default:
                        throw new InvestorCouponsError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new InvestorCouponsError('Unexpected error when validating crowdfunding investor coupon', { cause: error });
            }
            else {
                throw new InvestorCouponsError(`Unexpected error when validating crowdfunding investor coupon: '${error}'`);
            }
        }
    }
    async calculateQuotation(propertyId, bricksQuantity) {
        try {
            const { data } = await this.client.get(`v1/crowdfundingProperties/${propertyId}/quoting?bricks=${bricksQuantity}`);
            return new Quotation(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                throw new QuotationError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new QuotationError('Unexpected error when calculating crowdfunding quotation', { cause: error });
            }
            else {
                throw new QuotationError(`Unexpected error when calculating crowdfunding quotation: '${error}'`);
            }
        }
    }
    async getInvestmentSimulation(propertyId, numberOfBricks, numberOfYears) {
        try {
            // eslint-disable-next-line max-len
            const urlParams = `${propertyId}/simulateInvestment?bricksAmount=${numberOfBricks}&numberOfYears=${numberOfYears}`;
            const { data } = await this.client.get(`v1/crowdfundingProperties/${urlParams}`);
            return new InvestmentSimulation(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'BRICK_NUMBER_EXCEEDS_AVAILABLE':
                        throw new NumberOfBricksExceedsAvailable(numberOfBricks, { cause: error });
                    case 'MINIMUM_BRICKS_NOT_MET':
                        throw new MinimumBricksForSaleError(numberOfBricks, { cause: error });
                    default:
                        throw new InvestmentSimulationError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new InvestmentSimulationError('Unexpected error when getting crowdfunding investment simulation', { cause: error });
            }
            else {
                throw new InvestmentSimulationError(`Unexpected error when getting crowdfunding investment simulation: '${error}'`);
            }
        }
    }
    buildPropertyAmenity(amenity) {
        return new Amenity({ ...amenity, property: amenity.crowdfundingProperty });
    }
}
export default CrowdfundingPropertiesAPI;
