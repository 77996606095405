import config from '../config';

import GatewayAPILegacy from './gatewayAPILegacy';

const target = process.env.TARGET_ENV;
const { baseUrl, port } = config[target];

const getBaseURL = () => {
  let url = `${baseUrl}:${port}/marketplace/api/v1`;

  if (target === 'production') {
    url = 'https://api.100ladrillos.com/marketplace/api/v1';
  }

  if (target === 'staging') {
    url = 'https://api-staging.100ladrillos.com/marketplace/api/v1';
  }

  if (target === 'test') {
    url = 'http://api.test.100ladrillos.com/marketplace/api/v1';
  }

  if (target === 'development') {
    url = 'https://api-dev.100ladrillos.com/marketplace/api/v1';
  }

  return url;
};

class Api extends GatewayAPILegacy {
  constructor() {
    super(getBaseURL());
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Api();
