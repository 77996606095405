import Event from '../../entities/Event';

const LOCATION = {
  Crowdfunding: 'Presale',
  'Secondary Market': 'On Sale'
};

class PurchaseEvents {
  tabClicked(properties) {
    const { market } = properties;

    const name = `Marketplace - Properties List - Clicked - Tab - ${market}`;

    return new Event(name, properties);
  }

  propertyCardModelClicked(properties) {
    const { market, cardModel } = properties;

    const name = `Marketplace - Properties List - Clicked - ${market} - CardModel - ${cardModel}`;

    return new Event(name, properties);
  }

  propertyClick(properties) {
    const { propertyMarket, cardFormatType } = properties;

    const location = LOCATION[propertyMarket];

    const name = (
      `Marketplace ${location} - Properties List - Clicked - Property - ${cardFormatType}Card`
    );

    return new Event(name, properties);
  }

  propertyDetailTabClick(properties) {
    const { propertyMarket, tabTitle } = properties;

    const location = LOCATION[propertyMarket];

    const name = `Marketplace ${location} - Property Detail - Clicked - Tab - ${tabTitle}`;

    return new Event(name, properties);
  }

  startedPurchase(properties) {
    const { propertyMarket } = properties;

    const location = LOCATION[propertyMarket];

    const name = `Marketplace ${location} - Property Detail - Clicked - Button - Buy`;

    return new Event(name, properties);
  }

  completedPurchase(properties) {
    const { propertyMarket } = properties;

    const location = LOCATION[propertyMarket];

    const name = `Marketplace ${location} - Buy - Clicked - Button - Buy`;

    return new Event(name, properties);
  }

  oneTimePasswordTyped(properties) {
    const { propertyMarket } = properties;

    const location = LOCATION[propertyMarket];

    const name = `Marketplace ${location} - Buy - Typed - Input - OTP`;

    return new Event(name, properties);
  }
}

export default PurchaseEvents;
