import { ENROLLMENT_STATUSES } from '@constants';
class Status {
    name;
    pendingActivities;
    completedActivities;
    constructor(data) {
        this.name = data?.name ?? ENROLLMENT_STATUSES.registering;
        this.pendingActivities = data?.pendingActivities ?? [];
        this.completedActivities = this.getCompletedStepArrayName(data?.completedActivities);
    }
    getCompletedStepArrayName(completedActivities) {
        const completedActivitiesArrayName = completedActivities?.map(item => item.name);
        return [...new Set(completedActivitiesArrayName)];
    }
    isRegistering() {
        return this.name === ENROLLMENT_STATUSES.registering;
    }
    isIncomplete() {
        return this.name === ENROLLMENT_STATUSES.incomplete;
    }
    isEnrolling() {
        return (this.isRegistering() || this.isIncomplete());
    }
    isSigningDocuments() {
        return this.name === ENROLLMENT_STATUSES.signingDocuments;
    }
    isDocumentsSigned() {
        return this.name === ENROLLMENT_STATUSES.documentsSigned;
    }
    isValidatingDocuments() {
        return this.name === ENROLLMENT_STATUSES.validatingDocuments;
    }
    isRestricted() {
        return this.name === ENROLLMENT_STATUSES.restricted;
    }
    isUpgradingAccount() {
        return this.name === ENROLLMENT_STATUSES.upgradingAccount;
    }
    isCorrectingData() {
        return this.name === ENROLLMENT_STATUSES.correctingData;
    }
    isUnrestricted() {
        return this.name === ENROLLMENT_STATUSES.unrestricted;
    }
    isPendingToVerifyEmail() {
        return this.pendingActivities.includes('verify_email');
    }
    isReadyToSignRiskForm() {
        return this.pendingActivities.includes('sign_risk_form') && this.pendingActivities.length === 1;
    }
}
export default Status;
