import { EnrollmentProfile } from '@api/entities/Enrollment';

import NetworkError from '../errors/NetworkError';
import AddressError from '../errors/Address';

import GatewayAPI from './GatewayAPI';

class AddressAPI extends GatewayAPI {
  async register(address) {
    try {
      const { data } = await this._client.post('/profile/address', address);

      return new EnrollmentProfile(data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  async update(address) {
    try {
      const { data } = await this._client.put('/profile/address', address);

      return new EnrollmentProfile(data);
    } catch (error) {
      return this._handleError(error);
    }
  }

  _handleError(error) {
    if (!error.response) {
      throw new NetworkError();
    }

    const { response } = error;
    const { code } = response.data;

    throw new AddressError(code);
  }
}

export default AddressAPI;
