const errorMap = (code, errorProps) => {
  const { maximumBricksToBuy } = errorProps;

  const errors = {
    BRICK_QUANTITY_EXCEEDS_AVAILABLE: `Tu máximo de compra para esta propiedad
    es de ${maximumBricksToBuy} Ladrillos.`,
  };

  return errors[code] || '';
};

export default errorMap;
