import React from 'react';

import Loader from '@assets/images/loading.gif';

import './style.scss';

const LoadingOverlay = () => (
  <div styleName="container">
    <img src={Loader} alt="Cargando" />
    <p>Estamos cargando tu información</p>
  </div>
);

export default LoadingOverlay;
