class HeapInsightsDriver {
  sendEvent(event) {
    window.heap.track(event.name, event.properties);
  }

  anonymizeInvestorIdentity() {
    window.heap.resetIdentity();
  }

  identifyInvestor(id) {
    window.heap.identify(id);
  }

  setEventProperties(properties) {
    window.heap.clearEventProperties();
    window.heap.addEventProperties(properties);
  }

  setProfileProperties(profileProperties) {
    window.heap.addUserProperties(profileProperties);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new HeapInsightsDriver();
