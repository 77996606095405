import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { RenderIf } from '@components';

import placeholder from '@assets/images/image-placeholder.png';
import Loader from '@assets/images/cargador.gif';

import {
  ErrorText,
  GifLoader,
  ImageButton,
  ImageGridWrapper
} from './ImageGrid.style';

const GRID_IMAGES = 8;

const GridSkeleton = () => (
  <Fragment>
    {Array.from({ length: GRID_IMAGES }).map((_, index) => (
      <ImageButton key={index}>
        <img src={placeholder} alt="Imagen en progreso" />

        <GifLoader>
          <img src={Loader} alt="Animación de cargando" />
        </GifLoader>
      </ImageButton>
    ))}
  </Fragment>
);

const ImageGrid = props => {
  const {
    error,
    images,
    isLoading,
    onSelectImage,
    selectedImage
  } = props;

  return (
    <Fragment>
      <RenderIf condition={Boolean(error && !images?.length)}>
        <ErrorText>
          Algo salió mal durante la carga de imágenes.
          Este dialogo aparecerá más tarde y podrás intentarlo de nuevo
        </ErrorText>
      </RenderIf>

      <ImageGridWrapper>
        <RenderIf condition={Boolean(isLoading && !images?.length)}>
          <GridSkeleton />
        </RenderIf>

        <RenderIf condition={Boolean(images?.length)}>
          {images?.slice(0, GRID_IMAGES).map((image, index) => (
            <ImageButton
              id={`image-${index}`}
              key={image.id}
              isActive={selectedImage === image.id}
              onClick={() => onSelectImage(image.id)}
            >
              <img src={image.url} alt={`Imagen aleatoria ${index}`} />
            </ImageButton>
          ))}
        </RenderIf>
      </ImageGridWrapper>
    </Fragment>
  );
};

ImageGrid.defaultProps = {
  error: false,
  images: [],
  selectedImage: null
};

ImageGrid.propTypes = {
  error: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })),
  isLoading: PropTypes.bool.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  selectedImage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

export default ImageGrid;
