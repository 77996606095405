import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { flexAsColumn, flexAsRow } from '@components/Styled/mixins';
import { UpgradeAccountButton } from '@components';

import withUpgradeAccountButton from '../../../../hoc/withUpgradeAccountButton';

const UpgradeAccount = withUpgradeAccountButton(UpgradeAccountButton);

const UpgradeButton = styled(UpgradeAccount)`
  display: block;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

const ValueHolder = styled.div`
  ${flexAsColumn()};
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.gray[200]};
`;

const NavList = styled.nav`
  ${flexAsColumn(0.5)};

  padding: ${({ theme }) => theme.spacing(2)};
`;

const NavItem = styled(Link)`
  ${flexAsRow(2)};

  justify-content: start;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(1)}`};
  text-decoration: unset;
  border-radius: ${({ theme }) => theme.borderRadius()};
  color: ${({ theme }) => theme.colors.brand.main[900]};
  background-color: ${({ isActive, theme }) => (isActive && theme.colors.secondary.lightBlue)};

  &:hover {
    background-color: ${({ isActive, theme }) => (!isActive && theme.colors.neutral.gray[100])};;
  }

  > img {
    filter: ${({ isActive }) => (isActive ? 'unset' : 'grayscale(100%)')};
    opacity: ${({ isActive }) => (isActive ? '100%' : '30%')};
  }

  transition: all 0.2s;
`;

const MobileNavItem = styled(NavItem)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    display: none;
  }
`;

const ExternalNavItem = styled.a`
  ${flexAsRow(2)};

  justify-content: start;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(1)}`};
  text-decoration: unset;
  border-radius: ${({ theme }) => theme.borderRadius()};
  color: ${({ theme, isActive }) => (
    isActive ? theme.colors.brand.accent : theme.colors.brand.main[900]
  )};
`;

export {
  ExternalNavItem,
  MobileNavItem,
  NavItem,
  NavList,
  UpgradeButton,
  ValueHolder
};
