class Advisor {
    firstName;
    lastName;
    email;
    phoneNumber;
    photoURL;
    constructor(data) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.phoneNumber = data.phoneNumber;
        this.photoURL = data.photoURL;
    }
    isValid() {
        return Boolean(this.firstName
            && this.lastName
            && this.email
            && this.phoneNumber
            && this.photoURL);
    }
}
export default Advisor;
