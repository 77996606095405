import React from 'react';
import PropTypes from 'prop-types';

import { regularExpressions as regex } from '@utils';

import TextField from '../TextField';

const POSTAL_CODE_LENGTH = 5;

const normalize = value => {
  const formattedValue = value.replace(regex.onlyNumbers, '');

  return formattedValue;
};

const PostalCodeField = props => {
  const {
    onChange,
    inputProps,
    ...restProps
  } = props;

  const handleChange = event => {
    const inputEvent = event;
    inputEvent.target.value = normalize(inputEvent.target.value);

    onChange(inputEvent);
  };

  return (
    <TextField
      {...restProps}
      onChange={handleChange}
      inputProps={{
        maxLength: POSTAL_CODE_LENGTH,
        ...inputProps,
      }}
    />
  );
};

PostalCodeField.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputProps: PropTypes.shape({}),
};

PostalCodeField.defaultProps = {
  inputProps: {},
};

export default PostalCodeField;
