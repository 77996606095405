import IncomePeriodStatus from './IncomePeriodStatus';
class IncomePeriod {
    id;
    amountToDisperse;
    netDispersed;
    netFeeAmount;
    taxDispersed;
    taxFeeAmount;
    totalDispersed;
    totalFeesPaid;
    totalRemains;
    feeRate;
    numberOfBricks;
    amountPerBrick;
    createdAt;
    updatedAt;
    startedAt;
    finishedAt;
    status;
    property;
    period;
    constructor(data) {
        this.id = data.id;
        this.period = data.period;
        this.status = new IncomePeriodStatus(data.status);
        this.startedAt = new Date(data.startedAt);
        this.finishedAt = new Date(data.finishedAt);
        this.property = data.property;
        this.numberOfBricks = data.numberOfBricks;
        this.amountPerBrick = data.amountPerBrick;
        this.amountToDisperse = data.amountToDisperse;
        this.netDispersed = data.netDispersed;
        this.netFeeAmount = data.netFeeAmount;
        this.feeRate = data.feeRate;
        this.taxDispersed = data.taxDispersed;
        this.taxFeeAmount = data.taxFeeAmount;
        this.totalDispersed = data.totalDispersed;
        this.totalFeesPaid = data.totalFeesPaid;
        this.totalRemains = data.totalRemains;
        this.createdAt = new Date(data.createdAt);
        this.updatedAt = new Date(data.updatedAt);
    }
}
export default IncomePeriod;
