import styled from 'styled-components';

const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: 0 ${({ theme }) => theme.spacing(4)};

  > * {
    margin: 0;
    text-align: center;
  }

  > ul {
    line-height: ${({ theme }) => theme.spacing(2.5)};
    list-style: none;
    padding: 0;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export { ActionsWrapper, InfoWrapper };
