import APIError from '../APIError';

const INVALID_ID = 'INVALID_ID';
const PROPERTY_NOT_FOUND = 'PROPERTY_NOT_FOUND';
const PROPERTY_DOES_NOT_HAVE_BRICKS_SOLD = 'PROPERTY_DOES_NOT_HAVE_BRICKS_SOLD';

class TransactionalSummaryError extends APIError {
  constructor(code, name, details = null) {
    super(code, name || 'TransactionalSummaryError', details);
  }

  hasInvalidId() {
    return this._code === INVALID_ID;
  }

  isPropertyNotFound() {
    return this._code === PROPERTY_NOT_FOUND;
  }

  doesPropertyHaveNotBricksSold() {
    return this._code === PROPERTY_DOES_NOT_HAVE_BRICKS_SOLD;
  }
}

export default TransactionalSummaryError;
