import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useProfile from '../lib/profile/useProfile';
import useInsightsService from '../lib/insightsService/useInsightsService';

const withUpgradeAccountButton = UpgradeComponent => {
  const UpgradeAccountComponent = props => {
    const { profile } = useProfile();
    const location = useLocation();
    const navigate = useNavigate();
    const [tittle, setTittle] = useState('');
    const [navigateRoute, setNavigateRoute] = useState('');
    const { upgradeAccountEvent, reportInsight } = useInsightsService();

    const sendUpgradeAccountButtonEvent = () => {
      reportInsight(
        upgradeAccountEvent.clickNavbarButton,
        tittle,
        location.pathname
      );
    };

    const handleClick = () => {
      sendUpgradeAccountButtonEvent();
      navigate(navigateRoute);
    };

    useEffect(() => {
      if (profile?.status.isRestricted()) {
        setTittle('Mejorar mi cuenta');
        setNavigateRoute('/upgrade-account/benefits');

        return;
      }

      if (profile?.status.isUpgradingAccount()) {
        setTittle('Continuar mejorando mi cuenta');
        setNavigateRoute('/upgrade-account');

        return;
      }

      if (profile?.status.isCorrectingData()) {
        setTittle('Ir a verificar mis datos');
        setNavigateRoute('/confirm-account-data');
      }
    }, [profile?.status]);

    if (profile?.accountLevel.isLimitless() || !navigateRoute) {
      return null;
    }

    return (
      <UpgradeComponent
        {...props}
        name="upgradeAccountButton"
        onClick={handleClick}
      >
        {tittle}
      </UpgradeComponent>
    );
  };

  return UpgradeAccountComponent;
};

export default withUpgradeAccountButton;
