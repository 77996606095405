import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Text, Title } from '@components';

import ModalWrapper from './CurrentSessionModal.style';

const CurrentSessionModal = ({ isOpen, onClose }) => (
  <ModalWrapper isOpen={isOpen} onClose={onClose}>
    <Title>Ya tienes una sesión abierta</Title>

    <Icon name="icActiveSession" alt="Sesión activa" height={84} width={84} />

    <Text>
      Por tu seguridad solo puedes tener una sesión iniciada en un dispositivo.
    </Text>

    <Text>
      Para iniciar sesión en este dispositivo cierra tu sesión abierta
    </Text>
  </ModalWrapper>
);

CurrentSessionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CurrentSessionModal;
