import React from 'react';

import {
  ActionMediaContainer,
  ContentMediaContainer,
  MediaContainer,
  TitleMediaContainer
} from '@components';

import { investorFullNameViewImage } from '../../../../../assets/images/EnrollmentStepImages';
import { DescriptionText, TitleText } from '../../EnrollmentMediaContainer.style';

const INVESTOR_FULL_NAME_IMAGE_ALT = 'Propiedad con estrellas, dedo arriba e icono de ubicación';

const InvestorFullNameMediaContainer = () => (
  <MediaContainer>
    <TitleMediaContainer>
      <TitleText>Propiedades que lo tienen todo</TitleText>
    </TitleMediaContainer>

    <ContentMediaContainer>
      <img src={investorFullNameViewImage} alt={INVESTOR_FULL_NAME_IMAGE_ALT} />
    </ContentMediaContainer>

    <ActionMediaContainer>
      <DescriptionText>
        Nuestras propiedades cuentan con la mejor calidad de construcción, excelente ubicación e
        inquilinos de primer nivel.
      </DescriptionText>
    </ActionMediaContainer>
  </MediaContainer>
);

export default InvestorFullNameMediaContainer;
