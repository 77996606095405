/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import aficoImg from '@assets/images/partners/afico-logo.svg';
import CONDUSEFLogo from '@assets/images/CONDUSEF_logo.svg';
import CNBVLogo from '@assets/images/partners/cnbv_gray.svg';
import gritechImg from '@assets/images/partners/gritech-club@2x.png';
import guiaImg from '@assets/images/partners/grupo-guia-excelencia-inmobiliaria@2x.png';
import inmobiliareImg from '@assets/images/partners/logo-inmobiliare.png';
import investoImg from '@assets/images/partners/investo-vc@2x.png';
import kfVenturesImg from '@assets/images/partners/kf-ventures@2x.png';
import mindOverImg from '@assets/images/partners/grupo-4-s-360@2x.png';
import tyaImg from '@assets/images/partners/tya@2x.png';
import { goToExternalLink } from '@utils';

import './style.scss';

import FooterLink from './components/FooterLink';

const ARCO_RIGHTS_LINK = 'https://100ladrillos.zendesk.com/hc/es/articles/360001106571-Derechos-ARCO';
const CONFLICT_RESOLUTION_LINK = 'https://100ladrillos.zendesk.com/hc/es/articles/8630080133261--Qui%C3%A9n-atiende-resoluci%C3%B3n-de-controversias-consultas-aclaraciones-o-reclamaciones-en-100-Ladrillos-';
const CONTATC_US_LINK = 'https://100ladrillos.zendesk.com/hc/es/requests/new';
const COSTS_AND_FEES_LINK = 'https://www.100ladrillos.com/investors-commissions';
const EMAIL_CONTACT_LINK = 'mailto:hola@100Ladrillos.com';
const GENERAL_DESCRIPTION_OPERATION_LINK = 'https://100ladrillos.zendesk.com/hc/es/articles/9372213761037-Descripci%C3%B3n-general-de-la-operaci%C3%B3n';
const HOW_IT_WORKS_LINK = 'https://100ladrillos.com/how-it-works';
const PRIVACY_NOTICE_LINK = 'https://100ladrillos.zendesk.com/hc/es/articles/360001074632-Aviso-de-Privacidad';
const PROMOTIONS_LINK = 'https://promociones.100ladrillos.com/';
const TERMS_AND_CONDITIONS_LINK = 'https://100ladrillos.zendesk.com/hc/es/articles/360001099131-T%C3%A9rminos-y-Condiciones';
const UNE_PLATFORM_LINK = 'https://100ladrillos.zendesk.com/hc/es/articles/4417400689293';

const Footer = ({ isMarketplace }) => {
  const contentStyleName = cx({
    content: true,
    'content-marketplace': isMarketplace
  });

  const goToAuthorizationNumber = async () => {
    const url = 'https://www.dof.gob.mx/nota_detalle.php?codigo=5631310&fecha=30/09/2021';

    goToExternalLink(url);
  };

  const goToCondusefPlatform = async () => {
    const url = 'https://www.condusef.gob.mx/';

    goToExternalLink(url);
  };

  return (
    <footer styleName="footer-container">
      <div styleName={contentStyleName}>
        <section styleName="top">
          <div styleName="about">
            <FooterLink
              redirectLink={CONTATC_US_LINK}
              description="Contáctanos >"
            />
            <div styleName="address">
              <p>Edificio SONATA</p>
              <p>Calle Beethoven 287, Piso 3 Int 2A</p>
              <p>Col. Juan Manuel</p>
              <p>Guadalajara, Jalisco 44680</p>
              <p>+(52) 33 4170 8580</p>
              <FooterLink
                redirectLink={EMAIL_CONTACT_LINK}
                description="hola@100Ladrillos.com"
              />
            </div>
          </div>
          <div styleName="brands-container">
            <p styleName="text marginB">ALIADOS</p>
            <div styleName="brands">
              <img src={tyaImg} alt="" styleName="brand-logo tya-logo" />
              <img src={guiaImg} alt="" styleName="brand-logo grupo-guia-excelencia-inmobiliaria" />
              <img src={mindOverImg} alt="" styleName="brand-logo grupo-4s-logo-360" />
              <img src={kfVenturesImg} alt="" styleName="brand-logo logo-kf-ventures-480" />
              <img src={investoImg} alt="" styleName="brand-logo investo-vc-logo-360" />
              <img src={gritechImg} alt="" styleName="brand-logo group" />
              <img src={aficoImg} alt="" styleName="brand-logo group" />
              <img src={inmobiliareImg} alt="" styleName="brand-logo" />
            </div>
          </div>
        </section>
        <section styleName="bottom">
          <div styleName="links-terms">
            <p>100Ladrillos © 2018</p>
            <FooterLink
              redirectLink={TERMS_AND_CONDITIONS_LINK}
              description="Términos y condiciones"
              styleLink="link-content"
            />
            <FooterLink
              redirectLink={PRIVACY_NOTICE_LINK}
              description="Aviso de privacidad"
              styleLink="link-content"
            />
            <FooterLink
              redirectLink={ARCO_RIGHTS_LINK}
              description="Derechos ARCO"
              styleLink="link-content"
            />
          </div>

          <ul styleName="promotions-info">
            <div styleName="items-link">
              <li>
                <p>
                  Consulta términos y condiciones de
                  <FooterLink
                    redirectLink={PROMOTIONS_LINK}
                    description="Promociones"
                    styleLink="link-content"
                  />
                </p>
              </li>
              <li>
                <p>
                  Descripción general de la
                  <FooterLink
                    redirectLink={GENERAL_DESCRIPTION_OPERATION_LINK}
                    description="Operación"
                    styleLink="link-content"
                  />
                </p>
              </li>
            </div>
            <div styleName="items-link">
              <li>
                <p>
                  <FooterLink
                    redirectLink={HOW_IT_WORKS_LINK}
                    description="Cómo Funciona"
                  />
                </p>
              </li>
              <li>
                <p>
                  Unidad Especializada de Atención de Usuarios de Instituciones de Tecnología Financiera
                  <FooterLink
                    redirectLink={UNE_PLATFORM_LINK}
                    description="UNE"
                    styleLink="link-content"
                  />
                </p>
              </li>
            </div>
          </ul>
          <div styleName="resolution-link">
            <p>
              ¿
              <FooterLink
                redirectLink={CONFLICT_RESOLUTION_LINK}
                description="Quién atiende la resolución"
              />
              de controversias, consultas, aclaraciones, o reclamaciones en 100 Ladrillos?
            </p>
          </div>
          <div styleName="disclaimer-info">
            <p styleName="disclaimer">
              ¹ Los rendimientos de esta inversión son estimados y no están asegurados.
            </p>
            <p styleName="disclaimer">
              Plataforma Inmobiliaria Cien Ladrillos, S.A.P.I. de C.V., Institución de
              Financiamiento Colectivo, en términos de la Ley para regular las Instituciones de
              Tecnología Financiera, es una Institución de Tecnología Financiera autorizada,
              regulada y supervisada por las autoridades financieras mexicanas para organizarse
              y operar como Institución de Financiamiento Colectivo.
            </p>
            <p styleName="disclaimer">
              Ni el Gobierno Federal ni las entidades de la administración pública paraestatal
              podrán responsabilizarse o garantizar los recursos de los clientes que sean
              utilizados en las operaciones que celebren con las ITF o frente a otros, así como
              tampoco asumir alguna responsabilidad por las obligaciones contraídas por las ITF o
              por algún cliente frente a otro, en virtud de las operaciones que celebren.
            </p>
            <p styleName="disclaimer">
              Autorizada, regulada y supervisada para la organización y operación de una institución
              de financiamiento colectivo a denominarse Plataforma Inmobiliaria Cien Ladrillos,
              S.A.P.I. de C.V., Institución de Financiamiento Colectivo.
            </p>
            <p styleName="disclaimer">
              Número de autorización:
              <button
                id="authorization-number"
                type="button"
                styleName="link"
                onClick={goToAuthorizationNumber}
              >
                P065/2021
              </button>
            </p>
            <div styleName="cnbv-logo">
              <button
                id="cnbv-logo"
                type="button"
                styleName="button-content"
                onClick={goToAuthorizationNumber}
              >
                <img src={CNBVLogo} alt="Logo de CNBV" />
              </button>
              <button
                id="condusef-logo"
                type="button"
                styleName="button-content"
                onClick={goToCondusefPlatform}
              >
                <img src={CONDUSEFLogo} alt="Logo de CONDUSEF" />
              </button>
            </div>
          </div>
          <div styleName="fees-info">
            <FooterLink
              redirectLink={COSTS_AND_FEES_LINK}
              description="Consulta los Costos y las Comisiones de nuestros productos"
            />
          </div>
        </section>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  isMarketplace: false
};

Footer.propTypes = {
  isMarketplace: PropTypes.bool
};

export default Footer;
