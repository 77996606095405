const scrollTo = (to, offset, parentNode = 'content') => {
  const node = document.getElementById(to);
  const rootNode = document.getElementById(parentNode);

  rootNode.scrollTo({
    top: node.offsetTop - offset,
    behavior: 'smooth'
  });
};

export default scrollTo;
