import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from '../Icon';
import { Subtitle } from '../Styled';

import './style.scss';

const MobileAccordionItem = props => {
  const {
    id,
    title,
    children,
    onClick,
    isOpen,
    className,
    mobileOnly,
    customHeader = {}
  } = props;

  const {
    emphasize,
    iconName: customHeaderIcon
  } = customHeader;

  const itemHeaderStyle = cx({
    'item-header': true,
    open: !!isOpen,
    'mobile-only': mobileOnly,
    emphasize
  });

  const itemBodyStyle = cx({
    'item-body': true,
    open: !!isOpen,
    'mobile-only': mobileOnly
  });

  return (
    <Fragment>
      <button
        id={id}
        styleName={itemHeaderStyle}
        onClick={() => onClick(id)}
        type="button"
      >
        {customHeaderIcon && (
          <Icon name={customHeaderIcon} alt="Abrir / Cerrar" styleName="custom-header-icon" />
        )}
        <Subtitle>{title}</Subtitle>
        <Icon name="icArrow" alt="Abrir / Cerrar" styleName="accordion-control" />
      </button>
      <section className={className} styleName={itemBodyStyle}>
        {children}
      </section>
    </Fragment>
  );
};

const MobileAccordion = ({ className, children }) => (
  <section className={className} styleName="mobile-accordion">
    {children}
  </section>
);

MobileAccordionItem.defaultProps = {
  className: '',
  mobileOnly: false,
  customHeader: {}
};

MobileAccordion.defaultProps = {
  className: ''
};

MobileAccordionItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  mobileOnly: PropTypes.bool,
  customHeader: PropTypes.shape({
    emphasize: PropTypes.bool,
    iconName: PropTypes.string
  })
};

MobileAccordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export { MobileAccordion, MobileAccordionItem };
