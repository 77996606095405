import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const RenderIf = ({ condition, children, ...props }) => {
  if (!condition) {
    return null;
  }

  return (
    <Fragment>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...props
          });
        }

        return child;
      })}
    </Fragment>
  );
};

RenderIf.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

RenderIf.defaultProps = {
  children: null
};

export default RenderIf;
