import styled from 'styled-components';

const LastSessionNotice = styled.small`
  position: fixed;
  width: 100%;
  top: ${({ theme }) => theme.spacing(7)};
  left: 50%;
  font-style: italic;
  text-align: center;
  color:  ${({ theme }) => theme.palette.alternative5.light};
  transform: translate(-50%, 0);
  z-index: 10000;

  > span {
    font-family: ${({ theme }) => theme.typography.title.h3.fontFamily};
    font-style: normal;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}px) {
    transform: unset;
    left: unset;
    width: unset;
    right: ${({ theme }) => theme.spacing(4)};
  }
`;

export { LastSessionNotice };
