import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@components';
import { useAuth } from '@lib/authentication';
import Navbar from './Navbar';
const HOW_IT_WORKS = 'https://www.100ladrillos.com/como-funciona';
const PublicNavbar = ({ onOpenMenu }) => {
    const { isAuthenticated } = useAuth();
    const { pathname } = useLocation();
    return (_jsx(Navbar, { onOpenMenu: onOpenMenu, children: !isAuthenticated && (_jsxs(Fragment, { children: [_jsx(Link, { to: HOW_IT_WORKS, "hide-mobile": "true", children: _jsx(Button, { variant: "text", children: "C\u00F3mo funciona" }) }), pathname === '/signup' ? (_jsx(Link, { to: "/login", "hide-mobile": "true", children: _jsx(Button, { rounded: true, children: "Iniciar sesi\u00F3n" }) })) : (_jsx(Link, { to: "/signup", "hide-mobile": "true", children: _jsx(Button, { rounded: true, children: "Crear cuenta" }) }))] })) }));
};
export default PublicNavbar;
