import EventsReporter from '../EventsReporter';
import Event from '../../entities/Event';

class LogInEventsReporter extends EventsReporter {
  successful() {
    const eventName = 'Log in - Log in snippet - Clicked - Button - Log In';
    const event = new Event(eventName);

    this._insightsService.sendEvent(event);
  }

  emailError(error) {
    const eventName = 'Log in - Error - Clicked - Button - Next';
    const properties = this._createErrorProperties(error);
    const event = new Event(eventName, properties);

    this._insightsService.sendEvent(event);
  }

  logInError(error) {
    const eventName = 'Log in - Error - Clicked - Button - Log In';
    const properties = this._createErrorProperties(error);
    const event = new Event(eventName, properties);

    this._insightsService.sendEvent(event);
  }

  _createErrorProperties(error = {}) {
    const properties = {
      'Error Name': error?.name ?? null,
      'Error Code': error?.code ?? null,
      'Page Title': 'Log in',
      Path: '/login'
    };

    return properties;
  }
}

export default LogInEventsReporter;
