import styled from 'styled-components';

const StepActions = styled.div`
  display: flex;
  padding: 0;

  & > * {
    &:not(:first-child) {
      margin-left: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

export default StepActions;
