import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import logo from '@assets/images/100-ladrillos-logo.svg';
import { useAuth } from '@lib/authentication';
import { Icons, Icon } from '@components';
import { LogoutButton, MenuButton, MenuItems, NavbarDistribution } from './Navbar.style';
const Navbar = ({ children, onOpenMenu }) => {
    const { isAuthenticated, logout } = useAuth();
    return (_jsxs(NavbarDistribution, { children: [_jsx(MenuButton, { id: "menu-button", "aria-label": "Abrir men\u00FA", type: "button", onClick: onOpenMenu, children: _jsx(Icons.HamburgerMenuIcon, {}) }), _jsx(Link, { to: "/", children: _jsx("img", { src: logo, alt: "100 Ladrillos" }) }), _jsxs(MenuItems, { children: [children, isAuthenticated && (_jsx(LogoutButton, { id: "header-logout", onClick: logout, children: _jsx(Icon, { name: "icLogout", alt: "Salir" }) }))] })] }));
};
export default Navbar;
