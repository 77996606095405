import styled from 'styled-components';

import { Button } from '@components';

const SubmitButton = styled(Button)`
  width: ${({ theme }) => `${theme.spacing(16)}`};
`;

const UnusualAccessWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: inherit;
  gap: ${({ theme }) => `${theme.spacing(4)}`};
  margin-top: ${({ theme }) => `${theme.spacing(4)}`};
`;

export { SubmitButton, UnusualAccessWrapper };
