class AccountValue {
    // It is not understood what is the impact of this being private
    amount;
    balance;
    currentInvestedAmount;
    currentInvestedAmountWithoutRevolvingFund;
    valuationAmount;
    crowdfunded;
    investedInBricks;
    revolvingFund;
    available;
    capitalGain;
    incomeToRelease;
    rebates;
    constructor(data) {
        this.amount = data.accountValue;
        this.balance = data.balanceAmount;
        this.currentInvestedAmount = data.currentInvestedAmount;
        this.currentInvestedAmountWithoutRevolvingFund = data.currentInvestedAmountWithoutRevolvingFund;
        this.valuationAmount = data.valuationAmount;
        this.crowdfunded = data.crowdfundedAmount;
        this.investedInBricks = data.investedInBricksAmount;
        this.revolvingFund = data.revolvingFundAmount;
        this.available = data.availableAmount;
        this.capitalGain = data.capitalGain;
        this.incomeToRelease = data.pendingIncomeToRelease;
        this.rebates = data.rebateAmount;
    }
    hasSecondaryMarketInvestment() {
        return this.investedInBricks !== 0;
    }
    hasInvested() {
        return this.crowdfunded !== 0 || this.investedInBricks !== 0;
    }
}
export default AccountValue;
