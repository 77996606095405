class Pagination {
    totalPages;
    perPage;
    page;
    constructor(data) {
        this.totalPages = data.totalPages;
        this.perPage = data.perPage;
        this.page = data.page;
    }
    getCurrentPage() {
        return this.page;
    }
    getItemsPerPage() {
        return this.perPage;
    }
    getTotalPages() {
        return this.totalPages;
    }
    isLastPage() {
        return this.page === this.totalPages;
    }
    isFirstPage() {
        return this.page === 1;
    }
}
export default Pagination;
