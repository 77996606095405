import React from 'react';
import PropTypes from 'prop-types';

import TitleMediaContainerWrapper from './TitleMediaContainer.style';

const TitleMediaContainer = ({ children }) => (
  <TitleMediaContainerWrapper>
    {children}
  </TitleMediaContainerWrapper>
);

TitleMediaContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

TitleMediaContainer.defaultProps = {
  children: ''
};

export default TitleMediaContainer;
