class InvestorCoupon {
    id;
    prefix;
    description;
    code;
    status;
    bricksQuantity;
    unitPrice;
    expiresAt;
    createdAt;
    updatedAt;
    traderId;
    property;
    constructor(coupon) {
        this.id = coupon.id;
        this.prefix = coupon.prefix;
        this.description = coupon.description;
        this.code = coupon.code;
        this.status = coupon.status;
        this.bricksQuantity = coupon.bricksQuantity;
        this.unitPrice = coupon.unitPrice;
        this.expiresAt = new Date(coupon.expiresAt);
        this.createdAt = new Date(coupon.createdAt);
        this.updatedAt = new Date(coupon.updatedAt);
        this.traderId = coupon.traderId;
        this.property = coupon.property;
    }
}
export default InvestorCoupon;
