class HistoricalFundsBalance {
    currentBalance;
    targetAmount;
    historicalBalance;
    constructor(data) {
        this.currentBalance = data.currentBalance;
        this.targetAmount = data.targetAmount;
        this.historicalBalance = data.historicalBalance;
    }
}
export default HistoricalFundsBalance;
