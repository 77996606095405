import styled from 'styled-components';

import { CardContent } from '@components';
import { flexAsColumn, flexAsRow, grid } from '@components/Styled/mixins';
import { Banner } from '@components/UpgradeAccountBanner/UpgradeAccountBanner.style';

const AddFundsGrid = styled.div`
  ${grid(4)};

  padding: ${({ theme }) => theme.spacing(2)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(4)}`};
  }
`;

const AddFundsWrapper = styled.div`
  ${flexAsColumn(4)};

  background-color: ${({ theme }) => theme.palette.background.hbBackgroundA};
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(2)};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(4)}`};
  }

  > h2 {
    margin: 0;
  }
`;

const AddFundsBanner = styled(Banner)`
  ${flexAsColumn()};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    justify-content: center;

    > div:first-of-type {
      ${flexAsColumn(1)};

      justify-content: center;
      max-width: ${({ theme }) => theme.spacing(90)};
    }
  }
`;

const FundsContent = styled(CardContent)`
  > div {
    padding: ${({ theme }) => theme.spacing(2)} 0;
    border-bottom: 2px solid ${({ theme }) => theme.palette.grayB};
    text-align: left;
  }

  > div:last-of-type {
    border: unset;
  }

  button {
    text-align: left;
  }
`;

const Logos = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    ${flexAsRow(2)};

    justify-self: center;
  }
`;

export {
  AddFundsBanner,
  AddFundsGrid,
  AddFundsWrapper,
  FundsContent,
  Logos
};
