import React from 'react';
import PropTypes from 'prop-types';

import {
  MobileStep,
  MobileStepContent,
  MobileStepHeader,
  MobileStepper,
  MobileStepperIndicator
} from './MobileStepper.style';

const MobileSteps = props => {
  const {
    ActionsComponent,
    activeStepIndex,
    steps
  } = props;
  const activeStep = steps[activeStepIndex];

  return (
    <MobileStepper steps={steps} activeStepId={activeStep.id}>
      <MobileStep>
        <MobileStepperIndicator>
          {`Paso ${activeStep.index + 1} de ${steps.length}`}
        </MobileStepperIndicator>

        <MobileStepHeader>{activeStep.name}</MobileStepHeader>

        <MobileStepContent>{activeStep.content}</MobileStepContent>

        {ActionsComponent}
      </MobileStep>
    </MobileStepper>
  );
};

MobileSteps.defaultProps = {
  ActionsComponent: null,
  activeStepIndex: 0
};

MobileSteps.propTypes = {
  ActionsComponent: PropTypes.node,
  activeStepIndex: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.node
  })).isRequired
};

export default MobileSteps;
