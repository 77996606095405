import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import useCountdown from '../../hooks/useCountdown';

const CountdownButton = props => {
  const {
    children,
    disabled,
    seconds,
    ...restProps
  } = props;

  const remainingSeconds = useCountdown(seconds);

  return (
    <Button {...restProps} disabled={remainingSeconds > 0 || disabled}>
      {`${children} ${remainingSeconds || ''}`}
    </Button>
  );
};

CountdownButton.defaultProps = {
  disabled: false
};

CountdownButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  disabled: PropTypes.bool,
  seconds: PropTypes.number.isRequired
};

export default CountdownButton;
