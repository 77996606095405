import styled from 'styled-components';

const MobileStep = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.palette.background.hbBackgroundA};
  display: flex;
`;

const MobileStepHeader = styled.h3`
  ${({ theme }) => theme.typography.heading(3)}

  display: block;
  margin: 0;
  padding: 0;
  text-align: ${({ titleAlignment }) => titleAlignment};
`;

const MobileStepContent = styled.div`
  padding: 0;
  flex: 1;
`;

const MobileStepper = styled.div`
  display: flex;

  background-color: ${({ theme }) => theme.palette.background.hbBackgroundA};
  border-radius: ${({ theme }) => theme.borderRadius()};
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

const MobileStepperIndicator = styled.div`
  ${({ theme }) => theme.typography.font()};

  color: ${({ theme }) => `${theme.palette.alternative1.darker}`};
`;

export {
  MobileStep,
  MobileStepContent,
  MobileStepHeader,
  MobileStepper,
  MobileStepperIndicator
};
