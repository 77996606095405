import Event from '../../entities/Event';

class SignUpEvents {
  started(properties) {
    return new Event('Login started', properties);
  }

  completed(properties) {
    return new Event('Login completed', properties);
  }

  buttonClick(properties) {
    return new Event('Button clicked', properties);
  }
}

export default SignUpEvents;
