const getHeightSentence = ({ theme, height }) => {
  if (!height) {
    return null;
  }

  if (height.includes('%')) return `height: ${height};`;

  return `height: ${theme.spacing(height)};`;
};

export default getHeightSentence;
