import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from 'react';
import { useAPI, useRequest } from '@hooks';
import { renderSomethingWentWrong } from '@utils';
import { Paragraph } from '@components/Styled';
import { useErrorReporter } from '@lib/errorReporter';
import EmailHandler from './EmailHandler';
import PasswordHandler from './PasswordHandler';
import UpdatePasswordSuccess from './UpdatePasswordSuccess';
const STEPS = {
    emailVerification: 'emailVerification',
    newPasswordVerification: 'newPasswordVerification',
    SuccessfulPasswordUpdate: 'SuccessfulPasswordUpdate'
};
const ForgotPassword = () => {
    const { authentication } = useAPI();
    const [email, setEmail] = useState('');
    const errorReporter = useErrorReporter();
    const [currentStep, setStep] = useState(STEPS.emailVerification);
    const [errorMessage, setErrorMessage] = useState('');
    const [sentCodeResponse, isSendingCode, forgotPasswordCodeError, sendForgotPasswordCode] = useRequest(authentication.sendForgotPasswordCode);
    const [updatePasswordResponse, isUpdatingPassword, updatePasswordError, updateForgottenPassword] = useRequest(authentication.updateForgottenPassword);
    const handleSendCode = (acceptedEmail) => {
        setEmail(acceptedEmail);
        setErrorMessage('');
        sendForgotPasswordCode(acceptedEmail);
    };
    const handleSubmitCode = (code, password) => {
        setErrorMessage('');
        updateForgottenPassword({ email, code, password });
    };
    useEffect(() => {
        if (sentCodeResponse) {
            setStep(STEPS.newPasswordVerification);
        }
    }, [sentCodeResponse]);
    useEffect(() => {
        if (updatePasswordResponse) {
            setStep(STEPS.SuccessfulPasswordUpdate);
        }
    }, [updatePasswordResponse]);
    useEffect(() => {
        if (forgotPasswordCodeError) {
            setErrorMessage('Hubo un error al mandar el código.');
            errorReporter.critical(forgotPasswordCodeError);
        }
    }, [forgotPasswordCodeError]);
    useEffect(() => {
        if (updatePasswordError) {
            // V1 Error, should be updated for a v2 endpoint
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            switch (updatePasswordError?.code) {
                case 'INVALID_PASSWORD':
                    setErrorMessage('Min 6 caracteres, 1 mayúscula, 1 número y 1 carácter especial');
                    break;
                case 'INVALID_CODE':
                    setErrorMessage('Código inválido');
                    break;
                default:
                    errorReporter.critical(updatePasswordError);
                    renderSomethingWentWrong();
            }
        }
    }, [updatePasswordError]);
    return (_jsxs(Fragment, { children: [currentStep === STEPS.emailVerification && (_jsx(EmailHandler, { handleSendCode: handleSendCode, isSendingCode: isSendingCode })), currentStep === STEPS.newPasswordVerification && (_jsx(PasswordHandler, { isUpdatingPassword: isUpdatingPassword, resendCode: () => sendForgotPasswordCode(email), handleSubmitPassword: handleSubmitCode })), currentStep === STEPS.SuccessfulPasswordUpdate && (_jsx(UpdatePasswordSuccess, {})), _jsx(Paragraph, { color: "danger-main", children: errorMessage })] }));
};
export default ForgotPassword;
