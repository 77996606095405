import moment from 'moment';

function DateParser(timestamp = Date.now(), options = {}) {
  moment.updateLocale('es', {
    parentLocale: 'es'
  });

  const { ignoreOffset = false } = options;

  if (ignoreOffset) {
    return moment.parseZone(timestamp);
  }

  return moment(timestamp);
}

export default DateParser;
