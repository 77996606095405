import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import CountdownButton from '../CountdownButton';
import Icon from '../Icon';
import Modal from '../Modal';
import Text from '../Text';
import Title from '../Title';

import { ActionButtons, RevokeWarningContainer } from './RevokeWarningModal.style';

const RevokeWarningModal = ({ isOpen, onClose, onConfirm }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <RevokeWarningContainer>
      <Title>Has cambiado tus datos, tendrás que volver a firmar tus contratos</Title>

      <Icon name="icReadyToSign" alt="Actualizar documentos" height={64} width={64} />

      <Text>
        Al firmar nuevamente tus contratos tendrás que esperar a que nuestro equipo de soporte
        valide tus datos. Dentro de este proceso no podrás comprar, ni poner a la venta Ladrillos.
      </Text>

      <ActionButtons>
        <Button
          id="cancel"
          color="secondary"
          onClick={onClose}
        >
          Cancelar
        </Button>

        <CountdownButton
          id="redirect-to-sign-button"
          seconds={10}
          onClick={onConfirm}
        >
          Firmar nuevamente
        </CountdownButton>
      </ActionButtons>
    </RevokeWarningContainer>
  </Modal>
);

RevokeWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default RevokeWarningModal;
