import React from 'react';
import PropTypes from 'prop-types';

import { useCountdown } from '@hooks';

import { formatTime } from '@utils';

const Countdown = ({ seconds, onTimeExpire }) => {
  const { secondsToMinutesAndSeconds } = formatTime();
  const remainingSeconds = useCountdown(seconds, onTimeExpire);

  return <span>{secondsToMinutesAndSeconds(remainingSeconds)}</span>;
};

Countdown.propTypes = {
  seconds: PropTypes.number.isRequired,
  onTimeExpire: PropTypes.func
};

Countdown.defaultProps = {
  onTimeExpire: () => {}
};

export default Countdown;
