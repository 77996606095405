import styled from 'styled-components';

import { flexAsColumn } from '../Styled/mixins';

const KeepOnUpdateLimitlessAccountWrapper = styled.div`
  ${flexAsColumn(2)};
  max-width: 70%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    max-width: 90%;
  }
`;

const IconWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    display: flex;
  }
`;

export { KeepOnUpdateLimitlessAccountWrapper, IconWrapper };
