import { CORRECTION_REQUESTS } from '@constants';
class CorrectionRequest {
    correctionRequests;
    constructor(data) {
        this.correctionRequests = data.pendingCorrectionRequests ?? [];
    }
    hasRequests() {
        return this.correctionRequests.length > 0;
    }
    hasCURPRequest() {
        return this.correctionRequests.some(item => item === CORRECTION_REQUESTS.curp);
    }
    hasIdentificationRequest() {
        return this.correctionRequests.some(item => item === CORRECTION_REQUESTS.identification);
    }
    // This method should be eliminated when there are no longer any investors who are required
    // to sign the contract.
    hasW8ContractRequest() {
        return this.correctionRequests.some(item => item === CORRECTION_REQUESTS.w8Contract);
    }
}
export default CorrectionRequest;
