import React from 'react';
import PropTypes from 'prop-types';

import ContentMediaContainerWrapper from './ContentMediaContainer.style';

const ContentMediaContainer = ({ children }) => (
  <ContentMediaContainerWrapper>
    {children}
  </ContentMediaContainerWrapper>
);

ContentMediaContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export default ContentMediaContainer;
