import React from 'react';
import PropTypes from 'prop-types';

import LoadingLogo from '@assets/images/loading.gif';

import './style.scss';

const BricksLoader = props => {
  const { text } = props;

  return (
    <div tid="bricks-loader-container" styleName="bricks-loader-container">
      <div styleName="bricks-loader-content">
        <img
          src={LoadingLogo}
          alt="Verificando"
          styleName="bricks-loader-logo"
          tid="bricks-loader-logo"
        />
        {text && (
          <span
            styleName="bricks-loader-text"
            tid="bricks-loader-text"
          >
            {text}
          </span>
        )}
      </div>
    </div>
  );
};

BricksLoader.defaultProps = {
  text: ''
};

BricksLoader.propTypes = {
  text: PropTypes.string
};

export default BricksLoader;
