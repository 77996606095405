class SuggestedCURP {
    value;
    firstName;
    firstLastName;
    secondLastName;
    gender;
    birthState;
    birthdate;
    status;
    constructor(suggestedCURPData) {
        this.value = suggestedCURPData.curp;
        this.firstName = suggestedCURPData.firstName;
        this.firstLastName = suggestedCURPData.firstLastName;
        this.secondLastName = suggestedCURPData.secondLastName;
        this.gender = suggestedCURPData.gender;
        this.birthState = suggestedCURPData.birthState;
        this.birthdate = suggestedCURPData.birthdate;
        this.status = suggestedCURPData.status;
    }
}
export default SuggestedCURP;
