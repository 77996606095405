import { jsx as _jsx } from "react/jsx-runtime";
import ErrorReporterContext from './ErrorReporterContext';
const ErrorReporterProvider = ({ reporter, trader, children }) => {
    /*
     * Not all error reporters support user profiles, beasuse of that we should ensure that the
     * provided reporter has support user profiles support before setting up
     */
    if (trader?.id && trader?.email && typeof reporter.setUser === 'function') {
        reporter.setUser(trader.id, trader.email.address);
    }
    else if (!trader?.id && typeof reporter.clearUser === 'function') {
        reporter.clearUser();
    }
    return (_jsx(ErrorReporterContext.Provider, { value: reporter, children: children }));
};
ErrorReporterProvider.defaultProps = {
    trader: { id: '', email: '' }
};
export default ErrorReporterProvider;
