class PresalePrice {
    pricePerBrick;
    discountPercent;
    expiresAt;
    constructor(data) {
        this.pricePerBrick = data.pricePerBrick;
        this.discountPercent = data.discountPercent;
        this.expiresAt = data.expiresAt;
    }
}
export default PresalePrice;
