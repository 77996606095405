import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const ButtonGroup = ({ children }) => {
  return (
    <div styleName="buttons-group">
      {children}
    </div>
  );
};

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
};

export default ButtonGroup;
