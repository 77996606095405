import styled from 'styled-components';

import { flexAsColumn } from '@components/Styled/mixins';

const VerificationWrapper = styled.div`
  ${flexAsColumn(2)}

  align-items: center;

  > div, > form {
    min-width: 30%;
  }

  div {
    width: 100%;
  }

  > form {
    align-items: center;
    margin: ${({ theme }) => theme.spacing(2)} 0;
  }
`;

const OTPContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  > form {
    > div:last-of-type {
      display: none;
    }
  }
`;

export { VerificationWrapper, OTPContainer };
