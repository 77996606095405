import APIError from '../APIError';

const ALREADY_SIGNED = 'ALREADY_SIGNED';
const INVESTOR_HAS_PENDING_CORRECTIONS = 'INVESTOR_HAS_PENDING_CORRECTIONS';
const NOT_READY_TO_SIGN_DOCUMENT = 'NOT_READY_TO_SIGN_DOCUMENT';
const PRIVACY_AGREEMENT_MUST_BE_ACCEPTED = 'PRIVACY_AGREEMENT_MUST_BE_ACCEPTED';
const TERMS_AND_CONDITIONS_MUST_BE_ACCEPTED = 'TERMS_AND_CONDITIONS_MUST_BE_ACCEPTED';
const GEOLOCATION_MISSING_IN_SESSION = 'GEOLOCATION_MISSING_IN_SESSION';

class SignDocumentsError extends APIError {
  constructor(code, details = null) {
    super(code, 'SignDocumentsError', details);
  }

  hasInvalidFormat() {
    return this._code === PRIVACY_AGREEMENT_MUST_BE_ACCEPTED
      || this._code === TERMS_AND_CONDITIONS_MUST_BE_ACCEPTED;
  }

  hasInvestorPendingCorrections() {
    return this._code === INVESTOR_HAS_PENDING_CORRECTIONS;
  }

  isNotReadyToSignDocument() {
    return this._code === NOT_READY_TO_SIGN_DOCUMENT;
  }

  isAlreadySigned() {
    return this._code === ALREADY_SIGNED;
  }

  isGeolocationMissing() {
    return this._code === GEOLOCATION_MISSING_IN_SESSION;
  }
}

export default SignDocumentsError;
