import React from 'react';
import PropTypes from 'prop-types';

import { FormWrapper } from './Form.styled';

const Form = props => {
  const {
    children,
    className,
    id,
    onSubmit
  } = props;

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <FormWrapper id={id} className={className} onSubmit={handleSubmit}>
      {children}
    </FormWrapper>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  onSubmit: () => { }
};

Form.defaultProps = {
  className: undefined,
  id: undefined,
  onSubmit: () => { }
};

export default Form;
