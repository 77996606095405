import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import './style.scss';

const BackButton = props => {
  const {
    handleClick,
    text,
    tid,
    className
  } = props;

  return (
    <button
      id="back-button"
      tid={tid}
      styleName="back-button"
      type="button"
      onClick={handleClick}
      className={className}
    >
      <Icon name="icBackFilled" alt="" height="24" width="24" />
      <span>{text}</span>
    </button>
  );
};

BackButton.defaultProps = {
  tid: '',
  className: ''
};

BackButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  tid: PropTypes.string,
  className: PropTypes.string
};

export default BackButton;
