import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { Icon } from '@components';
import { Headline3, Paragraph } from '@components/Styled';
import { Money } from '@types';
import { MovementInfo, WithdrawalInfo } from './WithdrawalRequest.style';
function formatDate() {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('es-MX', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    });
    const formattedTime = date.toLocaleTimeString('es-MX', {
        hour: '2-digit',
        minute: '2-digit'
    });
    const formattedString = `${formattedDate} / ${formattedTime}`;
    return formattedString;
}
const WithdrawalRequest = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    if (isEmpty(state)) {
        navigate('/movements');
        return undefined;
    }
    const { account, amount } = state;
    const amountAsMoney = new Money(amount).toString();
    const dateAsString = formatDate();
    return (_jsxs(WithdrawalInfo, { children: [_jsx(Icon, { alt: "Icono de \u00E9xito", name: "icCheckmarkCircle", height: 40, width: 40 }), _jsx(Headline3, { children: "\u00A1Solicitud de retiro de fondos enviada exitosamente!" }), _jsx(Icon, { alt: "Icono de retiro", name: "icWithdrawShadow", height: 100, width: 100 }), _jsx(Paragraph, { children: "Se ha solicitado un retiro de fondos por:" }), _jsxs(MovementInfo, { children: [_jsx(Paragraph, { children: `${amountAsMoney} pesos` }), _jsx("hr", {}), _jsx(Paragraph, { children: `Cuenta: ${account}` }), _jsx("hr", {}), _jsx(Paragraph, { children: dateAsString })] }), _jsxs(Paragraph, { children: ['Los retiros se procesan antes de las ', _jsx("strong", { children: "2:00pm del d\u00EDa h\u00E1bil siguiente." })] }), _jsx(Paragraph, { children: "Una vez autorizado y realizado el traspaso de fondos a tu cuenta bancaria te notificaremos." })] }));
};
export default WithdrawalRequest;
