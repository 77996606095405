import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Icons from '../Icons';
import { Paragraph } from '../Styled';
import Icon from '../Icon';
import { Close, ToastContent } from './ToastComponent.style';
const DEFAULT_MILLISECONDS_LIFETIME = 1_000 * 5;
const AVAILABLE_ICONS = {
    success: {
        name: 'icCheckWhite',
        alt: 'Mensaje de éxito'
    },
    info: {
        name: 'icInfo',
        alt: 'Mensaje de información'
    },
    warning: {
        name: 'icWarningWhite',
        alt: 'Mensaje de precaución'
    },
    danger: {
        name: 'icSadFaceWhite',
        alt: 'Mensaje de error'
    }
};
const ToastComponent = (props) => {
    const { color, description, lifeTime = DEFAULT_MILLISECONDS_LIFETIME, onRemove, type } = props;
    const [timer, setTimer] = useState();
    const clearTimer = () => {
        clearTimeout(timer);
        setTimer(undefined);
    };
    const createTimer = (timeToDisappear = lifeTime) => {
        if (timer) {
            clearTimer();
        }
        const newTimer = setTimeout(() => {
            onRemove();
        }, timeToDisappear);
        setTimer(newTimer);
    };
    useEffect(() => {
        createTimer();
    }, []);
    const { name: iconName, alt: iconAlt } = AVAILABLE_ICONS[type];
    return (_jsxs(ToastContent, { color: color, onMouseLeave: () => createTimer(lifeTime / 2), onMouseMove: clearTimer, type: type, children: [_jsx(Icon, { name: iconName, height: 20, width: 20, alt: iconAlt }), _jsx(Paragraph, { children: description }), _jsx(Close, { type: "button", onClick: onRemove, children: _jsx(Icons.CrossIcon, { color: "white", size: "small" }) })] }));
};
export default ToastComponent;
