import { WithdrawError } from '../errors';
import { getAsErrorData, getAsRequestError, isRequestError } from '../errors/errorValidator';
import GatewayAPI from './GatewayAPI';
class WithdrawalAPI extends GatewayAPI {
    async getAvailableAccounts() {
        try {
            const { data } = await this._legacyClient.get('/traders/me/withdrawalAccounts', {});
            return data;
        }
        catch (error) {
            throw this._handleError(error);
        }
    }
    async sendWithdrawalCode(payload) {
        try {
            const { data } = await this._legacyClient.post('/withdrawal/sendWithdrawalCode', payload);
            return data;
        }
        catch (error) {
            throw this._handleError(error);
        }
    }
    async withdrawFunds(payload) {
        try {
            const { data } = await this._legacyClient.post('/withdrawal', payload);
            return data;
        }
        catch (error) {
            throw this._handleError(error);
        }
    }
    _handleError(error) {
        if (!(isRequestError(error))) {
            throw WithdrawError.getNetworkError();
        }
        const { response } = getAsRequestError(error);
        const { code } = getAsErrorData(response?.data);
        throw new WithdrawError(code);
    }
}
export default WithdrawalAPI;
