import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useForm, useRequest } from '@hooks';
import { useProfile } from '@lib/profile';
import { obfuscate, renderSuccessToast } from '@utils';

import Button from '../Button';
import CodeField from '../CodeField';
import SignW8BENContractModal from '../SignW8BENContractModal';
import UpgradeAccountToW8ContractModal from '../UpgradeAccountToW8ContractModal';

import ResendOptions from './ResendOptions';
import {
  CodeSubmissionContact,
  CountdownText,
  TwoFactorForm,
  VerifyActions
} from './TwoFactorConfirmation.style';
import ERROR_CODES from './errorCodes';

const AVAILABLE_CODE_CHANNELS = {
  email: 'Hemos enviado un código único de 6 dígitos a tu correo:',
  sms: 'Hemos enviado un código único de 6 dígitos a tu número celular',
  phoneCall: 'Recibirás tu código de 6 dígitos vía llamada  telefónica a tu número de celular'
};

const CODE_LENGTH = 6;
const AUTHENTICATION_FACTOR_LOCK_TIME = 60 * 5;
const RESEND_DELAY_TIME = 60;

const codeSchema = {
  code: {
    required: {
      message: 'El código de verificación es requerido.'
    },
    length: {
      min: CODE_LENGTH,
      max: CODE_LENGTH,
      message: `El código debe tener ${CODE_LENGTH} caracteres.`
    }
  }
};

const TwoFactorConfirmation = props => {
  const {
    channel: initChannel = 'email',
    disabled,
    onCancel,
    onError,
    onSuccess,
    sendCode,
    senderOptions = ['sms', 'phone', 'email'],
    verifyCode,
    isRequestOfNewAPI
  } = props;

  const [isAbleToResend, setIsAbleToResend] = useState(true);
  const [channel, setChannel] = useState(initChannel);

  const { profile, legacyProfile } = useProfile();
  const navigate = useNavigate();
  const form = useForm({ initialValues: { code: '' }, schema: codeSchema });

  const [
    responseCodeSent,
    isSendingCode,
    codeSentError,
    sendTwoFactorCode
  ] = useRequest(sendCode);

  const [
    responseVerifyCode,
    isVerifyingCode,
    verifyCodeError,
    verifyTwoFactorCode
  ] = useRequest(verifyCode);

  const email = profile?.email.address ?? legacyProfile?.email;
  const phone = profile?.phone.isVerified ? profile.phone.number : legacyProfile?.phone;

  const AVAILABLE_CONTACTS = {
    phoneCall: obfuscate(phone, 'phone'),
    sms: obfuscate(phone, 'phone'),
    email: obfuscate(email, 'email')
  };

  const onSubmit = () => verifyTwoFactorCode(form.values.code);

  const handleSendCode = customChannel => {
    setChannel(previousChannel => customChannel ?? previousChannel);
    form.resetValues();

    return sendTwoFactorCode(customChannel ?? channel);
  };

  const handleErrors = error => {
    // This adaptation will be removed when management new API requests
    const errorCode = isRequestOfNewAPI ? error?.cause.code : error?.code;

    switch (errorCode) {
      case ERROR_CODES.invalidCode:
        form.addError({
          field: 'code',
          message: 'El código ingresado es inválido.'
        });
        break;
      case ERROR_CODES.tokenNotFound:
        form.addError({
          field: 'code',
          message: 'El código ha expirado.'
        });
        break;
      case ERROR_CODES.codeValidationLimitReached:
      case ERROR_CODES.traderHasAuthenticationFactorLock:
      case ERROR_CODES.investorHasAuthenticationFactorLock:
        setIsAbleToResend(false);
        form.addError({
          field: 'code',
          message: 'Has alcanzado el limite de intentos.'
        });
        break;
      case ERROR_CODES.cantResendToken:
      case ERROR_CODES.cannotResendToken:
      case ERROR_CODES.channelNotSupported:
        form.addError({
          field: 'code',
          message: 'Ocurrió un problema al tratar de mandar el código de verificación.'
        });
        break;
      default:
        form.addError({
          field: 'code',
          message: 'Ocurrió un problema al tratar de mandar el código de verificación.'
        });
        onError(error);
        break;
    }
  };

  const isLoading = isSendingCode || isVerifyingCode;

  const hasInvestorRestrictedAccount = () => codeSentError?.hasInvestorRestrictedAccount?.();
  const isInvestorPendingToSignW8Contract = () => codeSentError?.isW8ContractNotSigned?.();

  useEffect(() => {
    if (responseCodeSent && !codeSentError) {
      renderSuccessToast('Se ha enviado el código exitosamente');
    } else if (codeSentError) {
      handleErrors(codeSentError);
    }
  }, [responseCodeSent, codeSentError]);

  useEffect(() => {
    if (responseVerifyCode && !verifyCodeError) {
      onSuccess(responseVerifyCode);
    } else if (verifyCodeError) {
      handleErrors(verifyCodeError);
    }
  }, [responseVerifyCode, verifyCodeError]);

  useEffect(() => {
    if (!disabled) {
      handleSendCode();
    }
  }, [disabled]);

  return (
    <Fragment>
      <SignW8BENContractModal
        isOpen={isInvestorPendingToSignW8Contract()}
        navigateRoute="/sign-w8-ben-contract"
        onClose={() => navigate(-1)}
      />

      <UpgradeAccountToW8ContractModal
        isOpen={hasInvestorRestrictedAccount()}
        onClose={() => navigate(-1)}
      />

      <TwoFactorForm onSubmit={form.handleSubmit(onSubmit)}>
        <CodeSubmissionContact>
          {AVAILABLE_CODE_CHANNELS[channel]}

          <span>
            {` ${AVAILABLE_CONTACTS[channel]}`}
          </span>
        </CodeSubmissionContact>

        <CountdownText>Tu código expira en 2 minutos</CountdownText>

        <CodeField
          id="code"
          disabled={disabled || isLoading}
          focus
          groupEvery={CODE_LENGTH / 2}
          name="code"
          values={form.values.code}
          {...form.fieldProps('code')}
        />

        {!disabled && (
          <ResendOptions
            countdown={isAbleToResend ? RESEND_DELAY_TIME : AUTHENTICATION_FACTOR_LOCK_TIME}
            onResend={handleSendCode}
            resendOptions={senderOptions}
          />
        )}

        <VerifyActions>
          <Button
            color="secondary"
            disabled={isVerifyingCode || isLoading}
            onClick={onCancel}
          >
            Cancelar
          </Button>

          <Button
            disabled={!form.isValid() || isLoading}
            isLoading={isVerifyingCode}
            type="submit"
          >
            Confirmar
          </Button>
        </VerifyActions>
      </TwoFactorForm>
    </Fragment>
  );
};

TwoFactorConfirmation.propTypes = {
  channel: PropTypes.oneOf(['email', 'sms', 'phone']),
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  sendCode: PropTypes.func.isRequired,
  senderOptions: PropTypes.arrayOf(PropTypes.string),
  verifyCode: PropTypes.func.isRequired,
  isRequestOfNewAPI: PropTypes.bool
};

TwoFactorConfirmation.defaultProps = {
  channel: 'email',
  disabled: false,
  onCancel: () => { },
  onError: () => { },
  onSuccess: () => { },
  senderOptions: ['sms', 'phone', 'email'],
  isRequestOfNewAPI: false
};

export default TwoFactorConfirmation;
