import { Money } from '@types';
class InvestmentSummary {
    invested;
    revolvingFund;
    capitalGain;
    total;
    paymentPlansDebt;
    constructor(data) {
        this.invested = {
            amount: new Money(data.invested.amount),
            percent: data.invested.percent
        };
        this.revolvingFund = {
            amount: new Money(data.revolvingFund.amount),
            percent: data.revolvingFund.percent
        };
        this.capitalGain = {
            amount: new Money(data.capitalGain.amount),
            percent: data.capitalGain.percent
        };
        if (data?.total) {
            this.total = {
                amount: new Money(data.total.amount),
                percent: data.total?.percent
            };
        }
        if (data?.paymentPlansDebt) {
            this.paymentPlansDebt = new Money(data.paymentPlansDebt);
        }
    }
}
export default InvestmentSummary;
