/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import BackButton from './components/BackButton';
import './style.scss';

const ButtonLegacy = ({
  id,
  color,
  onClick,
  children,
  disabled,
  type,
  className,
  shadow,
  backButtonProps
}) => {
  const isBackButton = Object.keys(backButtonProps).length > 0;

  if (isBackButton) {
    const allBackButtonProps = {
      text: 'Regresar',
      ...backButtonProps
    };

    return (
      <BackButton
        {...allBackButtonProps}
      />
    );
  }

  const styleName = cx({
    [color]: !!color,
    shadow: !!shadow
  });

  return (
    <button
      id={id}
      className={className}
      styleName={styleName}
      type={type}
      role={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

ButtonLegacy.defaultProps = {
  tid: '',
  color: 'primary',
  disabled: false,
  shadow: false,
  type: 'button',
  className: 'button',
  onClick: () => {},
  backButtonProps: {},
  children: ''
};

ButtonLegacy.propTypes = {
  id: PropTypes.string.isRequired,
  tid: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  shadow: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  backButtonProps: PropTypes.shape({})
};

export default ButtonLegacy;
