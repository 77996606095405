import React, { useState } from 'react';

import { useProfile } from '@lib/profile';

import AntiphishingModal from './AntiphishingModal';

const Antiphishing = () => {
  const { profile, legacyProfile } = useProfile();
  const [showAntiphishingModal, setShowAntiphishingModal] = useState(
    profile.canBuy() && !(legacyProfile.imageId && legacyProfile.phrase)
  );

  const handleAntiphishingModalClose = () => {
    setShowAntiphishingModal(false);
  };

  if (!showAntiphishingModal) return null;

  return (
    <AntiphishingModal
      isOpen={showAntiphishingModal}
      onClose={handleAntiphishingModalClose}
    />
  );
};

export default Antiphishing;
