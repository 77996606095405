import styled from 'styled-components';

const TitleWrapper = styled.h3`
  ${({ theme, variant }) => (
    variant === 'h1' ? theme.typography.title.h1 : theme.typography.title.h3
  )};
  margin: 0;
`;

export default TitleWrapper;
