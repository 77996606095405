import Address from './Address';
import AddressProof from './AddressProof';
import FiscalRegime from './FiscalRegime';
import Identification from './Identification';
class Status {
    id;
    consecutiveNumber;
    fullName;
    isLegalPerson;
    isNaturalPerson;
    taxId;
    email;
    phone;
    alternateEmail;
    alternatePhone;
    address;
    addressProof;
    riskForm;
    kyc;
    commercialContract;
    transactionalProfile;
    status;
    clabe;
    createdAt;
    updatedAt;
    names;
    firstLastName;
    secondLastName;
    curp;
    jobTitle;
    maritalStatus;
    hasForeignFiscalObligations;
    foreignFiscalObligationsCountry;
    foreignFiscalObligationsTaxpayerId;
    isRealOwner;
    realOwnerName;
    birthdate;
    birthCountry;
    birthState;
    gender;
    nationality;
    identification;
    hasOwnResources;
    fiscalRegime;
    constructor(statusData) {
        this.id = statusData.id;
        this.consecutiveNumber = statusData.consecutiveNumber;
        this.fullName = statusData.fullName;
        this.isLegalPerson = statusData.isLegalPerson;
        this.isNaturalPerson = statusData.isNaturalPerson;
        this.taxId = statusData.taxId;
        this.email = statusData.email;
        this.phone = statusData.phone;
        this.alternateEmail = statusData.alternateEmail;
        this.alternatePhone = statusData.alternatePhone;
        this.status = statusData.status;
        this.clabe = statusData.clabe;
        this.createdAt = statusData.createdAt;
        this.updatedAt = statusData.updatedAt;
        this.names = statusData.names;
        this.firstLastName = statusData.firstLastName;
        this.secondLastName = statusData.secondLastName;
        this.curp = statusData.curp;
        this.jobTitle = statusData.jobTitle;
        this.maritalStatus = statusData.maritalStatus;
        this.hasForeignFiscalObligations = statusData.hasForeignFiscalObligations;
        this.foreignFiscalObligationsCountry = statusData.foreignFiscalObligationsCountry;
        this.foreignFiscalObligationsTaxpayerId = statusData.foreignFiscalObligationsTaxpayerId;
        this.isRealOwner = statusData.isRealOwner;
        this.realOwnerName = statusData.realOwnerName;
        this.birthdate = statusData.birthdate;
        this.birthCountry = statusData.birthCountry;
        this.birthState = statusData.birthState;
        this.gender = statusData.gender;
        this.nationality = statusData.nationality;
        this.riskForm = statusData.riskForm;
        this.kyc = statusData.kyc;
        this.commercialContract = statusData.commercialContract;
        this.hasOwnResources = statusData.hasOwnResources;
        this.transactionalProfile = statusData.transactionalProfile;
        this.address = new Address(statusData.address);
        this.addressProof = new AddressProof(statusData.addressProof);
        this.identification = new Identification(statusData.identification);
        this.fiscalRegime = new FiscalRegime(statusData.fiscalRegime);
    }
    isPendingToSignRiskForm() {
        return !(this.riskForm?.isSigned);
    }
}
export default Status;
