import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import RenderIf from '../RenderIf';
import LegacyCard from '../LegacyCard';
import KeepOnUpdateLimitlessAccount from '../KeepOnUpdateLimitlessAccount';

import { TransactionTemplateWrapper } from './TransactionTemplate.style';

const TransactionTemplate = props => {
  const {
    expirationMessage,
    informativeSection,
    confirmationSection,
    isFrozenAndUpgradeAccount,
    completedActivities
  } = props;

  const showExpirationMessage = Object.keys(expirationMessage).length > 0;
  const showInformativeSection = Object.keys(informativeSection).length > 0;
  const showConfirmationSection = Object.keys(confirmationSection).length > 0;

  const generateExpirationMessage = () => {
    const { title, subTitle } = expirationMessage;

    return (
      <div tid="transaction-template-expiration" styleName="expiration-message">
        <span styleName="complete-your-order">{title}</span>
        <span styleName="time-disclosure">{subTitle}</span>
      </div>
    );
  };

  const generateInformativeSection = () => {
    const { title, description, content } = informativeSection;

    return (
      <div styleName="informative-section" tid="transaction-template-informative">
        <h3 styleName="title">{title}</h3>
        <div styleName="description">{description}</div>
        <div styleName="content">{content}</div>
      </div>
    );
  };

  const generateConfirmationSection = () => {
    const {
      title,
      description,
      content,
      actions
    } = confirmationSection;

    return (
      <div styleName="confirmation-section" tid="transaction-template-confirmation">
        <h3 styleName="title">{title}</h3>
        <div styleName="description">{description}</div>
        <div styleName="content">{content}</div>
        <div styleName="actions">{actions}</div>
      </div>
    );
  };

  return (
    <div styleName="transaction-template" tid="transaction-template">
      {showExpirationMessage && generateExpirationMessage()}
      <LegacyCard styleName="transaction-template-card" id="transaction-template-card">
        {showInformativeSection && generateInformativeSection()}
        <hr styleName="template-divider" />
        <RenderIf condition={showConfirmationSection
          && (isFrozenAndUpgradeAccount && isFrozenAndUpgradeAccount())}
        >
          <TransactionTemplateWrapper>
            <KeepOnUpdateLimitlessAccount
              completedActivitiesList={completedActivities}
            />
          </TransactionTemplateWrapper>
        </RenderIf>
        {showConfirmationSection
          && !(isFrozenAndUpgradeAccount && isFrozenAndUpgradeAccount())
          && generateConfirmationSection()}
      </LegacyCard>
    </div>
  );
};

TransactionTemplate.propTypes = {
  expirationMessage: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string
  }),
  informativeSection: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.any,
    content: PropTypes.node
  }),
  confirmationSection: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.any,
    content: PropTypes.any,
    actions: PropTypes.node
  }),
  isFrozenAndUpgradeAccount: PropTypes.func,
  completedActivities: PropTypes.arrayOf(
    PropTypes.string
  )
};

TransactionTemplate.defaultProps = {
  expirationMessage: {},
  informativeSection: {},
  confirmationSection: {},
  isFrozenAndUpgradeAccount: () => false,
  completedActivities: []
};

export default TransactionTemplate;
