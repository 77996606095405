class AddressProof {
    issueDate;
    rejectionMotive;
    proofType;
    status;
    fileURL;
    updateAt;
    constructor(addressProofData) {
        this.issueDate = addressProofData?.issueDate;
        this.rejectionMotive = addressProofData?.rejectionMotive;
        this.proofType = addressProofData?.proofType;
        this.status = addressProofData?.status;
        this.fileURL = addressProofData?.fileURL;
        this.updateAt = addressProofData?.updateAt;
    }
}
export default AddressProof;
