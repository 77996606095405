import styled from 'styled-components';

import { flexAsColumn, flexAsRow } from '@components/Styled/mixins';

const DisclaimerContent = styled.span`
  ${flexAsColumn(1)};

  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const NavigatorsSettingsImages = styled.span`
  ${flexAsRow(4)};
`;

const LocationBlockedModalWrapper = styled.div`
  ${flexAsColumn(3)};

  margin: ${({ theme }) => theme.spacing(2)};
  align-items: center;
  text-align: center;

  > div {
    ${flexAsColumn(2)};

    align-items: center;
    text-align: center;

    > img {
      max-width: ${({ theme }) => theme.spacing(10)};
    }
  }

  > button {
    width: ${({ theme }) => theme.spacing(24)};
  }
`;

const DesktopMessageWrapper = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}px) {
    display: none;
  }
`;

const MobileMessageWrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
    display: none;
  }
`;

export {
  DisclaimerContent,
  LocationBlockedModalWrapper,
  NavigatorsSettingsImages,
  MobileMessageWrapper,
  DesktopMessageWrapper
};
