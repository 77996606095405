class Escrow {
    escrowNumber;
    constitutionDate;
    expirationDate;
    notaryFolio;
    publicNotary;
    grantedPlace;
    publicRegistryFolio;
    clabe;
    createdAt;
    updatedAt;
    executiveSummaryUrl;
    numberOfBricks;
    fiduciaryInstitution;
    constructor(data) {
        this.escrowNumber = data.escrowNumber;
        this.constitutionDate = data.constitutionDate;
        this.expirationDate = data.expirationDate;
        this.notaryFolio = data.notaryFolio;
        this.publicNotary = data.publicNotary;
        this.grantedPlace = data.grantedPlace;
        this.publicRegistryFolio = data.publicRegistryFolio;
        this.clabe = data.clabe;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.executiveSummaryUrl = data.executiveSummaryUrl;
        this.numberOfBricks = data.numberOfBricks;
        this.fiduciaryInstitution = data.fiduciaryInstitution;
    }
}
export default Escrow;
