import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Text } from '@components/Styled';

import ActionText from '../ActionText';
import Countdown from '../Countdown';
import RenderIf from '../RenderIf';

import { ResendCountdownWrapper } from './TwoFactorConfirmation.style';

const AUTHENTICATION_FACTOR_LOCK_TIME = 300;
const RESEND_OPTIONS = {
  sms: {
    id: 'twoFactorCode-resend-sms',
    label: 'mensaje de texto',
    channel: 'sms'
  },
  phone: {
    id: 'twoFactorCode-resend-phoneCall',
    label: 'llamada telefónica',
    channel: 'phoneCall'
  },
  email: {
    id: 'twoFactorCode-resend-email',
    label: 'correo electrónico',
    channel: 'email'
  }
};

const ResendOptions = ({ countdown, onResend, resendOptions }) => {
  const [isWaitingToResendToken, setIsWaitingToResendToken] = useState(Boolean(countdown));

  const handleResend = channel => {
    onResend(channel);
    setIsWaitingToResendToken(true);
  };

  const isBlocked = countdown >= AUTHENTICATION_FACTOR_LOCK_TIME;

  return (
    <Fragment>
      <RenderIf condition={isWaitingToResendToken}>
        <ResendCountdownWrapper error={isBlocked}>
          <RenderIf condition={Boolean(countdown)}>
            Si no has recibido tu código, o ya expiró, puedes solicitar uno nuevo en
          </RenderIf>

          {' '}

          <Countdown
            onTimeExpire={() => setIsWaitingToResendToken(false)}
            seconds={countdown}
          />
        </ResendCountdownWrapper>
      </RenderIf>

      <RenderIf condition={!isWaitingToResendToken}>
        <RenderIf condition={Boolean(resendOptions.length >= 1)}>
          <Text>
            Si no has recibido tu código o ya expiró, puedes solicitar uno nuevo por

            {' '}

            {resendOptions.map((option, index) => (
              <Fragment key={option}>
                <span>
                  {index > 0 && index === resendOptions.length - 1 && ' o '}
                  {index > 0 && index < resendOptions.length - 1 && ', '}
                </span>

                <ActionText
                  id={RESEND_OPTIONS[option]?.id}
                  color="info"
                  onClick={() => handleResend(RESEND_OPTIONS[option]?.channel)}
                >
                  {RESEND_OPTIONS[option]?.label}
                </ActionText>
              </Fragment>
            ))}
          </Text>
        </RenderIf>

        <RenderIf condition={Boolean(resendOptions.length < 1)}>
          <Text>
            Puedes

            {' '}

            <ActionText
              id="twoFactorCode-resend"
              color="info"
              onClick={handleResend}
            >
              reenviar el código
            </ActionText>
            , si no lo has recibido o ya expiró.
          </Text>
        </RenderIf>
      </RenderIf>
    </Fragment>
  );
};

ResendOptions.propTypes = {
  countdown: PropTypes.number,
  onResend: PropTypes.func.isRequired,
  resendOptions: PropTypes.arrayOf(PropTypes.string)
};

ResendOptions.defaultProps = {
  countdown: 0,
  resendOptions: []
};

export default ResendOptions;
