import HundredBricksAPIError from '../HundredBricksAPIError';
class PropertyIsNotRegistered extends HundredBricksAPIError {
    propertyId;
    propertyType;
    constructor(propertyId, propertyType, options) {
        super('Property is not registered', options);
        this.propertyId = propertyId;
        this.propertyType = propertyType;
    }
}
export default PropertyIsNotRegistered;
