import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import coverImage from '@assets/images/new-login-background.png';
import coverSecondaryImage from '@assets/images/enrollment-secundary-background.jpg';
import { MediaContainer } from '@components';
const DefaultMediaContainer = () => {
    const { pathname } = useLocation();
    const isSecondaryImage = /summary/g.test(pathname);
    const backgroundImage = isSecondaryImage ? coverSecondaryImage : coverImage;
    return (_jsx(MediaContainer, { backgroundImage: backgroundImage }));
};
export default DefaultMediaContainer;
