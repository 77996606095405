import React from 'react';
import { PropTypes } from 'prop-types';

import { AccountLockCodes } from '@types';
import './style.scss';

const BlockedAccountBanner = props => {
  const { lockCode } = props;
  const {
    isShadowLockCode,
    isSoftLockCode,
    isLockCodeValid,
  } = new AccountLockCodes();
  const isTraderLocked = isLockCodeValid(lockCode);

  const getBannerText = () => {
    if (isShadowLockCode(lockCode)) {
      return ' solo puedes navegar sin realizar ningún movimiento';
    }

    if (isSoftLockCode(lockCode)) {
      return ' solo puedes retirar tus rentas, vender tus'
        + ' Ladrillos y retirar tu dinero disponible';
    }

    return '';
  };

  if (!isTraderLocked) {
    return null;
  }

  return (
    <div styleName="lock-banner" tid="blocked-account-banner">
      <p>
        Esta cuenta está
        {' '}
        <b>bloqueada</b>
        ,
        {getBannerText()}
      </p>
    </div>
  );
};

BlockedAccountBanner.propTypes = {
  lockCode: PropTypes.string.isRequired,
};

export default BlockedAccountBanner;
