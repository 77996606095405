import styled from 'styled-components';

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palette.danger.main};
  margin: 0;
`;

const ExpirationLabel = styled.p`
  color: ${({ theme }) => theme.palette.grayG};
  margin: 0;
`;

const ResendCountdownWrapper = styled.p`
  margin: 0;
`;

const ResendCountdown = styled.span`
  font-family: RobotoBold;
`;

const ResendOptionsWrapper = styled.div`
  &, > p {
    margin: 0;
  }
`;

export {
  ErrorMessage,
  ExpirationLabel,
  ResendCountdown,
  ResendCountdownWrapper,
  ResendOptionsWrapper
};
