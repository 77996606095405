import React from 'react';
import Proptypes from 'prop-types';

import { Level1, Level2, Level3 } from './Subtitle.style';

const Subtitle = props => {
  const {
    id,
    children,
    align,
    backgroundColor,
    color,
    height,
    level,
    width
  } = props;

  if (level === '2') {
    return (
      <Level2
        id={id}
        color={color}
        backgroundColor={backgroundColor}
        height={height}
        align={align}
        width={width}
      >
        {children}
      </Level2>
    );
  }

  if (level === '3') {
    return (
      <Level3
        id={id}
        color={color}
        backgroundColor={backgroundColor}
        height={height}
        align={align}
        width={width}
      >
        {children}
      </Level3>
    );
  }

  return (
    <Level1
      id={id}
      color={color}
      backgroundColor={backgroundColor}
      height={height}
      align={align}
      width={width}
    >
      {children}
    </Level1>
  );
};

Subtitle.propTypes = {
  children: Proptypes.node.isRequired,
  color: Proptypes.string,
  id: Proptypes.string,
  backgroundColor: Proptypes.string,
  height: Proptypes.string,
  align: Proptypes.string,
  width: Proptypes.string,
  level: Proptypes.string
};

Subtitle.defaultProps = {
  id: '',
  color: '',
  backgroundColor: '',
  height: '',
  align: '',
  width: '',
  level: '1'
};

export default Subtitle;
