class PropertyValueEstimate {
    total;
    numberOfBricks;
    valuePerBrick;
    details;
    constructor(data) {
        this.total = data.total;
        this.numberOfBricks = data.numberOfBricks;
        this.valuePerBrick = data.valuePerBrick;
        this.details = data.details;
    }
}
export default PropertyValueEstimate;
