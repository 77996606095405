import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import CompleteEnrollmentBanner from '@components/CompleteEnrollmentBanner';
import { useAuth } from '@lib/authentication';
import { useProfile } from '@lib/profile';
import { BlockedAccountBanner, Footer } from '@components';
import Layout from './Layout';
import PrivateNavbar from './components/Navbar/PrivateNavbar';
import PrivateSidenav from './components/Sidebar/PrivateSideNav';
import { ContentDistribution, Content } from './Layout.style';
const PrivateLayout = ({ children }) => {
    const { legacyProfile, profile } = useProfile();
    const { isAuthenticated } = useAuth();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => setIsSidebarOpen(prevState => !prevState);
    return (_jsxs(Layout, { children: [_jsx(PrivateNavbar, { onOpenMenu: toggleSidebar }), _jsx(BlockedAccountBanner, { lockCode: legacyProfile?.lockCode }), _jsxs(ContentDistribution, { children: [_jsx(PrivateSidenav, { isOpen: isSidebarOpen, onClose: toggleSidebar }), _jsxs(Content, { id: "content", isPrivateContent: true, children: [(!profile?.isDoneRegistering()) && (_jsx(CompleteEnrollmentBanner, {})), children, isAuthenticated && (_jsx(Footer, { isMarketplace: true }))] })] })] }));
};
export default PrivateLayout;
