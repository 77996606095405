import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PublicLayout from '@layout/PublicLayout';
import { ButtonViewProperties, CancelAccountUpgrade } from '@components';
import { Content, FormContent } from '@layout/Layout.style';
import { useProfile } from '@lib/profile';
import EnrollmentLayoutSupportRender from './EnrollmentLayoutSupportRender';
const EnrollmentLayout = ({ children }) => {
    const { enrollment } = useProfile();
    return (_jsxs(PublicLayout, { children: [_jsx(EnrollmentLayoutSupportRender, {}), _jsxs(Content, { children: [_jsx(ButtonViewProperties, {}), _jsx(FormContent, { id: "content", children: children })] }), enrollment?.isUpgradingAccount() && (_jsx(CancelAccountUpgrade, {}))] }));
};
export default EnrollmentLayout;
