import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { Notification as NotificationContainer } from '../NotificationCenter.style';
const BoughtBricks = (props) => {
    const { notification } = props;
    const property = notification.params?.propertyName ?? '----';
    const bricksText = notification.params?.totalBricks === 1
        ? `${notification.params?.totalBricks} Ladrillo`
        : `${notification.params?.totalBricks ?? 0} Ladrillos`;
    return (_jsxs(NotificationContainer, { children: [_jsxs(Subtitle, { level: "3", children: ["Has comprado", ` ${bricksText} en ${property}`] }), _jsxs(Paragraph, { children: ["Felicidades por ampliar tu patrimonio, ya eres propietario de", _jsx(Bold, { children: ` ${bricksText} ` }), "en", _jsx(Bold, { children: ` ${property}` }), ". Puedes ver los detalles de tu inversi\u00F3n y el progreso de tu propiedad desde la secci\u00F3n \u201CMi patrimonio\u201D."] }), _jsx(Paragraph, { color: "grayF", children: notification.getLocaleDate() })] }));
};
export default BoughtBricks;
