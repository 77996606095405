/* eslint-disable import/no-commonjs, global-require */
let insightDriver = null;

/** All the insights module/service should be deeply refactored, for this reason the eslint-disable
  * directives were added just to make the linter pass. Please avoid maintenance of this file
  */

switch (process.env.TARGET_ENV) {
  case 'production':
    insightDriver = require('./services/HeapInsightsService');
    break;
  case 'staging':
    insightDriver = require('./services/HeapInsightsService');
    break;
  case 'development':
    insightDriver = require('./services/HeapInsightsService');
    break;
  case 'local':
    insightDriver = require('./services/ConsoleInsightsService');
    break;
  default:
    insightDriver = require('./services/DummyInsightsService');
}

export default insightDriver.default;
