import HundredBricksAPI from '@lib/hundredBricksAPI/HundredBricksAPI';
import { APIError } from '@lib/api';
import RebatesDispersion from './RebatesDispersion';
import RebatesError from './RebatesError';
import InvalidQueryParam from './InvalidQueryParam';
class RebatesAPI extends HundredBricksAPI {
    async getRebatesDispersionSummary(params) {
        const { startDate, endDate } = params;
        try {
            const { data } = await this.client.get('v1/rebatesIncomes', { params });
            return new RebatesDispersion(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_START_DATE':
                        throw new InvalidQueryParam({ startDate }, { cause: error });
                    case 'INVALID_END_DATE':
                        throw new InvalidQueryParam({ endDate }, { cause: error });
                    case 'INVALID_DATE_RANGE':
                        throw new InvalidQueryParam({ startDate, endDate }, { cause: error });
                    default:
                        throw new RebatesError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new RebatesError('Unexpected rebates dispersion summary error', { cause: error });
            }
            else {
                throw new RebatesError(`Unexpected rebates dispersion summary error: '${error}'`);
            }
        }
    }
}
export default RebatesAPI;
