import styled from 'styled-components';

import { flexAsRow } from '../Styled/mixins';

const CheckActivityListItem = styled.div`
  ${flexAsRow(2)};
`;

const CheckActivityListWrapper = styled(CheckActivityListItem)`
  gap: ${({ theme }) => theme.spacing(3)};
  flex-direction: column;
`;

export { CheckActivityListWrapper, CheckActivityListItem };
