import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import {
  InvestmentLimitBodyWrapper,
  UpgradeAccountWrapper,
  UpgradeAccountIcon
} from '../../UpgradeAccount.styled';

import { UpgradeAccountButtonsWrapper } from './UpgradeAccountValidatingDocuments.styled';

const UpgradeAccountValidatingDocuments = ({ onClose, onRedirect }) => (
  <UpgradeAccountWrapper>
    <Title>Estamos verificando tu información</Title>

    <UpgradeAccountIcon
      name="icUpgradeAccountModal"
      alt="Imagen conviértete en un usuario sin límites"
    />

    <InvestmentLimitBodyWrapper>
      <Text>
        Este proceso puede tomar hasta un día hábil. No te preocupes,
        te notificaremos cuando estés listo.
      </Text>

      <Text>
        Mientras tanto, puedes ir analizando las propiedades que hoy están disponibles para ti.
      </Text>
    </InvestmentLimitBodyWrapper>

    <UpgradeAccountButtonsWrapper>
      <Button
        id="cancel"
        color="secondary"
        onClick={onClose}
      >
        Cancelar
      </Button>

      <Button
        id="view-properties"
        onClick={onRedirect}
      >
        Ver propiedades
      </Button>
    </UpgradeAccountButtonsWrapper>
  </UpgradeAccountWrapper>
);

UpgradeAccountValidatingDocuments.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired
};

export default UpgradeAccountValidatingDocuments;
