class Notification {
    id;
    type;
    createdAt;
    params;
    constructor(data) {
        this.id = data.id;
        this.type = data.type;
        this.createdAt = new Date(data.createdAt);
        this.params = data?.data;
    }
    getDaysOfBeingNotified(currentDate) {
        return currentDate.setHours(0, 0, 0, 0) - this.createdAt.setHours(0, 0, 0, 0);
    }
    getLocaleDate() {
        const LOCALE_DATE_OPTIONS = { month: 'short', day: 'numeric' };
        return this.createdAt.toLocaleDateString('es-MX', LOCALE_DATE_OPTIONS);
    }
}
export default Notification;
