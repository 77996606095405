import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import { Text } from '../Styled';

import { CheckActivityListWrapper, CheckActivityListItem } from './CheckActivityList.style';

const CheckActivityList = ({ activityList }) => (
  <CheckActivityListWrapper>
    {activityList.map(activity => (
      <CheckActivityListItem key={activity.name}>
        {
          activity.isCompleted
            ? (
              <Icon
                name="icSuccessfulCheck"
                alt="Actividad terminada"
                height="14"
                width="14"
              />
            )
            : (
              <Icon
                name="icPendingCheck"
                alt="Actividad pendiente"
                height="14"
                width="14"
              />
            )
        }

        <Text align="left">{activity.name}</Text>
      </CheckActivityListItem>
    ))}
  </CheckActivityListWrapper>
);

CheckActivityList.propTypes = {
  activityList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default CheckActivityList;
