import Money from '../../../../types/money';
import EventsReporter from '../EventsReporter';

import PurchaseEvents from './PurchaseEvents';

class PurchaseEventsReporter extends EventsReporter {
  constructor(insightsService) {
    super(insightsService);
    this._event = new PurchaseEvents();
  }

  marketplaceTabChange(sectionId) {
    const properties = {
      market: sectionId === 'crowdfunding-properties'
        ? 'Crowdfunding'
        : 'Secondary Market'
    };

    const event = this._event.tabClicked(properties);

    this._insightsService.sendEvent(event);
  }

  propertyCardModelChange(sectionId, cardModel) {
    const properties = {
      cardModel,
      market: sectionId === 'crowdfunding-properties-section'
        ? 'Crowdfunding'
        : 'Secondary Market'
    };

    const event = this._event.propertyCardModelClicked(properties);

    this._insightsService.sendEvent(event);
  }

  propertyClick(property, market) {
    const properties = {
      propertyName: property?.name,
      propertyMarket: market,
      propertyID: property?.id,
      cardFormatType: property?.cardFormatType
    };

    const event = this._event.propertyClick(properties);

    this._insightsService.sendEvent(event);
  }

  _createCompleteEventProperties(property, bricksAmount, pricePerBrick, total) {
    const bricksPrice = new Money(pricePerBrick).toString();
    const grandTotal = new Money(total).toString();

    const properties = {
      propertyName: property?.name ?? null,
      propertyID: property?.id ?? null,
      bricksAmount: bricksAmount ?? null,
      bricksPrice: bricksPrice ?? null,
      grandTotal: grandTotal ?? null
    };

    return properties;
  }
}

export default PurchaseEventsReporter;
