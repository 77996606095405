/* eslint-disable global-require */
/* eslint-disable import/no-commonjs */
/* eslint-disable import/extensions */
let investorSegmenter = null;
// The require is decided at compile time
switch (process.env.TARGET_ENV) {
    case 'production':
    case 'staging':
    case 'development':
        investorSegmenter = require('./segmenters/UsetifulSegmenter.ts');
        break;
    case 'local':
        investorSegmenter = require('./segmenters/ConsoleSegmenter.ts');
        break;
    default:
        investorSegmenter = require('./segmenters/DummySegmenter.ts');
}
// Remove when the tours is implemented
// eslint-disable-next-line import/no-unused-modules
const InvestorSegmenter = investorSegmenter.default;
// eslint-disable-next-line import/no-anonymous-default-export
export default new InvestorSegmenter();
