import { useRef, useCallback } from 'react';

/*
* This hook is executed when the ref is assigned to the element after render
* proccess, and execute the received callback sending the ref as parameter
* with the purpose of work with the element inside the callback.
* This is necessary because we can't use refs in functional components, and using
* useRef we do not have access to the element in the useEffect.
*/

const useRefWithCallback = callback => {
  const ref = useRef(null);

  const setRef = useCallback(element => {
    if (element) {
      callback(element);
    }

    ref.current = element;
  }, [callback]);

  return [setRef];
};

export default useRefWithCallback;
