class CapitalGainRecord {
    currentInvestmentValue;
    investedAmount;
    capitalGain;
    constructor(data) {
        this.capitalGain = data.capitalGain;
        this.investedAmount = data.investedAmount;
        this.currentInvestmentValue = data.valuationAmount;
    }
}
export default CapitalGainRecord;
