class CapitalGainDetail {
    investmentType;
    investedAmount;
    valuationAmount;
    property;
    capitalGain;
    constructor(data) {
        this.investmentType = data.investmentType;
        this.investedAmount = data.investedAmount;
        this.valuationAmount = data.valuationAmount;
        this.property = data.property;
        this.capitalGain = data.capitalGain;
    }
}
export default CapitalGainDetail;
