import styled from 'styled-components';

import { Title } from '@components';

const ModalTitle = styled(Title)`
  margin: 0;
  text-align: center;
`;

const InfoWrapper = styled.div`
  > * {
    margin: 0;
    text-align: center;
  }
`;

export { InfoWrapper, ModalTitle };
