// THIS SERVICES WILL BE REMOVED WHEN IT WILL BE MOVED TO TRADING
class PortfolioBrick {
    acquisitionDate;
    acquisitionPrice;
    canBeSelled;
    code;
    isOnSale;
    owner;
    property;
    constructor(data) {
        this.acquisitionDate = new Date(data.acquisitionDate);
        this.acquisitionPrice = data.acquisitionPrice;
        this.canBeSelled = data.canBeSelled;
        this.code = data.code;
        this.isOnSale = data.isOnSale;
        this.owner = data.owner;
        this.property = data.property;
    }
}
export default PortfolioBrick;
