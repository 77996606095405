import styled from 'styled-components';

import { flexAsRow } from '@components/Styled/mixins';

const AlertWrapper = styled.div`
  ${flexAsRow(2)};

  border-radius: ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.colors.support.info[400]};
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.colors.support.info[400]};
`;

export { AlertWrapper };
