import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Icon,
  Modal,
  Title
} from '@components';

import useRejectedDocs from './useRejectedDocs';
import TextWrapper from './RejectedDocsModal.style';

const RejectedDocsModal = () => {
  const location = useLocation();
  const { hasRejectedDocs, redirectToUpdateDocs } = useRejectedDocs();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMarketplaceRoute = ['/marketplace/properties/presale'].includes(location.pathname);

  const handleUpdateDocs = () => {
    setIsModalOpen(false);
    redirectToUpdateDocs();
  };

  useEffect(() => {
    if (!isMarketplaceRoute) return;

    if (hasRejectedDocs) {
      setIsModalOpen(true);
    }
  }, [isMarketplaceRoute, hasRejectedDocs]);

  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <Title>¡Actualiza tu documentación!</Title>

      <Icon
        alt="Actualizar documentos rechazados"
        height={84}
        name="icRejectedDocsUpdate"
        width={84}
      />

      <TextWrapper>
        Hemos tenido dificultad en procesar tu documentación.
        <br />
        Ayúdanos actualizándola.
      </TextWrapper>

      <Button id="go-to-update-documents" onClick={handleUpdateDocs}>
        Ir a actualizar documentación
      </Button>
    </Modal>
  );
};

export default RejectedDocsModal;
