import React from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from '../Icon';
import Button from '../Button';

import './style.scss';

const IncompleteTaxIdErrorPanel = () => {
  const navigate = useNavigate();

  return (
    <div styleName="incomplete-tax-id-error-panel">
      <Icon name="icSadFace" alt="Error en esta sección" styleName="error-image" />
      <h3 styleName="error-title">
        El RFC con Homoclave está incompleto
      </h3>
      <p styleName="error-message">
        Debido a que tu RFC con Homoclave está incompleto, no se
        pueden generar las facturas de comisión lo cual podría
        causar errores al momento de liberar rentas.
        <span styleName="full-width">
          Asegurate que sea tu RFC ya que una vez creada la factura, no habrá manera de repetirla.
        </span>
      </p>
      <Button
        id="error-panel-button"
        tid="error-panel-button"
        onClick={() => navigate('/perfil')}
      >
        COMPLETAR RFC
      </Button>
    </div>
  );
};

export default IncompleteTaxIdErrorPanel;
