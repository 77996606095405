import EnrollmentInvestor from '../EnrollmentInvestor';
import HundredBricksAPI from '../../HundredBricksAPI';
import { APIError } from '../../../api';
import InvalidBirthCountry from './InvalidBirthCountry';
import InvalidBirthdate from './InvalidBirthdate';
import InvalidBirthState from './InvalidBirthState';
import InvalidGender from './InvalidGender';
import InvalidNationality from './InvalidNationality';
import RegisterNameError from './RegisterNameError';
import RegisterPersonalDataError from './RegisterPersonalDataError';
class DemographicsAPI extends HundredBricksAPI {
    async registerFullName(name, firstLastName, secondLastName) {
        try {
            const { data } = await this.client.post('/v2/profile/name', { name, firstLastName, secondLastName });
            return new EnrollmentInvestor(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                throw new RegisterNameError({ cause: error });
            }
            else if (error instanceof Error) {
                throw new RegisterNameError('Unexpected error while registering full name', { cause: error });
            }
            else {
                throw new RegisterNameError(`Unexpected error while registering full name: ${error}`);
            }
        }
    }
    async registerPersonalData(birthCountry, birthdate, birthState, gender, nationality) {
        try {
            const { data } = await this.client.post('/v2/profile/demographics', {
                birthCountry,
                birthdate,
                birthState,
                gender,
                nationality
            });
            return new EnrollmentInvestor(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_BIRTHDATE':
                        throw new InvalidBirthdate(birthdate, { cause: error });
                    case 'INVALID_BIRTH_COUNTRY':
                        throw new InvalidBirthCountry(birthCountry, { cause: error });
                    case 'INVALID_BIRTH_STATE':
                        throw new InvalidBirthState(birthState, { cause: error });
                    case 'INVALID_GENDER':
                        throw new InvalidGender(gender, { cause: error });
                    case 'INVALID_NATIONALITY':
                        throw new InvalidNationality(nationality, { cause: error });
                    default:
                        throw new RegisterPersonalDataError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new RegisterPersonalDataError('Unexpected error while registering personal data', { cause: error });
            }
            else {
                throw new RegisterPersonalDataError(`Unexpected error while registering personal data: ${error}`);
            }
        }
    }
}
export default DemographicsAPI;
