import LoginError from './LoginError';
class AuthenticationAttemptsLimitReached extends LoginError {
    constructor(options) {
        super('The investor has authentication attempts limit reached', options);
    }
    getRemainingTime() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const metadata = this.cause?.cause?.response?.data?.metadata;
        if (metadata && typeof metadata.remainingLockTimeInMilliseconds === 'number') {
            return metadata.remainingLockTimeInMilliseconds;
        }
        return 300000;
    }
}
export default AuthenticationAttemptsLimitReached;
