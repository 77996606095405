import HundredBricksAPI from '../HundredBricksAPI';
import EnrollmentStatusError from './EnrollmentStatusError';
import Status from './Status';
class EnrollmentAPI extends HundredBricksAPI {
    async getStatus() {
        try {
            const { data } = await this.client.get('/v1/enrollment');
            return new Status(data);
        }
        catch (error) {
            if (error instanceof Error) {
                throw new EnrollmentStatusError({ cause: error });
            }
            else {
                throw new EnrollmentStatusError(`Unexpected error getting enrollment status: '${error}'`);
            }
        }
    }
}
export default EnrollmentAPI;
