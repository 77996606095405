import MoralProfile from '@api/entities/Enrollment/MoralProfile';

import { PhoneError } from '../errors/Enrollment';
import { SignRequest } from '../entities/SignDocuments';
import { TraderError } from '../errors';
import {
  ApprovalRequest,
  CorrectionRequest,
  EnrollmentProfile,
  Phone,
  Status
} from '../entities/Enrollment';

import GatewayAPI from './GatewayAPI';

class ProfileAPI extends GatewayAPI {
  async getProfile() {
    let data;

    try {
      const response = await this._client.get('/profile');

      if (response.data?.isLegalPerson) {
        return new MoralProfile(response.data);
      }

      data = response.data;
    } catch (error) {
      if (!error.response) {
        throw TraderError.getNetworkError();
      }

      const { response } = error;
      const { code } = response.data;

      throw new TraderError(code);
    }
    const phone = await this._getProfilePhone();
    const status = await this._getProfileStatus();

    return new EnrollmentProfile({ ...data, ...status, phone });
  }

  async updateTransactionalProfile(transactionalProfile) {
    const phone = await this._getProfilePhone();
    const status = await this._getProfileStatus();

    try {
      const { data }
        = await this._client.put('/profile/transactionalProfile', transactionalProfile);

      return new EnrollmentProfile({ ...data, ...status, phone });
    } catch (error) {
      if (!error.response) {
        throw TraderError.getNetworkError();
      }

      const { response } = error;
      const { code } = response.data;

      throw new TraderError(code);
    }
  }

  async upgradeAccountLevel() {
    try {
      const { data } = await this._client.post('/profile/upgradeAccountLevel');

      return new EnrollmentProfile(data);
    } catch (error) {
      return this._handleError(error, TraderError);
    }
  }

  async cancelAccountLevelUpgrade() {
    try {
      const { data } = await this._client.post('/profile/cancelAccountLevelUpgrade');

      return new EnrollmentProfile(data);
    } catch (error) {
      return this._handleError(error, TraderError);
    }
  }

  async obtainSignedContracts() {
    try {
      const { data } = await this._client.get('/profile/documents/signedContracts');

      return data;
    } catch (error) {
      return this._handleError(error, TraderError);
    }
  }

  async _getProfilePhone() {
    try {
      const { data } = await this._client.get('/profile/phone');

      return new Phone(data);
    } catch (error) {
      if (!error.response) {
        throw TraderError.getNetworkError();
      }

      const { response } = error;
      const { code } = response.data;
      const phoneError = new PhoneError(code);

      if (!phoneError.isPhoneAlreadyVerified() && !phoneError.isPhoneRegisteredYet()) {
        throw phoneError;
      }

      return new Phone({});
    }
  }

  async _getProfileStatus() {
    try {
      const { data } = await this._client.get('/profile/status');
      const pendingActivities = data.activities.pending.map(item => item.name);

      // The responsibility for approval request and correction request pendants should be removed
      // from the Status endpoint as it escapes from the status responsibilities.

      return {
        status: new Status({
          name: data.name,
          pendingActivities,
          completedActivities: data.activities.completed
        }),
        pendingApprovalRequests: new ApprovalRequest(data),
        pendingCorrectionRequests: new CorrectionRequest(data),
        pendingSignRequests: new SignRequest(data)
      };
    } catch (error) {
      throw this._handleError(error, TraderError);
    }
  }

  _handleError = (error, APIProfileError) => {
    if (!error.response) {
      throw APIProfileError.getNetworkError();
    }

    const errorCode = error.response.data.code;

    const profileAPIError = new APIProfileError(errorCode);

    throw profileAPIError;
  };
}

export default ProfileAPI;
