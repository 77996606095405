import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useLocation } from 'react-router-dom';
import { Fragment } from 'react';
import { Button } from '@components';
import { useAuth } from '@lib/authentication';
import { PublicSidebar } from './PublicSideNav.style';
const HOW_IT_WORKS = 'https://www.100ladrillos.com/como-funciona';
const PublicSideNav = ({ isOpen, onClose }) => {
    const { isAuthenticated } = useAuth();
    const { pathname } = useLocation();
    return (_jsx(PublicSidebar, { isOpen: isOpen, onClose: onClose, children: !isAuthenticated && (_jsxs(Fragment, { children: [_jsx(Link, { to: HOW_IT_WORKS, children: _jsx(Button, { variant: "text", children: "C\u00F3mo funciona" }) }), pathname === '/signup' ? (_jsx(Link, { to: "/login", children: _jsx(Button, { rounded: true, children: "Iniciar sesi\u00F3n" }) })) : (_jsx(Link, { to: "/signup", children: _jsx(Button, { rounded: true, children: "Crear cuenta" }) }))] })) }));
};
export default PublicSideNav;
