import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import UpgradeSuccessMediaContiner from '@views/UpgradeAccount/Success/UpgradeSuccessMediaContiner';
import SignUpMediaContainer from '@views/Signup/SignUpMediaContainer';
import CurpMediaContainer from '../Curp/CurpMediaContainer';
import VerifyEmailMediaContainer from '../Email/VerifyEmailMediaContainer';
import RegisterPhoneMediaContainer from '../Phone/RegisterPhoneMediaContainer';
import VerifyPhoneMediaContainer from '../Phone/VerifyPhoneMediaContainer';
import InvestorFullNameMediaContainer from '../InvestorFullName/InvestorFullNameMediaContainer';
import GeneralDataMediaContainer from '../GeneralData/GeneralDataMediaContainer';
import JobTitleMediaContainer from '../JobTitle/JobTitleMediaContainer';
import RealOwnerMediaContainer from '../RealOwner/RealOwnerMediaContainer';
import DefaultMediaContainer from './DefaultMediaContainer';
const EnrollmentLayoutSupportRender = () => {
    const { pathname } = useLocation();
    const SupportLayout = useMemo(() => {
        const isStepFound = (step) => pathname === `/enrollment/${step}`;
        if (pathname === '/signup') {
            return SignUpMediaContainer;
        }
        if (isStepFound('verify-email')) {
            return VerifyEmailMediaContainer;
        }
        if (isStepFound('phone')) {
            return RegisterPhoneMediaContainer;
        }
        if (isStepFound('verify-phone')) {
            return VerifyPhoneMediaContainer;
        }
        if (isStepFound('name')) {
            return InvestorFullNameMediaContainer;
        }
        if (isStepFound('demographic-info')) {
            return GeneralDataMediaContainer;
        }
        if (isStepFound('curp')) {
            return CurpMediaContainer;
        }
        if (isStepFound('job-title')) {
            return JobTitleMediaContainer;
        }
        if (isStepFound('real-owner')) {
            return RealOwnerMediaContainer;
        }
        if (isStepFound('upgrade-account/success')) {
            return UpgradeSuccessMediaContiner;
        }
        return DefaultMediaContainer;
    }, [pathname]);
    return (_jsx(SupportLayout, {}));
};
export default EnrollmentLayoutSupportRender;
