import { NetworkError, UpdateDataError } from '../errors';

import GatewayAPI from './GatewayAPI';

class UpdateDataAPI extends GatewayAPI {
  async confirmCurrentData() {
    try {
      const { data } = await this._client.post('/profile/confirmCurrentData');

      return data;
    } catch (error) {
      return this._handleError(error);
    }
  }

  _handleError = error => {
    if (!error.response) {
      throw new NetworkError();
    }

    const traderError = new UpdateDataError(error.response.data.code);

    throw traderError;
  };
}

export default UpdateDataAPI;
