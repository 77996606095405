import autoBind from 'auto-bind';
import Contract from './Contract';
import SignedDocument from './SignedDocument';
class SignedContract extends Contract {
    _isSigned = true;
    constructor(file, type) {
        super(type, file);
        autoBind(this);
    }
    get contract() {
        if (!(this.file instanceof SignedDocument))
            throw new Error();
        return this.file.documentUrl;
    }
    get isSigned() {
        return this._isSigned;
    }
}
export default SignedContract;
