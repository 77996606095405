class Event {
  constructor(name, properties) {
    this._name = name;
    this._properties = properties ?? {};
  }

  get name() {
    return this._name;
  }

  get properties() {
    return this._properties;
  }
}

export default Event;
