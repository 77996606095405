import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useProfile } from '@lib/profile';
const LimitlessLevelOutlet = () => {
    const navigate = useNavigate();
    const { profile, investorBalance } = useProfile();
    const { pathname, state } = useLocation();
    useEffect(() => {
        if (state?.from?.pathname) {
            navigate(state?.from.pathname);
        }
        if (pathname !== '/') {
            return;
        }
        if (profile?.pendingCorrectionRequests.hasW8ContractRequest()) {
            navigate('/perfil/w8-contract');
        }
        else if (!profile?.hasTransactionalProfile()) {
            navigate('/update-transactional-profile');
        }
        else if (investorBalance?.hasInvested()) {
            navigate('/marketplace');
        }
        else {
            navigate('/onboarding');
        }
    }, [pathname]);
    return (_jsx(Outlet, {}));
};
export default LimitlessLevelOutlet;
