import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'bricks-ui/packages/bricks-ui';
import { PaymentPlansProvider } from '@lib/paymentPlans';
import { AuthProvider } from '@lib/authentication';
import { ErrorReporter } from '@lib/errorReporter';
import { ProfileProvider } from '@lib/profile';
import { investorSegmenter, Segmenter } from '@lib/segmentation';
import GlobalStyle from '../Global.style';
import AppRoutes from './AppRoutes';
const Router = () => (_jsx(AuthProvider, { children: _jsxs(ProfileProvider, { children: [_jsx(ErrorReporter, { driver: process.env.ERROR_REPORTER_DRIVER || 'console', children: _jsx(PaymentPlansProvider, { children: _jsx(BrowserRouter, { children: _jsxs(ThemeProvider, { children: [_jsx(AppRoutes, {}), _jsx(GlobalStyle, {})] }) }) }) }), _jsx(Segmenter, { investorSegmenter: investorSegmenter })] }) }));
export default Router;
