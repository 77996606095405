class InvestmentSummary {
    initialInvestment;
    totalEstimatedCapitalGain;
    totalEstimatedLeasing;
    totalInvestmentValue;
    totalInvestmentAnualAverage;
    totalInvestmentProfit;
    constructor(data) {
        this.initialInvestment = data.initialInvestment;
        this.totalEstimatedCapitalGain = data.totalEstimatedCapitalGain;
        this.totalEstimatedLeasing = data.totalEstimatedLeasing;
        this.totalInvestmentValue = data.totalInvestmentValue;
        this.totalInvestmentAnualAverage = data.totalInvestmentAnualAverage;
        this.totalInvestmentProfit = data.totalInvestmentProfit;
    }
}
export default InvestmentSummary;
