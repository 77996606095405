class RevolvingFund {
    id;
    description;
    type;
    amount;
    balance;
    createdAt;
    filesUrl;
    constructor(data) {
        this.id = data.id;
        this.description = data.description;
        this.type = data.type;
        this.amount = data.amount;
        this.balance = data.balance;
        this.createdAt = new Date(data.createdAt);
        this.filesUrl = data.filesUrl;
    }
}
export default RevolvingFund;
