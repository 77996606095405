import HundredBricksAPI from '@lib/hundredBricksAPI/HundredBricksAPI';
import { APIError } from '@lib/api';
import Pagination, { InvalidQueryParam } from '../../pagination';
import { OnSaleInvestment, OnSaleInvestments, OnSaleInvestmentsError } from './onSale';
import { SoldInvestment, SoldInvestments, SoldInvestmentsError } from './sold';
import { InvalidFormatEntry, PortfolioInvestment, PortfolioInvestments, PortfolioInvestmentsError } from './portfolio';
import BricksDetail, { BricksInvestmentsNotFound, InvalidPropertyId, PropertyIsNotRegistered } from './portfolio/bricksDetail';
class InvestmentsAPI extends HundredBricksAPI {
    async getSoldInvestments(params) {
        try {
            const { data } = await this.client.get('v1/brickInvestments/sold', { params });
            const pagination = new Pagination(data.pagination);
            const investments = data?.items.map(investment => new SoldInvestment(investment));
            return new SoldInvestments({ investments, pagination, count: data.count });
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'PAGE_CANNOT_BE_LESS_THAN_ONE':
                        throw new InvalidQueryParam('page', params.page, { cause: error });
                    case 'PER_PAGE_CANNOT_BE_LESS_THAN_ONE':
                        throw new InvalidQueryParam('perPage', params.perPage, { cause: error });
                    case 'INVALID_PAGE':
                        throw new InvalidQueryParam('page', params.page, { cause: error });
                    case 'INVALID_PER_PAGE':
                        throw new InvalidQueryParam('perPage', params.perPage, { cause: error });
                    default:
                        throw new SoldInvestmentsError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new SoldInvestmentsError('Unexpected error when obtaining sold investments', { cause: error });
            }
            else {
                throw new SoldInvestmentsError(`Unexpected error when obtaining sold investments: '${error}'`);
            }
        }
    }
    async getOnSaleInvestments(params) {
        try {
            const { data } = await this.client.get('v1/brickInvestments/onSale', { params });
            const pagination = new Pagination(data.pagination);
            const investments = data?.items.map(investment => new OnSaleInvestment(investment));
            return new OnSaleInvestments({ investments, pagination, count: data.count });
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'PAGE_CANNOT_BE_LESS_THAN_ONE':
                        throw new InvalidQueryParam('page', params.page, { cause: error });
                    case 'PER_PAGE_CANNOT_BE_LESS_THAN_ONE':
                        throw new InvalidQueryParam('perPage', params.perPage, { cause: error });
                    case 'INVALID_PAGE':
                        throw new InvalidQueryParam('page', params.page, { cause: error });
                    case 'INVALID_PER_PAGE':
                        throw new InvalidQueryParam('perPage', params.perPage, { cause: error });
                    default:
                        throw new OnSaleInvestmentsError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new OnSaleInvestmentsError('Unexpected error when obtaining on sale investments', { cause: error });
            }
            else {
                throw new OnSaleInvestmentsError(`Unexpected error when obtaining on sale investments: '${error}'`);
            }
        }
    }
    async getPortfolioInvestments(params) {
        try {
            const { data } = await this.client.get('v1/brickInvestments', { params });
            const pagination = new Pagination(data.pagination);
            const investments = data?.items.map(investment => new PortfolioInvestment(investment));
            return new PortfolioInvestments({ investments, pagination, count: data.count });
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'VALIDATION_ERROR':
                        throw new InvalidFormatEntry({ cause: error });
                    case 'PAGE_CANNOT_BE_LESS_THAN_ONE':
                        throw new InvalidQueryParam('page', params.page, { cause: error });
                    case 'PER_PAGE_CANNOT_BE_LESS_THAN_ONE':
                        throw new InvalidQueryParam('perPage', params.perPage, { cause: error });
                    case 'INVALID_PAGE':
                        throw new InvalidQueryParam('page', params.page, { cause: error });
                    case 'INVALID_PER_PAGE':
                        throw new InvalidQueryParam('perPage', params.perPage, { cause: error });
                    default:
                        throw new PortfolioInvestmentsError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new PortfolioInvestmentsError('Unexpected error when obtaining portfolio investments', { cause: error });
            }
            else {
                throw new PortfolioInvestmentsError(`Unexpected error when obtaining portfolio investments: '${error}'`);
            }
        }
    }
    async getPortfolioInvestmentsDetailByProperty(propertyId) {
        try {
            const { data } = await this.client.get(`v1/properties/${propertyId}/brickInvestments`);
            return new BricksDetail(data);
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'INVALID_PROPERTY_ID':
                        throw new InvalidPropertyId(propertyId, { cause: error });
                    case 'PROPERTY_NOT_FOUND':
                        throw new PropertyIsNotRegistered(propertyId, { cause: error });
                    case 'BRICKS_INVESTMENTS_NOT_FOUND':
                        throw new BricksInvestmentsNotFound({ cause: error });
                    default:
                        throw new PortfolioInvestmentsError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new PortfolioInvestmentsError('Unexpected error when obtaining portfolio investment detail', { cause: error });
            }
            else {
                throw new PortfolioInvestmentsError(`Unexpected error when obtaining portfolio investment detail: '${error}'`);
            }
        }
    }
}
export default InvestmentsAPI;
