class Antiphishing {
    names;
    firstLastName;
    secondLastName;
    imageUrl;
    phrase;
    constructor(data) {
        this.names = data.names;
        this.firstLastName = data.firstLastName;
        this.secondLastName = data.secondLastName;
        this.imageUrl = data.image;
        this.phrase = data.phrase;
    }
}
export default Antiphishing;
