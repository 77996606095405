import React from 'react';

import {
  ActionMediaContainer,
  ContentMediaContainer,
  MediaContainer,
  TitleMediaContainer
} from '@components';

import { jobTitleViewImage } from '../../../../../assets/images/EnrollmentStepImages';
import { DescriptionText, TitleText } from '../../EnrollmentMediaContainer.style';

const JOB_TITLE_IMAGE_ALT = 'Persona con lupa, contrato y edificio alrededor de él';

const JobTitleMediaContainer = () => (
  <MediaContainer>
    <TitleMediaContainer>
      <TitleText>Disfruta de una experiencia de inversión sin complicaciones</TitleText>
    </TitleMediaContainer>

    <ContentMediaContainer>
      <img src={jobTitleViewImage} alt={JOB_TITLE_IMAGE_ALT} />
    </ContentMediaContainer>

    <ActionMediaContainer>
      <DescriptionText>
        En 100 Ladrillos nos encargamos de todo, no tendrás que lidiar con trámites molestos.
        Administramos el inmueble y cobramos las rentas para depositarlas directamente en tu
        cuenta.
      </DescriptionText>
    </ActionMediaContainer>
  </MediaContainer>
);

export default JobTitleMediaContainer;
