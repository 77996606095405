import { useState } from 'react';
import APIError from '@api/errors/APIError';
import HundredBricksAPIError from '@lib/hundredBricksAPI/HundredBricksAPIError';
import useAuth from '../../lib/authentication/useAuth';
import ErrorWhileRequesting from './ErrorWhileRequesting';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useRequest = (callback) => {
    const auth = useAuth();
    const [requestData, setRequestData] = useState({
        data: undefined,
        isLoading: false,
        error: undefined
    });
    const makeRequest = async (...args) => {
        let data;
        let error;
        setRequestData(prevData => ({ ...prevData, isLoading: true }));
        try {
            data = await callback(...args);
            if (auth.token)
                auth.updateSessionToken();
        }
        catch (requestError) {
            // APIError should be deprecated
            if (requestError instanceof APIError
                || requestError instanceof HundredBricksAPIError) {
                if (requestError.isUnauthorized())
                    auth.clearSession();
                error = requestError;
            }
            else {
                error = new ErrorWhileRequesting(requestError);
            }
        }
        finally {
            setRequestData(prevData => ({
                ...prevData, data, error, isLoading: false
            }));
        }
    };
    return [requestData.data, requestData.isLoading, requestData.error, makeRequest];
};
export default useRequest;
