import { ACCOUNT_LEVEL } from '@constants';
class AccountLevel {
    level;
    frozen;
    constructor(level, frozen) {
        this.level = level ?? ACCOUNT_LEVEL.pending;
        this.frozen = frozen ?? false;
    }
    get name() {
        return this.level;
    }
    isPendingToGetLevel() {
        return this.level === ACCOUNT_LEVEL.pending;
    }
    isBasic() {
        return this.level === ACCOUNT_LEVEL.basic;
    }
    isLimitless() {
        return this.level === ACCOUNT_LEVEL.limitless;
    }
    hasLevel() {
        return this.isBasic() || this.isLimitless();
    }
    isFrozen() {
        return this.frozen;
    }
}
export default AccountLevel;
