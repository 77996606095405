import autoBind from 'auto-bind';

class EventsReporter {
  constructor(insightsService) {
    this._insightsService = insightsService;

    autoBind(this);
  }
}

export default EventsReporter;
