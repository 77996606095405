import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import isEmpty from 'lodash.isempty';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useProfile } from '@lib/profile';
import { Button, Icon, Modal } from '@components';
import { Bold, Paragraph, Subtitle } from '@components/Styled';
import { usePaymentPlans } from '@lib/paymentPlans';
import { ButtonContainer } from './PendingPaymentPlanModal.style';
const PendingPaymentPlanModal = () => {
    const { hasPlans, plans } = usePaymentPlans();
    const { profile } = useProfile();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalRendered, setIsModalRendered] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const pendingPaymentPlanToAccept = plans?.items?.find(plan => plan.isPendingApproval());
    const hasPlansForDisplayModal = hasPlans && !isEmpty(pendingPaymentPlanToAccept);
    const isInMarketplacePath = pathname.includes('/marketplace');
    const handleCloseModal = () => setIsModalOpen(false);
    const handlePaymentPlanSigning = () => {
        setIsModalOpen(false);
        navigate(`/payment-plan/sign-contract/${pendingPaymentPlanToAccept?.id}`);
    };
    useEffect(() => {
        if (hasPlansForDisplayModal && isInMarketplacePath && !isModalRendered) {
            setIsModalOpen(true);
            setIsModalRendered(true);
        }
    }, [pendingPaymentPlanToAccept, isInMarketplacePath]);
    return (_jsxs(Modal, { isOpen: isModalOpen, onClose: handleCloseModal, children: [_jsx(Subtitle, { align: "center", children: `¡Gran noticia ${profile?.name}!` }), _jsx(Icon, { name: "icNewPaymentPlan", alt: "Plan de pago pendiente de firmar", height: 84, width: 84 }), _jsxs(Paragraph, { align: "center", children: ["Tienes acceso a un nuevo plan de pagos en la propiedad", _jsx(Bold, { children: ` ${pendingPaymentPlanToAccept?.crowdfundingProperty.name} ` }), "dise\u00F1ado solo para ti. Este plan te permitir\u00E1 maximizar tu inversi\u00F3n y aprovechar los beneficios exclusivos de una preventa."] }), _jsxs(ButtonContainer, { children: [_jsx(Button, { id: "pendingPaymentPlanModal-skip", onClick: handleCloseModal, color: "secondary", children: "Ver despu\u00E9s" }), _jsx(Button, { id: "pendingPaymentPlanModal-goToSignPaymentPlan", onClick: handlePaymentPlanSigning, children: "Ver detalles" })] })] }));
};
export default PendingPaymentPlanModal;
