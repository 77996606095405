/* eslint-disable no-useless-escape */
const IFC_NAME_REGEX = /^.*((cien|100) *?Ladrillos?).*$/i;
const CONSECUTIVE_CHARACTERS_REGEX = /^.*([a-zñ0-9])\1{3,}.*$/i;
// eslint-disable-next-line max-len
const SEQUENTIAL_CHARACTERS_REGEX = /^.*(abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmnñ|mnño|nñop|ñopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz|0123|1234|2345|3456|4567|5678|6789)+.*$/i;
const SEQUENTIAL_NUMBERS_REGEX = /(0123|1234|2345|3456|4567|5678|6789)/;
const NUMBER_REGEX = /^.*\d{1,}.*/;
const ALLOWED_CHARACTERS_REGEX = /^.*(["!#$%&'()*+,-.\/:;<=>?@\[\]\^_`{\|}~]).*/;

const PASSWORD_MINIMUM_SIZE = 6;

const passwordValidator = (password = '') => {
  const hasLength = password.length > 0;

  const hasNotSequentialChars = !SEQUENTIAL_CHARACTERS_REGEX.test(password)
    && !SEQUENTIAL_NUMBERS_REGEX.test(password);

  return {
    hasMimimumLength: () => password.length >= PASSWORD_MINIMUM_SIZE,
    hasNot100Ladrillos: () => !IFC_NAME_REGEX.test(password) && hasLength,
    hasNotConsecutiveChars: () => !CONSECUTIVE_CHARACTERS_REGEX.test(password) && hasLength,
    hasNotSequentialChars: () => hasNotSequentialChars && hasLength,
    hasAtLeastASpecialCharacter: () => ALLOWED_CHARACTERS_REGEX.test(password),
    hasAtLeastANumber: () => NUMBER_REGEX.test(password),
    hasAtLeastOneLowerCase: () => password.toUpperCase() !== password,
    hasAtLeastOneUpperCase: () => password.toLowerCase() !== password
  };
};

module.exports = passwordValidator;
