/* eslint-disable react/prop-types */
import React from 'react';
import { TextField as BuiTextField } from 'bricks-ui/packages/bricks-ui';

const TextField = props => {
  const { inputProps = {} } = props;

  return (
    <BuiTextField
      {...props}
      inputProps={{
        ...inputProps,
        maxLength: inputProps.maxLength || 50
      }}
    />
  );
};

export default TextField;
