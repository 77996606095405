import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Routes, Route } from 'react-router-dom';
import Movements from './Movements';
import AccountStatement from './AccountStatement';
import AddFunds from './AddFunds';
import MyMovements from './MyMovements';
import WithdrawFunds from './WithdrawFunds';
import WithdrawalRequest from './WithdrawalRequest';
const MovementsRouter = () => (_jsx(Routes, { children: _jsxs(Route, { path: "/", element: _jsx(Movements, {}), children: [_jsx(Route, { index: true, element: _jsx(MyMovements, {}) }), _jsx(Route, { path: "account-statement/:month/:year", element: _jsx(AccountStatement, {}) }), _jsx(Route, { path: "add-funds", element: _jsx(AddFunds, {}) }), _jsx(Route, { path: "withdraw", element: _jsx(WithdrawFunds, {}) }), _jsx(Route, { path: "withdraw/success", element: _jsx(WithdrawalRequest, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { replace: true, to: "/movements" }) })] }) }));
export default MovementsRouter;
