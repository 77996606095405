import HundredBricksAPI from '@lib/hundredBricksAPI/HundredBricksAPI';
import CrowdfundingInvestment from '@api/entities/CrowdfundingInvestment';
import { APIError } from '@lib/api';
import BricksError from './BricksError';
import InvalidInvestorId from './InvalidInvestorId';
import InvestorDoesNotExists from './InvestorDoesNotExists';
import BricksInPreSale from './BricksInPreSale';
import PortfolioBrick from './PortfolioBrick';
class BricksAPI extends HundredBricksAPI {
    async getBricksInPreSale() {
        try {
            const { data } = await this.client.get('/v1/traders/me/investments');
            return new BricksInPreSale(data.map(item => new CrowdfundingInvestment(item)));
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'TRADER_DOES_NOT_EXISTS':
                        throw new InvestorDoesNotExists({ cause: error });
                    case 'INVALID_ID':
                        throw new InvalidInvestorId({ cause: error });
                    default:
                        throw new BricksError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new BricksError('Could not obtain bricks in pre-sale', { cause: error });
            }
            else {
                throw new BricksError(`Unexpected error occurred obtaining bricks in pre-sale: '${error}'`);
            }
        }
    }
    async getPortfolioBricksByProperty(propertyId) {
        try {
            const { data } = await this.client.get(`/v1/traders/me/bricks/properties/${propertyId}`);
            return data.map(brick => new PortfolioBrick(brick));
        }
        catch (error) {
            if (error instanceof APIError) {
                switch (error.code) {
                    case 'TRADER_DOES_NOT_EXISTS':
                        throw new InvestorDoesNotExists({ cause: error });
                    case 'INVALID_ID':
                        throw new InvalidInvestorId({ cause: error });
                    default:
                        throw new BricksError({ cause: error });
                }
            }
            else if (error instanceof Error) {
                throw new BricksError('Unexpected error when obtaining portfolio bricks list', { cause: error });
            }
            else {
                throw new BricksError(`Unexpected error when obtaining portfolio bricks list: '${error}'`);
            }
        }
    }
}
export default BricksAPI;
