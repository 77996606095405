import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icons';
import Button from '../Button';
import RenderIf from '../RenderIf';

import { SiderNavDivider, SiderNavStyled, SiderNavTitle } from './SiderNav.style';

const SiderNav = ({
  children, style, title, subtitle
}) => {
  const [isOpenedMenu, setIsOpenedMenu] = useState(false);

  const toggleMenu = () => setIsOpenedMenu(!isOpenedMenu);
  const hideMenu = () => setIsOpenedMenu(false);

  const renderChildrenWithProps = () => React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        hideMenu
      });
    }

    return child;
  });

  let styleMenu = 'nav-menu-container';

  if (isOpenedMenu) {
    styleMenu += ' active';
  }

  return (
    <SiderNavStyled
      style={style}
      isOpenedMenu={isOpenedMenu}
      hasTitle={Boolean(title)}
      hasSubtitle={Boolean(subtitle)}
    >
      <div className="nav-header">
        <RenderIf condition={Boolean(title)}>
          <SiderNavTitle>{title}</SiderNavTitle>
        </RenderIf>
        <Button
          variant="text"
          className="kebab-menu"
          aria-label="open/close menu"
          onClick={toggleMenu}
        >
          <RenderIf condition={!isOpenedMenu}>
            <Icon.KebabMenuIcon />
          </RenderIf>
          <RenderIf condition={isOpenedMenu}>
            <Icon.CrossIcon small="size" />
          </RenderIf>
        </Button>
      </div>
      <RenderIf condition={Boolean(subtitle)}>
        <div className="nav-subheader">
          <SiderNavDivider />
          <SiderNavTitle level="subtitle1">{subtitle}</SiderNavTitle>
        </div>
      </RenderIf>
      <div className="nav-menu">
        <div className={styleMenu}>
          {renderChildrenWithProps()}
        </div>
      </div>
    </SiderNavStyled>
  );
};

SiderNav.propTypes = {
  /**
 * The content of the component
 */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,

  /**
   * To display navigation title
   */
  title: PropTypes.string,

  /**
   * To display navigation subtitle only on mobile devices
   */
  subtitle: PropTypes.string,

  /**
  * To add custom CSS styles
  */
  style: PropTypes.shape()
};

SiderNav.defaultProps = {
  style: {},
  title: '',
  subtitle: ''
};

export default SiderNav;
