import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';

import { useAPI, useRequest } from '@hooks';
import { Subtitle } from '@components/Styled';
import { useErrorReporter } from '@lib/errorReporter';
import { RenderIf, Icons } from '@components';

import Skeleton from './Skeleton';
import TimedNotifications from './TimedNotifications';
import { EmptyListNotification, NotificationsError } from './templates';
import {
  CloseButton,
  InvisibleWall,
  NotificationPanelCard
} from './NotificationCenter.style';

const NotificationsPanel = ({ onClose }) => {
  const api = useAPI();
  const errorReporter = useErrorReporter();

  const [
    notifications,
    obtainingNotifications,
    notificationsError,
    obtainNotifications
  ] = useRequest(api.notifications.getNotifications);

  const { CrossIcon } = Icons;

  useEffect(() => {
    obtainNotifications();
  }, []);

  useEffect(() => {
    if (notificationsError) {
      errorReporter.critical(notificationsError);
    }
  }, [errorReporter, notificationsError]);

  return (
    <Fragment>
      <InvisibleWall id="notificationsPanel-closePanelWall" onClick={onClose} />

      <NotificationPanelCard id="notificationPanel">
        <CloseButton id="notificationsPanel-closePanelButton" onClick={onClose}>
          <CrossIcon />
        </CloseButton>

        <RenderIf condition={obtainingNotifications}>
          <Subtitle level="2">Mis notificaciones</Subtitle>

          <Skeleton />
          <Skeleton />
        </RenderIf>

        <RenderIf condition={notificationsError}>
          <NotificationsError />
        </RenderIf>

        <RenderIf condition={notifications && isEmpty(notifications?.list)}>
          <EmptyListNotification />
        </RenderIf>

        {notifications && !isEmpty(notifications.list) && (
          <Fragment>
            <Subtitle level="2">Mis notificaciones</Subtitle>

            <TimedNotifications notifications={notifications.list} />
          </Fragment>
        )}
      </NotificationPanelCard>
    </Fragment>
  );
};

NotificationsPanel.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default NotificationsPanel;
