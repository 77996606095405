import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { VisibilityObserver } from '@types';
import { scrollTo } from '@utils';
import './style.scss';

export const SCROLL_OFFSET = 100;

const MenuTab = props => {
  const [activeElement, setActiveElement] = useState('');
  const { tabs = [], className, handleMenuTabClick } = props;

  const scrollToSection = id => {
    const element = document.querySelector(`#${id}`);

    if (element) {
      scrollTo(id, SCROLL_OFFSET);
    }

    setActiveElement(id);
  };

  const onElementVisible = element => {
    setActiveElement(element.id);
  };

  const initializeObserver = () => {
    const observer = new VisibilityObserver(onElementVisible);

    tabs.forEach(tab => {
      const element = document.querySelector(`#${tab.refId}`);

      if (element) {
        observer.observe(element);
      }
    });
  };

  useEffect(() => {
    initializeObserver();
  }, []);

  return (
    <div styleName="menu-tab" className={className}>
      {
        tabs.map(tab => {
          const { label, refId } = tab;
          const handleClick = () => {
            scrollToSection(refId);

            if (handleMenuTabClick) {
              handleMenuTabClick(refId);
            }
          };

          const menuItemStyle = cx({
            'menu-item': true,
            active: activeElement === refId,
          });

          return (
            <button
              tid={refId}
              key={refId}
              type="button"
              styleName={menuItemStyle}
              onClick={handleClick}
            >
              {label}
            </button>
          );
        })
      }
    </div>
  );
};

MenuTab.defaultProps = {
  className: '',
  handleMenuTabClick: null,
};

MenuTab.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node,
    refId: PropTypes.string,
  })).isRequired,
  className: PropTypes.string,
  handleMenuTabClick: PropTypes.func,
};

export default MenuTab;
