import { css } from 'styled-components';

const grid = (gapSize = null) => ({ theme }) => {
  const gap = gapSize ? `gap: ${theme.spacing(gapSize)};` : '';

  return css`
    display: grid;
    ${gap}
  `;
};

export default grid;
