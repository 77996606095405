class VolumeDiscount {
    discountPercentPerBrick;
    pricePerBrick;
    minimumBricks;
    maximumBricks;
    capRate;
    capitalGain;
    constructor(data) {
        this.discountPercentPerBrick = data.discountPercentPerBrick;
        this.pricePerBrick = data.pricePerBrick;
        this.minimumBricks = data.minimumBricks;
        this.maximumBricks = data.maximumBricks;
        this.capRate = data.capRate;
        this.capitalGain = data.capitalGain;
    }
}
export default VolumeDiscount;
